import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withKeys as _withKeys, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "warning"
}
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = { class: "field" }
const _hoisted_5 = { class: "control" }
const _hoisted_6 = { class: "field is-grouped is-justify-content-flex-end" }
const _hoisted_7 = { class: "control" }
const _hoisted_8 = { class: "button is-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_on_async = _resolveDirective("on-async")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { class: "label" }, "New Password", -1)),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "password",
          class: "input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reset.Password) = $event))
        }, null, 512), [
          [_vModelText, _ctx.reset.Password]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { class: "label" }, "Confirm New Password", -1)),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          type: "password",
          class: "input",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reset.ConfirmPassword) = $event)),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["enter"]))
        }, null, 544), [
          [_vModelText, _ctx.reset.ConfirmPassword]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _withDirectives((_openBlock(), _createElementBlock("a", _hoisted_8, _cache[5] || (_cache[5] = [
          _createTextVNode("Reset Password")
        ]))), [
          [_directive_on_async, _ctx.submit]
        ])
      ])
    ])
  ], 64))
}