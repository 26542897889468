import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-career-game hr-tile"
}
const _hoisted_2 = { class: "title is-5 h2" }
const _hoisted_3 = { class: "level-table" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  colspan: "2"
}
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_career_game_level = _resolveComponent("user-career-game-level")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.game.Difficulties, (difficulty) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: difficulty }, [
      (_ctx.hasSubmitted(difficulty.Id))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.game.Name) + " " + _toDisplayString(difficulty.Name) + " - " + _toDisplayString(_ctx.totalPoints(difficulty.Id)) + " points", 1),
            _createElementVNode("table", _hoisted_3, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _cache[0] || (_cache[0] = _createElementVNode("th", null, "Level", -1)),
                  (_ctx.category?.AllowedPartners > 0)
                    ? (_openBlock(), _createElementBlock("th", _hoisted_4, "Players"))
                    : _createCommentVNode("", true),
                  _cache[1] || (_cache[1] = _createElementVNode("th", null, "Time", -1)),
                  _cache[2] || (_cache[2] = _createElementVNode("th", null, null, -1)),
                  (_ctx.editable)
                    ? (_openBlock(), _createElementBlock("th", _hoisted_5, "Points"))
                    : (_openBlock(), _createElementBlock("th", _hoisted_6, "Points"))
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categorySegments, (level) => {
                return (_openBlock(), _createBlock(_component_user_career_game_level, {
                  key: level.Id,
                  game: _ctx.game,
                  category: _ctx.category,
                  difficulty: difficulty,
                  level: level,
                  runs: _ctx.runs,
                  editable: _ctx.editable
                }, null, 8, ["game", "category", "difficulty", "level", "runs", "editable"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}