import { Game, StaticContentService } from '@/api';
import store from "../store/index";

export class GameStore
{
    private static instance: Promise<GameStore>;

    private constructor() {}
    static get() : Promise<GameStore> {
        if(!GameStore.instance) {
            GameStore.instance = GameStore.create();
        }

        return GameStore.instance;
    }

    static refresh() : Promise<GameStore> {
        GameStore.instance = GameStore.create();
        return GameStore.instance;
    }

    private static async create(): Promise<GameStore> {
        // TODO: replace GameStore with central store usage?
        let gs = new GameStore()
        let metadata = await store.state.globals;

        let sorted = metadata?.Games;

        if(sorted == null) {
            sorted = new Array();
        } else {
            sorted = sorted.sort((a,b) => (a?.SortOrder ?? 0) - (b?.SortOrder ?? 0));

            for(let game of sorted) {
                game.Categories = game.Categories?.sort((a,b) => (a?.SortOrder ?? 0) - (b?.SortOrder ?? 0)) ?? [];
                game.RunnableSegments = game.RunnableSegments?.sort((a,b) => (a?.SortOrder ?? 0) - (b?.SortOrder ?? 0)) ?? [];
            }
        }

        gs.games = sorted;
        return gs;
    }

    public games: Game[] = new Array();

    public findGame(gameId: string) {
        return this.games.filter(g => g.Id === gameId)[0];
    }
}