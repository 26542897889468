import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/running.loop.gif'


const _hoisted_1 = {
  key: 0,
  class: "loading-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "loading-container",
          style: _normalizeStyle({animationDuration: _ctx.animationDuration + 's', animationTimingFunction: 'linear'})
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("img", { src: _imports_0 }, null, -1)
        ]), 4)
      ]))
    : _createCommentVNode("", true)
}