import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "submission-grid"
}
const _hoisted_2 = { class: "has-text-right submit-row" }
const _hoisted_3 = { class: "level-right" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_run_input = _resolveComponent("run-input")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.category)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "submission-header-row" }, [
          _createElementVNode("div", { class: "submission-cell header" }),
          _createElementVNode("div", { class: "submission-cell header time-input is-hidden-mobile" }, " New Times "),
          _createElementVNode("div", { class: "submission-cell header is-hidden-mobile" }, "Video")
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.base.submissions, (submission) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "submission-entry",
            key: submission.runnableSegmentId ?? 'empty'
          }, [
            _createVNode(_component_run_input, {
              context: submission,
              career: _ctx.base.career,
              recordData: _ctx.base.recordData,
              onChange: ($event: any) => (_ctx.base.dirty(submission))
            }, null, 8, ["context", "career", "recordData", "onChange"])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              class: "level-item subtitle mb-0 mr-4 is-6",
              to: {name: 'Game Calculators', params: _ctx.$route.params}
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Time Calculator")
              ])),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("button", {
              type: "button",
              class: "button is-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.base.submit && _ctx.base.submit(...args))),
              disabled: !_ctx.base.liveSubmissionCount
            }, "Submit " + _toDisplayString(_ctx.base.liveSubmissionCount) + " Run(s)", 9, _hoisted_4)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}