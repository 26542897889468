<template>
    <div class="submission-grid" v-if="category">
        <div class="submission-header-row">
            <div class="submission-cell header">
                
            </div>
            <div class="submission-cell header time-input is-hidden-mobile">
                New Times
            </div>
            <div class="submission-cell header is-hidden-mobile">Video</div>
        </div>

        <div class="submission-entry"  v-for="submission in base.submissions" :key="submission.runnableSegmentId ?? 'empty'">
            <run-input :context="submission" :career="base.career" :recordData="base.recordData" @change="base.dirty(submission)" />
        </div>
        
        <div class="has-text-right submit-row">
            <div class="level-right">
                <router-link class="level-item subtitle mb-0 mr-4 is-6" :to="{name: 'Game Calculators', params: $route.params}">Time Calculator</router-link>
                <button type="button" class="button is-primary" @click="base.submit" :disabled="!base.liveSubmissionCount">Submit {{base.liveSubmissionCount}} Run(s)</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import RunInput from "@/components/Input/RunInput.vue";
import Typeahead from '@/components/Input/Typeahead.vue';
import { Category, Difficulty, Game } from "@/api";
import SubmitBase, { SubmitHost } from "./SubmitBase";

@Component({
    components: {
        RunInput,
        Typeahead
    },
})
export default class Submit extends Vue implements SubmitHost {
    submitKind = "FullSubmitForm";

    @Prop()
    game: Game | null = null;

    @Prop()
    category: Category | null = null;

    @Prop()
    difficulty: Difficulty | null = null;

    base : SubmitBase = null!;

    public created() {
        this.base = new SubmitBase(this, this.$store, this.$router, this.$loading, this.$confirm, this.$emit, this.$toast, this.$filters);
    }

    public async mounted() {
        await this.base.mounted(this.submitKind);
    }

    @Watch("game")
    @Watch("category")
    @Watch("difficulty")
    public async initialize() {
        await this.base.initialize();
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.submission-grid {
    @include hr-tile-full-bleed;

    .select {
        margin: 0;
    }

    .level-name {
        margin-bottom: -0.4rem;
        letter-spacing: 0.05rem;
        font-size: 1.1rem;
    }

    .time-comparisons {
        margin-bottom: 0.6rem;
        display: inline-block;
    }

    .submission-header-row {
        display: grid;
        grid-template-columns: 2fr repeat(2, 1fr);
        border-bottom: 2px solid $border-color;
        font-family: "Oswald", sans-serif;

        .submission-cell {
            
            small {
                font-size: 0.7rem;
                color: #999;
            }
            
            &.header {
                text-transform: uppercase;
                letter-spacing: 0.15rem;
                padding-bottom: 15px;
            }
        }
    }

    .submission-entry {
        padding: 12px 25px 10px 25px;
        border-bottom: 2px solid $border-color;

        .submission-entry-row {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
            row-gap: 0.5rem;
            column-gap: 0.5rem;

            @media screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
            }

            .submission-cell {
                
                small {
                    font-size: 0.7rem;
                    color: #999;
                }
            }
        }
    }
    
    .warning-text {
        color: $primary;
    }

    .submit-row {
        margin-top: 15px;
        margin-right: 15px;
    }
}

</style>