<template>
    <div class="tabs">
        <ul>
            <li :class="{'is-active': $route.name == 'Profile'}"><router-link :to="{name: 'Profile'}">Career</router-link></li>
            <li :class="{'is-active': $route.name == 'History'}"><router-link :to="{name: 'History'}">History</router-link></li>
            <li :class="{'is-active': $route.name == 'Recent'}" v-if="isOwnProfile"><router-link :to="{name: 'Recent'}">Recent</router-link></li>
            <li :class="{'is-active': $route.name == 'UserSettings'}" v-if="isOwnProfile"><router-link :to="{name: 'UserSettings'}">Settings</router-link></li>
        </ul>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { Prop } from "vue-facing-decorator";

@Component
export default class UserProfileTabs extends Vue
{
    
    @Prop()
    public isOwnProfile: boolean = false;
}

</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

div.tabs {
    @include hr-tile-full-bleed;

    ul {
        padding-left: 15px;
        border-color: $border-color;

        li {
            border-color: $border-color;

            a {
                border-color: $border-color;
            }
        }
    }
}
</style>