<template>
    <div class="selector hr-tile">
        <ul class="breadcrumbs">
            <li class="breadcrumb-item">
                <router-link :to="'/'">Home</router-link>
            </li>
            <li class="breadcrumb-item">
                <span>Leaderboards</span>
            </li>
            <leaderboard-key-selector v-if="leaderboardKey" :selectionContainer="leaderboardKey" @update="leaderboardKey = $event; replaceRoute()" :showDifficulty="$store.state.windowWidth <= 1215"></leaderboard-key-selector>
        </ul>
    </div>
    <div class="hr-tile">
        <div>
            <small><router-link :to="{name: 'Leaderboards', params: $route.params}">&lt; Back to Leaderboard</router-link></small>
        </div>
        <div class="graph">
            <run-graph :items="history" class="graph-container" />
        </div>
    </div>
    <div class="leaderboards" v-if="game"  v-masonry:[$store.state.windowWidth]="$store.state.windowWidth <= 1215 ? 1 : 2">
        <template v-if="$store.state.windowWidth <= 1215"> <!-- Desktop CSS breakpoint -->
            <div >
                <div class="hr-tile">
                    <h4 class="title is-4">{{getDifficultyName(leaderboardKey.Difficulty)}} History</h4>
                    <RecordHistoryBoard :history="history[getDifficultyName(leaderboardKey.Difficulty)]"></RecordHistoryBoard>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-for="diff in category.Difficulties" :key="diff">
                <div class="hr-tile">
                    <h4 class="title is-4">{{getDifficultyName(diff)}} History</h4>
                    <RecordHistoryBoard :history="history[getDifficultyName(diff)]"></RecordHistoryBoard>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import RecordHistoryBoard from "@/components/Boards/RecordHistoryBoard.vue";
import LeaderboardKeySelector from "@/components/Boards/LeaderboardKeySelector.vue";
import { GameStore } from "@/store/games";
import { Category, Game, Leaderboard, LeaderboardEntry, LeaderboardFilter, LeaderboardHistory, LeaderboardHistoryEntry, LeaderboardKey, StaticContentService } from "@/api";
import RunGraph from "@/components/RunGraph.vue";
import { RouteParamValueRaw } from "vue-router";
import { Watch } from "vue-facing-decorator";
import Masonry from "@/directives/Masonry";

@Component({
    components: {
        LeaderboardKeySelector,
        RecordHistoryBoard,
        RunGraph
    },
    directives: {
        Masonry
    }
})
export default class RecordHistory extends Vue {
    leaderboardKey: LeaderboardKey | null = null;
    games: Game[] = new Array();
    runs: Record<string, LeaderboardEntry[]> = {};
    availableFilters: LeaderboardFilter[] = new Array();
    filters: Record<string, string> = {};
    game: Game | undefined | null = null;
    history: Record<string, any[]> = {};
    category: Category | undefined | null = null; 

    async created() {
        this.games = (await GameStore.get()).games;

        let gameStub = this.getParam("game");
        let categoryStub = this.getParam("category");
        let levelStub = this.getParam("level");
        let difficulty = this.getParam("difficulty");

        let game = this.games.find(g => g.UrlStub == gameStub);
        if(game?.Categories && game.RunnableSegments && game.Difficulties) {
            let key = {} as LeaderboardKey;
            key.GameId = game.Id;
            
            let cat = game.Categories.find(c => c.UrlStub == categoryStub);
            if(cat) {
                key.CategoryId = cat.Id;

                let level = game.RunnableSegments.find(s => s.UrlStub == levelStub);
                if(level) {
                    key.RunnableSegmentId = level.Id;
                }

                let diff = game.Difficulties.find(s => s.Name == difficulty);
                if(diff) {
                    key.Difficulty = diff.Id;
                } else {
                    key.Difficulty = game.Difficulties[0].Id;
                }
            }

            this.leaderboardKey = key;
        } else {
            this.leaderboardKey = {};
        }
    }

    getDifficultyName(difId: string) : string {
        return this.game?.Difficulties?.find(d => d.Id == difId)?.Name!;
    }

    getParam(name: string) : string | null {
        let val = this.$route.params[name];
        if(typeof val == "string") {
            return val;
        } else if (Array.isArray(val)) {
            return val[0];
        } else { 
            return null;
        }
    }

    @Watch("leaderboardKey", { immediate: true, deep: true})
    async load() {
        let key = this.leaderboardKey;
        if(key?.GameId == null|| key.CategoryId == null || key.RunnableSegmentId == null) return;

        this.game = this.games.find(g => g.Id == key!.GameId);
        this.category = this.game?.Categories?.find(c => c.Id == key?.CategoryId);

        this.runs = {} as Record<string, LeaderboardEntry[]>;
        this.filters = {} as Record<string, string>;
        this.history = {};
        this.availableFilters = new Array();

        let difficulties = this.game?.Difficulties ?? [];

        let leaderBoardAndHistoryTasks = difficulties.flatMap(diff => {
            if(key?.GameId == null|| key.CategoryId == null || key.RunnableSegmentId == null) return [];
            let leaderboard = StaticContentService.leaderboard(key.GameId, key.CategoryId, key.RunnableSegmentId, diff.Id!).catch(h => null);
            let history = StaticContentService.leaderboardHistory(key.GameId, key.CategoryId, key.RunnableSegmentId, diff.Id!).catch(h => null);
            return [leaderboard, history];
        }).filter(t => t) as Promise<any>[];

        let completed = await Promise.all(leaderBoardAndHistoryTasks);

        let i = 0;
        for(let diff of difficulties) {
            let leaderboard = completed[i++] as Leaderboard | null;
            let history = completed[i++] as LeaderboardHistory | null;

            this.runs[diff.Name!] = leaderboard?.Entries ?? [];
            this.history[diff.Name!] = history?.Entries ?? [];

            for(let filter of leaderboard?.Filters ?? []) {
                if(filter.Values!.filter(v => v === null).length == 0) {
                    filter.Values!.unshift(null!);
                }
            }

            for(let filter of leaderboard?.Filters ?? []) {
                this.filters[filter.Name!] = filter.DefaultValue!;

                if(!this.availableFilters.find(f => f.Name == filter.Name))
                    this.availableFilters.push(filter);
            }
        }

        this.replaceRoute();
    }

    public replaceRoute() {
        if(!this.leaderboardKey) return;

        let game = this.games.find(g => g.Id == this.leaderboardKey!.GameId);

        if(!game?.Categories || !game?.RunnableSegments) return;

        let cat = game.Categories.find(c => c.Id == this.leaderboardKey?.CategoryId);
        let level = game.RunnableSegments.find(c => c.Id == this.leaderboardKey?.RunnableSegmentId);
        let diff = game.Difficulties?.find(c => c.Id == this.leaderboardKey?.Difficulty);

        this.$router.replace({name: this.$route.matched[0].name, params: {
            game: game.UrlStub as RouteParamValueRaw,
            category: cat?.UrlStub as RouteParamValueRaw,
            level: level?.UrlStub as RouteParamValueRaw,
            difficulty: diff?.Name as RouteParamValueRaw,
        }})
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.graph {
    height: 360px;
    position: relative;

    .graph-container {
        height: 100%;
        position: relative;
    }
}

.filters {

    .select {
        margin: 0;
        background-color: $body-background-color;
        height: 3.2rem;
        text-align: left;
        position: relative;
        overflow: hidden;
        text-align: left;
        border-left: 1px solid $border-color;

        label {
            color: #fff;
            font-size: 0.8rem;
            width: 100%;
            display: inline-block;
            position:relative;
            padding:0 2.5rem;
            z-index: 4;
            text-transform: uppercase;
            font-family: sans-serif;
            pointer-events: none;
            user-select: none;
        }

        &:before {
            content: '\f0b0';
            position: absolute;
            left: 0.7rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: 4;
            font-family: 'Font Awesome 5 Free';
            color: #fff;
            pointer-events: none;
        }

        select {
            position: relative;
            top: -1.2rem;
            padding-left: 2.5rem;
            padding-top: 1.2rem;
            background: $body-background-color;
            height: 3rem;
            border-color: transparent;
            text-align: left;
            width: 100%;
        }
    }

}
</style>