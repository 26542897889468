<template>
<div class="user-career">
    
    <template v-if="!career">
        <div class="hr-tile">
            <user-profile-tabs :is-own-profile="isOwnProfile"></user-profile-tabs>
            <div class="headline">
                <h4 class="title is-4">User's Run Career</h4>
                <ContentLoader width="50" height="45" primary-color="#1f2a3c" secondary-color="#2c3b55">
                    <rect x="0" y="5" rx="2" ry="2" :width="(30 + Math.random() * 20)" height="35" />
                </ContentLoader>
            </div>
            <div v-for="i in 4" :key="i">
                <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                    <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                </ContentLoader>
            </div>
        </div>

        <div class="columns is-multiline">
            <template v-for="i in 2" :key="i">
                <div class="column is-half-desktop is-full-tablet">
                    <div class="user-career-game hr-tile">
                        <ContentLoader width="170" height="30" primary-color="#1f2a3c" secondary-color="#2c3b55">
                            <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="25" />
                        </ContentLoader>
                        <table class="level-table">
                            <thead>
                                <tr>
                                    <th>Level</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="j in 12" :key="j">
                                    <td>
                                        <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                                            <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                                        </ContentLoader>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </div>
    </template>
    <template v-else>
        <div class="hr-tile toggle-button-parent">
            <user-profile-tabs :is-own-profile="isOwnProfile"></user-profile-tabs>
            <div class="headline">
                <h4 class="title is-4">{{user?.Username ?? 'User'}}'s Run Career</h4>
                <div class="filters field is-horizontal">
                    <div class="select">
                        <select v-model="chosenCategory">
                            <option v-for="category in catOrder" :key="category">{{category}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div><h6 class="subtitle is-6 is-inline">Total Points: </h6>{{totalPoints}}</div>
            <div v-for="(info, cat) in career.RankInfoByCategory" :key="cat">
                <h6 class="subtitle is-6 is-inline">{{cat}} Points: </h6>{{info.Points}}
            </div>
            <div><h6 class="subtitle is-6 is-inline">Total Trophies: </h6>{{totalTrophies}}</div>
            <div v-for="(info, cat) in career.RankInfoByCategory" :key="cat">
                <template v-if="info.GoldCount + info.SilverCount + info.BronzeCount">
                <h6 class="subtitle is-6 is-inline">{{cat}} Trophies: </h6> 
                <span v-if="info.GoldCount" class="trophy-container"><i class="trophy in-line rank-1"></i> &times; {{info.GoldCount}} </span>
                <span v-if="info.SilverCount" class="trophy-container"><i class="trophy in-line rank-2"></i> &times; {{info.SilverCount}} </span>
                <span v-if="info.BronzeCount" class="trophy-container"><i class="trophy in-line rank-3"></i> &times; {{info.BronzeCount}} </span>
                </template>
            </div>
        </div>

        <div v-masonry:[$route]="$store.state.windowWidth <= 1215 ? 1 : 2">
            <template v-for="game in categoryGames" :key="game.Id">
                <user-career-game :runs="career.RunsByCategory[chosenCategory]" :categoryName="chosenCategory" :game="game" :editable="canEditRuns" :submittedOnly="true"></user-career-game>
            </template>
        </div>
    </template>
</div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { Prop, Watch } from "vue-facing-decorator";
import UserCareerGame from "@/components/User/UserCareerGame.vue";
import { GameStore } from "@/store/games";
import { Game, Claim, UserCareer as UserCareerDto, UserProfile } from "@/api";
import OnAsync from "@/directives/OnAsync";
import Masonry from "@/directives/Masonry";
import { useToast } from "vue-toastification";
import UserProfileTabs from "@/components/User/UserProfileTabs.vue";
import { ContentLoader } from "vue-content-loader";

@Component({
    components: {
        UserCareerGame,
        UserProfileTabs,
        ContentLoader
    },
    directives: {
        OnAsync,
        Masonry
    }
})
export default class UserCareer extends Vue {
    chosenCategory: string | null = null;
    games: Game[] = new Array();
    toast = useToast();
    levelSearch: string | null = null;
    canEditRuns = false;

    @Prop()
    public user: UserProfile | null = null;

    @Prop()
    public career: UserCareerDto | null = null;

    @Prop()
    public isOwnProfile: boolean = false;

    get catOrder() {
        return Object.keys(this.career?.RunsByCategory!).sort((a, b) => {  
            if (a == "Solo") return -1;
            if (b == "Solo") return 1;
            if (a == "Coop") return -1;
            if (b == "Coop") return 1;
            return 0;
        });
    }

    async mounted() {
        this.games = (await GameStore.get()).games.filter(g => !g.Hidden);

        await this.load();
    }

    @Watch("career")
    public async watchCareer() {
        await this.load();
    }

    async load() {
        var hrClaims = this.$store.state.auth.hrClaimStrings;

        this.canEditRuns = (this.$store.state.auth.claims.userId == this.user?.UserId && hrClaims.indexOf(Claim.SELF_READ_WRITE) > 0)
            || hrClaims.indexOf(Claim.MODIFY_RUNS_GLOBAL) > 0
            || hrClaims.indexOf(Claim.MODIFY_RUNS_LIMITED) > 0;

        if(this.career?.RunsByCategory == null) return;

        this.chosenCategory = this.catOrder[0];
    }

    get totalPoints() {
        if(this.career?.RankInfoByCategory == null) return;

        return Object.values(this.career.RankInfoByCategory).reduce((a,r) => a + (r?.Points! || 0), 0)
    }

    get totalTrophies() {
        if(this.career?.RankInfoByCategory == null) return;

        return Object.values(this.career.RankInfoByCategory).reduce((a,r) => a + (r?.GoldCount! || 0) + (r?.SilverCount! || 0) + (r?.BronzeCount! || 0), 0)
    }

    get categoryGames() {
        return this.games.filter(g => g.Categories && g.Categories.filter(c => c.Name == this.chosenCategory).length > 0);
    }
}
</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.games-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: dense;
}

.filters {
    align-items: baseline;

    .input.search {
        height: 2.5em;
        border: none;
        border-bottom: 2px solid #1f2a3c;
        background: transparent;
        transform: translateY(1px);

        &:hover, &:active, &:focus {
            border-color: $primary;
        }
    }
}


    .trophy-container {
        margin-right: 1rem;
    }




.toggle-button-parent {
    position: relative;
}


.view-toggle-button {
    position: absolute;
    top: 10px;
    right: 10px;
}

.headline {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 0.5rem;

    .title {
        margin-bottom: 0;
    }
}

</style>