<template>
    <div class="stream-feed">
        <div v-if="feed">
            <div class="feed-entry" v-for="entry in feed.Entries" :key="entry.RunId">
                <div class="images">
                    <a v-bind:href="entry.StreamUrl" target="_blank" rel="noopener noreferrer"><img
                        class="profile-image"
                        v-bind:src="entry.ProfileImageUrl.replace('300x300', '70x70')"
                        width="40"
                        height="40"
                    /></a>
                </div>
                <div>
                    <span class="stream-user"><a v-bind:href="entry.StreamUrl" target="_blank" rel="noopener noreferrer">{{entry.Username}}</a> - <small>{{entry.Viewers}} viewers</small></span>
                    <small class="stream-title">{{entry.Title}}</small>
                    <br>
                    <small>{{uptime(entry.StartedAt)}}</small>
                    <div v-if="canModify">
                        <a @click="removeFromList(entry)" v-if="entry.UserId && entry.toggled == undefined">Toggle stream visibility for {{entry.Username}}</a>
                        <span v-if="entry.toggled">Visibilty toggled, will update on next refresh</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!feed || feed.Entries == 0">
            <p>No streams to show!</p>
        </div>
        <p class="is-size-7 has-text-grey-light" v-if="feed && $store.state.devMode">Updated {{feed.UpdatedAt}}</p>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { Claim, ProfilesService, StaticContentService, StreamListEntry, StreamListFeed } from "@/api";
import AuthStore from "@/store/auth";


@Component({})
export default class StreamFeed extends Vue {
    private static shouldUseInitialLoad = true;

    private refreshInterval : number | undefined = undefined;
    public feed: StreamListFeed | null = null;
    public canModify: boolean = false;
    public skippedRefresh: boolean = false;

    //Make uptime readable
    uptime(timestamp: string) {
        var time = new Date(Date.now() - new Date(timestamp).getTime()).toISOString();
        var hours = parseInt(time.substr(11,2));
        var minutes = parseInt(time.substr(14,2));
        var seconds = parseInt(time.substr(17,2));

        var timecode = "Streaming for " + seconds + " second";

        if (seconds > 1) {
            timecode = "Streaming for " + seconds + " seconds";
        }

        if (minutes > 0) {
            timecode = "Streaming for " + minutes + " minute";
            if (minutes > 1) {
                timecode = timecode + "s";
            }
        }

        if (hours > 0) {
            timecode = "Streaming for " + hours + " hour";
            if (hours > 1) {
                timecode = timecode + "s";
            }
            
            if (minutes > 0) {
                timecode = timecode + " and " + minutes + " minute";
                if (minutes > 1) {
                    timecode = timecode + "s";
                }
            }
        }

        return timecode;
    }

    async created() {
        this.refresh();
    }

    async mounted() {
        this.refreshInterval = setInterval(() => this.refresh(), 1000 * 60);
        try { await AuthStore.pendingAuth; } catch {}
        this.canModify = AuthStore.hasClaim(Claim.MODIFY_STREAM_LIST);

        document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
    }

    beforeUnmount() {
        clearInterval(this.refreshInterval);
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    async handleVisibilityChange() {
        if (document.visibilityState !== "hidden" && this.skippedRefresh) {
            this.refresh();
        }
    }

    async refresh() {
        if(StreamFeed.shouldUseInitialLoad) {
            StreamFeed.shouldUseInitialLoad = false;
            let globals = await this.$store.state.globals;
            if(globals?.StreamFeed?.Entries) {
                this.feed = globals.StreamFeed;
                this.feed.Entries = globals.StreamFeed.Entries.sort((a,b) => (b.Viewers || 0) - (a.Viewers || 0));
                return;
            }
        }

        if (document.visibilityState === "hidden") {
            // We'll skip pulling new info if the tab isn't active, and signal that we've done so for when we come back
            this.skippedRefresh = true;
            return;
        }

        this.skippedRefresh = false;

        let feed = await StaticContentService.streamList();

        if(feed.Entries)
        {
            feed.Entries = feed.Entries.sort((a,b) => (b.Viewers || 0) - (a.Viewers || 0));
        }

        this.feed = feed;
    }

    public async removeFromList(entry: StreamListEntry) {
        await ProfilesService.toggleStreamVisibility(entry.UserId!);

        (entry as any).toggled = true;
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.feed-entry {
    display: flex;
    margin-bottom: 0.75rem;

    .images {
        display: inline-block;
        width: 50px;
        height: 50px;
        position: relative;
        flex-shrink: 0;
        margin-right: 15px;

        .profile-image {
            border-radius: 50%;
            margin: 5px;
            z-index: 3;
        }
    }

    .stream-user {
        font-size: 1.1rem;
        display: block;
    }

    .stream-title {
        font-size: 0.9rem;
        color: #ccc;
    }
}
</style>
