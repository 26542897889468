import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "hr-tile" }
const _hoisted_2 = { class: "breadcrumbs" }
const _hoisted_3 = { class: "breadcrumb-item" }
const _hoisted_4 = { class: "rules hr-tile greeble-tr" }
const _hoisted_5 = { class: "columns is-multiline" }
const _hoisted_6 = { class: "column is-one-fifth is-full-mobile content" }
const _hoisted_7 = { class: "list" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "column is-three-quarters is-full-mobile content" }
const _hoisted_10 = ["id"]
const _hoisted_11 = { class: "title is-4" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "collapsable-title" }
const _hoisted_15 = { class: "title is-5" }
const _hoisted_16 = { class: "extras-container" }
const _hoisted_17 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _directive_collapsible = _resolveDirective("collapsible")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: '/' }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("li", { class: "breadcrumb-item" }, [
          _createElementVNode("span", null, "Rules")
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "title is-4" }, "Game Rules", -1)),
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
              return (_openBlock(), _createElementBlock("li", {
                key: game.Id
              }, [
                _createElementVNode("a", {
                  href: '#'+game.UrlStub
                }, _toDisplayString(game.Name), 9, _hoisted_8)
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "title is-4" }, "Site Rules", -1)),
          _createVNode(_component_Markdown, {
            source: _ctx.siteRules,
            class: "rule-section"
          }, null, 8, ["source"])
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "rules hr-tile",
        key: game.Id,
        id: game.UrlStub
      }, [
        _createElementVNode("h4", _hoisted_11, _toDisplayString(game.Name) + " Rules", 1),
        (game.Rules)
          ? (_openBlock(), _createBlock(_component_Markdown, {
              key: 0,
              source: game.Rules,
              class: "rule-section",
              breaks: true
            }, null, 8, ["source"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(game.Categories, (cat) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: cat.Id
          }, [
            (cat.Rules && !cat.IsExtension)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("h1", null, _toDisplayString(cat.Name) + " Specific", 1),
                  _createVNode(_component_Markdown, {
                    source: cat.Rules,
                    class: "rule-section",
                    breaks: true
                  }, null, 8, ["source"])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        (game.Categories.find(c => c.IsExtension))
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _withDirectives((_openBlock(), _createElementBlock("h5", _hoisted_15, _cache[4] || (_cache[4] = [
                  _createTextVNode("Extras Rules")
                ]))), [
                  [_directive_collapsible, void 0, "parent"]
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("h6", { class: "subtitle is-7 mb-0" }, "(Click to expand)", -1))
              ]),
              _createElementVNode("div", _hoisted_16, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(game.Categories, (cat) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: cat.Id
                  }, [
                    (cat.Rules && cat.IsExtension)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createElementVNode("h1", null, _toDisplayString(cat.Name) + " Specific", 1),
                          _createVNode(_component_Markdown, {
                            source: cat.Rules,
                            class: "rule-section",
                            breaks: true
                          }, null, 8, ["source"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_10))
    }), 128))
  ], 64))
}