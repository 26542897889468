import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "column is-two-thirds" }
const _hoisted_3 = { class: "hr-tile" }
const _hoisted_4 = { class: "breadcrumbs" }
const _hoisted_5 = { class: "breadcrumb-item" }
const _hoisted_6 = { class: "hr-tile greeble-tr featured-records" }
const _hoisted_7 = { class: "filters" }
const _hoisted_8 = { class: "title is-4" }
const _hoisted_9 = { class: "select" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "column is-one-third" }
const _hoisted_12 = { class: "hr-tile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ExtensionRecordView = _resolveComponent("ExtensionRecordView")!
  const _component_RunFeed = _resolveComponent("RunFeed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createVNode(_component_router_link, { to: '/' }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Home")
              ])),
              _: 1
            })
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("li", { class: "breadcrumb-item" }, [
            _createElementVNode("span", null, "Extras")
          ], -1))
        ])
      ]),
      _createElementVNode("section", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.recordBoardTitle), 1),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.gameId) = $event))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: game.Id,
                  value: game.Id
                }, _toDisplayString(game.Name), 9, _hoisted_10))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.gameId]
            ])
          ])
        ]),
        _createVNode(_component_ExtensionRecordView, { gameId: _ctx.gameId }, null, 8, ["gameId"])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("section", _hoisted_12, [
        _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "title is-5" }, "Latest Extra Records", -1)),
        (_ctx.recordsFeed)
          ? (_openBlock(), _createBlock(_component_RunFeed, {
              key: 0,
              feed: _ctx.recordsFeed.ExtraEntries
            }, null, 8, ["feed"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}