<template>
<div>
    <div class="field is-horizontal">
        <label class="label field-label is-normal">Video</label>
        <div class="control field-body">
            <VideoLinkInput v-model="context.evidenceLinks[0]" placeholder="Video link" :required="context.requiresEvidence && !context.editingDuration && !context.evidenceLinks[0]" @input="onChange()" :class="{'is-danger': context.requiresEvidence && !context.editingDuration && !validUrl(context.evidenceLinks[0])}" ></VideoLinkInput>
        </div>
    </div>

    <template v-if="category">
        <div  v-for="filter in category.Filters" :key="filter.Id" class="field is-horizontal">
            <label class="label field-label is-normal">{{filter.Name}}</label>
            <div class="control field-body">
                <div class="select">
                    <select v-model="context.filterValues[filter.Id]">
                        <option :value="undefined" v-if="filter.Required == false"></option>
                        <option v-for="val in filter.Values" :key="val.Id" :value="val.Id">{{val.Name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </template>

    <div class="field is-horizontal" v-for="(partner, i) in context.partners" :key="i">
        <label class="label field-label is-normal">
            Partner {{i+1}}
            <div class="run-input-cell partner-remove-field" v-if="i+1 > context.requiredPartners">
                <button type="button" class="button is-ghost" @click="removePartner(partner)"><i class="fas fa-times-circle"></i></button>
            </div>
        </label>
        <div class="control field-body partners-field-body">
            <div class="run-input-cell partner-field">
                <typeahead :api="userTypeaheadApi" :display-transform="m => m.Username" placeholder="Find partner" v-model="context.partners[i]" :model-transform="m => { return {id: m.UserId, username: m.Username }}" :bindTransform="m => m.username" :required="true"></typeahead>
            </div>
            <div class="run-input-cell additional-evidence-field">
                <input class="input" type="text" size="10" placeholder="Partner video link" v-model="context.evidenceLinks[i+1]"  :required="context.requiresEvidence && !context.editingDuration && !context.evidenceLinks[i+1]" @change="onChange()" :class="{'is-danger': context.requiresEvidence && !context.editingDuration && !validUrl(context.evidenceLinks[i+1])}" />
            </div>
            
        </div>
    </div>

    <div class="field is-horizontal" v-if="context.partners.length < context.allowedPartners">
        <label class="label field-label is-normal"></label>
        <div class="control field-body">
            <button type="button" class="button is-ghost" @click="context.partners.push({}); onChange();">Add Partner</button>
        </div>
    </div>
</div>
</template>

<script lang="ts">


import { GameStore } from "@/store/games";
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import Typeahead from '@/components/Input/Typeahead.vue';
import VideoLinkInput from "@/components/Integrations/VideoLinkInput.vue";
import { Category, Game, RunnableSegment, UserCareer, ProfilesService, RecordBoardEntry } from "@/api";
import UrlService from "@/services/UrlService";
import RunInputService, { RunInputContext, RunInputContextPartner } from "@/services/RunInputService";


@Component({
    components: {
        Typeahead,
        VideoLinkInput
    },
})
export default class RunInput extends Vue {
    @Prop()
    career: UserCareer | null = null;

    @Prop()
    recordData: { [runnableSegmentId: string]: { [difficulty: string]: RecordBoardEntry[] } } | null = null;

    @Prop()
    context: RunInputContext = <RunInputContext>{};

    games: Game[] = new Array();
    game: Game | null = null;
    category: Category | null = null;

    userTypeaheadApi = ProfilesService.findUsers;

    async mounted(){
        await this.initialize();
    }

    @Watch("context")
    async initialize() {
        this.games = (await GameStore.get()).games;
        this.game = this.games.find(g => g.Id == this.context?.gameId) ?? null;
        this.category = this.game?.Categories?.find(c => c.Id == this.context?.categoryId) ?? null;
    }

    public removePartner(partner: RunInputContextPartner) {
        RunInputService.removePartner(this.context, partner);
    }

    get categoryLevels(): {[props: string]: RunnableSegment} {
        if(this.category == null) return {};

        var initial = {} as {[props: string]: RunnableSegment};

        return this.game?.RunnableSegments!
            .filter(l => this.category!.AllowedSegments!.indexOf(l.Id!) >= 0)
            .reduce ((a: any, c) => { a[c.Id!] = c; return a; }, initial);
    }

    public validUrl(val: string | null) {
        return UrlService.isValidUrl(val);
    }

    public onChange() {
        this.$emit("change", { run: this.context });
    }
}


</script>

<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.partners-field-body {
    flex-direction: column;
}

.partner-remove-field {
    width: 100%;
    button {
        width: 100%;
        height: 1.6rem;
    }
}

.field-body {
    flex-grow: 4;

    .select {
        width: 100%;
        margin: 0;

        select {
            width: 100%;
        }
    }
}

.field-label {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 1rem;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .field-label {
        flex-direction: row;
        align-items: flex-start;
        justify-content: left;
    }

    .partner-remove-field {
        width: 2rem;
        button {
            width: 2rem;
            height: 1.6rem;
        }
    }
}
</style>