import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["width"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 1,
  class: "timecode"
}
const _hoisted_8 = {
  key: 2,
  class: "has-text-grey-light"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 1,
  class: "run-cards"
}
const _hoisted_14 = ["width"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "run-details" }
const _hoisted_17 = ["href"]
const _hoisted_18 = {
  key: 1,
  class: "timecode"
}
const _hoisted_19 = {
  key: 2,
  class: "has-text-grey-light"
}
const _hoisted_20 = { class: "run-details" }
const _hoisted_21 = { class: "runners" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "timestamp" }
const _hoisted_26 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.$store.state.isMobileSmall)
    ? (_openBlock(), _createElementBlock("table", _hoisted_1, [
        _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "Game"),
            _createElementVNode("th", null, "Time"),
            _createElementVNode("th", null, "Runner"),
            _createElementVNode("th", null, "Date")
          ])
        ], -1)),
        (!_ctx.boardData)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_2, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                return _createElementVNode("tr", { key: i }, [
                  _createElementVNode("td", null, [
                    _createVNode(_component_ContentLoader, {
                      width: "170",
                      height: "18",
                      "primary-color": "#1f2a3c",
                      "secondary-color": "#2c3b55"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("rect", {
                          x: "0",
                          y: "5",
                          rx: "2",
                          ry: "2",
                          width: (150 + Math.random() * 20),
                          height: "10"
                        }, null, 8, _hoisted_3)
                      ]),
                      _: 1
                    })
                  ]),
                  _cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1)),
                  _cache[1] || (_cache[1] = _createElementVNode("td", null, null, -1)),
                  _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1))
                ])
              }), 64))
            ]))
          : _createCommentVNode("", true),
        (_ctx.boardData)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_4, [
              (_ctx.boardData.length == 0)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_5, _cache[3] || (_cache[3] = [
                    _createElementVNode("td", {
                      colspan: "999",
                      class: "has-text-centered"
                    }, [
                      _createElementVNode("p", null, "There aren't any extra categories for this game yet!")
                    ], -1)
                  ])))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.boardData, (entry) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: entry.Id
                }, [
                  _createElementVNode("td", null, [
                    _createVNode(_component_router_link, {
                      to: _ctx.getLink(entry)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(entry.name) + " ", 1),
                        _createElementVNode("small", null, " - " + _toDisplayString(entry.difficulty), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _createElementVNode("td", null, [
                    (entry.records && entry.records.length > 0 && entry.records[0].Participants && entry.records[0].Participants.length && entry.records[0].Participants[0].EvidenceLink)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: entry.records[0].Participants[0].EvidenceLink,
                          class: "timecode"
                        }, _toDisplayString(_ctx.$filters.durationDisplay(entry.records[0].Duration)), 9, _hoisted_6))
                      : (entry.records.length > 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$filters.durationDisplay(entry.records[0].Duration)), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_8, "No times"))
                  ]),
                  _createElementVNode("td", null, [
                    (entry.records.length > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entry.records[0].Participants, (p, i) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: p.Username
                            }, [
                              _createVNode(_component_router_link, {
                                to: '/profiles/' + p.Username
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(p.Username), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"]),
                              (entry.records[0].Participants.length > 0 && i < entry.records[0].Participants.length-1)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_10, ", "))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (entry.records.length > 1)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_11, " + " + _toDisplayString(entry.records.length - 1) + " more", 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, [
                    (entry.records.length > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.formatDate(entry.records[0].OccurredAt)), 1))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_13, [
        (!_ctx.boardData)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(5, (i) => {
              return _createElementVNode("div", {
                class: "run-card",
                key: i
              }, [
                _createVNode(_component_ContentLoader, {
                  width: "170",
                  height: "18",
                  "primary-color": "#1f2a3c",
                  "secondary-color": "#2c3b55"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("rect", {
                      x: "0",
                      y: "5",
                      rx: "2",
                      ry: "2",
                      width: (150 + Math.random() * 20),
                      height: "10"
                    }, null, 8, _hoisted_14)
                  ]),
                  _: 1
                })
              ])
            }), 64))
          : _createCommentVNode("", true),
        (_ctx.boardData)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.boardData.length == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[5] || (_cache[5] = [
                    _createElementVNode("div", { class: "has-text-centered run-card" }, [
                      _createElementVNode("p", null, "There aren't any extra categories for this game yet!")
                    ], -1)
                  ])))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.boardData, (entry) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: entry.Id,
                  class: "run-card"
                }, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_router_link, {
                      to: _ctx.getLink(entry),
                      class: "has-text-weight-bold"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(entry.name) + " - " + _toDisplayString(entry.difficulty), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createElementVNode("div", null, [
                      (entry.records && entry.records.length > 0 && entry.records[0].Participants && entry.records[0].Participants.length && entry.records[0].Participants[0].EvidenceLink)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: entry.records[0].Participants[0].EvidenceLink,
                            class: "timecode"
                          }, _toDisplayString(_ctx.$filters.durationDisplay(entry.records[0].Duration)), 9, _hoisted_17))
                        : (entry.records.length > 0 && !entry.records[0].Participants.length)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$filters.durationDisplay(entry.records[0].Duration)), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_19, "No times"))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      (entry.records.length > 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_22, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entry.records[0].Participants, (p, i) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: p.Username
                              }, [
                                _createVNode(_component_router_link, {
                                  to: '/profiles/' + p.Username
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(p.Username), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"]),
                                (entry.records[0].Participants.length > 0 && i < entry.records[0].Participants.length-1)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_23, ", "))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true),
                      (entry.records.length > 1)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_24, " + " + _toDisplayString(entry.records.length - 1) + " more", 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      (entry.records.length > 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.formatDate(entry.records[0].OccurredAt)), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 128))
            ], 64))
          : _createCommentVNode("", true)
      ]))
}