import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "user-history" }
const _hoisted_2 = { class: "hr-tile" }
const _hoisted_3 = { class: "title is-4" }
const _hoisted_4 = {
  key: 0,
  class: "button is-primary"
}
const _hoisted_5 = { class: "graph" }
const _hoisted_6 = {
  key: 0,
  class: "columns is-multiline"
}
const _hoisted_7 = { class: "user-career-game hr-tile" }
const _hoisted_8 = { class: "title is-5" }
const _hoisted_9 = { class: "level-table" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_run_graph = _resolveComponent("run-graph")!
  const _component_ContentLoader = _resolveComponent("ContentLoader")!
  const _directive_on_async = _resolveDirective("on-async")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$route.params.username) + "'s " + _toDisplayString(_ctx.game?.Name) + " " + _toDisplayString(_ctx.segment?.Name) + " History - " + _toDisplayString(_ctx.category?.Name), 1),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        (_ctx.$store.state.devMode)
          ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_4, _cache[0] || (_cache[0] = [
              _createTextVNode("Rebuild history")
            ]))), [
              [_directive_on_async, _ctx.rebuildHistory]
            ])
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_run_graph, {
            class: "graph-container",
            items: _ctx.runsByDifficulty
          }, null, 8, ["items"])
        ])
      ]),
      (_ctx.game)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.game.Difficulties, (difficulty) => {
              return (_openBlock(), _createElementBlock("div", {
                key: difficulty.Id,
                class: "column is-half-desktop is-full-tablet"
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.game?.Name) + " " + _toDisplayString(_ctx.category?.Name) + " " + _toDisplayString(_ctx.segment?.Name) + " - " + _toDisplayString(difficulty?.Name), 1),
                  _createElementVNode("table", _hoisted_9, [
                    _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "Date"),
                        _createElementVNode("th", null, "Time")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runsByDifficulty[difficulty.Name!], (run) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: run.RunId
                        }, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(run.OccurredAt)), 1),
                          _createElementVNode("td", null, [
                            (run.Participants[0]?.EvidenceLink)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                  href: run.Participants[0]?.EvidenceLink
                                }, _toDisplayString(_ctx.$filters.durationDisplay(run.Duration)), 9, _hoisted_10))
                              : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$filters.durationDisplay(run.Duration)), 1))
                          ])
                        ]))
                      }), 128))
                    ]),
                    (!_ctx.history)
                      ? (_openBlock(), _createElementBlock("tbody", _hoisted_12, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(8, (i) => {
                            return _createElementVNode("tr", { key: i }, [
                              _createElementVNode("td", null, [
                                _createVNode(_component_ContentLoader, {
                                  width: "170",
                                  height: "18",
                                  "primary-color": "#1f2a3c",
                                  "secondary-color": "#2c3b55"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("rect", {
                                      x: "0",
                                      y: "5",
                                      rx: "2",
                                      ry: "2",
                                      width: (150 + Math.random() * 20),
                                      height: "10"
                                    }, null, 8, _hoisted_13)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1))
                            ])
                          }), 64))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}