import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "run-board" }
const _hoisted_2 = {
  key: 0,
  class: "leaderboard-table"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { colspan: "999" }
const _hoisted_6 = ["width"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "run-info" }
const _hoisted_11 = { class: "ranks" }
const _hoisted_12 = {
  key: 1,
  class: "rank"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "timecode" }
const _hoisted_15 = ["href"]
const _hoisted_16 = {
  key: 1,
  class: "timecode"
}
const _hoisted_17 = ["href", "title"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = {
  key: 0,
  class: "run-filters"
}
const _hoisted_20 = ["colspan"]
const _hoisted_21 = {
  key: 0,
  class: "filter-value"
}
const _hoisted_22 = {
  key: 1,
  class: "run-cards"
}
const _hoisted_23 = { class: "run-info" }
const _hoisted_24 = ["width"]
const _hoisted_25 = {
  key: 1,
  class: "run-card"
}
const _hoisted_26 = {
  key: 0,
  class: "run-card"
}
const _hoisted_27 = { class: "run-info" }
const _hoisted_28 = { class: "headline" }
const _hoisted_29 = { class: "ranks" }
const _hoisted_30 = {
  key: 1,
  class: "rank"
}
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { class: "time-info" }
const _hoisted_33 = ["href"]
const _hoisted_34 = {
  key: 1,
  class: "timecode"
}
const _hoisted_35 = ["href", "title"]
const _hoisted_36 = { class: "timestamp subtitle is-6" }
const _hoisted_37 = { class: "run-info" }
const _hoisted_38 = {
  key: 0,
  class: "filter-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.$store.state.isMobileSmall)
      ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _cache[0] || (_cache[0] = _createElementVNode("th", { class: "ranks" }, "Rank", -1)),
              _cache[1] || (_cache[1] = _createElementVNode("th", null, "Runner", -1)),
              _cache[2] || (_cache[2] = _createElementVNode("th", null, "Time", -1)),
              _cache[3] || (_cache[3] = _createElementVNode("th", null, "Date", -1)),
              _cache[4] || (_cache[4] = _createElementVNode("th", null, "Points", -1)),
              (_ctx.hasPlatformFilter)
                ? (_openBlock(), _createElementBlock("th", _hoisted_3, "Platform"))
                : _createCommentVNode("", true)
            ])
          ]),
          (!_ctx.runs)
            ? (_openBlock(), _createElementBlock("tbody", _hoisted_4, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
                  return _createElementVNode("tr", { key: i }, [
                    _createElementVNode("td", _hoisted_5, [
                      _createVNode(_component_ContentLoader, {
                        width: "170",
                        height: "18",
                        "primary-color": "#1f2a3c",
                        "secondary-color": "#2c3b55"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("rect", {
                            x: "0",
                            y: "5",
                            rx: "2",
                            ry: "2",
                            width: (150 + Math.random() * 20),
                            height: "10"
                          }, null, 8, _hoisted_6)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                }), 64))
              ]))
            : (_ctx.runs.length == 0)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_7, _cache[5] || (_cache[5] = [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", { colspan: "999" }, " No runs to show yet! ")
                  ], -1)
                ])))
              : (_ctx.filteredEntries)
                ? (_openBlock(), _createElementBlock("tbody", _hoisted_8, [
                    (_ctx.filteredEntries.length == 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_9, _cache[6] || (_cache[6] = [
                          _createElementVNode("td", { colspan: "999" }, " No runs match the specified filter(s) ", -1)
                        ])))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredEntries, (entry) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: entry.RunId
                      }, [
                        _createElementVNode("tr", _hoisted_10, [
                          _createElementVNode("td", _hoisted_11, [
                            (entry.Rank <= 3)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: _normalizeClass(["trophy", 'rank-' + entry.Rank])
                                }, null, 2))
                              : _createCommentVNode("", true),
                            (entry.Rank > 3)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(entry.Rank), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("td", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entry.Participants, (p, i) => {
                              return (_openBlock(), _createElementBlock("span", { key: p }, [
                                _createVNode(_component_router_link, {
                                  to: '/profiles/' + p.Username
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(p.Username), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"]),
                                (entry.Participants.length > 0 && i < entry.Participants.length-1)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, ", "))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ]),
                          _createElementVNode("td", _hoisted_14, [
                            (entry.Participants[0].EvidenceLink)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                  href: entry.Participants[0].EvidenceLink,
                                  class: "timecode"
                                }, _toDisplayString(_ctx.$filters.durationDisplay(entry.Duration)), 9, _hoisted_15))
                              : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$filters.durationDisplay(entry.Duration)), 1)),
                            (entry.Participants.length > 1)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(entry.Participants.slice(1), (partner) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, {
                                    key: partner.Username
                                  }, [
                                    (partner.EvidenceLink)
                                      ? (_openBlock(), _createElementBlock("a", {
                                          key: 0,
                                          target: "_blank",
                                          rel: "noopener noreferrer",
                                          href: partner.EvidenceLink,
                                          title: partner.Username,
                                          class: "partner-evidence"
                                        }, _cache[7] || (_cache[7] = [
                                          _createElementVNode("i", { class: "fas fa-video" }, null, -1)
                                        ]), 8, _hoisted_17))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                }), 128))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(entry.OccurredAt)), 1),
                          _createElementVNode("td", null, _toDisplayString(entry.Points), 1),
                          (_ctx.hasPlatformFilter)
                            ? (_openBlock(), _createElementBlock("td", _hoisted_18, _toDisplayString(entry.FilterValues["Platform"]), 1))
                            : _createCommentVNode("", true)
                        ]),
                        (_ctx.extraFilters)
                          ? (_openBlock(), _createElementBlock("tr", _hoisted_19, [
                              _createElementVNode("td", {
                                colspan: _ctx.hasPlatformFilter ? 6 : 5
                              }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extraFilters, (filterValue, filter) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, { key: filter }, [
                                    (entry.FilterValues[filter])
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(filter) + ": " + _toDisplayString(entry.FilterValues[filter]), 1))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                }), 128))
                              ], 8, _hoisted_20)
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_22, [
          (!_ctx.runs)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(10, (i) => {
                return _createElementVNode("div", {
                  class: "run-card",
                  key: i
                }, [
                  _createElementVNode("div", _hoisted_23, [
                    _createVNode(_component_ContentLoader, {
                      class: "headline",
                      width: "170",
                      height: "18",
                      "primary-color": "#1f2a3c",
                      "secondary-color": "#2c3b55"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("rect", {
                          x: "0",
                          y: "5",
                          rx: "2",
                          ry: "2",
                          width: (150 + Math.random() * 20),
                          height: "10"
                        }, null, 8, _hoisted_24)
                      ]),
                      _: 1
                    })
                  ])
                ])
              }), 64))
            : (_ctx.runs.length == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[8] || (_cache[8] = [
                  _createElementVNode("div", { class: "run-info" }, [
                    _createElementVNode("div", { class: "headline" }, " No runs to show yet! ")
                  ], -1)
                ])))
              : (_ctx.filteredEntries)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    (_ctx.filteredEntries.length == 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, _cache[9] || (_cache[9] = [
                          _createElementVNode("div", { class: "run-info" }, [
                            _createElementVNode("div", { class: "headline" }, " No runs match the specified filter(s) ")
                          ], -1)
                        ])))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredEntries, (entry) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "run-card",
                        key: entry.RunId
                      }, [
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("div", _hoisted_29, [
                              (entry.Rank <= 3)
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: _normalizeClass(["trophy", 'rank-' + entry.Rank])
                                  }, null, 2))
                                : _createCommentVNode("", true),
                              (entry.Rank > 3)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(entry.Rank), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entry.Participants, (p, j) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: p.Username
                                }, [
                                  _createVNode(_component_router_link, {
                                    to: '/profiles/' + p.Username
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(p.Username), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to"]),
                                  (j != (entry.Participants.length - 1))
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_31, ", "))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128)),
                              _createElementVNode("div", null, [
                                _createTextVNode(_toDisplayString(entry.Points) + " ", 1),
                                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "subtitle is-6" }, "points", -1))
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_32, [
                            (entry.Participants[0].EvidenceLink)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  href: entry.Participants[0].EvidenceLink,
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                  class: "timecode"
                                }, _toDisplayString(_ctx.$filters.durationDisplay(entry.Duration)), 9, _hoisted_33))
                              : (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_ctx.$filters.durationDisplay(entry.Duration)), 1)),
                            (entry.Participants.length > 1)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(entry.Participants.slice(1), (partner) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, {
                                    key: partner.Username
                                  }, [
                                    (partner.EvidenceLink)
                                      ? (_openBlock(), _createElementBlock("a", {
                                          key: 0,
                                          href: partner.EvidenceLink,
                                          title: partner.Username,
                                          target: "_blank",
                                          rel: "noopener noreferrer",
                                          class: "partner-evidence"
                                        }, _cache[11] || (_cache[11] = [
                                          _createElementVNode("i", { class: "fas fa-video" }, null, -1)
                                        ]), 8, _hoisted_35))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                }), 128))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.formatDate(entry.OccurredAt)), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("div", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filterValue, filter) => {
                              return (_openBlock(), _createElementBlock(_Fragment, { key: filter }, [
                                (entry.FilterValues[filter])
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(filter) + ": " + _toDisplayString(entry.FilterValues[filter]), 1))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          ])
                        ])
                      ]))
                    }), 128))
                  ], 64))
                : _createCommentVNode("", true)
        ]))
  ]))
}