<template>
    <Authenticating></Authenticating>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator"
import Authenticating from "@/components/Authenticating.vue"
import { IntegrationsService } from "@/api";


@Component({
    components: {Authenticating}
})
export default class YoutubeAuth extends Vue {
    async created() {
        let params = new URLSearchParams(this.$route.hash.substring(1));
        let token = params.get("access_token");

        if(token && typeof token == "string") {
            
            try {
                await IntegrationsService.linkYoutube({ 
                        UserAccessToken: token
                    });
                    
                this.$toast.success("Linked your YouTube account!");
            } catch {
                this.$toast.error("Failed to link YouTube account, please try again");
            }
        } else {
            this.$toast.error("Unable to link YouTube account, unexpected data from YouTube - likely a site bug");
        }

        setTimeout(() => this.$router.push({name: 'User Profile', params: {'username': this.$store.state.auth.username}}), 1000);
    }
}
</script>