import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "country-designation-selector" }
const _hoisted_2 = {
  key: 0,
  class: "select"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_country_flag = _resolveComponent("country-flag")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_country_flag, {
      country: _ctx.chosen.alpha3 ?? 'null',
      size: "normal"
    }, null, 8, ["country"]),
    (_ctx.editing)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chosen) = $event)),
            disabled: _ctx.saving
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                key: option.numeric,
                value: option
              }, _toDisplayString(option.country), 9, _hoisted_4))
            }), 128))
          ], 8, _hoisted_3), [
            [_vModelSelect, _ctx.chosen]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("i", {
      class: _normalizeClass(["clickable fas", {'fa-pencil-alt':_ctx.editable, 'fa-save': _ctx.savable, 'fa-spinner': _ctx.saving, 'fa-check': _ctx.saved}]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.transition && _ctx.transition(...args))),
      onKeydown: [
        _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.transition && _ctx.transition(...args)), ["enter"])),
        _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.transition && _ctx.transition(...args)), ["space"]))
      ],
      title: "Choose country flag",
      role: "button",
      tabindex: "0"
    }, null, 34)
  ]))
}