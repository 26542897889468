<template>
    <div class="profile-info">
        <div class="hr-tile greeble-tr">
            <div class="is-multiline">
                <div class="column">
                    <h2 class="title is-4">
                        {{ profileUsername ?? $route.params.username }}
                        <template v-if="profile && profile.IsBanned">(Banned)</template>
                        <template v-if="profile?.CountryDesignation">
                            <country-flag :country="profile.CountryDesignation" size="normal" :title="flagInfo" />
                        </template>
                    </h2>
                    <div>
                        <strong class="muted is-size-7 pt-0">{{registrationDate}} ({{ getLastSeen() }})</strong>
                    </div>
                </div>
                <div class="column" v-if="profile">
                
                <integration-profile service="Twitch" :link="linkTwitch" :unlink="unlinkTwitch" :editable="false" :hasValue="!!profile.TwitchInfo"
                    :avatarUrl="profile.TwitchInfo?.Avatar" 
                    :profileUrl="'https://twitch.tv/' + profile.TwitchInfo?.Username" 
                    :profileName="profile.TwitchInfo?.Username" />
                    
                <integration-profile service="YouTube" :link="linkYoutube" :unlink="unlinkYoutube" :editable="false" :hasValue="!!profile.Youtube"
                    :avatarUrl="profile.Youtube?.Avatar" 
                    :profileUrl="profile.Youtube?.ChannelUrl" 
                    :profileName="profile.Youtube?.ChannelTitle" />

                <integration-profile service="Discord" :link="linkDiscord" :unlink="unlinkDiscord" :editable="false" :hasValue="!!profile.DiscordInfo"
                    :avatarUrl="'https://cdn.discordapp.com/avatars/' + profile.DiscordInfo?.UserID + '/' + profile.DiscordInfo?.Avatar" 
                    :profileUrl="'https://discordapp.com/users/' + profile.DiscordInfo?.UserID" 
                    :profileName="profile.DiscordInfo?.Username + '#' + profile.DiscordInfo?.Discriminator" />

                <integration-profile service="Xbox" :link="linkXbox" :unlink="unlinkXbox" :editable="false" :hasValue="!!profile.XboxInfo"
                    :avatarUrl="profile.XboxInfo?.Avatar" 
                    :profileUrl="'https://account.xbox.com/en-us/profile?gamertag='+profile.XboxInfo?.Gamertag" 
                    :profileName="profile.XboxInfo?.Gamertag" />

                <integration-profile service="Steam" :link="linkSteam" :unlink="unlinkSteam" :editable="false" :hasValue="!!profile.SteamInfo"
                    :avatarUrl="profile.SteamInfo?.Avatar" 
                    :profileUrl="profile.SteamInfo?.ProfileUrl" 
                    :profileName="profile.SteamInfo?.PersonaName" />
                </div>
            </div>

            <div v-if="games && career">
                <div><h6 class="subtitle is-6 is-inline">Main Category Points: </h6>{{mainPoints}}</div>
                <div><h6 class="subtitle is-6 is-inline">Main Category Trophies: </h6>{{mainTrophies}}</div>

                <div v-for="cat in mainCats" :key="cat">
                    <template v-if="career.RankInfoByCategory[cat]">
                    <h6 class="subtitle is-6 is-inline">{{cat}}: </h6>{{career.RankInfoByCategory[cat].Points}}<small class="muted">pts</small> &nbsp;
                    <template v-for="info in [career.RankInfoByCategory[cat]]" :key="info">
                        <template v-if="info.GoldCount + info.SilverCount + info.BronzeCount">
                        <span v-if="info.GoldCount" class="trophy-container"><i class="trophy in-line rank-1"></i> &times; {{info.GoldCount}} </span>
                        <span v-if="info.SilverCount" class="trophy-container"><i class="trophy in-line rank-2"></i> &times; {{info.SilverCount}} </span>
                        <span v-if="info.BronzeCount" class="trophy-container"><i class="trophy in-line rank-3"></i> &times; {{info.BronzeCount}} </span>
                        </template>
                    </template>
                    </template>
                </div>
                <h6 class="subtitle is-6 is-inline">Extras: </h6>{{extraPoints}}<small class="muted">pts</small> &nbsp;
                <template v-for="info in [extraTrophies]" :key="info">
                <template v-if="info.GoldCount + info.SilverCount + info.BronzeCount">
                    <span v-if="info.GoldCount" class="trophy-container"><i class="trophy in-line rank-1"></i> &times; {{info.GoldCount}} </span>
                    <span v-if="info.SilverCount" class="trophy-container"><i class="trophy in-line rank-2"></i> &times; {{info.SilverCount}} </span>
                    <span v-if="info.BronzeCount" class="trophy-container"><i class="trophy in-line rank-3"></i> &times; {{info.BronzeCount}} </span>
                </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { Category, Game, IntegrationsService, ProfilesService, UserCareer, UserProfile } from "@/api";
import Configuration from "@/config";
import AuthStore from "@/store/auth";
import OnAsync from "@/directives/OnAsync";
import FocusToggle from "@/directives/FocusToggle";
import { ContentLoader } from "vue-content-loader";
import CountryFlag from "vue-country-flag-next";
import CountryDesignationSelector from "./CountryDesignationSelector.vue";
import IntegrationProfile from "./IntegrationProfile.vue";
import countries from "@/assets/countries";
import { GameStore } from "@/store/games";
import ProfileActions from "./ProfileActions.vue";

TimeAgo.addDefaultLocale(en);

@Component({
    components: {
        ContentLoader,
        CountryFlag,
        CountryDesignationSelector,
        IntegrationProfile,
        ProfileActions
    },
    directives: {
        OnAsync,
        FocusToggle,
    },
})
export default class ProfileInfo extends Vue {
    @Prop()
    public selectedProfile: UserProfile | null = null;
    @Prop()
    readonly career: UserCareer | null = null;
    @Prop()
    readonly ownProfile: boolean = false;
    @Prop()
    readonly profileUsername: string | null = null;

    public username: string = "UnknownUser";
    public profile: UserProfile | null = null;
    public streamIsVisible = false;
    public games: Game[] = new Array();
    public mainCats: string[] = new Array();

    // TODO get from backend via global config
    public validationStreakRequirement = 5;

    async created() {
        this.games = (await GameStore.get()).games;
        this.mainCats = [...new Set(this.games.flatMap(g => g.Categories!).filter(c => c!.IsExtension == false).map(c => c.Name!))];
    }


    mounted() {
        this.profile = this.selectedProfile;
        this.streamIsVisible = this.profile?.StreamIsVisible ?? false;
    }

    @Watch("selectedProfile")
    updateProfile() {
        this.profile = this.selectedProfile;
        this.streamIsVisible = this.profile?.StreamIsVisible ?? false;
    }

    getLastSeen() {
        var lastSeen = this.selectedProfile?.LastSeen;
        if (lastSeen) {
            if (lastSeen > Date.now() - 300000) {
                return "Online Now";
            } else {
                const timeAgo = new TimeAgo("en-us");
                return "Last seen " + timeAgo.format(new Date(lastSeen));
            }
        }
    }

    async reloadProfile() {
        if (!this.ownProfile) return;

        await AuthStore.pendingAuth;

        this.profile = await ProfilesService.getUserProfile(this.$store.state.auth.claims.userId!);
        this.streamIsVisible = this.profile?.StreamIsVisible ?? false;
    }

    async rebuildCareer() {
        let rebuildEvent = {
            Event: "UserCareerUpdateEvent",
            Payload: {
                UserId: this.profile?.UserId,
            },
        };

        //await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
    }

    async rebuildProfile() {
        let rebuildEvent = {
            Event: "UserProfileUpdateEvent",
            Payload: {
                UserId: this.profile?.UserId,
            },
        };

        //await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
    }

    async linkDiscord() {
        window.location.href =
            "https://discord.com/api/oauth2/authorize?client_id=" +
            Configuration.DiscordClientID +
            "&redirect_uri=" +
            Configuration.RedirectURIBase +
            "/integrate/discord&response_type=code&scope=identify";
    }

    async unlinkDiscord() {
        await IntegrationsService.unlinkDiscord();
        await this.reloadProfile();
    }

    async linkTwitch() {
        window.location.href =
            "https://id.twitch.tv/oauth2/authorize?client_id=" +
            Configuration.TwitchClientID +
            "&redirect_uri=" +
            Configuration.RedirectURIBase +
            "/integrate/twitch&response_type=code";
    }

    async unlinkTwitch() {
        await IntegrationsService.unlinkTwitch();
        await this.reloadProfile();
    }

    async linkXbox() {
        let page = "https://login.live.com/oauth20_authorize.srf";

        this.synthesizeAndSubmitForm(
            page,
            {
                client_id: Configuration.XboxClientID,
                redirect_uri: Configuration.RedirectURIBase + "/integrate/xbox",
                scope: "Xboxlive.signin Xboxlive.offline_access",
                response_type: "code",
                approval_prompt: "auto",
            },
            "get"
        );
    }

    async unlinkXbox() {
        await IntegrationsService.unlinkXbox();
        await this.reloadProfile();
    }

    async linkSteam() {
        let page = "https://steamcommunity.com/openid/login";

        this.synthesizeAndSubmitForm(page, {
            "openid.identity": "http://specs.openid.net/auth/2.0/identifier_select",
            "openid.claimed_id": "http://specs.openid.net/auth/2.0/identifier_select",
            "openid.ns": "http://specs.openid.net/auth/2.0",
            "openid.mode": "checkid_setup",
            "openid.realm": Configuration.RedirectURIBase,
            "openid.return_to": Configuration.RedirectURIBase + "/integrate/steam",
        });
    }

    async unlinkSteam() {
        await IntegrationsService.unlinkSteam();
        await this.reloadProfile();
    }

    async linkYoutube() {
        // only enable for dev mode for testing
        if(!this.$store.state.devMode)
            return;

        let page = "https://accounts.google.com/o/oauth2/v2/auth";

        this.synthesizeAndSubmitForm(
            page,
            {
                client_id: Configuration.YoutubeClientID,
                redirect_uri: Configuration.RedirectURIBase + "/integrate/youtube",
                scope: "https://www.googleapis.com/auth/youtube.readonly",
                include_granted_scopes: "true",
                response_type: "token",
            },
            "get"
        );
    }

    async unlinkYoutube() {
        await IntegrationsService.unlinkYoutube();
        await this.reloadProfile();
    }

    public togglingStreamVisibility = false;
    async toggleStreamVisibility(e: MouseEvent) {
        e.preventDefault();

        if (this.togglingStreamVisibility) return;
        if (!this.profile?.UserId) return;

        this.togglingStreamVisibility = true;

        try {
            await ProfilesService.toggleStreamVisibility(this.profile.UserId);
            await this.reloadProfile();
        } finally {
            this.togglingStreamVisibility = false;
        }
    }

    synthesizeAndSubmitForm(path: string, params: any, method = "post") {
        const form = document.createElement("form");
        form.method = method;
        form.action = path;

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement("input");
                hiddenField.type = "hidden";
                hiddenField.name = key;
                hiddenField.value = params[key];

                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    }

    get flagInfo() {
      return `Flag of ${countries.all().find(c => c.alpha3 == this.profile?.CountryDesignation)?.country}`;
    }

    get mainPoints() {
        if(this.career?.RankInfoByCategory == null) return;

        const mainCats = new Set(this.games.flatMap(g => g.Categories).filter(c => !c?.IsExtension).map(c => c?.Name!));

        return [...mainCats].reduce((a,cat) => a + (this.career?.RankInfoByCategory![cat]?.Points || 0), 0)
    }

    get mainTrophies() {
        if(this.career?.RankInfoByCategory == null) return;

        const mainCats = new Set(this.games.flatMap(g => g.Categories).filter(c => !c?.IsExtension).map(c => c?.Name!));


        return [...mainCats].reduce((a,cat) => {
          if(!this.career?.RankInfoByCategory) return a;
          if(!this.career?.RankInfoByCategory?.hasOwnProperty(cat)) return a;

          a += this.career?.RankInfoByCategory![cat].GoldCount || 0;
          a += this.career?.RankInfoByCategory![cat].SilverCount || 0;
          a += this.career?.RankInfoByCategory![cat].BronzeCount || 0;
          return a;
        }, 0);

    }

    get extraPoints() {
        if(this.career?.RankInfoByCategory == null) return;

        const extraCats = new Set(this.games.flatMap(g => g.Categories).filter(c => c?.IsExtension).map(c => c?.Name!));

        return [...extraCats].reduce((a,cat) => a + (this.career?.RankInfoByCategory![cat]?.Points || 0), 0)
    }

    get extraTrophies() {
        if(this.career?.RankInfoByCategory == null) return;

        const extraCats = new Set(this.games.flatMap(g => g.Categories).filter(c => c?.IsExtension).map(c => c?.Name!));

        return [...extraCats].reduce((a,cat) => {
          if(!this.career?.RankInfoByCategory) return a;
          if(!this.career?.RankInfoByCategory?.hasOwnProperty(cat)) return a;

          a.GoldCount += this.career?.RankInfoByCategory![cat].GoldCount || 0;
          a.SilverCount += this.career?.RankInfoByCategory![cat].SilverCount || 0;
          a.BronzeCount += this.career?.RankInfoByCategory![cat].BronzeCount || 0;
          return a;
        }, {
          GoldCount: 0,
          SilverCount: 0,
          BronzeCount: 0
        });
    }

    get registrationDate() {
        if(this.profile?.RegistrationDate) {
            return `Registered ${new Date(this.profile.RegistrationDate).toLocaleDateString()}`;
        }

        return "";
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.is-whole-flex {
    flex: 0 0 100%;
    
}

.is-end-justified-tablet {
    justify-content: flex-end;
    display: flex;

    @media screen and (max-width: 768px) {
        justify-content: flex-start;
        display: flex;
    }
}

.flag {
    font-size: 1.1rem;
}

.profile-info {
    position: relative;
}

.trophy-container {
    margin-right: 1rem;
}


.headline {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 0.5rem;

    .horizontal-titles {
        .title,
        .subtitle {
            margin-bottom: 0;
            display: inline-block;
        }
        .title {
            margin-right: 1rem;
        }
    }
}

div {
    text-align: left;
}

input[type="text"] {
    width: 250px;
}


.own-profile-actions {
    margin-left: 2rem;

    .dropdown-content {
        border-radius: 0;
    }

    button {
        border: none;
        cursor: pointer;
        background-color: $grey-darker;

        &:hover {
            background-color: $grey-light;
        }
    }
}

.title {
    margin-bottom:0;
}
</style>