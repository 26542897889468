<template>
    <table v-if="!$store.state.isMobileSmall">
        <thead>
            <tr>
                <th>Game</th>
                <th>Time</th>
                <th>Runner</th>
                <th>Date</th>
            </tr>
        </thead>
        <tbody v-if="!rows">
            <tr v-for="i in 16" :key="i">
                <td>
                    <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                        <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                    </ContentLoader>
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
        <tbody v-for="group in groupedRows">
            <tr v-for="entry in group.vals" :key="entry.id" :style="{background: group.bg}">
                <td>
                    <router-link :to="entry.leaderboard">{{entry.name}} <small> - {{entry.difficulty}}</small></router-link>
                </td>
                <td>
                  <a target="_blank" rel="noopener noreferrer"
                        v-if="entry.records && entry.records.length > 0 && entry.records[0].Participants && entry.records[0].Participants.length && entry.records[0].Participants[0].EvidenceLink"
                        :href="entry.records[0].Participants[0].EvidenceLink"
                        class="timecode"
                    >
                        {{$filters.durationDisplay(entry.records[0].Duration)}}
                    </a>
                    <span v-if="entry.records.length > 0 && !entry.records[0].Participants.length" class="timecode">
                        {{$filters.durationDisplay(entry.records[0].Duration)}}
                    </span>
                </td>
                <td>
                    <span v-if="entry.records.length > 0">
                        <span v-for="(p,i) in entry.records[0].Participants" :key="p.Username">
                            <router-link :to="'/profiles/' + p.Username">{{ p.Username }}</router-link><span v-if="entry.records[0].Participants.length > 0 && i < entry.records[0].Participants.length-1">, </span>
                        </span>
                    </span>
                    <small v-if="entry.records.length > 1"> + {{entry.records.length - 1}} more</small>
                </td>
                <td><span v-if="entry.records.length > 0">{{formatDate(entry.records[0].OccurredAt)}}</span></td>
            </tr>
        </tbody>
    </table>
    <div v-else class="run-cards">
        <template v-if="!rows">
            <div v-for="i in 16" :key="i" class="run-card">
                <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                    <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                </ContentLoader>
            </div>
        </template>
        
        <template v-if="rows">
            <div v-for="entry in rows" v-bind:key="entry.id" class="run-card">
                <div class="run-details">
                    <router-link :to="entry.leaderboard" class="has-text-weight-bold">{{entry.name}} - {{entry.difficulty}}</router-link>
                    <div>
                        <a target="_blank" rel="noopener noreferrer"
                            v-if="entry.records && entry.records.length > 0 && entry.records[0].Participants && entry.records[0].Participants.length && entry.records[0].Participants[0].EvidenceLink"
                            :href="entry.records[0].Participants[0].EvidenceLink"
                            class="timecode"
                        >
                            {{$filters.durationDisplay(entry.records[0].Duration)}}
                        </a>
                        <span v-if="entry.records.length > 0 && !entry.records[0].Participants.length" class="timecode">
                            {{$filters.durationDisplay(entry.records[0].Duration)}}
                        </span>
                    </div>
                </div>
                <div class="run-details">
                    <div class="runners">
                        <span v-if="entry.records.length > 0">
                            <span v-for="(p,i) in entry.records[0].Participants" :key="p.Username">
                                <router-link :to="'/profiles/' + p.Username">{{ p.Username }}</router-link><span v-if="entry.records[0].Participants.length > 0 && i < entry.records[0].Participants.length-1">, </span>
                            </span>
                        </span>
                        <small v-if="entry.records.length > 1"> + {{entry.records.length - 1}} more</small>
                    </div>
                    <div class="timestamp">
                        <span v-if="entry.records.length > 0">{{formatDate(entry.records[0].OccurredAt)}}</span>
                    </div>
                </div>
            </div>
        </template>
    </div>
    <div class="extra-links has-text-centered">
        <router-link to="/records/oldest">View Oldest Records</router-link>
    </div>
</template>

<script lang="ts">
import { FeaturedRecordBoard, FeaturedRecordBoardRow, Game, RecordBoard, RecordBoardEntry, RecordBoardRow, StaticContentService } from "@/api";
import { GameStore } from "@/store/games";
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import { RecordBoardKeySelectorConsts } from "./RecordBoardKeySelectorConsts";
import { ContentLoader } from "vue-content-loader";

export interface RecordRow {
    name: string;
    difficulty: string;
    id: string;
    records: RecordBoardEntry[];
    leaderboard: any;
}

@Component({
    components: {
        ContentLoader
    }
})
export default class RecordBoardView extends Vue {
    @Prop()
    readonly recordBoardKey: any;

    @Prop()
    readonly rows: RecordRow[] | null = null;

    games: Game[] = new Array();

    private dateFormatter = new Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" });

    async created() {
        this.games = (await GameStore.get()).games;
    }

    formatDate(date: string) : string {
      return this.dateFormatter.format(new Date(date));
    }

    public getDifficultyName( difficultyId: string) {

    }

    get groupedRows() {
        var grouped = this.rows?.reduce((a, r) => 
        {
            let found = a.filter(k => k.key == r.id)[0];
            if(!found) {
                found = {key: r.id, vals:[], bg: ""};
                a.push(found);
            }

            found.vals.push(r);

            return a;
        }, [] as {key: string, vals: RecordRow[], bg: string}[]);

        if(grouped == null || grouped == undefined)
            return [];

        for(var i = 0; i < grouped.length; i++) {
            if(i % 2 == 0) {
                grouped[i].bg = "rgba(255, 255, 255, 0)";
            } else {
                grouped[i].bg = "rgba(128, 230, 255, 0.05)";
            }
        }

        return grouped;
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

$tile-double-padding: 2 * $tile-padding;

table {
    width: calc(100% + #{$tile-double-padding});
    margin-left: -$tile-padding;

    tr {
        td:not(:nth-child(1)),
        th:not(:nth-child(1)) {
            text-align: center;
        }

        td:last-child,
        th:last-child {
            text-align: right;
            padding-right: 25px;
            white-space: nowrap;
        }

        td:first-child,
        th:first-child {
            padding-left: 25px;
        }
    }

    tbody  td {
      vertical-align: middle;
    }

    tbody {
        border-bottom: 2px solid $border-color;
    }
}

.extra-links {
    margin-top: 15px;
    margin-bottom: 5px;
}

.run-cards {
    @include hr-tile-full-bleed;
    margin-top: 7px;

    border-top: 2px solid $border-color;
    border-bottom: 2px solid $border-color;

    .run-card {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 7px;
        padding-bottom: 7px;
        
        &:not(:last-child) {
            border-bottom: 1px solid $border-color;
        }

        .run-details {
            display: flex;
            justify-content:space-between;
        }

        .runners {
            font-size: small;
        }

        .timestamp {
            font-size: small;
            color: #ccc;
            text-align: end;
            white-space: nowrap;
        }
    }

}
</style>
