import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "integration-images" }
const _hoisted_2 = ["href", "title"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.editable || _ctx.hasValue)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(["integration-profile", {'editable': _ctx.editable && _ctx.actionable}])
      }, [
        _createElementVNode("span", _hoisted_1, [
          (_ctx.hasValue)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.profileUrl,
                target: "_blank",
                rel: "noopener noreferrer",
                title: _ctx.profileName + ' on ' + _ctx.service
              }, [
                _createElementVNode("img", {
                  class: "integration-icon",
                  src: _ctx.serviceIcon
                }, null, 8, _hoisted_3),
                _createElementVNode("img", {
                  class: "integration-avatar",
                  src: _ctx.avatarUrl
                }, null, 8, _hoisted_4)
              ], 8, _hoisted_2))
            : (_ctx.editable)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "integration-icon-fixed",
                  src: _ctx.serviceIcon
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
        ]),
        (_ctx.editable && _ctx.actionable)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: _normalizeClass(["clickable fas", {'fa-link': !_ctx.hasValue && _ctx.link, 'fa-unlink': _ctx.hasValue && _ctx.unlink }]),
              role: "button",
              tabindex: "0",
              title: (_ctx.hasValue ? 'Unlink' : 'Link') + ' your ' + _ctx.service + ' account',
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
              onKeydown: [
                _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)), ["enter"])),
                _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)), ["space"]))
              ]
            }, null, 42, _hoisted_6))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}