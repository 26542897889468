<template>
    <div class="typeahead" @focusout="handleFocusOut" tabindex="0">
        <div class="input-container" :class="{'loading': loading}">
            <input v-if="!value" ref="typeaheadInput" class="input" v-model="queryInput" @keyup="queryKeyUp" @focus="queryFocus" :placeholder="placeholder" :class="{required}" />
            <div v-if="value" class="selected-item" @click="clearSelected">{{displayValue}}</div>
        </div>
        <div class="expanded-container" v-if="showLimitMessage" :style="expandedStyle">
            <p class="item-message">
                Type at least {{charLimit}} characters
            </p>
        </div>
        <div class="expanded-container" v-if="showResults" :style="expandedStyle">
            <p class="item-message" v-if="searchResults.length == 0">
                No results for '{{queryInput}}'
            </p>
            <p class="item" role="button" tabindex="0" v-for="result in searchResults" :key="result"
                @click="select(result)">
                {{displayTransform(result)}}
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";

@Component({})
export default class Typeahead extends Vue {
    @Prop()
    public modelValue: any;

    // Required, API to send queries to. Should be something like http://example.com/Entity/search?query=
    @Prop()
    public api: ((s: string) => Promise<any[]>) | string = "/search?query=";

    // Optional, minimum length to trigger API call
    @Prop()
    public minLength: number = 2;

    // Optional, transform the selected entry when assigning to modelValue
    @Prop()
    public modelTransform: (m: any) => any = m => m;

    // Optional, transform the provided value when binding initially
    @Prop()
    public bindTransform: (m: any) => any = m => m;

    // Optional, transform entries for display purposes
    @Prop()
    public displayTransform: (m: any) => any = m => m;

    @Prop()
    public placeholder: string | null = null;

    @Prop()
    public required: boolean = false;

    public queryInput: string | null = null;
    public showResults = false;
    public showLimitMessage = false;
    public searchResults: any[] | null = new Array();
    public loading = false;
    public value: any = null;
    public expandedStyle = {
        width: "auto"
    };

    public charLimit = 3;

    private timeout: number | undefined;

    public async mounted() {
        let xformed = this.bindTransform(this.modelValue);
        if(xformed != null) {
            let initResults = await this.query(xformed);
            if(initResults.length === 1) {
                this.select(initResults[0]);
            } else {
                this.queryInput = xformed;
                this.queryKeyUp(null);
            }
        }
    }

    public queryKeyUp(e: (KeyboardEventInit & Event) | null) {
        if(e != null) {
            this.updateExpandedStyle(e.target!);
        }

        if(this.queryInput == null) {
            this.loading = false;
            this.showResults = false;
            this.showLimitMessage = false;
            this.searchResults = new Array();
            return;
        }
        
        let trimmed = this.queryInput.trim();
        if(trimmed == null || trimmed.length < this.charLimit) {
            this.loading = false;
            this.showResults = false;
            this.showLimitMessage = true;
            this.searchResults = new Array();
            return;
        }

        this.loading = true;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.queryChanged(trimmed);
        }, 250);
    }
  
    public queryFocus(e: FocusEventInit & Event) {
        if(e != null) {
            this.updateExpandedStyle(e.target!);
        }

        let trimmed = this.queryInput?.trim();
        if(trimmed != null && trimmed.length >= this.charLimit) {
            this.showLimitMessage = false;
        } else {
            this.showLimitMessage = true;
        }
    }

    public async queryChanged(searchValue: string) {
        this.loading = true;

        this.searchResults = await this.query(searchValue);

        this.showResults = true;
        this.loading = false;
        this.showLimitMessage = false;
    }

    private async query(searchValue: string): Promise<any[]> {
        if(typeof this.api === 'string') {
            let response = await fetch(this.api + encodeURIComponent(searchValue));
            return await response.json();
        } else {
            return await this.api(searchValue);
        }
    }

    public select(value: any) {
        this.value = value;
        this.$emit("update:modelValue", this.modelTransform(value));
        this.showResults = false;
        this.showLimitMessage = false;
    }

    public clearSelected() {
        this.value = null;
        this.$emit("update:modelValue", null);

        // Focus on next tick so that v-if adds element to DOM
        setTimeout(() => {
            (this.$refs as any).typeaheadInput.focus();
            this.showResults = true;
        }, 0);
    }

    get displayValue() {
        return this.displayTransform(this.value);
    }

    public handleFocusOut(e: FocusEventInit & Event) {
        if(e.relatedTarget != null 
            && e.relatedTarget instanceof Node
            && e.currentTarget instanceof Node
            && e.currentTarget.contains(e.relatedTarget)) {
            return;
        }

        this.showResults = false;
        this.showLimitMessage = false;
    }

    private updateExpandedStyle(e: EventTarget) {
        let elem = e as HTMLElement;
        this.expandedStyle.width = elem.clientWidth + "px";
    }
}

</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.typeahead {
    width: 100%;
    max-width: 100%;
    z-index: 4;

    &:focus {
        .input-container{
            border-color: $primary;
        }
    }

    &:hover {
        .input-container{
            border-color: $grey-light;
        }

        &:focus {
            .input-container{
                border-color: $primary;
            }
        }
    }

    .input-container {
        position: relative;
        background: darken($body-background-color, 15%);
        color: $text;
        border: none;
        border-radius: 0;
        height: 2.5em;
        line-height: 1.5;
        max-width: 100%;
        width: 100%;
        align-items: center;
        display: inline-flex;
        user-select: none;
        cursor: pointer;
        overflow: hidden;
        z-index: 4;

        .input.required {
            border-color: $warning-color;
        }

        &.loading:after {
            content: '↻';
            position: absolute;
            font-size: 2rem;
            line-height: 2rem;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-58%);
        }

        .selected-item {
            position: absolute;
            padding: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }

    .expanded-container {
        position: absolute;
        background: darken($body-background-color, 15%);
        border: 1px solid $border-color;
        padding: 4px;
        z-index: 5;

        .item {
            position: relative;
            padding-left: 6px;
            cursor: pointer;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;

            &:hover {
                background-color: darken($body-background-color, 0%);
            }
        }

        .item-message {
            position: relative;
            padding-left: 6px;
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }
}

</style>