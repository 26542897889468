import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "run-board" }
const _hoisted_2 = {
  key: 0,
  class: "leaderboard-table"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { colspan: "999" }
const _hoisted_5 = ["width"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "participants" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "timecode" }
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 1,
  class: "timecode"
}
const _hoisted_13 = ["href", "title"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 1,
  class: "run-cards"
}
const _hoisted_17 = { class: "run-info" }
const _hoisted_18 = ["width"]
const _hoisted_19 = {
  key: 1,
  class: "run-card"
}
const _hoisted_20 = { class: "run-info" }
const _hoisted_21 = { class: "headline" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "time-info" }
const _hoisted_25 = ["href"]
const _hoisted_26 = {
  key: 1,
  class: "timecode"
}
const _hoisted_27 = ["href", "title"]
const _hoisted_28 = { class: "timestamp subtitle is-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.$store.state.isMobileSmall)
      ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", { class: "participants" }, "Runner"),
              _createElementVNode("th", null, "Time"),
              _createElementVNode("th", null, "Date"),
              _createElementVNode("th", null, "Timesave")
            ])
          ], -1)),
          (!_ctx.history)
            ? (_openBlock(), _createElementBlock("tbody", _hoisted_3, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
                  return _createElementVNode("tr", { key: i }, [
                    _createElementVNode("td", _hoisted_4, [
                      _createVNode(_component_ContentLoader, {
                        width: "170",
                        height: "18",
                        "primary-color": "#1f2a3c",
                        "secondary-color": "#2c3b55"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("rect", {
                            x: "0",
                            y: "5",
                            rx: "2",
                            ry: "2",
                            width: (150 + Math.random() * 20),
                            height: "10"
                          }, null, 8, _hoisted_5)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                }), 64))
              ]))
            : (_ctx.history.length == 0)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, _cache[0] || (_cache[0] = [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", { colspan: "999" }, " No runs to show yet! ")
                  ], -1)
                ])))
              : (_openBlock(), _createElementBlock("tbody", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.history, (entry, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: entry.RunId,
                      class: "run-info"
                    }, [
                      _createElementVNode("td", _hoisted_8, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entry?.Participants, (p, i) => {
                          return (_openBlock(), _createElementBlock("span", { key: p }, [
                            _createVNode(_component_router_link, {
                              to: '/profiles/' + p.Username
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(p.Username), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"]),
                            (entry.Participants.length > 0 && i < entry.Participants.length-1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_9, ", "))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("td", _hoisted_10, [
                        (entry.Participants[0].EvidenceLink)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              target: "_blank",
                              rel: "noopener noreferrer",
                              href: entry.Participants[0].EvidenceLink,
                              class: "timecode"
                            }, _toDisplayString(_ctx.$filters.durationDisplay(entry.Duration)), 9, _hoisted_11))
                          : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$filters.durationDisplay(entry.Duration)), 1)),
                        (entry.Participants.length > 1)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(entry.Participants.slice(1), (partner) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: partner.Username
                              }, [
                                (partner.EvidenceLink)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      target: "_blank",
                                      rel: "noopener noreferrer",
                                      href: partner.EvidenceLink,
                                      title: partner.Username,
                                      class: "partner-evidence"
                                    }, _cache[1] || (_cache[1] = [
                                      _createElementVNode("i", { class: "fas fa-video" }, null, -1)
                                    ]), 8, _hoisted_13))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(entry.OccurredAt)), 1),
                      (index == 0)
                        ? (_openBlock(), _createElementBlock("td", _hoisted_14, "-"))
                        : _createCommentVNode("", true),
                      (index > 0)
                        ? (_openBlock(), _createElementBlock("td", _hoisted_15, _toDisplayString(_ctx.$filters.durationDisplay(_ctx.history[index-1].Duration - entry.Duration, true)), 1))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_16, [
          (!_ctx.history)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(10, (i) => {
                return _createElementVNode("div", {
                  class: "run-card",
                  key: i
                }, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_ContentLoader, {
                      class: "headline",
                      width: "170",
                      height: "18",
                      "primary-color": "#1f2a3c",
                      "secondary-color": "#2c3b55"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("rect", {
                          x: "0",
                          y: "5",
                          rx: "2",
                          ry: "2",
                          width: (150 + Math.random() * 20),
                          height: "10"
                        }, null, 8, _hoisted_18)
                      ]),
                      _: 1
                    })
                  ])
                ])
              }), 64))
            : (_ctx.history.length == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[3] || (_cache[3] = [
                  _createElementVNode("div", { class: "run-info" }, [
                    _createElementVNode("div", { class: "headline" }, " No runs to show yet! ")
                  ], -1)
                ])))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.history, (entry, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "run-card",
                    key: entry.RunId
                  }, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "ranks" }, null, -1)),
                        _createElementVNode("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entry?.Participants, (p, j) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: p.Username
                            }, [
                              _createVNode(_component_router_link, {
                                to: '/profiles/' + p.Username
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(p.Username), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"]),
                              (j != (entry.Participants.length - 1))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_22, ", "))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128)),
                          (index > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                _createTextVNode(_toDisplayString(_ctx.$filters.durationDisplay(_ctx.history[index-1].Duration - entry.Duration, true)) + " ", 1),
                                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "subtitle is-6" }, "of timesave", -1))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        (entry.Participants[0].EvidenceLink)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: entry.Participants[0].EvidenceLink,
                              target: "_blank",
                              rel: "noopener noreferrer",
                              class: "timecode"
                            }, _toDisplayString(_ctx.$filters.durationDisplay(entry.Duration)), 9, _hoisted_25))
                          : (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.$filters.durationDisplay(entry.Duration)), 1)),
                        (entry.Participants.length > 1)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(entry.Participants.slice(1), (partner) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: partner.Username
                              }, [
                                (partner.EvidenceLink)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      href: partner.EvidenceLink,
                                      title: partner.Username,
                                      target: "_blank",
                                      rel: "noopener noreferrer",
                                      class: "partner-evidence"
                                    }, _cache[6] || (_cache[6] = [
                                      _createElementVNode("i", { class: "fas fa-video" }, null, -1)
                                    ]), 8, _hoisted_27))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.formatDate(entry.OccurredAt)), 1)
                      ])
                    ]),
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "run-info" }, [
                      _createElementVNode("div")
                    ], -1))
                  ]))
                }), 128))
        ]))
  ]))
}