import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "run-input" }
const _hoisted_2 = { class: "run-input-entry-row" }
const _hoisted_3 = { class: "run-input-cell" }
const _hoisted_4 = { class: "level-name" }
const _hoisted_5 = { class: "time-comparisons" }
const _hoisted_6 = {
  key: 0,
  class: "timecode"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "timecode"
}
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { class: "run-input-cell time-input" }
const _hoisted_11 = { class: "time-input-wrapper" }
const _hoisted_12 = { class: "time-input-field" }
const _hoisted_13 = {
  key: 0,
  class: "fas fa-award record-icon"
}
const _hoisted_14 = {
  key: 0,
  class: "point-viz"
}
const _hoisted_15 = {
  key: 0,
  class: "warning-text"
}
const _hoisted_16 = { class: "run-input-cell" }
const _hoisted_17 = { class: "evidence-field" }
const _hoisted_18 = ["required"]
const _hoisted_19 = {
  key: 0,
  class: "run-input-entry-row"
}
const _hoisted_20 = {
  key: 0,
  class: "run-input-cell partner-remove-field"
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "run-input-cell partner-field" }
const _hoisted_23 = { class: "run-input-cell additional-evidence-field" }
const _hoisted_24 = ["onUpdate:modelValue", "required"]
const _hoisted_25 = { class: "run-input-entry-row" }
const _hoisted_26 = {
  key: 0,
  class: "run-input-cell add-partner is-hidden-tablet has-text-right"
}
const _hoisted_27 = {
  key: 1,
  class: "run-input-cell filters"
}
const _hoisted_28 = ["onUpdate:modelValue"]
const _hoisted_29 = {
  value: "",
  disabled: "disabled",
  selected: "selected"
}
const _hoisted_30 = {
  key: 0,
  value: undefined
}
const _hoisted_31 = ["value"]
const _hoisted_32 = {
  key: 2,
  class: "run-input-cell add-partner is-hidden-mobile"
}
const _hoisted_33 = {
  key: 1,
  class: "run-input-entry-row mt-4"
}
const _hoisted_34 = { class: "run-input-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_input = _resolveComponent("time-input")!
  const _component_typeahead = _resolveComponent("typeahead")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.context.runnableSegmentName), 1),
        _createElementVNode("small", _hoisted_5, [
          _cache[10] || (_cache[10] = _createTextVNode(" Record ")),
          (_ctx.segmentRecord != null)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$filters.durationDisplay(_ctx.segmentRecord.Duration)), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_7, "not set")),
          _cache[11] || (_cache[11] = _createTextVNode(" // ")),
          (_ctx.context.pb?.Duration)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, "PB " + _toDisplayString(_ctx.$filters.durationDisplay(_ctx.context.pb.Duration)) + " (" + _toDisplayString(_ctx.getPoints(_ctx.context.pb)) + " points)", 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_9, "No PB"))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[13] || (_cache[13] = _createElementVNode("label", { class: "label is-hidden-tablet" }, "New Time ", -1)),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("span", _hoisted_12, [
            (_ctx.context.probablyRecord && !_ctx.context.editingDuration)
              ? (_openBlock(), _createElementBlock("span", _hoisted_13))
              : _createCommentVNode("", true),
            _createVNode(_component_time_input, {
              modelValue: _ctx.context.duration,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.context.duration) = $event)),
              onUpdate: _cache[1] || (_cache[1] = ($event: any) => {_ctx.updatePoints(); _ctx.onChange();}),
              onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.context.editingDuration = true)),
              onBlur: _cache[3] || (_cache[3] = ($event: any) => {_ctx.context.editingDuration = false; _ctx.onChange();})
            }, null, 8, ["modelValue"])
          ]),
          (_ctx.context.pointsEstimate)
            ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                _createTextVNode(_toDisplayString(_ctx.context.pointsEstimate) + " ", 1),
                _cache[12] || (_cache[12] = _createElementVNode("small", null, "points", -1))
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.context.probablyRecord && !_ctx.context.editingDuration && _ctx.context.probablyMistake)
          ? (_openBlock(), _createElementBlock("span", _hoisted_15, "This time is dramatically faster than world record, are you sure this is correct?"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _cache[14] || (_cache[14] = _createElementVNode("label", { class: "label is-hidden-tablet" }, "Video ", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: _normalizeClass(["input", {'is-danger': _ctx.context.requiresEvidence && !_ctx.context.editingDuration && !_ctx.validUrl(_ctx.context.evidenceLinks[0])}]),
            placeholder: "Video link",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.context.evidenceLinks[0]) = $event)),
            required: _ctx.context.requiresEvidence && !_ctx.context.editingDuration && !_ctx.context.evidenceLinks[0],
            onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onChange()))
          }, null, 42, _hoisted_18), [
            [_vModelText, _ctx.context.evidenceLinks[0]]
          ])
        ])
      ])
    ]),
    (_ctx.context.partners.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.context.partners, (partner, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "partner-row",
              key: i
            }, [
              (i+1 > _ctx.context.requiredPartners)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "button is-ghost",
                      onClick: ($event: any) => (_ctx.removePartner(partner))
                    }, _cache[15] || (_cache[15] = [
                      _createElementVNode("i", { class: "fas fa-times-circle" }, null, -1)
                    ]), 8, _hoisted_21)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_typeahead, {
                  api: _ctx.userTypeaheadApi,
                  "display-transform": m => m.Username,
                  placeholder: "Find partner",
                  modelValue: _ctx.context.partners[i],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.context.partners[i]) = $event),
                  "model-transform": m => { return {id: m.UserId, username: m.Username }},
                  bindTransform: m => m.username,
                  required: true
                }, null, 8, ["api", "display-transform", "modelValue", "onUpdate:modelValue", "model-transform", "bindTransform"])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass(["input", {'is-danger': _ctx.context.requiresEvidence && !_ctx.context.editingDuration && !_ctx.validUrl(_ctx.context.evidenceLinks[i+1])}]),
                  type: "text",
                  size: "10",
                  placeholder: "Partner video link",
                  "onUpdate:modelValue": ($event: any) => ((_ctx.context.evidenceLinks[i+1]) = $event),
                  required: _ctx.context.requiresEvidence && !_ctx.context.editingDuration && !_ctx.context.evidenceLinks[i+1],
                  onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onChange()))
                }, null, 42, _hoisted_24), [
                  [_vModelText, _ctx.context.evidenceLinks[i+1]]
                ])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_25, [
      (_ctx.context.partners.length < _ctx.context.allowedPartners)
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _createElementVNode("button", {
              type: "button",
              class: "button is-ghost",
              onClick: _cache[7] || (_cache[7] = ($event: any) => {_ctx.context.partners.push({}); _ctx.onChange();})
            }, "Add Partner")
          ]))
        : _createCommentVNode("", true),
      (_ctx.category)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category.Filters, (filter) => {
              return (_openBlock(), _createElementBlock("div", {
                key: filter.Id,
                class: "select"
              }, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((_ctx.context.filterValues[filter.Id]) = $event)
                }, [
                  _createElementVNode("option", _hoisted_29, _toDisplayString(filter.Name), 1),
                  (filter.Required == false)
                    ? (_openBlock(), _createElementBlock("option", _hoisted_30))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.Values, (val) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: val.Id,
                      value: val.Id
                    }, _toDisplayString(val.Name), 9, _hoisted_31))
                  }), 128))
                ], 8, _hoisted_28), [
                  [_vModelSelect, _ctx.context.filterValues[filter.Id]]
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.context.partners.length < _ctx.context.allowedPartners)
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createElementVNode("button", {
              type: "button",
              class: "button is-ghost",
              onClick: _cache[8] || (_cache[8] = ($event: any) => {_ctx.context.partners.push({}); _ctx.onChange();})
            }, "Add Partner")
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.adminEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _cache[16] || (_cache[16] = _createElementVNode("label", { class: "label" }, "Run Occurred At (admin only)", -1)),
            _createVNode(_component_VueDatePicker, {
              modelValue: _ctx.context.occurredAt,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.context.occurredAt) = $event)),
              dark: "",
              format: _ctx.occurredAtFormat,
              "is-24": true,
              utc: ""
            }, null, 8, ["modelValue", "format"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}