<template>
    <div class="warning" v-if="errorMessage">{{ errorMessage }}</div>
    <div class="field">
        <label class="label">Username</label>
        <div class="control">
            <input type="text" class="input" v-model="authReq.Username" />
        </div>
    </div>
    <div class="field">
        <label class="label">Password</label>
        <div class="control">
            <input type="password" class="input" v-model="authReq.Secret" v-on:keyup.enter="submit" />
        </div>
    </div>
    <div class="field is-grouped is-flex-direction-row-reverse is-justify-content-flex-end">
        <div class="control">
            <a class="button is-primary" href="#" v-on-async="submit">Login</a>
        </div>
        <div class="control">
            <router-link class="button is-text" to="/auth/register">Register</router-link>
        </div>
        <div class="is-flex-grow-1">
            <router-link to="/auth/forgot"><small>Forgot password?</small></router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import AuthStore from "@/store/auth";
import OnAsync from "@/directives/OnAsync";
import { AuthenticationRequest, AuthType } from "@/api";

@Component({
    directives: {
        OnAsync
    }
})
export default class Login extends Vue {
    authReq: AuthenticationRequest = <AuthenticationRequest>{
         AuthType: AuthType.PASSWORD
    };
    errorMessage: string | null = null;

    async submit() {
        try {
            let resp = await AuthStore.login(this.authReq);

            if (resp) {
                this.errorMessage = null;
                this.authReq = <AuthenticationRequest>{};
                this.$router.push("/");
            } else {
                this.authReq.Secret = "";
                this.errorMessage = "Authentication failed, try again";
            }
        } catch (e) {
            this.authReq.Secret = "";
            this.errorMessage = "Authentication failed, try again";
        }
    }
}
</script>
