<template>
    <template v-if="selectionContainer">
        <li class="breadcrumb-item">
            <span class="select">
                {{ gameName }}
                <select v-model="selectionContainer.GameId" @change="gameChanged">
                    <option v-for="game in games" :key="game.Id" :value="game.Id">
                        {{ game.Name }}
                    </option>
                </select>
            </span>
        </li>

        <li class="breadcrumb-item">
            <span class="select">
                {{ catName }}
                <select v-model="selectionContainer.CategoryId" @change="categoryChanged">
                    <option v-for="category in normalCats" :key="category.Id" :value="category.Id">
                        {{ category.Name }}
                    </option>
                    <template v-for="(category, i) in extensionCats" :key="category.Id" >
                        <option v-if="i == 0" class="separator" disabled="disabled">Extras</option>
                        <option :value="category.Id">
                            {{ category.Name }}
                        </option>
                    </template>
                </select>
            </span>
        </li>

        <li class="breadcrumb-item">
            <span class="select">
                {{ levelName }}
                <select v-model="selectionContainer.RunnableSegmentId" @change="levelChanged">
                    <option v-for="level in levels" :key="level.Id" :value="level.Id">
                        {{ level.Name }}
                    </option>
                </select>
            </span>
        </li>

        <li class="breadcrumb-item" v-if="showDifficulty">
            <span class="select">
                {{ diffName }}
                <select v-model="selectionContainer.Difficulty" @change="difficultyChanged">
                    <option v-for="diff in difficulties" :key="diff.Id" :value="diff.Id">
                        {{ diff.Name }}
                    </option>
                </select>
            </span>
        </li>
    </template>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import { GameStore } from "@/store/games";
import { Game, Category, RunnableSegment, DifficultyDto, Difficulty } from "@/api";
import { RecordBoardKeySelectorConsts } from "./RecordBoardKeySelectorConsts";

@Component({
    emits: ["update"]
})
export default class LeaderboardKeySelector extends Vue {

    @Prop()
    readonly selectionContainer: any;

    @Prop()
    readonly showDifficulty: boolean = true;

    gameName : string | undefined | null = null;
    catName: string | undefined | null = null
    levelName : string | undefined | null = null
    diffName: string | undefined | null = null

    game: Game | undefined | null = null;
    games: Game[] = [];
    categories: Category[] = [];
    allLevels: RunnableSegment[] = [];
    levels: RunnableSegment[] = [];
    difficulties: Difficulty[] = [];

    async created() {
        await this.load();
    }

    mounted() {
        //if(this.selectionContainer == {}) {
        //    const storedKey = localStorage.getItem(RecordBoardKeySelectorConsts.LeaderboardPersistenceKey);
        //
        //    if(storedKey) {
        //        Object.assign(this.selectionContainer, JSON.parse(storedKey));
        //        this.$emit("update", this.selectionContainer);
        //    }
        //} else {
        //    this.persistSelection();
        //}
    }

    async load() {
        var gamestore = await GameStore.get();
        this.games = gamestore.games.filter(g => !g.Hidden);
        this.gameChanged();
        this.levelChanged();
        this.difficultyChanged();
    }

    @Watch("selectionContainer")
    onselectionContainerChanged(val: any, oldVal: any) {
        this.gameChanged();
        this.levelChanged();
        this.difficultyChanged();
        this.persistSelection();
    }

    persistSelection() {
        localStorage.setItem(RecordBoardKeySelectorConsts.LeaderboardPersistenceKey, JSON.stringify(this.selectionContainer));
    }

    gameChanged() {
        if (!this.selectionContainer.GameId){
            this.selectionContainer.GameId = this.games[0].Id;
        }

        this.game = this.games.find((g) => g.Id === this.selectionContainer.GameId);

        if(!this.game) {
            this.game = this.games[0];
            this.selectionContainer.GameId = this.game.Id;
        }

        this.gameName = this.game.Name;

        this.categories = this.game?.Categories ?? [];
        this.allLevels = this.game?.RunnableSegments ?? [];
        this.difficulties = this.game?.Difficulties ?? [];

        if(!this.categories.find(c => c.Id == this.selectionContainer.CategoryId)) {
            this.selectionContainer.CategoryId = this.categories[0]?.Id;
        }

        this.categoryChanged();
    }

    categoryChanged() {
        let newLevels = this.allLevels;
        let newDiffs: Difficulty[] = [];

        if (this.selectionContainer.CategoryId != null) {
            let cat = this.categories.find((c) => c.Id === this.selectionContainer.CategoryId);

            if(!cat) {
                cat = this.categories[0];
            }

            this.catName = cat.Name;
            let allDifs = this.game?.Difficulties ?? [];
            this.difficulties = cat.Difficulties == null ? allDifs : allDifs.filter(d => cat!.Difficulties!.indexOf(d.Id!) >= 0);

            newLevels = this.allLevels.filter(
                (l) => cat!.AllowedSegments!.indexOf(l.Id!) >= 0
            );

            newDiffs = this.game?.Difficulties?.filter(d => d?.Id && cat?.Difficulties?.indexOf(d.Id)! >= 0) ?? [];
        }

        let same =
            Array.isArray(this.levels) &&
            Array.isArray(newLevels) &&
            this.levels.length === newLevels.length &&
            this.levels.every((val, index) => val.Id === newLevels[index].Id);

        if (!same) {
            this.levels = newLevels;
            this.difficulties = newDiffs;
        }

        if(!this.levels.find(c => c.Id == this.selectionContainer.RunnableSegmentId)){
            this.selectionContainer.RunnableSegmentId = this.levels[0].Id;
            this.levelChanged();
        }

        if(!this.difficulties.find(c => c.Id == this.selectionContainer.Difficulty)){
            this.selectionContainer.Difficulty = this.difficulties[0].Id;
            this.difficultyChanged();
        }

        this.$emit("update", this.selectionContainer);
        this.persistSelection();
    }

    levelChanged() {
        let level = this.levels.find(l => l.Id == this.selectionContainer.RunnableSegmentId);
        this.levelName = level?.Name;

        this.$emit("update", this.selectionContainer);
    }

    difficultyChanged() {
        let diff = this.difficulties.find(d => d.Id == this.selectionContainer.Difficulty);
        this.diffName = diff?.Name;

        this.$emit("update", this.selectionContainer);
    }

    get normalCats() {
        return this.categories?.filter(c => !c.IsExtension);
    }

    get extensionCats() {
        return this.categories?.filter(c => c.IsExtension);
    }
}
</script>
