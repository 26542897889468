<template>
<div class="user-history">
    <template v-if="$route.name == 'History'">
        <div v-if="!history">
            <div class="hr-tile">
                <user-profile-tabs :is-own-profile="isOwnProfile"></user-profile-tabs>
                <div class="headline">
                    <h4 class="title is-4">{{$route.params.username ?? 'User'}}'s Run History</h4>
                    <div>
                        <div class="select">
                            <select>
                                <option></option>
                            </select>
                        </div>
                        <button class="button is-primary" v-on-async="rebuildHistory" v-if="$store.state.devMode">Rebuild history</button>
                    </div>
                </div>
            </div>

            <div class="columns is-multiline">
                <template v-for="i in 4" :key="i">
                    <div class="column is-half-desktop is-full-tablet">
                        <div class="user-career-game hr-tile">
                            <ContentLoader width="170" height="43.5" primary-color="#1f2a3c" secondary-color="#2c3b55">
                                <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="21" />
                            </ContentLoader>
                            <table class="level-table">
                                <thead>
                                    <tr>
                                        <th>Level</th>
                                        <th>PB</th>
                                        <th>Past PBs</th>
                                        <th>Avg Timesave</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="j in 12" :key="j">
                                        <td>
                                            <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                                                <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                                            </ContentLoader>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div v-else>
            <div class="hr-tile toggle-button-parent">
                <user-profile-tabs :is-own-profile="isOwnProfile"></user-profile-tabs>
                <div class="headline">
                    <h4 class="title is-4">{{user?.Username ?? 'User'}}'s Run History</h4>
                    <div class="filters field is-horizontal">
                        <button class="button is-primary" v-on-async="rebuildHistory" v-if="$store.state.devMode">Rebuild history</button>
                        <div class="select">
                            <select v-model="chosenCategory">
                                <template v-for="(runs,category) in history.EntriesByCategory" :key="category">
                                    <option v-if="runs.length > 0">{{category}}</option>
                                </template>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div style="min-height: 100vh" v-masonry:[$route]="$store.state.windowWidth <= 1215 ? 1 : 2">
                <template v-for="game in categoryGames" :key="game.Id">
                    <UserHistoryGame :runs="history.EntriesByCategory[chosenCategory]" :categoryName="chosenCategory" :game="game" :submittedOnly="true"></UserHistoryGame>
                </template>
                <template v-for="game in categoryGames" :key="game.Id">
                    <UserHistoryGame :runs="history.EntriesByCategory[chosenCategory]" :categoryName="chosenCategory" :game="game" :submittedOnly="false"></UserHistoryGame>
                </template>
            </div>
        </div>
    </template>
    <router-view v-else-if="user" :userId="user.UserId"></router-view>
</div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { Prop, Watch } from "vue-facing-decorator";
import UserHistoryGame from "@/components/User/UserHistoryGame.vue";
import { GameStore } from "@/store/games";
import { Game, Claim, StaticContentService, UserProfile, UserCareer } from "@/api";
import OnAsync from "@/directives/OnAsync";
import { useToast } from "vue-toastification";
import UserProfileTabs from "@/components/User/UserProfileTabs.vue";
import { ContentLoader } from "vue-content-loader";
import Masonry from "@/directives/Masonry";

@Component({
    components: {
        UserHistoryGame,
        UserProfileTabs,
        ContentLoader
    },
    directives: {
        OnAsync,
        Masonry
    }
})
export default class UserHistory extends Vue {
    history: any = null;
    chosenCategory: string | null = null;
    games: Game[] = new Array();
    toast = useToast();
    levelSearch: string | null = null;

    @Prop()
    public user: UserProfile | null = null;

    @Prop()
    public career: UserCareer | null = null;

    @Prop()
    public isOwnProfile: boolean = false;

    async mounted() {
        await this.load();
        this.games = (await GameStore.get()).games.filter(g => !g.Hidden);
    }

    @Watch("user")
    @Watch("isOwnProfile")
    async load() {
        if(this.user?.UserId == null) return;
        try {
            this.history = await StaticContentService.userHistory(this.user.UserId);
            let cats = Object.keys(this.history.EntriesByCategory)
                .filter(c => this.history.EntriesByCategory[c].length > 0);
            let solo = cats.findIndex(c => c == "Solo");

            this.chosenCategory = solo >= 0 ? "Solo" : cats[0];
        } catch (e: any) {
            this.toast.error("Unable to load user history: " + e.toString());
        }
    }

    async rebuildHistory() {
        let rebuildEvent = {
            Event: "UserRunHistoryRollupUpdateEvent",
            Payload: {
                UserId: this.user?.UserId,
            },
        };

        //await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
        await this.load();
    }

    get categoryGames() {
        return this.games.filter(g => g.Categories && g.Categories.filter(c => c.Name == this.chosenCategory).length > 0);
    }
}
</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.filters {
    align-items: baseline;

    .input.search {
        height: 2.5em;
        border: none;
        border-bottom: 2px solid #1f2a3c;
        background: transparent;
        transform: translateY(1px);

        &:hover, &:active, &:focus {
            border-color: $primary;
        }
    }
}

.toggle-button-parent {
    position: relative;
}

.view-toggle-button {
    position: absolute;
    top: 10px;
    right: 10px;
}

.headline {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 0.5rem;

    .title {
        margin-bottom: 0;
    }
}
</style>