import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "field is-horizontal" }
const _hoisted_2 = { class: "control field-body" }
const _hoisted_3 = { class: "label field-label is-normal" }
const _hoisted_4 = { class: "control field-body" }
const _hoisted_5 = { class: "select" }
const _hoisted_6 = ["onUpdate:modelValue"]
const _hoisted_7 = {
  key: 0,
  value: undefined
}
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "label field-label is-normal" }
const _hoisted_10 = {
  key: 0,
  class: "run-input-cell partner-remove-field"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "control field-body partners-field-body" }
const _hoisted_13 = { class: "run-input-cell partner-field" }
const _hoisted_14 = { class: "run-input-cell additional-evidence-field" }
const _hoisted_15 = ["onUpdate:modelValue", "required"]
const _hoisted_16 = {
  key: 1,
  class: "field is-horizontal"
}
const _hoisted_17 = { class: "control field-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoLinkInput = _resolveComponent("VideoLinkInput")!
  const _component_typeahead = _resolveComponent("typeahead")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { class: "label field-label is-normal" }, "Video", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_VideoLinkInput, {
          modelValue: _ctx.context.evidenceLinks[0],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.context.evidenceLinks[0]) = $event)),
          placeholder: "Video link",
          required: _ctx.context.requiresEvidence && !_ctx.context.editingDuration && !_ctx.context.evidenceLinks[0],
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange())),
          class: _normalizeClass({'is-danger': _ctx.context.requiresEvidence && !_ctx.context.editingDuration && !_ctx.validUrl(_ctx.context.evidenceLinks[0])})
        }, null, 8, ["modelValue", "required", "class"])
      ])
    ]),
    (_ctx.category)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.category.Filters, (filter) => {
          return (_openBlock(), _createElementBlock("div", {
            key: filter.Id,
            class: "field is-horizontal"
          }, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(filter.Name), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((_ctx.context.filterValues[filter.Id]) = $event)
                }, [
                  (filter.Required == false)
                    ? (_openBlock(), _createElementBlock("option", _hoisted_7))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.Values, (val) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: val.Id,
                      value: val.Id
                    }, _toDisplayString(val.Name), 9, _hoisted_8))
                  }), 128))
                ], 8, _hoisted_6), [
                  [_vModelSelect, _ctx.context.filterValues[filter.Id]]
                ])
              ])
            ])
          ]))
        }), 128))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.context.partners, (partner, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "field is-horizontal",
        key: i
      }, [
        _createElementVNode("label", _hoisted_9, [
          _createTextVNode(" Partner " + _toDisplayString(i+1) + " ", 1),
          (i+1 > _ctx.context.requiredPartners)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("button", {
                  type: "button",
                  class: "button is-ghost",
                  onClick: ($event: any) => (_ctx.removePartner(partner))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("i", { class: "fas fa-times-circle" }, null, -1)
                ]), 8, _hoisted_11)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_typeahead, {
              api: _ctx.userTypeaheadApi,
              "display-transform": m => m.Username,
              placeholder: "Find partner",
              modelValue: _ctx.context.partners[i],
              "onUpdate:modelValue": ($event: any) => ((_ctx.context.partners[i]) = $event),
              "model-transform": m => { return {id: m.UserId, username: m.Username }},
              bindTransform: m => m.username,
              required: true
            }, null, 8, ["api", "display-transform", "modelValue", "onUpdate:modelValue", "model-transform", "bindTransform"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["input", {'is-danger': _ctx.context.requiresEvidence && !_ctx.context.editingDuration && !_ctx.validUrl(_ctx.context.evidenceLinks[i+1])}]),
              type: "text",
              size: "10",
              placeholder: "Partner video link",
              "onUpdate:modelValue": ($event: any) => ((_ctx.context.evidenceLinks[i+1]) = $event),
              required: _ctx.context.requiresEvidence && !_ctx.context.editingDuration && !_ctx.context.evidenceLinks[i+1],
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChange()))
            }, null, 42, _hoisted_15), [
              [_vModelText, _ctx.context.evidenceLinks[i+1]]
            ])
          ])
        ])
      ]))
    }), 128)),
    (_ctx.context.partners.length < _ctx.context.allowedPartners)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { class: "label field-label is-normal" }, null, -1)),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("button", {
              type: "button",
              class: "button is-ghost",
              onClick: _cache[3] || (_cache[3] = ($event: any) => {_ctx.context.partners.push({}); _ctx.onChange();})
            }, "Add Partner")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}