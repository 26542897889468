import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "home hr-tile" }
const _hoisted_2 = {
  key: 0,
  class: "columns is-multiline"
}
const _hoisted_3 = { class: "column is-offset-one-fifth" }
const _hoisted_4 = { class: "column is-offset-one-fifth" }
const _hoisted_5 = {
  key: 0,
  class: "ml-6 mb-5"
}
const _hoisted_6 = {
  key: 0,
  class: "integration-option"
}
const _hoisted_7 = { class: "field" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 0,
  class: "has-text-warning"
}
const _hoisted_10 = { class: "column is-offset-one-fifth" }
const _hoisted_11 = { class: "action-button" }
const _hoisted_12 = { class: "action-button" }
const _hoisted_13 = {
  key: 0,
  class: "button has-background-danger"
}
const _hoisted_14 = { class: "action-button" }
const _hoisted_15 = {
  key: 0,
  class: "button has-background-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_profile_tabs = _resolveComponent("user-profile-tabs")!
  const _component_CountryDesignationSelector = _resolveComponent("CountryDesignationSelector")!
  const _component_integration_profile_edit = _resolveComponent("integration-profile-edit")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_on_async = _resolveDirective("on-async")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_user_profile_tabs, { "is-own-profile": _ctx.isOwnProfile }, null, 8, ["is-own-profile"]),
    (_ctx.profile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "column is-full" }, [
            _createElementVNode("h3", { class: "section-header" }, "General Info")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "setting-label" }, "Country Flag", -1)),
            _createVNode(_component_CountryDesignationSelector, { modelValue: _ctx.profile }, null, 8, ["modelValue"])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "column is-full" }, [
            _createElementVNode("h3", { class: "section-header" }, "Integrations")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_integration_profile_edit, {
              service: "Twitch",
              link: _ctx.linkTwitch,
              unlink: _ctx.unlinkTwitch,
              editable: _ctx.isOwnProfile,
              hasValue: !!_ctx.profile.TwitchInfo,
              avatarUrl: _ctx.profile.TwitchInfo?.Avatar,
              profileUrl: 'https://twitch.tv/' + _ctx.profile.TwitchInfo?.Username,
              profileName: _ctx.profile.TwitchInfo?.Username
            }, null, 8, ["link", "unlink", "editable", "hasValue", "avatarUrl", "profileUrl", "profileName"]),
            (_ctx.profile.TwitchInfo)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_ctx.profile.TwitchInfo)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        (_ctx.isOwnProfile)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("div", _hoisted_7, [
                                _withDirectives(_createElementVNode("input", {
                                  id: "streamVisibility",
                                  type: "checkbox",
                                  name: "streamVisibility",
                                  class: "switch",
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.streamIsVisible) = $event)),
                                  disabled: _ctx.togglingStreamVisibility,
                                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleStreamVisibility && _ctx.toggleStreamVisibility(...args)))
                                }, null, 8, _hoisted_8), [
                                  [_vModelCheckbox, _ctx.streamIsVisible]
                                ]),
                                _cache[3] || (_cache[3] = _createElementVNode("label", {
                                  for: "streamVisibility",
                                  class: "label"
                                }, "Visible on Stream List", -1))
                              ]),
                              (_ctx.profile.ValidationStreak < _ctx.validationStreakRequirement)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.validationStreakRequirement-_ctx.profile.ValidationStreak) + " more verified runs until eligible", 1))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_integration_profile_edit, {
              service: "Discord",
              link: _ctx.linkDiscord,
              unlink: _ctx.unlinkDiscord,
              editable: _ctx.isOwnProfile,
              hasValue: !!_ctx.profile.DiscordInfo,
              avatarUrl: 'https://cdn.discordapp.com/avatars/' + _ctx.profile.DiscordInfo?.UserID + '/' + _ctx.profile.DiscordInfo?.Avatar,
              profileUrl: 'https://discordapp.com/users/' + _ctx.profile.DiscordInfo?.UserID,
              profileName: _ctx.profile.DiscordInfo?.Username + '#' + _ctx.profile.DiscordInfo?.Discriminator
            }, null, 8, ["link", "unlink", "editable", "hasValue", "avatarUrl", "profileUrl", "profileName"]),
            _createVNode(_component_integration_profile_edit, {
              service: "Xbox",
              link: _ctx.linkXbox,
              unlink: _ctx.unlinkXbox,
              editable: _ctx.isOwnProfile,
              hasValue: !!_ctx.profile.XboxInfo,
              avatarUrl: _ctx.profile.XboxInfo?.Avatar,
              profileUrl: 'https://account.xbox.com/en-us/profile?gamertag='+_ctx.profile.XboxInfo?.Gamertag,
              profileName: _ctx.profile.XboxInfo?.Gamertag
            }, null, 8, ["link", "unlink", "editable", "hasValue", "avatarUrl", "profileUrl", "profileName"]),
            _createVNode(_component_integration_profile_edit, {
              service: "Steam",
              link: _ctx.linkSteam,
              unlink: _ctx.unlinkSteam,
              editable: _ctx.isOwnProfile,
              hasValue: !!_ctx.profile.SteamInfo,
              avatarUrl: _ctx.profile.SteamInfo?.Avatar,
              profileUrl: _ctx.profile.SteamInfo?.ProfileUrl,
              profileName: _ctx.profile.SteamInfo?.PersonaName
            }, null, 8, ["link", "unlink", "editable", "hasValue", "avatarUrl", "profileUrl", "profileName"]),
            _createVNode(_component_integration_profile_edit, {
              service: "YouTube",
              link: _ctx.linkYoutube,
              unlink: _ctx.unlinkYoutube,
              editable: _ctx.isOwnProfile,
              hasValue: !!_ctx.profile.Youtube,
              avatarUrl: _ctx.profile.Youtube?.Avatar,
              profileUrl: _ctx.profile.Youtube?.ChannelUrl,
              profileName: _ctx.profile.Youtube?.ChannelTitle
            }, null, 8, ["link", "unlink", "editable", "hasValue", "avatarUrl", "profileUrl", "profileName"])
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "column is-full" }, [
            _createElementVNode("h3", { class: "section-header" }, "Danger Zone")
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, [
              (_ctx.isOwnProfile)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: {name: 'Change Username'},
                    class: "button has-background-primary"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" Change Username ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("p", _hoisted_12, [
              (_ctx.isOwnProfile)
                ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_13, _cache[5] || (_cache[5] = [
                    _createTextVNode(" Export User Data ")
                  ]))), [
                    [_directive_on_async, _ctx.exportUserData]
                  ])
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("p", _hoisted_14, [
              (_ctx.isOwnProfile)
                ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_15, _cache[6] || (_cache[6] = [
                    _createTextVNode(" Delete Account (permanently!) ")
                  ]))), [
                    [_directive_on_async, _ctx.deleteUserAccount]
                  ])
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}