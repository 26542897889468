<template>
  <Line :data="chartData" :options="chartConfig" />
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import { Line } from "vue-chart-3";
import TimeService from "@/services/TimeService";

export interface RunGraphItem
{
    OccurredAt: string;
    Duration: string;
    RunId: string;
    Participants: string[];
}

@Component({
  components: {Line},
})
export default class RunGraph  extends Vue {

    private static seriesColors = ["#4975a3", "#e08916", "rgb(255, 255, 255)", "rgb(255, 255, 255)"];

    @Prop()
    items: RunGraphItem[] | Record<string, RunGraphItem[]> | null = null;

    chartConfig = {
        responseive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: "time",
                time: {
                    unit: "year"
                }
            },
            y: {
                type: "linear",
                ticks: {
                    callback: RunGraph.durationScaleFormat
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: RunGraph.tooltipTitle,
                    label: RunGraph.tooltipLabel,
                }
            },
            legend: {
                display: true,
                onHover: function(e: any) {
                    const target = e.native ? e.native.target : e.target;
                    target.style.cursor = 'pointer';
                }
            }
        },
        onClick: RunGraph.itemClick,
        onHover: function(e: any, items: any[]) {
            const target = e.native ? e.native.target : e.target;
            target.style.cursor = items[0] ? 'pointer' : 'default';
        },
    };

    chartData = {
        datasets: [
            {
                label: "Time",
                data: [] as any[],
                fill: false,
                borderColor: "rgb(255, 255, 255)",
                borderWidth: 2,
                tension: 0.0,
            },
        ],
    };

    mounted() {
        this.updateChartData();
    }

    @Watch("items", {deep: true})
    async itemsChange(val: any, oldVal: any) {
        await this.updateChartData();
    }

    updateChartData() {
        if(this.items == null) {
            this.chartData = {
                datasets: [
                    {
                        label: "Time",
                        data: [],
                        fill: false,
                        borderColor: "rgb(255, 255, 255)",
                        borderWidth: 2,
                        tension: 0.0,
                    },
                ]
            };

            return;
        }

        if(Array.isArray(this.items)) {
            let points = this.items.map(e => { return {
                x: e.OccurredAt,
                y: TimeService.stringToSeconds(e.Duration),
                duration: this.$filters.durationDisplay(e.Duration),
                participants: e.Participants,
                occurredAt: e.OccurredAt
            };});

            this.chartData = {
                datasets: [
                    {
                        label: "Time",
                        data: points,
                        fill: false,
                        borderColor: "rgb(255, 255, 255)",
                        borderWidth: 2,
                        tension: 0.0,
                    },
                ],
            };
        } else {
            let sets = Object.keys(this.items);
            let color = 0;

            this.chartData = {
                datasets: sets.filter(s => (this.items as Record<string,RunGraphItem[]>)[s].length > 0).map(s =>
                {
                    return {
                        label: s,
                        data: (<Record<string, RunGraphItem[]>>this.items)[s].map(e => { return {
                            x: e.OccurredAt,
                            y: TimeService.stringToSeconds(e.Duration),
                            durationSeconds: e.Duration,
                            duration: this.$filters.durationDisplay(e.Duration),
                            participants: e.Participants,
                            occurredAt: e.OccurredAt
                        };}),
                        fill: false,
                        borderColor: RunGraph.seriesColors[color++],
                        borderWidth: 2,
                        tension: 0.0,
                    };
                })
            }
        }
    }

    static durationScaleFormat(tick: any) {
        return TimeService.secondsToString(tick);
    }

    static tooltipTitle(context: any) {
        let item = context[0]?.raw;

        return item.participants.map((p: any) => typeof p == "string" ? p : (p.Username || p.username)).join(", ") + " on " + TimeService.iso8601ToShortFormat(item.occurredAt);
    }

    static tooltipLabel(context: any) {
        let item = context.raw;

        if(context.dataIndex == 0)
            return item.duration;

        let timesave = context.dataset.data[context.dataIndex-1].durationSeconds - item.durationSeconds;
        return `${item.duration} - ${TimeService.secondsToString(timesave, true)} timesave`;
    }


    static itemClick(event: any, context: any[]) {
        if(context.length == 0)
            return;

        let link = context[0]?.element?.$context?.raw?.participants[0]?.EvidenceLink;

        if(link) {
            window.open(link, "_blank");
        }
    }
}
</script>