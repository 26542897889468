<template>
    <div class="home">
        <div class="columns">
            <div class="column is-two-thirds">
                <section class="hr-tile is-relative">
                    <div class="flag-background"></div>
                    <p>
                        <strong>HaloRuns is a safe, inclusive community for gamers of all identities,</strong> including gamers of color,
                        LGBTQIA2S+ gamers, disabled gamers, women gamers, and people of all ages, genders, faiths,
                        nationalities, and citizenship status. Bigotry is not tolerated and may result in a ban from the
                        site and community. Bigotry includes: discrimination, bullying or mocking, harassment, offensive
                        statements, intentional disrespect of one's identity (e.g. misgendering), via any medium
                        including text, voice chat, and streaming.
                    </p>
                </section>
                <section class="hr-tile greeble-tr featured-records">
                    <div class="filters">
                        <h4 class="title is-4">{{recordBoardTitle}}</h4>
                        <span v-if="recordBoardKey">
                            <div class="select">
                                <select v-model="recordBoardKey.GameId" @change="gameChanged">
                                    <option value="featured">Featured</option>
                                    <option v-for="game in games" :key="game.Id" :value="game.Id">
                                        {{ game.Name }}
                                    </option>
                                </select>
                            </div>

                            <div class="select">
                                <select v-model="recordBoardKey.CategoryId" @change="refreshRecordBoard">
                                    <option v-for="category in categories" :key="category.Id" :value="category.Id">
                                        {{ category.Name }}
                                    </option>
                                </select>
                            </div>
                        </span>
                    </div>
                    <RecordBoardView :recordBoardKey="recordBoardKey" :rows="boardData"></RecordBoardView>
                </section>
            </div>

            <div class="column is-one-third">
                <section class="hr-tile">
                    <h4 class="title is-5">
                        Latest World Records
                        <small class="muted is-6">
                            <router-link :to="{name: 'Recent Records'}">(more)</router-link>
                        </small>
                    </h4>
                    <RunFeed v-if="recordsFeed" :feed="recordsFeed.MainEntries"></RunFeed>
                    <div class="separator"></div>
                    <h4 class="title is-5">Recent Runs</h4>
                    <RunFeed v-if="runsFeed" :feed="runsFeed.Entries"></RunFeed>
                </section>

                <section class="hr-tile">
                    <h4 class="title is-5">Current Streamers</h4>
                    <StreamFeed></StreamFeed>
                </section>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import RecordBoardView, { RecordRow } from "@/components/Boards/RecordBoardView.vue";
import RunFeed from "@/components/Feeds/RunFeed.vue";
import StreamFeed from "@/components/Feeds/StreamFeed.vue";
import { RecordBoardKeySelectorConsts } from "@/components/Boards/RecordBoardKeySelectorConsts";
import { GameStore } from "@/store/games";
import {
    Category,
    FeaturedRecordBoard,
    FeaturedRecordBoardRow,
    Game,
    LatestRecordsFeed,
    LatestRunsFeed,
    RecordBoardKey,
    RecordBoardRow,
    StaticContentService,
} from "@/api";
import { Watch } from "vue-facing-decorator";

@Component({
    components: {
        RecordBoardView,
        RunFeed,
        StreamFeed,
    },
})
export default class Home extends Vue {
    public recordBoardKey: RecordBoardKey = { GameId: RecordBoardKeySelectorConsts.FeaturedKey, CategoryId: "Solo" };
    games: Game[] = new Array();
    featuredBoard: FeaturedRecordBoard | null = null;
    boardData: RecordRow[] | null = null;
    runsFeed: LatestRunsFeed | null = null;
    recordsFeed: LatestRecordsFeed | null = null;
    categories: Category[] = [
        <Category>{
            Name: "Solo",
            UrlStub: "Solo",
            Featured: true,
            Id: "Solo",
        },
    ];

    async created() {
        this.games = (await GameStore.get()).games.filter((g) => !g.Hidden && (g.Categories?.filter(c => c.Featured)?.length ?? 0) > 0);
        let globals = await this.$store.state.globals;

        if (!globals?.FeaturedRecordBoard || !globals.RunsFeed || !globals.RecordsFeed) {
            await this.refresh();
        } else {
            this.featuredBoard = globals.FeaturedRecordBoard;
            this.updateCategories();
            this.boardData = this.transformBoardData(globals.FeaturedRecordBoard.EntriesByDesignation!["Solo"], p => p.GameId!);
            this.runsFeed = globals.RunsFeed;
            this.recordsFeed = globals.RecordsFeed;
        }
    }

    async mounted() {
        await this.refresh();
    }

    get recordBoardTitle() {
        if (this.recordBoardKey.GameId == RecordBoardKeySelectorConsts.FeaturedKey) {
            return "Full Game Records";
        } else {
            var filtered = this.games.filter((g) => g.Id == this.recordBoardKey.GameId);
            if (filtered.length == 1) {
                return `${filtered[0].Name} Records`;
            } else {
                return "Game Records";
            }
        }
    }

    oldGameValue?: string | null = null;
    gameChanged() {
        if (this.recordBoardKey.GameId != this.oldGameValue) {
            this.updateCategories();

            if (!this.categories.find((c) => c.Id == this.recordBoardKey.CategoryId)) {
                this.recordBoardKey.CategoryId = this.categories[0].Id;
                this.refreshRecordBoard();
            }
        }

        this.oldGameValue = this.recordBoardKey.GameId;
    }

    updateCategories() {
        if (this.recordBoardKey.GameId == RecordBoardKeySelectorConsts.FeaturedKey) {
            if (this.featuredBoard) {
                this.categories = Object.keys(this.featuredBoard.EntriesByDesignation!).map(
                    (name) =>
                        <Category>{
                            Name: name,
                            UrlStub: name,
                            Featured: true,
                            Id: name,
                        }
                );
            } else {
                this.categories = ["Solo", "Coop"].map(
                    (name) =>
                        <Category>{
                            Name: name,
                            UrlStub: name,
                            Featured: true,
                            Id: name,
                        }
                );
            }
        } else {
            this.categories =
                this.games.find((g) => g.Id == this.recordBoardKey.GameId)?.Categories?.filter((c) => c.Featured) ??
                [];
        }
    }

    async refresh() {
        this.recordsFeed = await StaticContentService.latestRecordsFeed();
        this.runsFeed = await StaticContentService.latestRunsFeed();

        await this.refreshRecordBoard();
    }

    async refreshRecordBoard() {
        if (this.recordBoardKey.GameId == RecordBoardKeySelectorConsts.FeaturedKey) {
            let board = await StaticContentService.featuredRecordBoard();
            this.boardData = this.transformBoardData(
                board.EntriesByDesignation![this.recordBoardKey.CategoryId ?? "Solo"]
            , p => p.GameId!);
        } else {
            let board = await StaticContentService.recordBoard(
                this.recordBoardKey.GameId!,
                this.recordBoardKey.CategoryId!
            );
            this.boardData = this.transformBoardData(board.Entries!, p => p.RunnableSegmentId!);
        }
    }

    private transformBoardData(boardData: (FeaturedRecordBoardRow | RecordBoardRow)[], idProp: (p: (FeaturedRecordBoardRow & RecordBoardRow)) => string): RecordRow[] {
        return (boardData ?? []).reduce((r: RecordRow[], e: FeaturedRecordBoardRow & RecordBoardRow) => {
            let name = e.GameName ?? e.Name ?? "Unspecified Game";

            if (e.CategoryName && e.CategoryName != this.recordBoardKey?.CategoryId) {
                name += " " + e.CategoryName;
            }

            var difs = Object.keys(e.RecordsByDifficulty ?? {}).sort();

            for (const dif of difs) {
                let difName = this.getDifficultyName(e, dif);
                let link = this.getLink(e, difName);
                r.push({
                    name: name,
                    difficulty: difName,
                    id: idProp(e),
                    records: e.RecordsByDifficulty![dif],
                    leaderboard: link,
                });
            }

            return r;
        }, <RecordRow[]>[]);
    }

    private getDifficultyName(entry: FeaturedRecordBoardRow & RecordBoardRow, diffId: string) : string {
        let game = entry.GameId
            ? this.games.find((g) => g.Id == entry.GameId)
            : this.games.find((g) => g.Id == this.recordBoardKey.GameId);

        if (!game) return "Uknown Difficulty";

        var diff = game.Difficulties?.find(d => d.Id == diffId);

        return diff?.Name ?? "Unknown Difficulty";
    }

    private getLink(entry: FeaturedRecordBoardRow & RecordBoardRow, difficulty: string) {
        let game = entry.GameId
            ? this.games.find((g) => g.Id == entry.GameId)
            : this.games.find((g) => g.Id == this.recordBoardKey.GameId);

        if (!game) return { name: "Leaderboards" };

        let params: any = {
            game: game.UrlStub,
            level: game.RunnableSegments?.find((r) => r.Id == entry.SegmentId || r.Name == entry.Name)?.UrlStub,
            difficulty: difficulty,
        };

        if (!game.Categories) {
            return { name: "Leaderboards", params };
        }

        let cat = game.Categories.find((c) => c.Id == entry.CategoryId);

        // fallback to looking for recordboardKey
        if(!cat) {
            cat = game.Categories.find(
                (c) =>
                    c.Name == this.recordBoardKey.CategoryId ||
                    c.Id == this.recordBoardKey.CategoryId
            );
        }

        if (!cat) {
            return { name: "Leaderboards", params };
        }

        params.category = cat.UrlStub;

        if (this.recordBoardKey.GameId == RecordBoardKeySelectorConsts.FeaturedKey || !game.RunnableSegments) {
            return { name: "Leaderboards", params };
        }

        return { name: "Leaderboards", params };
    }
}
</script>

<style scoped lang="scss">
.input.search {
    border: none;
    border-bottom: 2px solid #1f2a3c;
    background: transparent;
}

.separator {
    margin-bottom: 3rem;
}

.flag-background {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height:100%;
    box-sizing: border-box;
    opacity: 0.1;
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));

    background: linear-gradient(
        to bottom,
        #e40303,
        #e40303 16.67%,
        #ff8c00 16.67%,
        #ff8c00 33.33%,
        #ffed00 33.33%,
        #ffed00 50%,
        #008026 50%,
        #008026 66.67%,
        #004dff 66.67%,
        #004dff 83.33%,
        #750787 83.33%,
        #750787
    );
    overflow: hidden;

    &::before {
        position: absolute;
        content: "";
        top:0;
        left: -40px;
        width: 200px;
        height: 50%;
        background: linear-gradient(
            to right,
            #fff,
            #fff 40%,
            #f6a8b7 40%,
            #f6a8b7 55%,
            #5ccefa 55%,
            #5ccefa 70%,
            #784f16 70%,
            #784f16 85%,
            #000 85%,
            #000
        );
        transform: skew(45deg);
        transform-origin: 100% 100%;
    }
    &::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: -40px;
        width: 200px;
        height:  calc(50% + 1px);
        background: linear-gradient(
            to right,
            #fff,
            #fff 40%,
            #f6a8b7 40%,
            #f6a8b7 55%,
            #5ccefa 55%,
            #5ccefa 70%,
            #784f16 70%,
            #784f16 85%,
            #000 85%,
            #000
        );
        transform: skew(-45deg);
        transform-origin: 0% 0%;
    }
}

.featured-records .filters {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 6px; // child's select has a 4px margin, so this is 6px
    flex-wrap: wrap;

    h4 {
        margin-bottom: 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 768px) {
    .featured-records .filters {
        text-align: center;
        display: block;
        margin-bottom: 0.5rem;

        h4 {
            margin-bottom: 1rem;
        }
    }
}
</style>
