import { Directive, VNode } from "vue";

const OnAsync: Directive<HTMLElement & VNode, (() => Promise<any>)> = {
    mounted(el, binding) {
        if(binding.value == null) {
            throw "No async method provided to OnAsync directive";
        }

        let intendedEvent = binding.arg;

        if(intendedEvent == "" || intendedEvent == null) {
            intendedEvent = "click";
        }

        el.addEventListener(intendedEvent, async e =>
        {
            if(el.getAttribute("disabled")) {
                return;
            }

            try {
                el.setAttribute("disabled", "disabled");
                await binding.value();
            } finally {
                el.removeAttribute("disabled");
            }
        });
    }
};

export default OnAsync;