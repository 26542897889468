<template>
    <tbody>
        <tr v-if="levelEntry.length == 0">
            <td><router-link :to="{name: 'Leaderboards', params: {game: game.UrlStub, category: category.UrlStub, level: level.UrlStub, difficulty: difficulty.Name}}">{{level.Name}}</router-link></td>
            <td> - </td>
            <td> - </td>
            <td> - </td>
        </tr>
        <tr v-for="entry in levelEntry" :key="entry.RunnableSegmentId">
            <td><router-link :to="{name: 'Leaderboards', params: {game: game.UrlStub, category: category.UrlStub, level: level.UrlStub, difficulty: difficulty.Name}}">{{level.Name}}</router-link></td>
            <td><a v-if="entry.Runs[entry.Runs.length -1].Participants[0].EvidenceLink" :href="entry.Runs[entry.Runs.length -1].Participants[0].EvidenceLink" class="timecode" target="_blank" rel="noopener noreferrer">{{ $filters.durationDisplay(entry.PersonalBest) }}</a>
            <span v-else class="timecode">{{$filters.durationDisplay(entry.PersonalBest)}}</span></td>
            <td><router-link :to="entryLink(entry)" >{{entry.TotalRuns}}</router-link></td>
            <td>{{$filters.durationDisplay(entry.AverageTimesave, true)}}</td>
        </tr>
    </tbody>
</template>
<script lang="ts">
import { Category, DifficultyDto, Game, Run, RunnableSegment } from "@/api";
import { GameStore } from "@/store/games";
import { Component, Vue } from "vue-facing-decorator";
import { Prop } from "vue-facing-decorator";

@Component({})
export default class UserHistoryGameLevel extends Vue {
    @Prop()
    runs: any[] = new Array();

    @Prop()
    game: Game | null = null;

    @Prop()
    category: Category | null = null;

    @Prop()
    difficulty: DifficultyDto | null = null;

    @Prop()
    level: RunnableSegment | null = null;
    games: Record<string, Game> = {};

    async created() {
        let games = (await GameStore.get()).games;
        this.games = games.reduce((o,g) => { o[g.Id!] = g; return o; }, {} as Record<string, Game>);
    }

    get levelEntry() {
        return this.runs.filter((r) => r.RunnableSegmentId == this.level?.Id && r.DifficultyId == this.difficulty?.Id);
    }

    public entryLink(entry: any) {
        let route = {name: "Level History", params: {gameId: entry.GameId, categoryId: entry.CategoryId, segmentId: entry.RunnableSegmentId}};

        let game = this.games[entry.GameId];

        if(game != null) {
            route.params.gameId = game.UrlStub;

            let cat = game.Categories?.find(c => c.Id == entry.CategoryId)?.UrlStub;
            if(cat != null) {
                route.params.categoryId = cat;
            }

            let seg = game.RunnableSegments?.find(c => c.Id == entry.RunnableSegmentId)?.UrlStub;
            if(seg != null) {
                route.params.segmentId = seg;
            }
        }

        return route;
    }
}
</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;
table tbody:not(:last-child) {
    border-bottom: 1px solid $border-color;
}
</style>