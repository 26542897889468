import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { class: "control" }
const _hoisted_5 = { class: "field is-grouped is-justify-content-flex-end" }
const _hoisted_6 = { class: "control" }
const _hoisted_7 = { class: "button is-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_on_async = _resolveDirective("on-async")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.resetInitiated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " An email with instructions to reset your password was sent to the email address for the account "))
      : _createCommentVNode("", true),
    (!_ctx.resetInitiated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("label", { class: "label" }, "Username", -1)),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.resetRequest.Username) = $event))
              }, null, 512), [
                [_vModelText, _ctx.resetRequest.Username]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _withDirectives((_openBlock(), _createElementBlock("a", _hoisted_7, _cache[2] || (_cache[2] = [
                _createTextVNode("Send Reset Email")
              ]))), [
                [_directive_on_async, _ctx.submit, "click"]
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}