import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.hydratedBadge)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "badge-display",
        title: _ctx.hydratedBadge.Reason
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _ctx.hydratedBadge.ImageUrl,
            alt: _ctx.hydratedBadge.Reason
          }, null, 8, _hoisted_2)
        ]),
        _createElementVNode("div", null, _toDisplayString(_ctx.hydratedBadge.Name), 1)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}