<template>
    <tbody>
        <tr v-if="levelRuns.length == 0">
            <td><router-link :to="{name: 'Leaderboards', params: {game: game.UrlStub, category: category.UrlStub, level: level.UrlStub, difficulty: difficulty.Name}}">{{level.Name}}</router-link></td>
            <td v-if="category?.AllowedPartners > 0">-</td>
            <td>-</td>
            <td></td>
            <td></td>
            <td width="0%" v-if="editable"></td>
        </tr>
        <tr v-for="(run,r) in levelRuns" :key="run?.Id">
            <td v-if="r == 0">
                <router-link :to="{name: 'Leaderboards', params: {game: game.UrlStub, category: category.UrlStub, level: level.UrlStub, difficulty: difficulty.Name}}">{{level.Name}}</router-link>
            </td>
            <td v-if="r > 0"></td>
            <td v-if="category?.AllowedPartners > 0">
                <template v-for="(partner,p) in run.Participants" :key="partner.Username"><router-link :to="{name: 'Profile', params: {username: partner.Username}}">{{partner.Username}}</router-link><template v-if="p < run.Participants.length-1">, </template></template>
            </td>
            <td>
                <a v-if="run.Participants[0].EvidenceLink" :href="run.Participants[0].EvidenceLink" class="timecode" target="_blank" rel="noopener noreferrer">{{ $filters.durationDisplay(run.Duration) }}</a>
                <span v-else class="timecode">{{$filters.durationDisplay(run.Duration)}}</span>
            </td>
            <td class="has-text-right pr-0">
                <span v-if="run.RankInfo?.Rank == 1" class="trophy-container"><i class="trophy in-line rank-1"></i></span>
                <span v-if="run.RankInfo?.Rank == 2" class="trophy-container"><i class="trophy in-line rank-2"></i></span>
                <span v-if="run.RankInfo?.Rank == 3" class="trophy-container"><i class="trophy in-line rank-3"></i></span>
            </td>
            <td>
                <span>{{run.RankInfo?.Score}}</span>
            </td>
            <td width="0%" v-if="editable">
                <router-link v-if="run" :to="'/runs/' + run.Id + '/' + run.PartitionKey">
                    <i class="fas fa-edit icon-button" aria-hidden></i>
                    <span class="visible-hidden">Edit run</span>
                </router-link>
            </td>
        </tr>
    </tbody>
</template>
<script lang="ts">
import { Category, DifficultyDto, Game, Run, RunnableSegment } from "@/api";
import { Component, Vue } from "vue-facing-decorator";
import { Prop } from "vue-facing-decorator";

@Component({})
export default class UserCareerGameLevel extends Vue {
    @Prop()
    runs: any[] = new Array();

    @Prop()
    game: Game | null = null;

    @Prop()
    category: Category | null = null;

    @Prop()
    difficulty: DifficultyDto | null = null;

    @Prop()
    level: RunnableSegment | null = null;

    @Prop()
    editable: boolean = false;

    get levelRuns() {
        return this.runs.filter((r) => r.RunnableSegmentId == this.level?.Id && r.DifficultyId == this.difficulty?.Id);
    }
}
</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;
table tbody {
    &:not(:last-child) {
        border-bottom: 1px solid $border-color;
    }

    .trophy-container {
        margin-right: 0.7rem;
    }
}
</style>