import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "stream-feed" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "images" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "stream-user" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "stream-title" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 2,
  class: "is-size-7 has-text-grey-light"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.feed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feed.Entries, (entry) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "feed-entry",
              key: entry.RunId
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", {
                  href: entry.StreamUrl,
                  target: "_blank",
                  rel: "noopener noreferrer"
                }, [
                  _createElementVNode("img", {
                    class: "profile-image",
                    src: entry.ProfileImageUrl.replace('300x300', '70x70'),
                    width: "40",
                    height: "40"
                  }, null, 8, _hoisted_5)
                ], 8, _hoisted_4)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_6, [
                  _createElementVNode("a", {
                    href: entry.StreamUrl,
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }, _toDisplayString(entry.Username), 9, _hoisted_7),
                  _cache[0] || (_cache[0] = _createTextVNode(" - ")),
                  _createElementVNode("small", null, _toDisplayString(entry.Viewers) + " viewers", 1)
                ]),
                _createElementVNode("small", _hoisted_8, _toDisplayString(entry.Title), 1),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("small", null, _toDisplayString(_ctx.uptime(entry.StartedAt)), 1),
                (_ctx.canModify)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (entry.UserId && entry.toggled == undefined)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            onClick: ($event: any) => (_ctx.removeFromList(entry))
                          }, "Toggle stream visibility for " + _toDisplayString(entry.Username), 9, _hoisted_10))
                        : _createCommentVNode("", true),
                      (entry.toggled)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Visibilty toggled, will update on next refresh"))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.feed || _ctx.feed.Entries == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[2] || (_cache[2] = [
          _createElementVNode("p", null, "No streams to show!", -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.feed && _ctx.$store.state.devMode)
      ? (_openBlock(), _createElementBlock("p", _hoisted_13, "Updated " + _toDisplayString(_ctx.feed.UpdatedAt), 1))
      : _createCommentVNode("", true)
  ]))
}