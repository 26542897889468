import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      _createElementVNode("li", {
        class: _normalizeClass({'is-active': _ctx.$route.name == 'Profile'})
      }, [
        _createVNode(_component_router_link, { to: {name: 'Profile'} }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Career")
          ])),
          _: 1
        })
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass({'is-active': _ctx.$route.name == 'History'})
      }, [
        _createVNode(_component_router_link, { to: {name: 'History'} }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("History")
          ])),
          _: 1
        })
      ], 2),
      (_ctx.isOwnProfile)
        ? (_openBlock(), _createElementBlock("li", {
            key: 0,
            class: _normalizeClass({'is-active': _ctx.$route.name == 'Recent'})
          }, [
            _createVNode(_component_router_link, { to: {name: 'Recent'} }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Recent")
              ])),
              _: 1
            })
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.isOwnProfile)
        ? (_openBlock(), _createElementBlock("li", {
            key: 1,
            class: _normalizeClass({'is-active': _ctx.$route.name == 'UserSettings'})
          }, [
            _createVNode(_component_router_link, { to: {name: 'UserSettings'} }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Settings")
              ])),
              _: 1
            })
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}