import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nav-wrapper" }
const _hoisted_2 = {
  id: "mainNavbar",
  class: "container nav"
}
const _hoisted_3 = { class: "nav-mandatory" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-toggle" }
const _hoisted_7 = { class: "nav-left" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = {
  key: 0,
  class: "nav-right"
}
const _hoisted_13 = {
  key: 0,
  class: "nav-item non-upper"
}
const _hoisted_14 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_15 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_16 = {
  key: 3,
  class: "nav-item"
}
const _hoisted_17 = {
  key: 4,
  class: "nav-item"
}
const _hoisted_18 = {
  key: 1,
  class: "nav-right"
}
const _hoisted_19 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_20 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_21 = {
  key: 2,
  class: "nav-item non-upper"
}
const _hoisted_22 = { class: "nav-item dropdown is-right" }
const _hoisted_23 = {
  class: "dropdown-menu",
  id: "user-menu",
  role: "menu"
}
const _hoisted_24 = { class: "dropdown-content" }
const _hoisted_25 = {
  key: 1,
  class: "dropdown-divider"
}
const _hoisted_26 = {
  key: 2,
  class: "nav-right"
}
const _hoisted_27 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_focus_toggle = _resolveDirective("focus-toggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("img", {
                src: "/img/logo_vec_color.png",
                width: "64",
                height: "64"
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_link, { to: "/leaderboards" }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Leaderboards")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
        }, "≡")
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["nav-extras", { 'nav-shown': _ctx.$store.state.nav }])
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_router_link, { to: "/extras" }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Extras")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_router_link, { to: "/runners" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Runners")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_router_link, { to: "/rules" }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Rules")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_router_link, { to: "/discord" }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("img", {
                  class: "nav-desktop",
                  src: "/img/Discord-Logo-White.png",
                  width: "18",
                  height: "18"
                }, null, -1),
                _createElementVNode("span", { class: "nav-mobile" }, "HaloRuns Discord ", -1)
              ])),
              _: 1
            })
          ])
        ]),
        (_ctx.$store.state.isMobile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_ctx.auth.isAuthenticated)
                ? (_openBlock(), _createElementBlock("p", _hoisted_13, [
                    _createVNode(_component_router_link, {
                      to: '/profiles/' + _ctx.auth.username
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.auth.username), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.auth.isAuthenticated)
                ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
                    _createVNode(_component_router_link, { to: "/submit" }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Submit")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.auth.isAuthenticated && _ctx.hasAdminClaim)
                ? (_openBlock(), _createElementBlock("p", _hoisted_15, [
                    _createVNode(_component_router_link, { to: "/admin" }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("Admin")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.auth.isAuthenticated)
                ? (_openBlock(), _createElementBlock("p", _hoisted_16, [
                    _createVNode(_component_router_link, { to: "/auth/login" }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode("Login")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.auth.isAuthenticated)
                ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                    _createElementVNode("a", {
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                    }, "Logout")
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_ctx.auth.isAuthenticated)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                (_ctx.auth.isAuthenticated)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_19, [
                      _createVNode(_component_router_link, { to: "/submit" }, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode("Submit")
                        ])),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hasAdminClaim && _ctx.attentionRequired)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_20, [
                      _createVNode(_component_router_link, { to: _ctx.attentionRequired }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode("(!)")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.auth.isAuthenticated)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_21, [
                      _createVNode(_component_router_link, {
                        to: '/profiles/' + _ctx.auth.username
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.auth.username), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_22, [
                  _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: "dropdown-trigger",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.menuPress && _ctx.menuPress(...args))),
                    tabindex: "0"
                  }, _cache[16] || (_cache[16] = [
                    _createElementVNode("button", {
                      class: "button",
                      "aria-haspopup": "true",
                      "aria-controls": "dropdown-menu",
                      "tab-index": "-1"
                    }, [
                      _createElementVNode("span", { class: "icon is-small" }, [
                        _createElementVNode("i", {
                          class: "fas fa-caret-down",
                          "aria-hidden": "true"
                        })
                      ])
                    ], -1)
                  ]))), [
                    [_directive_focus_toggle, 'is-active', "parent"]
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      (_ctx.hasAdminClaim)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: {name: 'Admin'},
                            class: "dropdown-item"
                          }, {
                            default: _withCtx(() => _cache[17] || (_cache[17] = [
                              _createTextVNode("Admin")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.hasAdminClaim)
                        ? (_openBlock(), _createElementBlock("hr", _hoisted_25))
                        : _createCommentVNode("", true),
                      (_ctx.showAdvanced)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 2,
                            class: "dropdown-item",
                            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clearCaches && _ctx.clearCaches(...args)))
                          }, " Clear Caches "))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        class: "dropdown-item",
                        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                      }, " Logout ")
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _createElementVNode("p", _hoisted_27, [
                  _createVNode(_component_router_link, { to: "/auth/login" }, {
                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createTextVNode("Login")
                    ])),
                    _: 1
                  })
                ])
              ]))
      ], 2)
    ])
  ]))
}