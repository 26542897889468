<template>
    <div class="overlay" v-if="showModal" @click="overlayCancel">
        <div class="modal-row">
            <div class="modal-box">
                <p class="is-large" v-for="message in messages" :key="message">{{message}}</p>
                <br/>
                <textarea class="textarea" v-if="args.entryType != 'password'" v-model="promptValue"></textarea>
                <input type="password" class="input" v-if="args.entryType == 'password'" v-model="promptValue" />
                <div class="buttons">
                    <button class="button is-ghost" @click="cancel()">{{args.cancelAlias}}</button>
                    <button class="button is-primary" @click="confirm()">{{args.confirmAlias}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">

import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import PromptArguments, { PromptPlugin } from '@/plugins/Prompt';


@Component({})
export default class Prompt extends Vue {
    private static DefaultArgs: PromptArguments = {
        message: "Are you sure?",
        confirmAlias: "Confirm",
        cancelAlias: "Cancel",
        entryType: "text"
    };

    showModal = false;
    promptValue: string | null = null;
    args = Prompt.DefaultArgs;
    messages: string[] = new Array();
    pendingConfirm: Promise<[boolean, string|null]> = Promise.resolve<[boolean, string|null]>([true, null]);

    resolver: ((v: [boolean, string|null]) => void) | null = null;

    created() {
        PromptPlugin.prompt = this.prompt;
    }

    public prompt(args: PromptArguments | null): Promise<[boolean, string|null]> {
        this.pendingConfirm = new Promise<[boolean, string|null]>((resolve, reject) => {
            this.resolver = resolve;
        });
        
        this.args = Object.assign({}, Prompt.DefaultArgs, args);

        if(args?.message) {
            this.messages = args?.message.split("\n");
        } else {
            this.messages = [args?.message!];
        }

        this.showModal = true;
        return this.pendingConfirm;
    }

    public cancel() {
        if(this.resolver)
            this.resolver([false, this.promptValue]);

        this.showModal = false;
        this.promptValue = null;
    }

    public confirm() {
        if(this.resolver)
            this.resolver([true, this.promptValue]);

        this.showModal = false;
        this.promptValue = null;
    }

    public overlayCancel(e: InputEvent) {
        if((e.target as HTMLElement).classList.contains("overlay")) {
            this.cancel();
        }
    }
}

</script>

<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.9);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 

    .modal-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; 
        width: 100%;

        .modal-box {
            min-width: 30%;
            background-color: $body-background-color;
            padding: 2rem;
            z-index: 1000;

            .buttons {
                margin-top: 2rem;
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
            }
        }
    }
}


</style>