/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class MetadataService {

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static async updateNonUserTwitchStreams(
requestBody?: Array<string>,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Metadata/streamlist/twitchAccounts`,
            body: requestBody,
        });
        return result.body;
    }

}