declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $toast: ToastInterface
    }
}

import { ToastInterface, useToast } from "vue-toastification";
import { ToastID } from "vue-toastification/dist/types/types";

export class ToastPlugin {
    static toaster = useToast();

    static install(app: any, options: any) {
      app.config.globalProperties.$toast = useToast();
    }
}