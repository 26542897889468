<template>
<div class="user-history">
    <div>
        <div class="hr-tile">
            <h4 class="title is-4">{{$route.params.username}}'s {{game?.Name}} {{segment?.Name}} History - {{category?.Name}} </h4>
            <br/>
            <button class="button is-primary" v-on-async="rebuildHistory" v-if="$store.state.devMode">Rebuild history</button>
            <div class="graph">
                <run-graph class="graph-container" :items="runsByDifficulty"></run-graph>
            </div>
        </div>

        <div v-if="game" class="columns is-multiline">
            <div v-for="difficulty in game.Difficulties" :key="difficulty.Id"  class="column is-half-desktop is-full-tablet">
                <div class="user-career-game hr-tile">
                    <h2 class="title is-5">{{game?.Name}} {{category?.Name}} {{segment?.Name}} - {{difficulty?.Name}}</h2>
                    <table class="level-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="run in runsByDifficulty[difficulty.Name!]" :key="run.RunId">
                                <td>{{formatDate(run.OccurredAt)}}</td>
                                <td>
                                    <a target="_blank"  rel="noopener noreferrer" v-if="run.Participants[0]?.EvidenceLink" :href="run.Participants[0]?.EvidenceLink">{{$filters.durationDisplay(run.Duration)}}</a>
                                    <span v-else>{{$filters.durationDisplay(run.Duration)}}</span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="!history">
                            <tr v-for="i in 8" :key="i">
                                <td>
                                    <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                                        <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                                    </ContentLoader>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
    </div>
</div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { Prop } from "vue-facing-decorator";
import UserHistoryGame from "@/components/User/UserHistoryGame.vue";
import { GameStore } from "@/store/games";
import { Game, Category, RunnableSegment, UserRunHistoryRollup, UserSegmentHistoryEntry, StaticContentService } from "@/api";
import OnAsync from "@/directives/OnAsync";
import { useToast } from "vue-toastification";
import RunGraph from "@/components/RunGraph.vue";
import TimeService from "@/services/TimeService";
import { ContentLoader } from "vue-content-loader";

@Component({
    components: {
        UserHistoryGame,
        RunGraph,
        ContentLoader
    },
    directives: {
        OnAsync
    }
})
export default class UserSegmentHistory extends Vue {
    history: UserRunHistoryRollup | null = null;
    games: Game[] = new Array();
    toast = useToast();

    @Prop()
    public userId: string | null = null;
    game: Game | undefined | null = null;
    category: Category | undefined | null = null;
    segment: RunnableSegment | undefined | null = null;
    runsByDifficulty: Record<string, UserSegmentHistoryEntry[]> = {};

    async created() {
        this.games = (await GameStore.get()).games;
        let gameId = this.$route.params.gameId;
        let catId = this.$route.params.categoryId;
        let segmentId = this.$route.params.segmentId;

        this.game = this.games.find(g => g.Id == gameId || g.UrlStub == gameId);
        if(this.game?.Categories == null || this.game?.RunnableSegments == null) return;

        this.category = this.game.Categories.find(c => c.Id == catId || c.UrlStub == catId);
        this.segment = this.game.RunnableSegments.find(g => g.Id == segmentId || g.UrlStub == segmentId);

        await this.load();
    }

    async load() {
        if(this.userId == null) return;
        try {
            this.history = await StaticContentService.userHistory(this.userId);

            if(this.history?.EntriesByCategory == null) return;

            this.runsByDifficulty = this.history.EntriesByCategory[this.category!.Name!]
                .filter(e => e.GameId == this.game?.Id && e.CategoryId == this.category?.Id && e.RunnableSegmentId == this.segment?.Id)
                .reduce((o,r) => {
                    let dif = this.game?.Difficulties?.find(d => d.Id == r?.DifficultyId!);
                    o[dif?.Name!] = r.Runs ?? [];
                    return o;
                }, {} as Record<string,UserSegmentHistoryEntry[]>);
        } catch (e: any) {
            this.toast.error("Unable to load user history: " + e.toString());
        }
    }

    async rebuildHistory() {
        let rebuildEvent = {
            Event: "UserRunHistoryRollupUpdateEvent",
            Payload: {
                UserId: this.userId,
            },
        };

        //await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
        await this.load();
    }

    public formatDate(datetime: string) {
        return TimeService.iso8601ToShortFormat(datetime);
    }
}
</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;



.filters {
    align-items: baseline;

    .input.search {
        height: 2.5em;
        border: none;
        border-bottom: 2px solid #1f2a3c;
        background: transparent;
        transform: translateY(1px);

        &:hover, &:active, &:focus {
            border-color: $primary;
        }
    }
}

.graph {
    height: 320px;
    position: relative;

    .graph-container {
        height: 100%;
        position: relative;
    }
}

</style>