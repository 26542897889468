<template>
    <div class="home hr-tile">
        <user-profile-tabs :is-own-profile="isOwnProfile"></user-profile-tabs>
        
    </div>

    <div v-if="career" class="hr-tile career-details">
        <h2 class="title is-4">
            <span class="select inline-select">
                {{ chosenCategory }}
                <select v-model="chosenCategory">
                    <option v-for="category in catOrder" :key="category">{{category}}</option>
                </select>
            </span>
             Career
        </h2>
        <template v-if="career.RankInfoByCategory[chosenCategory]">
            <div>
                <h6 class="subtitle is-6 is-inline">Games: </h6>
                <span v-for="game in games" :key="game.Id" class="game-symbol" :class="{'submitted': career.SubmittedGamesByCategory[chosenCategory].indexOf(game.Id) >= 0}">
                    {{game.UrlStub}}
                </span>
            </div>
            <div>
                <h6 class="subtitle is-6 is-inline">Live Runs: </h6>{{career.RunsByCategory[chosenCategory]?.length || 0}}
            </div>
            <h6 class="subtitle is-6 is-inline">Points: </h6>{{career.RankInfoByCategory[chosenCategory].Points}}<small class="muted">pts</small>
            <div v-for="info in [career.RankInfoByCategory[chosenCategory]]" :key="info">
                <h6 class="subtitle is-6 is-inline">Trophies: </h6>
                <template v-if="info.GoldCount + info.SilverCount + info.BronzeCount">
                    <span v-if="info.GoldCount" class="trophy-container"><i class="trophy in-line rank-1"></i> &times; {{info.GoldCount}} </span>
                    <span v-if="info.SilverCount" class="trophy-container"><i class="trophy in-line rank-2"></i> &times; {{info.SilverCount}} </span>
                    <span v-if="info.BronzeCount" class="trophy-container"><i class="trophy in-line rank-3"></i> &times; {{info.BronzeCount}} </span>
                </template>
            </div>
            
        </template>
    </div>

    <div style="min-height: 100vh" v-masonry:[$route]="$store.state.windowWidth <= 1215 ? 1 : 2">
        <template v-if="career && chosenCategory" v-for="game in categoryGames" :key="game.Id">
            <user-career-game :runs="career.RunsByCategory[chosenCategory]" :categoryName="chosenCategory" :game="game" :editable="canEditRuns" :submittedOnly="true"></user-career-game>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { Prop, Watch } from "vue-facing-decorator";
import ProfileInfo from "@/components/User/ProfileInfo.vue";
import {
  Claim,
    Game,
    RunSubmissionsService,
    RunSubmissionStatus,
    UserCareer,
    UserProfile
} from "@/api";
import OnAsync from "@/directives/OnAsync";
import { GameStore } from "@/store/games";
import TimeService from "@/services/TimeService";
import UserProfileTabs from "@/components/User/UserProfileTabs.vue";
import UserCareerGame from "./UserCareerGame.vue";
import { ContentLoader } from "vue-content-loader";
import Masonry from "@/directives/Masonry";
import { useToast } from "vue-toastification";
import { th } from "date-fns/locale";

interface ResolvedRunSubmission {
    Description: string;
    SubDescription: string;
    Duration?: number;
    Evidence?: string | null;
    SubmittedAt?: string;
    Status?: RunSubmissionStatus;
    Reason?: string | null;
}

@Component({
    components: {
        ProfileInfo,
        UserProfileTabs,
        UserCareerGame,
        ContentLoader
    },
    directives: {
        OnAsync,
        Masonry
    }
})
export default class UserSummary extends Vue {
    public recentSubmissions: ResolvedRunSubmission[] | null = null;
    public games: Game[] = new Array();

    chosenCategory: string | null = null;
    toast = useToast();
    levelSearch: string | null = null;
    canEditRuns = false;

    @Prop()
    public user: UserProfile | null = null;

    @Prop()
    public career: UserCareer | null = null;

    @Prop()
    public isOwnProfile: boolean = false;

    get catOrder() {
        return Object.keys(this.career?.RunsByCategory!).sort((a, b) => {  
            if (a == "Solo") return -1;
            if (b == "Solo") return 1;
            if (a == "Coop") return -1;
            if (b == "Coop") return 1;
            return 0;
        });
    }

    async created() {
        this.games = (await GameStore.get()).games;

        if (this.isOwnProfile) {
            await this.showRecentSubmissions();
        }
    }

    async mounted() {
        await this.load();
    }

    @Watch("career")
    public async watchCareer() {
        await this.load();
    }

    async load() {
        this.chosenCategory = null;

        var hrClaims = this.$store.state.auth.hrClaimStrings;

        this.canEditRuns = (this.$store.state.auth.claims.userId == this.user?.UserId && hrClaims.indexOf(Claim.SELF_READ_WRITE) > 0)
            || hrClaims.indexOf(Claim.MODIFY_RUNS_GLOBAL) > 0
            || hrClaims.indexOf(Claim.MODIFY_RUNS_LIMITED) > 0;

        if(this.career?.RunsByCategory == null) return;

        this.chosenCategory = this.catOrder[0];
    }

    get totalPoints() {
        if(this.career?.RankInfoByCategory == null) return;

        return Object.values(this.career.RankInfoByCategory).reduce((a,r) => a + (r?.Points! || 0), 0)
    }

    get totalTrophies() {
        if(this.career?.RankInfoByCategory == null) return;

        return Object.values(this.career.RankInfoByCategory).reduce((a,r) => a + (r?.GoldCount! || 0) + (r?.SilverCount! || 0) + (r?.BronzeCount! || 0), 0)
    }

    get categoryGames() {
        return this.games.filter(g => g.Categories && g.Categories.filter(c => c.Name == this.chosenCategory).length > 0);
    }

    @Watch("isOwnProfile")
    async showRecentSubmissions() {
        if(this.isOwnProfile == false) {
            this.recentSubmissions = null;
            return;
        }

        let rawSubmissions = await RunSubmissionsService.getRecentSubmissions();
        if (!rawSubmissions || rawSubmissions.length == 0) {
            this.recentSubmissions = null;
        }

        let resolvedSubs = new Array() as ResolvedRunSubmission[];

        for (let sub of rawSubmissions) {
            var game = this.games.find((g) => g.Id == sub.GameId);
            if (!game?.Categories || !game.RunnableSegments) continue;
            var cat = game.Categories.find((c) => c.Id == sub.CategoryId);
            var seg = game.RunnableSegments.find((s) => s.Id == sub.RunnableSegmentId);
            if (!cat || !seg) continue;

            let desc = `${game.Name} ${sub.DifficultyId}`;
            let subdesc = `${seg.Name} - ${cat.Name}`;

            resolvedSubs.push({
                Description: desc,
                SubDescription: subdesc,
                Duration: sub.Duration,
                Evidence: sub.Participants![0].EvidenceLink,
                SubmittedAt: TimeService.iso8601ToShortFormat(sub.SubmittedAt!),
                Status: sub.Status,
                Reason: sub.Reason,
            });
        }

        this.recentSubmissions = resolvedSubs;
    }
}
</script>

<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.submission-entries {
    @include hr-tile-full-bleed;
    border-top: 2px solid $border-color;

    .submission-entry {
        border-bottom: 1px solid $border-color;
        margin-bottom: 5px;
        padding: 5px 15px;
    }
}

.career-details {
    .inline-select select {
        font-size: 1.5rem;
        height: 2.4rem;
        letter-spacing: 0.1rem;
        font-weight: 500;
    }

    .inline-select.select::after {
        right: 0.6rem;
    }

    .inline-select:not(.is-multiple) {
        height: 1.8rem;
    }

    .game-symbol {
        margin: 0 0.25rem;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        opacity: 0.25;

        &:first-child {
            margin-left: 0;
        }

        &.submitted {
            opacity: 1;
        }
    }
}

.trophy-container {
    margin-right: 1rem;
}

</style>