<template>
    <div class="run-feed">
        <div v-if="feed">
            <div class="feed-entry" v-for="entry in feed" :key="entry.RunId">
                <div class="images">
                    <img class="game-image" v-bind:src="'/img/app-icons/' + entry.Difficulty + '.svg'">
                </div>
                <div>
                    <p>{{ entry.GameName }} - <a v-if="entry.Participants[0].EvidenceLink" :href="entry.Participants[0].EvidenceLink" target="_blank" rel="noopener noreferrer" class="timecode">{{$filters.durationDisplay(entry.Duration)}}</a><span v-else class="timecode">{{$filters.durationDisplay(entry.Duration)}}</span><span v-if="entry.Tie"> tied</span> by 
                        <span v-for="(p,i) in entry.Participants" :key="p">
                            <router-link :to="'/profiles/' + p.Username">{{ p.Username}}</router-link><span v-if="entry.Participants.length > 0 && i < entry.Participants.length-1">, </span>
                        </span></p>
                    <small><router-link :to="leaderboardLink(entry)">{{ entry.LevelName }} - {{entry.CategoryName}}</router-link></small>
                </div>
            </div>
        </div>
        <div v-if="!feed">
            <p>No runs to show!</p>
        </div>
    </div>
</template>

<script lang="ts">
import { RunFeedEntry } from "@/api";
import { Component, Vue, Prop } from "vue-facing-decorator";

@Component({})
export default class RunFeed extends Vue {
    @Prop()
    public feed:  RunFeedEntry[] | undefined | null = null;

    public leaderboardLink(entry: RunFeedEntry) {
        return entry.LeaderboardUrl!.replace(window.location.protocol + '//' + window.location.host, '');
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.feed-entry {
    display: flex;

    $badge-size: 26px;
    $badge-padding: 5px;

    $game-image-size: 35px;
    $game-image-container: $game-image-size + ($badge-size + $badge-padding * 2) / 2;

    .images {
        display: inline-block;
        width: $game-image-size;
        height: $game-image-size;
        position: relative;
        flex-shrink: 0;
        margin-right: 15px;

        .game-image {
            border-radius: 50%;
            margin: 5px;
            z-index: 3;
            width: $game-image-size;
            height: $game-image-size;
        }
    }

    margin-bottom: 1rem;
}

</style>
