import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, vModelSelect as _vModelSelect, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-history" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "hr-tile" }
const _hoisted_4 = { class: "headline" }
const _hoisted_5 = { class: "title is-4" }
const _hoisted_6 = {
  key: 0,
  class: "button is-primary"
}
const _hoisted_7 = { class: "columns is-multiline" }
const _hoisted_8 = { class: "user-career-game hr-tile" }
const _hoisted_9 = ["width"]
const _hoisted_10 = { class: "level-table" }
const _hoisted_11 = ["width"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "hr-tile toggle-button-parent" }
const _hoisted_14 = { class: "headline" }
const _hoisted_15 = { class: "title is-4" }
const _hoisted_16 = { class: "filters field is-horizontal" }
const _hoisted_17 = {
  key: 0,
  class: "button is-primary"
}
const _hoisted_18 = { class: "select" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { style: {"min-height":"100vh"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_profile_tabs = _resolveComponent("user-profile-tabs")!
  const _component_ContentLoader = _resolveComponent("ContentLoader")!
  const _component_UserHistoryGame = _resolveComponent("UserHistoryGame")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_on_async = _resolveDirective("on-async")!
  const _directive_masonry = _resolveDirective("masonry")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.name == 'History')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!_ctx.history)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_user_profile_tabs, { "is-own-profile": _ctx.isOwnProfile }, null, 8, ["is-own-profile"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$route.params.username ?? 'User') + "'s Run History", 1),
                    _createElementVNode("div", null, [
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "select" }, [
                        _createElementVNode("select", null, [
                          _createElementVNode("option")
                        ])
                      ], -1)),
                      (_ctx.$store.state.devMode)
                        ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_6, _cache[1] || (_cache[1] = [
                            _createTextVNode("Rebuild history")
                          ]))), [
                            [_directive_on_async, _ctx.rebuildHistory]
                          ])
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (i) => {
                    return _createElementVNode("div", {
                      key: i,
                      class: "column is-half-desktop is-full-tablet"
                    }, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_ContentLoader, {
                          width: "170",
                          height: "43.5",
                          "primary-color": "#1f2a3c",
                          "secondary-color": "#2c3b55"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("rect", {
                              x: "0",
                              y: "5",
                              rx: "2",
                              ry: "2",
                              width: (150 + Math.random() * 20),
                              height: "21"
                            }, null, 8, _hoisted_9)
                          ]),
                          _: 1
                        }),
                        _createElementVNode("table", _hoisted_10, [
                          _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, "Level"),
                              _createElementVNode("th", null, "PB"),
                              _createElementVNode("th", null, "Past PBs"),
                              _createElementVNode("th", null, "Avg Timesave")
                            ])
                          ], -1)),
                          _createElementVNode("tbody", null, [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (j) => {
                              return _createElementVNode("tr", { key: j }, [
                                _createElementVNode("td", null, [
                                  _createVNode(_component_ContentLoader, {
                                    width: "170",
                                    height: "18",
                                    "primary-color": "#1f2a3c",
                                    "secondary-color": "#2c3b55"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("rect", {
                                        x: "0",
                                        y: "5",
                                        rx: "2",
                                        ry: "2",
                                        width: (150 + Math.random() * 20),
                                        height: "10"
                                      }, null, 8, _hoisted_11)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _cache[3] || (_cache[3] = _createElementVNode("td", null, null, -1)),
                                _cache[4] || (_cache[4] = _createElementVNode("td", null, null, -1)),
                                _cache[5] || (_cache[5] = _createElementVNode("td", null, null, -1))
                              ])
                            }), 64))
                          ])
                        ])
                      ])
                    ])
                  }), 64))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_user_profile_tabs, { "is-own-profile": _ctx.isOwnProfile }, null, 8, ["is-own-profile"]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("h4", _hoisted_15, _toDisplayString(_ctx.user?.Username ?? 'User') + "'s Run History", 1),
                    _createElementVNode("div", _hoisted_16, [
                      (_ctx.$store.state.devMode)
                        ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_17, _cache[7] || (_cache[7] = [
                            _createTextVNode("Rebuild history")
                          ]))), [
                            [_directive_on_async, _ctx.rebuildHistory]
                          ])
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_18, [
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chosenCategory) = $event))
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.history.EntriesByCategory, (runs, category) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: category }, [
                              (runs.length > 0)
                                ? (_openBlock(), _createElementBlock("option", _hoisted_19, _toDisplayString(category), 1))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ], 512), [
                          [_vModelSelect, _ctx.chosenCategory]
                        ])
                      ])
                    ])
                  ])
                ]),
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_20, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryGames, (game) => {
                    return (_openBlock(), _createBlock(_component_UserHistoryGame, {
                      key: game.Id,
                      runs: _ctx.history.EntriesByCategory[_ctx.chosenCategory],
                      categoryName: _ctx.chosenCategory,
                      game: game,
                      submittedOnly: true
                    }, null, 8, ["runs", "categoryName", "game"]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryGames, (game) => {
                    return (_openBlock(), _createBlock(_component_UserHistoryGame, {
                      key: game.Id,
                      runs: _ctx.history.EntriesByCategory[_ctx.chosenCategory],
                      categoryName: _ctx.chosenCategory,
                      game: game,
                      submittedOnly: false
                    }, null, 8, ["runs", "categoryName", "game"]))
                  }), 128))
                ])), [
                  [_directive_masonry, _ctx.$store.state.windowWidth <= 1215 ? 1 : 2, _ctx.$route]
                ])
              ]))
        ], 64))
      : (_ctx.user)
        ? (_openBlock(), _createBlock(_component_router_view, {
            key: 1,
            userId: _ctx.user.UserId
          }, null, 8, ["userId"]))
        : _createCommentVNode("", true)
  ]))
}