<template>
<span class="integration-profile" :class="{'editable': editable && actionable}" v-if="editable || hasValue">
    <span class="integration-images">

        <template v-if="hasValue">
            <a
                :href="profileUrl"
                target="_blank"
                rel="noopener noreferrer"
                :title="profileName + ' on ' + service"
            >
                <img class="integration-icon" :src="serviceIcon" />
                <img class="integration-avatar" :src="avatarUrl" />
            </a>
        </template>
        <template v-else-if="editable">
            <img class="integration-icon-fixed" :src="serviceIcon" />
        </template>
    </span>

    <i v-if="editable && actionable" class="clickable fas" :class="{'fa-link': !hasValue && link, 'fa-unlink': hasValue && unlink }" role="button" tabindex="0" :title="(hasValue ? 'Unlink' : 'Link') + ' your ' + service + ' account'"  @click="action"  @keydown.enter="action" @keydown.space="action" ></i>
</span>



</template>
<script lang="ts" scoped>
import { Component, Vue } from "vue-facing-decorator";
import { Prop, Watch } from "vue-facing-decorator";


@Component({
    components: {}
})
export default class IntegrationProfile extends Vue {

    @Prop() 
    public service: string = "unknown";

    @Prop()
    public unlink: (() => Promise<void>) | null = null;

    @Prop()
    public link: (() => Promise<void>) | null = null;

    @Prop()
    public profileName: string | null = null;

    @Prop()
    public profileUrl: string | null = null;

    @Prop()
    public avatarUrl: string | null = null;

    @Prop()
    public editable = false;

    @Prop()
    public hasValue = false;

    async mounted() {


    }

    async action() {
        this.hasValue ? await this.unlink!() : await this.link!();
    }

    get actionable() {
        return this.hasValue ? !!this.unlink : !!this.link;
    }

    get serviceIcon() {
        switch(this.service.toLowerCase())
        {
            case "xbox": return "/img/integrations/Xbox.svg";
            case "twitch": return "/img/integrations/TwitchGlitchPurple.svg";
            case "steam": return "/img/integrations/Steam.svg";
            case "youtube": return "/img/integrations/YouTube.svg";
            case "discord": return "/img/integrations/Discord.svg";
            default: return "/img/user.jpg"
        }
    }
}

</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.integration-profile {
    display: inline-block;
    width: 34px;
    height: 34px;
    overflow: hidden;
    position: relative;

    &.editable {
        width: 48px;
    }

    .clickable {
        position: absolute;
        top: 2px;
        right: 0px;
        cursor: pointer;


        margin-left: -7px;
        margin-top: -3px;
        font-size: 13px;
        background-color: $primary;
        border-radius: 50%;
        padding: 5px 4px 5px 6px;
        z-index: 3;
        border: 2px solid $body-background-color;

        &:hover, &:active {
            color: darken($primary, 35%);
        }
    }

    .integration-images {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 5px;
        overflow: hidden;
        position: relative;
        top: 0;
        left: 0;

        &:hover {
            .integration-icon {
                top: -30px;
            }

            .integration-avatar {
                top: 0;
            }
        }

        .integration-icon, .integration-icon-fixed {
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            transition: top 250ms;
        }

        .integration-avatar {
            position: absolute;
            top: 30px;
            left: 0;
            width: 24px;
            height: 24px;
            border-radius: 15px;
            transition: top 250ms;
        }
    }
}



</style>