import { GlobalMetadata, ProfilesService } from '@/api';
import { UserProfile } from '@/api/models/UserProfile';
import { InjectionKey } from 'vue'
import { Store, useStore as baseUseStore, createStore } from 'vuex'
import { AuthState } from './auth';

export interface State {
  auth: AuthState,
  user: UserProfile | null,
  nav: boolean,
  devMode: boolean,
  isMobile: boolean,
  isMobileSmall: boolean,
  windowWidth: number
  globals: Promise<GlobalMetadata>,
  attentionRequired: string | null
}

declare module '@vue/runtime-core' {
  // provide typings for `this.$store`
  interface ComponentCustomProperties {
    $store: Store<State>
  }
}

export const key: InjectionKey<Store<State>> = Symbol();

let globalResolver : ((val: GlobalMetadata) => void) | null = null;

const globalPromise = new Promise<GlobalMetadata>(function(resolve, reject) {
    globalResolver = (val: GlobalMetadata) => {
      resolve(val);
    }
});

export default createStore<State>({
  state: {
      auth: {
          jwt: null,
          isAuthenticated: false,
          username: "Anonymous",
          claims: {},
          hrClaimStrings: [],
          refreshHandle: 0
      },
      user: null,
      nav: false,
      devMode: false,
      isMobile: false,
      isMobileSmall: false,
      windowWidth: 0,
      globals: globalPromise,
      attentionRequired: null
  },
  mutations: {
    initializeGlobals(state, promise: Promise<GlobalMetadata>) {
      if(globalResolver != null) {
        promise.then(v => globalResolver!(v));
      } else {
        state.globals = promise;
      }
    },
    authenticate(state, authState) {
      if(state.auth.refreshHandle > 0) {
        clearTimeout(state.auth.refreshHandle);
      }

      state.auth = authState;
    },
    getProfile(state, profileState) {
      state.user = profileState;
    },
    toggleNav(state, _) {
      state.nav = !state.nav;
    },
    setNav(state, navVal) {
      state.nav = navVal;
    },
    enableDevMode(state, _) {
      state.devMode = true;
    },
    setWindowWidth(state, newVal) {
      state.windowWidth = newVal;
    },
    setMobile(state, newVal) {
      state.isMobile = newVal;
    },
    setMobileSmall(state, newVal) {
      state.isMobileSmall = newVal;
    },
    attentionRequired(state, newVal) {
      state.attentionRequired = newVal;
    }
  },
  actions: {
    async getProfile ({ commit, state }) {
      commit('getProfile', await ProfilesService.getUserProfile(state.auth.claims.userId!))
    },
    async clearCaches({commit, state}) {
      console.log("clearing caches");
      const keys = await window.caches.keys();
      await Promise.all(keys.map(key => caches.delete(key)));
      window.location.reload();
    }
  },
  modules: {
  }
});

export function useStore () {
  return baseUseStore(key)
}