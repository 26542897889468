import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-row" }
const _hoisted_2 = { class: "modal-box" }
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showModal)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "overlay",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.overlayCancel && _ctx.overlayCancel(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
              return (_openBlock(), _createElementBlock("p", {
                class: "is-large",
                key: message
              }, _toDisplayString(message), 1))
            }), 128)),
            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
            (_ctx.args.entryType != 'password')
              ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                  key: 0,
                  class: "textarea",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.promptValue) = $event))
                }, null, 512)), [
                  [_vModelText, _ctx.promptValue]
                ])
              : _createCommentVNode("", true),
            (_ctx.args.entryType == 'password')
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 1,
                  type: "password",
                  class: "input",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.promptValue) = $event))
                }, null, 512)), [
                  [_vModelText, _ctx.promptValue]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "button is-ghost",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel()))
              }, _toDisplayString(_ctx.args.cancelAlias), 1),
              _createElementVNode("button", {
                class: "button is-primary",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.confirm()))
              }, _toDisplayString(_ctx.args.confirmAlias), 1)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}