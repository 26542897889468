import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "columns" }
const _hoisted_3 = { class: "column is-two-thirds" }
const _hoisted_4 = { class: "hr-tile greeble-tr featured-records" }
const _hoisted_5 = { class: "filters" }
const _hoisted_6 = { class: "title is-4" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "select" }
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "select" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "column is-one-third" }
const _hoisted_13 = { class: "hr-tile" }
const _hoisted_14 = { class: "title is-5" }
const _hoisted_15 = { class: "muted is-6" }
const _hoisted_16 = { class: "hr-tile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecordBoardView = _resolveComponent("RecordBoardView")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_RunFeed = _resolveComponent("RunFeed")!
  const _component_StreamFeed = _resolveComponent("StreamFeed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("section", { class: "hr-tile is-relative" }, [
          _createElementVNode("div", { class: "flag-background" }),
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, "HaloRuns is a safe, inclusive community for gamers of all identities,"),
            _createTextVNode(" including gamers of color, LGBTQIA2S+ gamers, disabled gamers, women gamers, and people of all ages, genders, faiths, nationalities, and citizenship status. Bigotry is not tolerated and may result in a ban from the site and community. Bigotry includes: discrimination, bullying or mocking, harassment, offensive statements, intentional disrespect of one's identity (e.g. misgendering), via any medium including text, voice chat, and streaming. ")
          ])
        ], -1)),
        _createElementVNode("section", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.recordBoardTitle), 1),
            (_ctx.recordBoardKey)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.recordBoardKey.GameId) = $event)),
                      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.gameChanged && _ctx.gameChanged(...args)))
                    }, [
                      _cache[4] || (_cache[4] = _createElementVNode("option", { value: "featured" }, "Featured", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: game.Id,
                          value: game.Id
                        }, _toDisplayString(game.Name), 9, _hoisted_9))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, _ctx.recordBoardKey.GameId]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.recordBoardKey.CategoryId) = $event)),
                      onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.refreshRecordBoard && _ctx.refreshRecordBoard(...args)))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: category.Id,
                          value: category.Id
                        }, _toDisplayString(category.Name), 9, _hoisted_11))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, _ctx.recordBoardKey.CategoryId]
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_RecordBoardView, {
            recordBoardKey: _ctx.recordBoardKey,
            rows: _ctx.boardData
          }, null, 8, ["recordBoardKey", "rows"])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("section", _hoisted_13, [
          _createElementVNode("h4", _hoisted_14, [
            _cache[7] || (_cache[7] = _createTextVNode(" Latest World Records ")),
            _createElementVNode("small", _hoisted_15, [
              _createVNode(_component_router_link, { to: {name: 'Recent Records'} }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("(more)")
                ])),
                _: 1
              })
            ])
          ]),
          (_ctx.recordsFeed)
            ? (_openBlock(), _createBlock(_component_RunFeed, {
                key: 0,
                feed: _ctx.recordsFeed.MainEntries
              }, null, 8, ["feed"]))
            : _createCommentVNode("", true),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "separator" }, null, -1)),
          _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "title is-5" }, "Recent Runs", -1)),
          (_ctx.runsFeed)
            ? (_openBlock(), _createBlock(_component_RunFeed, {
                key: 1,
                feed: _ctx.runsFeed.Entries
              }, null, 8, ["feed"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("section", _hoisted_16, [
          _cache[10] || (_cache[10] = _createElementVNode("h4", { class: "title is-5" }, "Current Streamers", -1)),
          _createVNode(_component_StreamFeed)
        ])
      ])
    ])
  ]))
}