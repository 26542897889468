<template>
    <div class="warning" v-if="errorMessage">{{errorMessage}}</div>
    <div class="field">
        <label class="label">New Password</label>
        <div class="control">
            <input type="password" class="input" v-model="reset.Password" />
        </div>
    </div>
    <div class="field">
        <label class="label">Confirm New Password</label>
        <div class="control">
            <input type="password" class="input" v-model="reset.ConfirmPassword" v-on:keyup.enter="submit" />
        </div>
    </div>
    <div class="field is-grouped is-justify-content-flex-end">
        <div class="control">
            <a class="button is-primary" v-on-async="submit">Reset Password</a>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import AuthStore from "@/store/auth";
import { AuthService, ResetPasswordRequest, ProfilesService, AuthType, StaticContentService } from "@/api";
import OnAsync from "@/directives/OnAsync";

export interface IResetRequest {
    UserId: string;
    Password: string | null;
    ConfirmPassword: string | null;
    ResetToken: string;
}

@Component({
    directives: {
        OnAsync
    }
})
export default class ResetPassword extends Vue {
    reset: ResetPasswordRequest | IResetRequest = {};
    errorMessage: string | null = null;

    async mounted() {
        if(this.$route.hash == "")
        {
            this.$router.push("/");
            return;
        }

        var parts = this.$route.hash.substr(1).split("/");

        if(parts.length != 2 || parts[0] == null || parts[1] == null)
        {
            this.$router.push("/");
            return;
        }

        this.reset.UserId = parts[0];
        this.reset.ResetToken = parts[1];
        this.$router.replace("/auth/reset");
    }

    async submit() {
        try {
            let resp = await AuthService.resetPassword(this.reset as ResetPasswordRequest);
            AuthStore.useToken(resp, AuthStore.refresh);

            this.errorMessage = null;
            this.reset.Password = null;
            this.reset.ConfirmPassword = null;

            this.$router.push("/");
        } catch {
            this.reset = <IResetRequest>{};
            this.errorMessage = "Reset failed. Each password reset link can only be used once. Try again, or request a new reset link.";
        }
    }
}
</script>