<template>
    <div class="badge-display" v-if="hydratedBadge" :title="hydratedBadge.Reason">
        <div>
            <img :src="hydratedBadge.ImageUrl" :alt="hydratedBadge.Reason"/>
        
        </div>
        <div>{{ hydratedBadge.Name }}</div>
    </div>
    
</template>
<script lang="ts">
import { BadgeAwardDto, BadgeDto, UserBadgeAward } from "@/api";
import { BadgeImageService } from "@/services/BadgeImageService";
import { Component, Vue } from "vue-facing-decorator";
import { Prop } from "vue-facing-decorator";

@Component({})
export default class UserBadgeDisplay extends Vue {
    @Prop()
    badge!: UserBadgeAward;

    public hydratedBadge: {ImageUrl: string, Name: string, Reason: string} | null = null;

    async mounted() { 
        var badgeData = await BadgeImageService.getBadge(this.badge.BadgeId!);

        this.hydratedBadge = {
            ImageUrl: 'data:image/png;base64,' + badgeData?.Image64,
            Name: badgeData?.Name!,
            Reason: this.badge.GrantedReason!
        };
    }
}
</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

</style>