import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "run-feed" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "images" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 1,
  class: "timecode"
}
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.feed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feed, (entry) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "feed-entry",
              key: entry.RunId
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  class: "game-image",
                  src: '/img/app-icons/' + entry.Difficulty + '.svg'
                }, null, 8, _hoisted_4)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(entry.GameName) + " - ", 1),
                  (entry.Participants[0].EvidenceLink)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: entry.Participants[0].EvidenceLink,
                        target: "_blank",
                        rel: "noopener noreferrer",
                        class: "timecode"
                      }, _toDisplayString(_ctx.$filters.durationDisplay(entry.Duration)), 9, _hoisted_5))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$filters.durationDisplay(entry.Duration)), 1)),
                  (entry.Tie)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, " tied"))
                    : _createCommentVNode("", true),
                  _cache[0] || (_cache[0] = _createTextVNode(" by ")),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entry.Participants, (p, i) => {
                    return (_openBlock(), _createElementBlock("span", { key: p }, [
                      _createVNode(_component_router_link, {
                        to: '/profiles/' + p.Username
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(p.Username), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]),
                      (entry.Participants.length > 0 && i < entry.Participants.length-1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, ", "))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]),
                _createElementVNode("small", null, [
                  _createVNode(_component_router_link, {
                    to: _ctx.leaderboardLink(entry)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(entry.LevelName) + " - " + _toDisplayString(entry.CategoryName), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.feed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[1] || (_cache[1] = [
          _createElementVNode("p", null, "No runs to show!", -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}