import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-career-game hr-tile"
}
const _hoisted_2 = { class: "title is-5" }
const _hoisted_3 = { class: "level-table" }
const _hoisted_4 = {
  key: 1,
  class: "user-career-game hr-tile"
}
const _hoisted_5 = { class: "subtitle is-5 h2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_history_game_level = _resolveComponent("user-history-game-level")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.game.Difficulties, (difficulty) => {
    return (_openBlock(), _createElementBlock(_Fragment, {
      key: difficulty.Id
    }, [
      (_ctx.submittedOnly && _ctx.hasSubmitted(difficulty.Id))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.game.Name) + " " + _toDisplayString(difficulty.Name), 1),
            _createElementVNode("table", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "Level"),
                  _createElementVNode("th", null, "PB"),
                  _createElementVNode("th", null, "Past PBs"),
                  _createElementVNode("th", null, "Avg Timesave")
                ])
              ], -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categorySegments, (level) => {
                return (_openBlock(), _createBlock(_component_user_history_game_level, {
                  key: level.Id,
                  game: _ctx.game,
                  category: _ctx.category,
                  difficulty: difficulty,
                  level: level,
                  runs: _ctx.runs
                }, null, 8, ["game", "category", "difficulty", "level", "runs"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.submittedOnly && !_ctx.hasSubmitted(difficulty.Id))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.game.Name) + " " + _toDisplayString(difficulty.Name) + " - No Submissions", 1)
          ]))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}