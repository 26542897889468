<template>
  <div class="loading-container">
    <img src="@/assets/running.loop.gif"/>
    <h1>Please wait, authenticating...</h1>
  </div>
</template>
<style lang="scss" scoped>
.loading-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;

  img {
    display: inline-block;
  }
}
</style>