export default class PointsService {

    public static calculatePoints(recordDuration: number, runDuration: number, maxPoints: number): number
    {
        if(runDuration < recordDuration) return maxPoints;

        var rawPoints = PointsService.calculateRawPoints(recordDuration, runDuration);

        return Math.floor(rawPoints * maxPoints);
    }

    public static calculateRawPoints(recordDuration: number, runDuration: number): number
    {
        var ratio = recordDuration / Math.max(runDuration, 1);
        return 0.008 * Math.exp(4.8284 * ratio);
    }
}