import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hr-tile" }
const _hoisted_2 = { class: "breadcrumbs" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "columns is-desktop" }
const _hoisted_5 = { class: "column is-one-third-desktop" }
const _hoisted_6 = { class: "hr-tile" }
const _hoisted_7 = { class: "time-table" }
const _hoisted_8 = { class: "column is-two-thirds-desktop" }
const _hoisted_9 = {
  key: 0,
  class: "hr-tile"
}
const _hoisted_10 = {
  key: 1,
  class: "hr-tile rules"
}
const _hoisted_11 = { class: "title is-4" }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_input = _resolveComponent("time-input")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Markdown = _resolveComponent("Markdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routeComponents, (match, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: match.path,
            class: "breadcrumb-item"
          }, [
            (i == i)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(match.name), 1))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("table", _hoisted_7, [
            _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Level"),
                _createElementVNode("th", null, "Time")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryLevels, (level) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: level.Id
                }, [
                  _createElementVNode("td", null, _toDisplayString(level.Name), 1),
                  _createElementVNode("td", null, [
                    _createVNode(_component_time_input, {
                      modelValue: level.Duration,
                      "onUpdate:modelValue": ($event: any) => ((level.Duration) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ])
                ]))
              }), 128))
            ]),
            _createElementVNode("tfoot", null, [
              _createElementVNode("tr", null, [
                _cache[0] || (_cache[0] = _createElementVNode("td", { class: "has-text-right" }, "Total", -1)),
                _createElementVNode("td", null, _toDisplayString(_ctx.levelDurationSum), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1)),
                _createElementVNode("td", null, [
                  _createVNode(_component_router_link, {
                    to: {name: 'Simple Submit', params: _ctx.$route.params}
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Submit Time")
                    ])),
                    _: 1
                  }, 8, ["to"])
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (!_ctx.selectedGame)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[4] || (_cache[4] = [
              _createElementVNode("h4", { class: "title is-4" }, "Game Rules", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.selectedGame)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.selectedGame.Name) + " Rules", 1),
              (_ctx.selectedGame.Rules)
                ? (_openBlock(), _createBlock(_component_Markdown, {
                    key: 0,
                    source: _ctx.selectedGame.Rules,
                    class: "rule-section",
                    breaks: true
                  }, null, 8, ["source"]))
                : _createCommentVNode("", true),
              (_ctx.selectedCategory.Rules)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.selectedCategory.Name) + " Rules", 1),
                    _createVNode(_component_Markdown, {
                      source: _ctx.selectedCategory.Rules,
                      class: "rule-section",
                      breaks: true
                    }, null, 8, ["source"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}