<template>
    <div class="columns is-centered">
        <div class="column is-half hr-tile">
            <h2 class="title is-3">User Registration</h2>
            <h4 class="subtitle is-6">All fields are required</h4>
            <RegisterForm></RegisterForm>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import RegisterForm from '@/components/Auth/Register.vue'

@Component({
  components: {
      RegisterForm
  },
})
export default class Register extends Vue 
{
    created() {
        if(this.$store.state.auth.isAuthenticated) {
            this.$router.push("/");
        }
    }
}
</script>
