import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "breadcrumb-item" }
const _hoisted_2 = { class: "select" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "breadcrumb-item" }
const _hoisted_5 = { class: "select" }
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 0,
  class: "separator",
  disabled: "disabled"
}
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "breadcrumb-item" }
const _hoisted_10 = { class: "select" }
const _hoisted_11 = ["value"]
const _hoisted_12 = {
  key: 0,
  class: "breadcrumb-item"
}
const _hoisted_13 = { class: "select" }
const _hoisted_14 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.selectionContainer)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("li", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.gameName) + " ", 1),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectionContainer.GameId) = $event)),
              onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.gameChanged && _ctx.gameChanged(...args)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: game.Id,
                  value: game.Id
                }, _toDisplayString(game.Name), 9, _hoisted_3))
              }), 128))
            ], 544), [
              [_vModelSelect, _ctx.selectionContainer.GameId]
            ])
          ])
        ]),
        _createElementVNode("li", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.catName) + " ", 1),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectionContainer.CategoryId) = $event)),
              onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.categoryChanged && _ctx.categoryChanged(...args)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.normalCats, (category) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: category.Id,
                  value: category.Id
                }, _toDisplayString(category.Name), 9, _hoisted_6))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extensionCats, (category, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: category.Id
                }, [
                  (i == 0)
                    ? (_openBlock(), _createElementBlock("option", _hoisted_7, "Extras"))
                    : _createCommentVNode("", true),
                  _createElementVNode("option", {
                    value: category.Id
                  }, _toDisplayString(category.Name), 9, _hoisted_8)
                ], 64))
              }), 128))
            ], 544), [
              [_vModelSelect, _ctx.selectionContainer.CategoryId]
            ])
          ])
        ]),
        _createElementVNode("li", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, [
            _createTextVNode(_toDisplayString(_ctx.levelName) + " ", 1),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectionContainer.RunnableSegmentId) = $event)),
              onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.levelChanged && _ctx.levelChanged(...args)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levels, (level) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: level.Id,
                  value: level.Id
                }, _toDisplayString(level.Name), 9, _hoisted_11))
              }), 128))
            ], 544), [
              [_vModelSelect, _ctx.selectionContainer.RunnableSegmentId]
            ])
          ])
        ]),
        (_ctx.showDifficulty)
          ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
              _createElementVNode("span", _hoisted_13, [
                _createTextVNode(_toDisplayString(_ctx.diffName) + " ", 1),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectionContainer.Difficulty) = $event)),
                  onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.difficultyChanged && _ctx.difficultyChanged(...args)))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.difficulties, (diff) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: diff.Id,
                      value: diff.Id
                    }, _toDisplayString(diff.Name), 9, _hoisted_14))
                  }), 128))
                ], 544), [
                  [_vModelSelect, _ctx.selectionContainer.Difficulty]
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    : _createCommentVNode("", true)
}