import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "integration-profile-edit" }
const _hoisted_2 = { class: "integration-label" }
const _hoisted_3 = { class: "integration-images" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "label" }
const _hoisted_6 = {
  key: 0,
  class: "is-flex flex-direction-row is-align-items-center"
}
const _hoisted_7 = { class: "linked-profile is-flex flex-direction-row is-align-items-center" }
const _hoisted_8 = { class: "is-inline-block" }
const _hoisted_9 = ["href", "title"]
const _hoisted_10 = { class: "integration-images" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("img", {
          class: "integration-icon",
          src: _ctx.serviceIcon
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.service), 1)
    ]),
    _createElementVNode("div", null, [
      (_ctx.hasValue)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _cache[6] || (_cache[6] = _createTextVNode(" Account ")),
              _createElementVNode("span", _hoisted_8, [
                _createElementVNode("a", {
                  class: "is-flex flex-direction-row is-align-items-center",
                  href: _ctx.profileUrl,
                  target: "_blank",
                  rel: "noopener noreferrer",
                  title: _ctx.profileName + ' on ' + _ctx.service
                }, [
                  _createElementVNode("span", _hoisted_10, [
                    _createElementVNode("img", {
                      class: "integration-avatar",
                      src: _ctx.avatarUrl
                    }, null, 8, _hoisted_11)
                  ]),
                  _createTextVNode(_toDisplayString(_ctx.profileName), 1)
                ], 8, _hoisted_9)
              ]),
              _cache[7] || (_cache[7] = _createTextVNode(" linked! "))
            ]),
            _createElementVNode("button", {
              class: "button has-background-danger",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
              onKeydown: [
                _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)), ["enter"])),
                _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)), ["space"]))
              ]
            }, "Unlink", 32)
          ]))
        : (_ctx.disabled)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "button button-seconary",
              disabled: _ctx.disabled
            }, "Link Account (disbled)", 8, _hoisted_12))
          : (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "button button-primary has-background-primary",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
              onKeydown: [
                _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)), ["enter"])),
                _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)), ["space"]))
              ]
            }, "Link Account", 32))
    ])
  ]))
}