import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 1,
  class: "timecode"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("tbody", null, [
    (_ctx.levelEntry.length == 0)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
          _createElementVNode("td", null, [
            _createVNode(_component_router_link, {
              to: {name: 'Leaderboards', params: {game: _ctx.game.UrlStub, category: _ctx.category.UrlStub, level: _ctx.level.UrlStub, difficulty: _ctx.difficulty.Name}}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.level.Name), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("td", null, " - ", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("td", null, " - ", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("td", null, " - ", -1))
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levelEntry, (entry) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: entry.RunnableSegmentId
      }, [
        _createElementVNode("td", null, [
          _createVNode(_component_router_link, {
            to: {name: 'Leaderboards', params: {game: _ctx.game.UrlStub, category: _ctx.category.UrlStub, level: _ctx.level.UrlStub, difficulty: _ctx.difficulty.Name}}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.level.Name), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("td", null, [
          (entry.Runs[entry.Runs.length -1].Participants[0].EvidenceLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: entry.Runs[entry.Runs.length -1].Participants[0].EvidenceLink,
                class: "timecode",
                target: "_blank",
                rel: "noopener noreferrer"
              }, _toDisplayString(_ctx.$filters.durationDisplay(entry.PersonalBest)), 9, _hoisted_2))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$filters.durationDisplay(entry.PersonalBest)), 1))
        ]),
        _createElementVNode("td", null, [
          _createVNode(_component_router_link, {
            to: _ctx.entryLink(entry)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(entry.TotalRuns), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _createElementVNode("td", null, _toDisplayString(_ctx.$filters.durationDisplay(entry.AverageTimesave, true)), 1)
      ]))
    }), 128))
  ]))
}