declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $prompt: (args?: PromptArguments | null) => Promise<[boolean, string | null]>
    }
}

export default interface PromptArguments {
    message?: string;
    cancelAlias?: string;
    confirmAlias?: string;
    entryType?: "text" | "password";
}

export class PromptPlugin {
    static prompt = (args: PromptArguments | null) => Promise.resolve<[boolean, string|null]>([false, null]);

    static install(app: any, options: any) {
      app.config.globalProperties.$prompt = (args: PromptArguments | null) => this.prompt(args);
    }
}