export default class UrlService {

    public static tryGetValidUrl(val: string | null) {
        let url;

        if(!val) return null;
        
        try {
            url = new URL(val);
        } catch (_) {
            try {
                url = new URL("https://" + val);
            } catch {
                return null;
            }
        }

        if(url.protocol !== "http:" && url.protocol !== "https:") {
            return null;
        }

        return url.toString();
    }

    public static isValidUrl(val: string | null) {
        return UrlService.tryGetValidUrl(val) != null;
    }
}