/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Run } from '../models/Run';
import type { RunDeleteRequest } from '../models/RunDeleteRequest';
import type { RunQuery } from '../models/RunQuery';
import type { RunUpdateDto } from '../models/RunUpdateDto';
import { request as __request } from '../core/request';

export class RunsService {

    /**
     * @param identifier 
     * @param partitionKey 
     * @returns Run OK
     * @throws ApiError
     */
    public static async getRun(
identifier: string,
partitionKey?: string,
): Promise<Run> {
        const result = await __request({
            method: 'GET',
            path: `/api/Runs/${identifier}`,
            query: {
                'partitionKey': partitionKey,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns Run OK
     * @throws ApiError
     */
    public static async queryRuns(
requestBody?: RunQuery,
): Promise<Array<Run>> {
        const result = await __request({
            method: 'POST',
            path: `/api/Runs/query`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns Run OK
     * @throws ApiError
     */
    public static async updateRun(
requestBody?: RunUpdateDto,
): Promise<Run> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Runs`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static async deleteRun(
requestBody?: RunDeleteRequest,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/Runs`,
            body: requestBody,
        });
        return result.body;
    }

}