/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { useToast } from 'vue-toastification';

import { SubmitFormPrefPersistenceKey } from '@/router/index';
import { RecordBoardKeySelectorConsts } from './components/Boards/RecordBoardKeySelectorConsts';


let refreshing = false;
if (process.env.NODE_ENV === 'production') {
  let toaster = useToast();

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.');
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      toaster.info("New site version detected, updating...");

      // clear app's local storage of sugar features (fail-safe in case of bad state in storage)
      localStorage.removeItem(SubmitFormPrefPersistenceKey);
      localStorage.removeItem(RecordBoardKeySelectorConsts.LeaderboardPersistenceKey);

      registration.waiting?.postMessage({
        type: 'SKIP_WAITING'
      });

      if (refreshing) return;

      refreshing = true;

      caches.keys().then(function (cachesNames) {
        console.log("Deleting caches");
        return Promise.all(cachesNames.map(function (cacheName) {
          return caches.delete(cacheName).then(function () {
            console.log("Cache with name " + cacheName + " is deleted");
          });
        }))
      }).then(function () {
        console.log("All caches are deleted");
      });

      setTimeout(() => {
          window.location.reload();
      }, 1000);

    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
