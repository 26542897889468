<template>
    <div class="columns is-centered">
        <div class="column is-one-third hr-tile">
            <ResetPassword></ResetPassword>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ResetPassword from '@/components/Auth/ResetPassword.vue';

@Component({
  components: {
      ResetPassword
  },
})
export default class Login extends Vue 
{
    created() {
        if(this.$store.state.auth.isAuthenticated) {
            this.$router.push("/");
        }
    }
}
</script>
