<template>
<div class="integration-profile-edit">
    <div class="integration-label">
        <span class="integration-images"><img class="integration-icon" :src="serviceIcon" /></span>
        <span class="label"> {{ service }} </span>
    </div>
    <div>
        <template v-if="hasValue">
            <div class="is-flex flex-direction-row is-align-items-center">
                <span class="linked-profile is-flex flex-direction-row is-align-items-center">
                    Account <span class="is-inline-block">
                    <a class="is-flex flex-direction-row is-align-items-center"
                        :href="profileUrl"
                        target="_blank"
                        rel="noopener noreferrer"
                        :title="profileName + ' on ' + service"
                    >
                    <span class="integration-images"><img class="integration-avatar" :src="avatarUrl" /></span>{{ profileName }}
                    </a></span>
                    linked!
                </span>
                <button class="button has-background-danger"  @click="action"  @keydown.enter="action" @keydown.space="action">Unlink</button>
            </div>
            
        </template>
        <template v-else-if="disabled">
            <button class="button button-seconary" :disabled="disabled">Link Account (disbled)</button>
        </template>
        <template v-else>
            <button class="button button-primary has-background-primary"  @click="action"  @keydown.enter="action" @keydown.space="action">Link Account</button>
        </template>
    </div>
</div>



</template>
<script lang="ts" scoped>
import { Component, Vue } from "vue-facing-decorator";
import { Prop, Watch } from "vue-facing-decorator";


@Component({
    components: {}
})
export default class IntegrationProfileEdit extends Vue {

    @Prop() 
    public service: string = "unknown";

    @Prop()
    public unlink: (() => Promise<void>) | null = null;

    @Prop()
    public link: (() => Promise<void>) | null = null;

    @Prop()
    public profileName: string | null = null;

    @Prop()
    public profileUrl: string | null = null;

    @Prop()
    public avatarUrl: string | null = null;

    @Prop()
    public editable = false;

    @Prop()
    public hasValue = false;

    @Prop()
    public disabled = false;

    async mounted() {


    }

    async action() {
        this.hasValue ? await this.unlink!() : await this.link!();
    }

    get actionable() {
        return this.hasValue ? !!this.unlink : !!this.link;
    }

    get serviceIcon() {
        switch(this.service.toLowerCase())
        {
            case "xbox": return "/img/integrations/Xbox.svg";
            case "twitch": return "/img/integrations/TwitchGlitchPurple.svg";
            case "steam": return "/img/integrations/Steam.svg";
            case "youtube": return "/img/integrations/YouTube.svg";
            case "discord": return "/img/integrations/Discord.svg";
            default: return "/img/user.jpg"
        }
    }
}

</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.integration-profile-edit {
    margin-bottom:30px;

    .linked-profile {
        background-color: rgba(0, 0, 0, 0.3);
        border: 1px solid $border-color;
        border-radius: 5px;
        padding: 10px;
        margin-left: 5px;
        color: $highlight-color;
        vertical-align: middle;
        margin-right:10px;
        white-space: pre-wrap;
    }

    .integration-label {
        position:relative;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        font-family: "Oswald", sans-serif;
        line-height:20px;
        margin-bottom:5px;

        .label {
            position:absolute;
            top:4px;
            left: 30px;
            display:inline-block;
        }
    }

    .clickable {
        position: absolute;
        top: 2px;
        right: 0px;
        cursor: pointer;


        margin-left: -7px;
        margin-top: -3px;
        font-size: 13px;
        background-color: $primary;
        border-radius: 50%;
        padding: 5px 4px 5px 6px;
        z-index: 3;
        border: 2px solid $body-background-color;

        &:hover, &:active {
            color: darken($primary, 35%);
        }
    }

    .integration-images {
        display: inline-block;
        width: 24px;
        height: 24px;
        overflow: hidden;
        position: relative;
        margin-right:5px;
        margin-left:5px;
        top: 0;
        left: 0;

        .integration-icon, .integration-icon-fixed {
            position: absolute;
            top: 0px;
            left: 0;
            width: 20px;
            height: 20px;
            margin-top: 5px;
            transition: top 250ms;
        }

        .integration-avatar {
            position: relative;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            border-radius: 15px;
            margin-right:5px;
        }
    }
}



</style>