<template>
    <div class="typeahead" @focusout="handleFocusOut" tabindex="0">
        <div class="input-container" :class="{'loading': loading}">
            <input v-if="!value" ref="typeaheadInput" class="input" v-model="rawInput" @focus="queryFocus" @change="inputChanged" :placeholder="placeholder" :class="{required}" />
            <div v-if="value" class="selected-item" @click="clearSelected">
                <p v-if="value?.Title">{{ displayValue }}</p>
                <p class="small muted" v-if="value?.Url">{{ value.Url }}</p>
                <p class="small muted" v-else-if="rawInput">{{ rawInput }}</p>
            </div>
        </div>
        <div class="expanded-container" v-if="showResults" :style="expandedStyle">
            <h4>Your Recent Uploads</h4>
            <p class="item" role="button" tabindex="0" v-for="vid in recentVideos" :key="vid.Url"
                @click="select(vid)">
                <p>{{ vid.Title }}</p>
                <img :src="vid.ThumbnailUrl" />
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { IntegrationsService, IntegrationVideoInfo } from "@/api";
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";

@Component({})
export default class VideoLinkInput extends Vue {
    @Prop()
    public modelValue: any;

    @Prop()
    public placeholder: string | undefined = undefined;

    @Prop()
    public required: boolean = false;

    public showResults = false;
    public recentVideos: any[] | null = new Array();
    public loading = false;
    public value: any = null;
    public expandedStyle = {
        width: "auto"
    };

    public rawInput: string | undefined | null = null;

    public async mounted() {
        this.load();
    }

    public queryFocus(e: FocusEventInit & Event) {
        if(e != null) {
            this.updateExpandedStyle(e.target!);
        }
        
        if((this.recentVideos?.length ?? 0) > 0)
            this.showResults = true;
    }

    public async inputChanged(e: Event) {
        this.$emit("update:modelValue", this.rawInput);
    }

    private async load(): Promise<void> {
        // TODO concat videos from twitch?
        this.loading = true;
        this.recentVideos = await IntegrationsService.youtubeRecentUploads();
        this.loading = false;
    }

    public select(value: any) {
        this.value = value;
        this.rawInput = null;
        this.$emit("update:modelValue", value.Url);
        this.showResults = false;
    }

    public clearSelected() {
        this.value = null;
        this.rawInput = null;
        this.$emit("update:modelValue", null);

        // Focus on next tick so that v-if adds element to DOM
        setTimeout(() => {
            (this.$refs as any).typeaheadInput.focus();
            this.showResults = true;
        }, 0);
    }

    get displayValue() {
        return this.value.Title.substr(0, Math.min(this.value.Title.length, 42))
         + (this.value.Title.length > 42 ? "..." : "");
    }

    public handleFocusOut(e: FocusEventInit & Event) {
        if(e.relatedTarget != null 
            && e.relatedTarget instanceof Node
            && e.currentTarget instanceof Node
            && e.currentTarget.contains(e.relatedTarget)) {
            return;
        }

        this.showResults = false;
    }

    private updateExpandedStyle(e: EventTarget) {
        let elem = e as HTMLElement;
        this.expandedStyle.width = elem.clientWidth + "px";
    }
}

</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.typeahead {
    width: 100%;
    max-width: 100%;
    z-index: 4;

    &:focus {
        .input-container{
            border-color: $primary;
        }
    }

    &:hover {
        .input-container{
            border-color: $grey-light;
        }

        &:focus {
            .input-container{
                border-color: $primary;
            }
        }
    }

    .input-container {
        position: relative;
        background: darken($body-background-color, 15%);
        color: $text;
        border: none;
        border-radius: 0;
        min-height: 2.5em;
        line-height: 1.5;
        max-width: 100%;
        width: 100%;
        align-items: center;
        display: inline-flex;
        user-select: none;
        cursor: pointer;
        overflow: hidden;
        z-index: 4;

        .input.required {
            border-color: $warning-color;
        }

        &.loading:after {
            content: '↻';
            position: absolute;
            font-size: 2rem;
            line-height: 2rem;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-58%);
        }

        .selected-item {
            position: relative;
            padding: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }

    .expanded-container {
        position: absolute;
        background: darken($body-background-color, 15%);
        border: 1px solid $border-color;
        padding: 4px;
        z-index: 5;
        overflow-y: scroll;
        max-height: 350px;

        .item {
            position: relative;
            padding-left: 6px;
            cursor: pointer;
            user-select: none;
            padding-top: 6px;
            padding-bottom: 6px;
            width: 120px;
            height: 90px;

            &:hover {
                background-color: darken($body-background-color, 0%);
            }

            img {
                position: absolute;
                top: 0; 
                left: 0;
                z-index: 1;
            }

            p {
                position:relative;
                z-index: 2;
                background: rgba(0, 0, 0, 0.5);
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .item-message {
            position: relative;
            padding-left: 6px;
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }
}

</style>