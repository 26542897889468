<template>
    <div class="columns is-centered">
        <div class="column is-one-third hr-tile">
            <div class="warning" v-if="errorMessage">{{errorMessage}}</div>
            <div class="field">
                <label class="label">New Username</label>
                <div class="control">
                    <input type="text" class="input" v-model="req.NewUsername" />
                </div>
            </div>
            <div class="field">
                <label class="label">Confirm Password</label>
                <div class="control">
                    <input type="password" class="input" v-model="req.Password" v-on:keyup.enter="submit" />
                </div>
            </div>
            <div class="field is-grouped is-justify-content-flex-end">
                <div class="control">
                    <a class="button is-primary" v-on-async="submit">Change Username</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import AuthStore from '@/store/auth';
import { AccountsService, AuthType, ChangeUsernameRequest } from '@/api';
import OnAsync from '@/directives/OnAsync';

@Component({
    directives: {
        OnAsync
    }
})
export default class ChangeUsername extends Vue 
{
    req: ChangeUsernameRequest = {};
    errorMessage: string | null = null;

    async mounted() {
        try { await AuthStore.pendingAuth; } catch {}
        
        if(!this.$store.state.auth.isAuthenticated) {
            this.$router.push("/");
            return;
        }
    }

    async submit() {
        try {
            this.req.UserId = this.$store.state.auth.claims.userId;
            await AccountsService.changeUsername(this.$store.state.auth.claims.userId, this.req);
            let loginResp = await AuthStore.login({
                AuthType: AuthType.PASSWORD,
                Username: this.req.NewUsername!,
                Secret: this.req.Password!,
            });

            this.errorMessage = null;
            this.req = {};

            if (loginResp) {
                this.$router.push("/");
            } else {
                this.$router.push("/auth/login");
            }
        } catch (e: any) {
            this.req = {};
            var start = e.body.indexOf(":")+1;
            this.errorMessage = "Username change failed: " + e.body.substr(start, e.body.indexOf("\n") - start).toString();
        }
    }
}
</script>
