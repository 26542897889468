import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "selector hr-tile" }
const _hoisted_2 = { class: "breadcrumbs" }
const _hoisted_3 = { class: "breadcrumb-item" }
const _hoisted_4 = { class: "hr-tile" }
const _hoisted_5 = { class: "graph" }
const _hoisted_6 = {
  key: 0,
  class: "leaderboards"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "hr-tile" }
const _hoisted_9 = { class: "title is-4" }
const _hoisted_10 = { class: "hr-tile" }
const _hoisted_11 = { class: "title is-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_leaderboard_key_selector = _resolveComponent("leaderboard-key-selector")!
  const _component_run_graph = _resolveComponent("run-graph")!
  const _component_RecordHistoryBoard = _resolveComponent("RecordHistoryBoard")!
  const _directive_masonry = _resolveDirective("masonry")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: '/' }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("li", { class: "breadcrumb-item" }, [
          _createElementVNode("span", null, "Leaderboards")
        ], -1)),
        (_ctx.leaderboardKey)
          ? (_openBlock(), _createBlock(_component_leaderboard_key_selector, {
              key: 0,
              selectionContainer: _ctx.leaderboardKey,
              onUpdate: _cache[0] || (_cache[0] = ($event: any) => {_ctx.leaderboardKey = $event; _ctx.replaceRoute()}),
              showDifficulty: _ctx.$store.state.windowWidth <= 1215
            }, null, 8, ["selectionContainer", "showDifficulty"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", null, [
        _createElementVNode("small", null, [
          _createVNode(_component_router_link, {
            to: {name: 'Leaderboards', params: _ctx.$route.params}
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("< Back to Leaderboard")
            ])),
            _: 1
          }, 8, ["to"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_run_graph, {
          items: _ctx.history,
          class: "graph-container"
        }, null, 8, ["items"])
      ])
    ]),
    (_ctx.game)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.$store.state.windowWidth <= 1215)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.getDifficultyName(_ctx.leaderboardKey.Difficulty)) + " History", 1),
                  _createVNode(_component_RecordHistoryBoard, {
                    history: _ctx.history[_ctx.getDifficultyName(_ctx.leaderboardKey.Difficulty)]
                  }, null, 8, ["history"])
                ])
              ]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.category.Difficulties, (diff) => {
                return (_openBlock(), _createElementBlock("div", { key: diff }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.getDifficultyName(diff)) + " History", 1),
                    _createVNode(_component_RecordHistoryBoard, {
                      history: _ctx.history[_ctx.getDifficultyName(diff)]
                    }, null, 8, ["history"])
                  ])
                ]))
              }), 128))
        ])), [
          [_directive_masonry, _ctx.$store.state.windowWidth <= 1215 ? 1 : 2, _ctx.$store.state.windowWidth]
        ])
      : _createCommentVNode("", true)
  ], 64))
}