import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hr-tile" }
const _hoisted_2 = { class: "breadcrumbs" }
const _hoisted_3 = { class: "breadcrumb-item" }
const _hoisted_4 = {
  key: 0,
  class: "breadcrumb-item"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "columns" }
const _hoisted_7 = { class: "column is-one-quarter" }
const _hoisted_8 = {
  key: 0,
  class: "home hr-tile"
}
const _hoisted_9 = { class: "user-badge-display" }
const _hoisted_10 = {
  key: 1,
  class: "home hr-tile"
}
const _hoisted_11 = { class: "column is-three-quarters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProfileInfo = _resolveComponent("ProfileInfo")!
  const _component_user_badge_display = _resolveComponent("user-badge-display")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: '/' }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        (_ctx.$route.matched.length == 2)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, _cache[1] || (_cache[1] = [
              _createElementVNode("span", null, "User Profile", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.$route.matched.length > 2)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.$route.matched, (match, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: match.path,
                class: "breadcrumb-item"
              }, [
                (i < _ctx.$route.matched.length - 1)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: {name: match.name, params: _ctx.$route.params}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(match.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : _createCommentVNode("", true),
                (i == _ctx.$route.matched.length - 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(match.name), 1))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ProfileInfo, {
          profileUsername: _ctx.user?.Username,
          selectedProfile: _ctx.user,
          career: _ctx.career,
          ownProfile: _ctx.isOwnProfile
        }, null, 8, ["profileUsername", "selectedProfile", "career", "ownProfile"]),
        (_ctx.user?.Badges?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Badges", -1)),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user?.Badges, (badge) => {
                  return (_openBlock(), _createBlock(_component_user_badge_display, {
                    key: badge.Id,
                    badge: badge
                  }, null, 8, ["badge"]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[3] || (_cache[3] = [
              _createElementVNode("h3", null, "Achievements", -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(), _createBlock(_component_router_view, {
          career: _ctx.career,
          user: _ctx.user,
          isOwnProfile: _ctx.isOwnProfile,
          key: _ctx.$route.fullPath
        }, null, 8, ["career", "user", "isOwnProfile"]))
      ])
    ])
  ], 64))
}