/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticationRequest } from '../models/AuthenticationRequest';
import type { ForgotPasswordRequest } from '../models/ForgotPasswordRequest';
import type { RegisterConfirmRequest } from '../models/RegisterConfirmRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { UserRegistrationRequest } from '../models/UserRegistrationRequest';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * @param requestBody 
     * @returns string OK
     * @throws ApiError
     */
    public static async authenticate(
requestBody?: AuthenticationRequest,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/Auth`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static async forgotPassword(
requestBody?: ForgotPasswordRequest,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Auth/forgot`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string OK
     * @throws ApiError
     */
    public static async resetPassword(
requestBody?: ResetPasswordRequest,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/Auth/reset`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static async register(
requestBody?: UserRegistrationRequest,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Auth/register`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string OK
     * @throws ApiError
     */
    public static async registerConfirm(
requestBody?: RegisterConfirmRequest,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/Auth/registerConfirm`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static async logout(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Auth/logout`,
        });
        return result.body;
    }

}