import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "columns is-centered" }
const _hoisted_2 = { class: "column is-half hr-tile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginForm = _resolveComponent("LoginForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "title is-3" }, "User Login", -1)),
      _createVNode(_component_LoginForm)
    ])
  ]))
}