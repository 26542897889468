import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "edit-run" }
const _hoisted_2 = { class: "hr-tile" }
const _hoisted_3 = { class: "breadcrumbs" }
const _hoisted_4 = { class: "breadcrumb-item" }
const _hoisted_5 = {
  key: 0,
  class: "hr-tile"
}
const _hoisted_6 = { class: "title has-text-centered" }
const _hoisted_7 = {
  key: 1,
  class: "hr-tile"
}
const _hoisted_8 = { class: "has-text-centered" }
const _hoisted_9 = { class: "has-text-centered" }
const _hoisted_10 = { class: "edit-run-input" }
const _hoisted_11 = { class: "has-text-right" }
const _hoisted_12 = {
  type: "button",
  class: "button is-text"
}
const _hoisted_13 = {
  type: "button",
  class: "button is-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_run_input = _resolveComponent("run-input")!
  const _directive_on_async = _resolveDirective("on-async")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_router_link, { to: '/' }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("li", { class: "breadcrumb-item" }, [
          _createElementVNode("span", null, "Edit Run")
        ], -1))
      ])
    ]),
    (_ctx.run == null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.error || 'Loading...'), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.run != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "title" }, "Edit Run", -1)),
          _createElementVNode("p", _hoisted_8, [
            _createTextVNode(" Editing your time of " + _toDisplayString(_ctx.$filters.durationDisplay(_ctx.run.Duration)) + " for ", 1),
            _createVNode(_component_router_link, {
              to: {name: 'Leaderboards', params: {game: _ctx.game.UrlStub, category: _ctx.category.UrlStub, level: _ctx.segment.UrlStub, difficulty: _ctx.difficulty.Name}}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.runDescription), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createTextVNode(" originally submitted on " + _toDisplayString(_ctx.formatDate(_ctx.run.SubmittedAt)), 1)
          ]),
          _createElementVNode("p", _hoisted_9, [
            _cache[2] || (_cache[2] = _createTextVNode(" Do you have a faster time than this one? ")),
            _createVNode(_component_router_link, {
              to: {name: 'Simple Submit', params: {game: _ctx.game.UrlStub, category: _ctx.category.UrlStub, difficulty: _ctx.difficulty.Name}}
            }, {
              default: _withCtx(() => [
                _createTextVNode("Submit a new time for " + _toDisplayString(_ctx.runDescription), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            (_ctx.runContext)
              ? (_openBlock(), _createBlock(_component_run_input, {
                  key: 0,
                  career: _ctx.career,
                  context: _ctx.runContext,
                  recordData: _ctx.recordData,
                  adminEdit: _ctx.hasGameEditClaim
                }, null, 8, ["career", "context", "recordData", "adminEdit"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_12, _cache[3] || (_cache[3] = [
              _createTextVNode("Delete Run")
            ]))), [
              [_directive_on_async, _ctx.deleteRun]
            ]),
            _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_13, _cache[4] || (_cache[4] = [
              _createTextVNode("Save Changes")
            ]))), [
              [_directive_on_async, _ctx.save]
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}