import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "modal-row" }
const _hoisted_2 = {
  key: 0,
  class: "title-bar"
}
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showModal)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "overlay",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.overlayCancel && _ctx.overlayCancel(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "modal-box",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.suppressCancel && _ctx.suppressCancel(...args)))
          }, [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
              return (_openBlock(), _createElementBlock("p", {
                class: "is-large",
                key: message
              }, _toDisplayString(message), 1))
            }), 128)),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.args.cancelAlias)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "button is-ghost",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                  }, _toDisplayString(_ctx.args.cancelAlias), 1))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "button is-primary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm()))
              }, _toDisplayString(_ctx.args.confirmAlias), 1)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}