declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $confirm: (args?: ConfirmArguments | null) => Promise<boolean>
    }
}

export default interface ConfirmArguments {
    title?: string | null;
    message?: string;
    cancelAlias?: string | null;
    confirmAlias?: string;
}

export class ConfirmPlugin {
    static confirmAction = (args: ConfirmArguments | null) => Promise.resolve(true);

    static install(app: any, options: any) {
      app.config.globalProperties.$confirm = (args: ConfirmArguments | null) => this.confirmAction(args);
    }
}