import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  width: "0%"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 1,
  class: "timecode"
}
const _hoisted_9 = { class: "has-text-right pr-0" }
const _hoisted_10 = {
  key: 0,
  class: "trophy-container"
}
const _hoisted_11 = {
  key: 1,
  class: "trophy-container"
}
const _hoisted_12 = {
  key: 2,
  class: "trophy-container"
}
const _hoisted_13 = {
  key: 3,
  width: "0%"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("tbody", null, [
    (_ctx.levelRuns.length == 0)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
          _createElementVNode("td", null, [
            _createVNode(_component_router_link, {
              to: {name: 'Leaderboards', params: {game: _ctx.game.UrlStub, category: _ctx.category.UrlStub, level: _ctx.level.UrlStub, difficulty: _ctx.difficulty.Name}}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.level.Name), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          (_ctx.category?.AllowedPartners > 0)
            ? (_openBlock(), _createElementBlock("td", _hoisted_2, "-"))
            : _createCommentVNode("", true),
          _cache[0] || (_cache[0] = _createElementVNode("td", null, "-", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("td", null, null, -1)),
          _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1)),
          (_ctx.editable)
            ? (_openBlock(), _createElementBlock("td", _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levelRuns, (run, r) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: run?.Id
      }, [
        (r == 0)
          ? (_openBlock(), _createElementBlock("td", _hoisted_4, [
              _createVNode(_component_router_link, {
                to: {name: 'Leaderboards', params: {game: _ctx.game.UrlStub, category: _ctx.category.UrlStub, level: _ctx.level.UrlStub, difficulty: _ctx.difficulty.Name}}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.level.Name), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true),
        (r > 0)
          ? (_openBlock(), _createElementBlock("td", _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.category?.AllowedPartners > 0)
          ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(run.Participants, (partner, p) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: partner.Username
                }, [
                  _createVNode(_component_router_link, {
                    to: {name: 'Profile', params: {username: partner.Username}}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(partner.Username), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  (p < run.Participants.length-1)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(", ")
                      ], 64))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("td", null, [
          (run.Participants[0].EvidenceLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: run.Participants[0].EvidenceLink,
                class: "timecode",
                target: "_blank",
                rel: "noopener noreferrer"
              }, _toDisplayString(_ctx.$filters.durationDisplay(run.Duration)), 9, _hoisted_7))
            : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$filters.durationDisplay(run.Duration)), 1))
        ]),
        _createElementVNode("td", _hoisted_9, [
          (run.RankInfo?.Rank == 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _cache[3] || (_cache[3] = [
                _createElementVNode("i", { class: "trophy in-line rank-1" }, null, -1)
              ])))
            : _createCommentVNode("", true),
          (run.RankInfo?.Rank == 2)
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _cache[4] || (_cache[4] = [
                _createElementVNode("i", { class: "trophy in-line rank-2" }, null, -1)
              ])))
            : _createCommentVNode("", true),
          (run.RankInfo?.Rank == 3)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _cache[5] || (_cache[5] = [
                _createElementVNode("i", { class: "trophy in-line rank-3" }, null, -1)
              ])))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("span", null, _toDisplayString(run.RankInfo?.Score), 1)
        ]),
        (_ctx.editable)
          ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
              run
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: '/runs/' + run.Id + '/' + run.PartitionKey
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createElementVNode("i", {
                        class: "fas fa-edit icon-button",
                        "aria-hidden": ""
                      }, null, -1),
                      _createElementVNode("span", { class: "visible-hidden" }, "Edit run", -1)
                    ])),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}