<template>
    <template v-for="difficulty in game.Difficulties" :key="difficulty.Id">
        <div v-if="submittedOnly && hasSubmitted(difficulty.Id)" class="user-career-game hr-tile">
            <h2 class="title is-5">{{game.Name}} {{difficulty.Name}}</h2>
            <table class="level-table">
                <thead>
                    <tr>
                        <th>Level</th>
                        <th>PB</th>
                        <th>Past PBs</th>
                        <th>Avg Timesave</th>
                    </tr>
                </thead>
                <template v-for="level in categorySegments" :key="level.Id">
                    <user-history-game-level :game="game" :category="category" :difficulty="difficulty" :level="level" :runs="runs" />
                </template>
            </table>
        </div>
        <div v-if="!submittedOnly && !hasSubmitted(difficulty.Id)" class="user-career-game hr-tile">
            <h2 class="subtitle is-5 h2">{{game.Name}} {{difficulty.Name}} - No Submissions</h2>
        </div>
    </template>
</template>
<script lang="ts">
import { Category, Game, Run } from "@/api";
import { Component, Vue } from "vue-facing-decorator";
import { Prop } from "vue-facing-decorator";
import UserHistoryGameLevel from "@/components/User/UserHistoryGameLevel.vue";

@Component({
    components: {
        UserHistoryGameLevel
    }
})
export default class UserHistoryGame extends Vue {
    @Prop()
    runs: Run[] = new Array();

    @Prop()
    game: Game | null = null;

    @Prop()
    categoryName: string | null = null;

    @Prop()
    submittedOnly: boolean = true;

    hasSubmitted(diff: string) {
        return this.runs.filter(r => r.GameId == this.game!.Id && r.CategoryId == this.category?.Id && r.DifficultyId == diff).length > 0;
    }

    get category() {
        return this.game?.Categories?.find((c) => c.Name == this.categoryName);
    }

    get categorySegments() {
        var catSegs = this.category?.AllowedSegments!;
        return this.game?.RunnableSegments?.filter(s => catSegs.indexOf(s.Id!) >= 0);
    }
}
</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.title, .subtitle {
    margin-bottom: 0;
    padding-bottom: 0;
}

.no-margin {
    margin-bottom: 0;
}
</style>