<template>
    <template v-for="difficulty in game.Difficulties" :key="difficulty">
        <div v-if="hasSubmitted(difficulty.Id)" class="user-career-game hr-tile">
            <h2 class="title is-5 h2">{{game.Name}} {{difficulty.Name}} - {{totalPoints(difficulty.Id)}} points</h2>
            <table class="level-table">
                <thead>
                    <tr>
                        <th>Level</th>
                        <th v-if="category?.AllowedPartners > 0">Players</th>
                        <th>Time</th>
                        <th></th>
                        <th v-if="editable" colspan="2">Points</th>
                        <th v-else>Points</th>
                    </tr>
                </thead>
                <template v-for="level in categorySegments" :key="level.Id">
                    <user-career-game-level :game="game" :category="category" :difficulty="difficulty" :level="level" :runs="runs" :editable="editable" />
                </template>
            </table>
        </div>
    </template>
</template>
<script lang="ts">
import { Category, Game, Run } from "@/api";
import { Component, Vue } from "vue-facing-decorator";
import { Prop } from "vue-facing-decorator";
import UserCareerGameLevel from "@/components/User/UserCareerGameLevel.vue";

@Component({
    components: {
        UserCareerGameLevel
    }
})
export default class UserCareerGame extends Vue {
    @Prop()
    runs: Run[] = new Array();

    @Prop()
    game: Game | null = null;

    @Prop()
    categoryName: string | null = null;

    @Prop()
    editable: boolean = false;

    @Prop()
    submittedOnly: boolean = true;

    totalPoints(diff: string) {
        if(!this.runs) return 0;

        return this.runs.filter(r => r.GameId == this.game!.Id && r.CategoryId == this.category?.Id && r.DifficultyId == diff).reduce((a, r) => a + (r.RankInfo?.Score! || 0), 0);
    }

    hasSubmitted(diff: string) {
        if(!this.runs) return false;

        return this.runs.filter(r => r.GameId == this.game!.Id && r.CategoryId == this.category?.Id && r.DifficultyId == diff).length > 0;
    }

    get category() {
        return this.game?.Categories?.find((c) => c.Name == this.categoryName);
    }

    get categorySegments() {
        var catSegs = this.category?.AllowedSegments!;
        return this.game?.RunnableSegments?.filter(s => catSegs.indexOf(s.Id!) >= 0);
    }
}
</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.title, .subtitle {
    margin-bottom: 0;
    padding-bottom: 0;
}

.no-margin {
    margin-bottom: 0;
}

.trophy-container {
    margin-right: 1em;
}
</style>