<template>
<div class="hr-tile">
    <ul class="breadcrumbs">
        <li class="breadcrumb-item">
            <router-link :to="'/'">Home</router-link>
        </li>
        <li class="breadcrumb-item">
            <span>Oldest Records</span>
        </li>
    </ul>
</div>
<div class="hr-tile">
    <div class="filters">
        <h4 class="title is-4">Oldest Records</h4>
        <div v-if="records">
            <span class="select">
                <select v-model="filters.GameId" @change="filter()">
                    <option :value="null">Any Game</option>
                    <option v-for="game in games" :key="game.Id" :value="game.Id">
                        {{ game.Name }}
                    </option>
                </select>
            </span>
            <span class="select">
                <select v-model="filters.CategoryName" @change="filter()">
                    <option :value="null">Any Category</option>
                    <option v-for="category in categories" :key="category" :value="category">
                        {{ category }}
                    </option>
                </select>
            </span>
        </div>
    </div>
    <table v-if="!$store.state.isMobileSmall" class="record-table">
        <thead>
            <tr>
                <th>Rank</th>
                <th>Date</th>
                <th>Run</th>
                <th>Duration</th>
                <th>Participant(s)</th>
                <th>Age</th>
            </tr>
        </thead>
        <tbody v-if="filteredRecords">
            <tr v-for="record in filteredRecords" :key="record.rank">
                <td class="rank">{{record.rank}}</td>
                <td>{{record.occurredAt}}</td>
                <td class="has-text-centered">
                    <div>{{record.segmentName}} {{record.categoryName}}</div>
                    <div class="subtitle is-6">{{record.gameName}} - {{record.difficulty}}</div>
                </td>
                <td>
                    <a
                        v-if="record.participants && record.participants.length && record.participants[0].EvidenceLink"
                        :href="record.participants[0].EvidenceLink"
                        class="timecode"
                    >
                        {{$filters.durationDisplay(record.duration)}}
                    </a>
                    <span v-if="!record.participants.length" class="timecode">
                        {{$filters.durationDisplay(record.duration)}}
                    </span>
                </td>
                <td>
                    <span  v-for="(p,j) in record.participants" :key="p.Username">
                        <router-link :to="'/profiles/' + p.Username">{{p.Username}}</router-link><span v-if="j != (record.participants.length - 1)">, </span>
                    </span>
                </td>
                <td>{{record.ageDays}} <span class="subtitle is-6">days</span></td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-for="i in 20" :key="i">
                <td class="rank">#</td>
                <td>
                    <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                        <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                    </ContentLoader>
                </td>
                <td>
                    <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                        <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                    </ContentLoader>
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
    <div v-else class="record-cards">
        <template v-if="filteredRecords">
            <div class="record-card" v-for="record in filteredRecords" :key="record.rank">
                <div class="rank">{{record.rank}}</div>
                <div class="content">
                    <div class="record-info">
                        <div class="headline">
                            <div>
                                <div>{{record.segmentName}} {{record.categoryName}}</div>
                                <div class="subtitle is-6">{{record.gameName}} - {{record.difficulty}}</div>
                            </div>
                        </div>
                        <div>
                            <a
                                v-if="record.participants && record.participants.length && record.participants[0].EvidenceLink"
                                :href="record.participants[0].EvidenceLink"
                                class="timecode"
                            >
                                {{$filters.durationDisplay(record.duration)}}
                            </a>
                            <span v-if="!record.participants.length" class="timecode">
                                {{$filters.durationDisplay(record.duration)}}
                            </span>
                        </div>
                    </div>
                    <div class="record-info">
                        <div>
                            <small  v-for="(p,j) in record.participants" :key="p.Username">
                                <router-link :to="'/profiles/' + p.Username">{{p.Username}}</router-link><span v-if="j != (record.participants.length - 1)">, </span>
                            </small>
                            - <small>{{record.ageDays}}</small> <span class="subtitle is-6">days ago</span>
                        </div>
                        
                        <div class="timestamp subtitle is-6">
                            {{record.occurredAt}}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="record-card" v-for="i in 20" :key="i">
                <div class="rank">#</div>
                <div class="content">
                    <div class="record-info">
                        <div class="headline">
                            <div>
                                <div>
                                    <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                                        <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                                    </ContentLoader>
                                </div>
                                <div class="subtitle is-6">
                                    <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                                        <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                                    </ContentLoader>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ContentLoader width="120" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                                <rect x="0" y="5" rx="2" ry="2" :width="(100 + Math.random() * 20)" height="10" />
                            </ContentLoader>
                        </div>
                    </div>
                    <div class="record-info">
                        <div>
                            <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                                <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                            </ContentLoader>
                        </div>
                        
                        <div class="timestamp subtitle is-6">
                            <ContentLoader width="120" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                                <rect x="0" y="5" rx="2" ry="2" :width="(100 + Math.random() * 20)" height="10" />
                            </ContentLoader>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import LeaderboardKeySelector from "@/components/Boards/LeaderboardKeySelector.vue";
import { Category, Game, LeaderboardKey, OldestRecordBoard, OldestRecordBoardEntry, RunParticipantInfo, StaticContentService } from "@/api"
import TimeService from "@/services/TimeService";
import { GameStore } from "@/store/games";
import { ContentLoader } from "vue-content-loader";

interface OldestRecordEntry {
    rank: number;
    occurredAt: string | undefined;
    segmentName: string | undefined;
    categoryName: string | undefined;
    gameName: string | undefined;
    difficulty: string | undefined;
    duration: number | undefined;
    participants: RunParticipantInfo[];
    ageDays: number | undefined;
}

interface Filters {
    GameId: string | null;
    CategoryName: string | null;
}

@Component({
    components: {
        LeaderboardKeySelector,
        ContentLoader
    }
})
export default class OldestRecords extends Vue {
    filters: Filters = { GameId: null, CategoryName: null };
    records: OldestRecordBoard | null = null;
    filteredRecords: OldestRecordEntry[] | null = null;
    games: (Game|null)[] = new Array();
    categories: (string|null)[] = new Array();
    categoryLookup: Record<string, string> = {};
    gameLookup: Record<string, [Game, Record<string, Category>]> | null = null;

    async created() {
        this.records = await StaticContentService.oldestRecords();
        let games = (await GameStore.get()).games.filter(g => !g.Hidden);

        this.gameLookup = games.reduce((a,g) => {
            let catLookup = g.Categories!.reduce((ca, c) => {
                ca[c.Id!] = c;
                return ca;
            }, {} as Record<string, Category>);

            a[g.Id!] = [g, catLookup]
            return a;
        }, {} as Record<string, [Game, Record<string, Category>]>);

        this.games = games;
        this.categories = [...new Set(games.flatMap(g => g.Categories).map(c => c!.Name))].filter(c => c) as string[];
        this.categoryLookup = games.flatMap(g => g.Categories).reduce((p,v) => 
        {
            if(v?.Id) {
                p[v!.Id!] = v.Name!;
            }

            return p;
        }, {} as Record<string, string>);

        this.filter();
    }

    public filter() {
        if(!this.records?.Entries) {
            this.filteredRecords = new Array();
            return;
        }

        if(!this.filters) {
            this.filteredRecords = this.records.Entries.map(this.mapEntry);
            return;
        }

        let mapped = this.records.Entries.map((r,i) => {
            if(!this.filters) return null;

            var match = true;

            if(match && this.filters.GameId && r.GameId != this.filters.GameId) {
                match = false;
            }

            if(match && this.filters.CategoryName && this.categoryLookup[r.CategoryId!] != this.filters.CategoryName) {
                match = false;
            }

            return match ? this.mapEntry(r, i) : null;
        });

        this.filteredRecords = mapped.filter(m => m) as OldestRecordEntry[];
    }

    mapEntry(entry: OldestRecordBoardEntry, index: number) : OldestRecordEntry {
        return {
            rank: index+1,
            occurredAt: this.formatDate(entry.OccurredAt!),
            segmentName: this.segmentName(entry),
            categoryName: this.category(entry),
            gameName: this.game(entry),
            difficulty: this.difficultyName(entry),
            duration: entry.Duration,
            participants: entry.Participants!,
            ageDays: this.age(entry.OccurredAt!)
        }
    }

    formatDate(date: string) {
        return TimeService.iso8601ToShortFormat(date);
    }

    age(date: string | undefined) {
        if(!date) return 0;
        return Math.round((new Date().getTime()-new Date(date).getTime())/(1000*60*60*24));
    }

    game(record: OldestRecordBoardEntry) {
        if(this.gameLookup == null || record?.GameId == null) return;

        return this.gameLookup[record.GameId][0]?.Name;
    }

    category(record: OldestRecordBoardEntry) {
        if(this.gameLookup == null || record?.GameId == null) return;

        return this.gameLookup[record.GameId][1][record.CategoryId!]?.Name;
    }

    segmentName(record: OldestRecordBoardEntry) {
        if(this.gameLookup == null || record?.GameId == null) return;

        return this.gameLookup[record.GameId][0]?.RunnableSegments?.find(s => s.Id == record.RunnableSegmentId)?.Name;
    }

    difficultyName(record: OldestRecordBoardEntry) {
        if(this.gameLookup == null || record?.GameId == null) return;

        return this.gameLookup[record.GameId][0]?.Difficulties?.find(s => s.Id == record.Difficulty)?.Name;
    }
}

</script>

<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.filters {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 6px; // child's select has a 4px margin, so this is 6px
    flex-wrap: wrap;

    h4.title {
        margin-bottom: 0;
        white-space: nowrap;
    }
}
@media only screen and (max-width: 768px) {
    .filters {
        text-align: center;
        display: block;
        margin-bottom: 0.5rem;

        h4 {
            margin-bottom: 1rem;
        }
    }
}

.record-table {
    @include hr-tile-full-bleed;

    tr {
        td:not(:nth-child(1)),
        th:not(:nth-child(1)) {
            text-align: center;
        }

        td:last-child,
        th:last-child {
            text-align: right;
            padding-right: 25px;
            white-space: nowrap;
        }

        td:first-child,
        th:first-child {
            padding-left: 25px;
        }
    }

    tbody  td {
      vertical-align: middle;
    }

    tbody {
        border-bottom: 2px solid $border-color;
    }
}




.record-cards {
    @include hr-tile-full-bleed;

    border-top: 2px solid $border-color;
    border-bottom: 2px solid $border-color;

    .record-card {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 7px;
        padding-bottom: 7px;
        display: flex;
        
        &:not(:last-child) {
            border-bottom: 1px solid $border-color;
        }

        .rank {
            font-size: 1.3rem;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
        }

        .content {
            flex-grow: 1;
        }

        .record-info {
            display: flex;
            justify-content: space-between;

            .headline {
                display: flex;
                margin-bottom: 0.35rem;
            }
        }

        .timestamp {
            text-align: end;
            white-space: nowrap;
        }
    }
}
</style>