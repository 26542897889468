/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GameDto } from '../models/GameDto';
import { request as __request } from '../core/request';

export class GamesService {

    /**
     * @returns GameDto OK
     * @throws ApiError
     */
    public static async getAllGames(): Promise<Array<GameDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Games`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns GameDto OK
     * @throws ApiError
     */
    public static async updateGame(
requestBody?: GameDto,
): Promise<GameDto> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Games`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns GameDto OK
     * @throws ApiError
     */
    public static async createGame(
requestBody?: GameDto,
): Promise<GameDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/Games`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param identifier 
     * @returns GameDto OK
     * @throws ApiError
     */
    public static async getGame(
identifier: string,
): Promise<GameDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/Games/${identifier}`,
        });
        return result.body;
    }

}