import { Directive, nextTick, VNode } from "vue";

const Masonry: Directive<HTMLElement & VNode, number> = {

    mounted(el, binding, vnode) {
        let subject = el as HTMLElement;
        var cols = binding.value;

        if(!binding.value) {
            cols = 2;
        }

        subject.classList.add("masonry-grid");

        nextTick(() => {
            let height = applyStyling(subject, cols);
        });


    },

    updated(el, binding) {
         let subject = el as HTMLElement;
         var cols = binding.value;

         if(!binding.value) {
             cols = 2;
         }

         nextTick(() => {
             let height = applyStyling(subject, cols);
         });
    }
};

export default Masonry;
function applyStyling(subject: HTMLElement, cols: number): number {
    let colHeights = new Array() as number[];
    let childHeights = new Array() as number[];

    let gutterSize = 22;
    let totalGutters = gutterSize * (cols - 1);

    for (let i = 0; i < subject.children.length; i++) {
        let child = subject.children[i] as HTMLElement;
        child.classList.add("masonry-item");
        child.style.width = "calc( (100% / " + cols + ") - " + (totalGutters / cols) + "px)";
    }

    for (let i = 0; i <= cols; i++) {
        colHeights.push(0);
    }

    for (let i = 0; i < subject.children.length; i++) {
        let child = subject.children[i] as HTMLElement;
        let order = (i + 1) % cols || cols;
        child.style.order = order.toString();

        if(cols > 1 && order != 1) {
            child.style.marginLeft = gutterSize + "px";
        } else {
            child.style.removeProperty("margin-left");
        }

        // cloning and getting size out-of-bounds to avoid parent container clipping heights
        const clone = child.cloneNode(true) as HTMLElement;
        clone.style.position = 'absolute';
        clone.style.visibility = 'hidden';
        clone.style.width = `${child.offsetWidth}px`;
        clone.style.height = 'auto';
        clone.style.top = '-9999px';
      
        document.body.appendChild(clone);
        let height = clone.getBoundingClientRect().height;
        let style = window.getComputedStyle(clone);
        height += parseFloat(style.marginTop);
        height += parseFloat(style.marginBottom);

        document.body.removeChild(clone);   

        childHeights[i] = height;
        colHeights[order] += height;
    }

    let highest = Math.max.apply(Math, colHeights);

    subject.style.height = highest+'px';

    return highest;
}