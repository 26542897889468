import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["placeholder"]
const _hoisted_2 = { class: "item-message" }
const _hoisted_3 = {
  key: 0,
  class: "item-message"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "typeahead",
    onFocusout: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleFocusOut && _ctx.handleFocusOut(...args))),
    tabindex: "0"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["input-container", {'loading': _ctx.loading}])
    }, [
      (!_ctx.value)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            ref: "typeaheadInput",
            class: _normalizeClass(["input", {required: _ctx.required}]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryInput) = $event)),
            onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.queryKeyUp && _ctx.queryKeyUp(...args))),
            onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.queryFocus && _ctx.queryFocus(...args))),
            placeholder: _ctx.placeholder
          }, null, 42, _hoisted_1)), [
            [_vModelText, _ctx.queryInput]
          ])
        : _createCommentVNode("", true),
      (_ctx.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "selected-item",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clearSelected && _ctx.clearSelected(...args)))
          }, _toDisplayString(_ctx.displayValue), 1))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showLimitMessage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "expanded-container",
          style: _normalizeStyle(_ctx.expandedStyle)
        }, [
          _createElementVNode("p", _hoisted_2, " Type at least " + _toDisplayString(_ctx.charLimit) + " characters ", 1)
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.showResults)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "expanded-container",
          style: _normalizeStyle(_ctx.expandedStyle)
        }, [
          (_ctx.searchResults.length == 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, " No results for '" + _toDisplayString(_ctx.queryInput) + "' ", 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (result) => {
            return (_openBlock(), _createElementBlock("p", {
              class: "item",
              role: "button",
              tabindex: "0",
              key: result,
              onClick: ($event: any) => (_ctx.select(result))
            }, _toDisplayString(_ctx.displayTransform(result)), 9, _hoisted_4))
          }), 128))
        ], 4))
      : _createCommentVNode("", true)
  ], 32))
}