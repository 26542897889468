import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "hr-tile" }
const _hoisted_2 = { class: "breadcrumbs" }
const _hoisted_3 = { class: "breadcrumb-item" }
const _hoisted_4 = { class: "hr-tile" }
const _hoisted_5 = {
  key: 0,
  class: "record-table"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "has-text-centered" }
const _hoisted_8 = { class: "subtitle is-6" }
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  key: 1,
  class: "timecode"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["width"]
const _hoisted_14 = ["width"]
const _hoisted_15 = {
  key: 1,
  class: "record-cards"
}
const _hoisted_16 = { class: "content" }
const _hoisted_17 = { class: "record-info" }
const _hoisted_18 = { class: "headline" }
const _hoisted_19 = { class: "subtitle is-6" }
const _hoisted_20 = ["href"]
const _hoisted_21 = {
  key: 1,
  class: "timecode"
}
const _hoisted_22 = { class: "record-info" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "timestamp subtitle is-6" }
const _hoisted_25 = { class: "content" }
const _hoisted_26 = { class: "record-info" }
const _hoisted_27 = { class: "headline" }
const _hoisted_28 = ["width"]
const _hoisted_29 = { class: "subtitle is-6" }
const _hoisted_30 = ["width"]
const _hoisted_31 = ["width"]
const _hoisted_32 = { class: "record-info" }
const _hoisted_33 = ["width"]
const _hoisted_34 = { class: "timestamp subtitle is-6" }
const _hoisted_35 = ["width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ContentLoader = _resolveComponent("ContentLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: '/' }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("li", { class: "breadcrumb-item" }, [
          _createElementVNode("span", null, "Recent Record Runs")
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "title is-4" }, "Recent 50 Records", -1)),
      (!_ctx.$store.state.isMobileSmall)
        ? (_openBlock(), _createElementBlock("table", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Date"),
                _createElementVNode("th", null, "Run"),
                _createElementVNode("th", null, "Duration"),
                _createElementVNode("th", null, "Participant(s)")
              ])
            ], -1)),
            (_ctx.records)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.records.Entries, (record) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: record.RunId
                    }, [
                      _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(record.OccuredAt)), 1),
                      _createElementVNode("td", _hoisted_7, [
                        _createElementVNode("div", null, _toDisplayString(record.LevelName) + " " + _toDisplayString(record.CategoryName), 1),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(record.GameName) + " - " + _toDisplayString(record.Difficulty), 1)
                      ]),
                      _createElementVNode("td", null, [
                        (record.Participants && record.Participants.length && record.Participants[0].EvidenceLink)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: record.Participants[0].EvidenceLink,
                              class: "timecode"
                            }, _toDisplayString(_ctx.$filters.durationDisplay(record.Duration)), 9, _hoisted_9))
                          : _createCommentVNode("", true),
                        (!record.Participants.length)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$filters.durationDisplay(record.Duration)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.Participants, (p, j) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: p.Username
                          }, [
                            _createVNode(_component_router_link, {
                              to: '/profiles/' + p.Username
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(p.Username), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"]),
                            (j != (record.Participants.length - 1))
                              ? (_openBlock(), _createElementBlock("span", _hoisted_11, ", "))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ])
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("tbody", _hoisted_12, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(20, (i) => {
                    return _createElementVNode("tr", { key: i }, [
                      _createElementVNode("td", null, [
                        _createVNode(_component_ContentLoader, {
                          width: "170",
                          height: "18",
                          "primary-color": "#1f2a3c",
                          "secondary-color": "#2c3b55"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("rect", {
                              x: "0",
                              y: "5",
                              rx: "2",
                              ry: "2",
                              width: (150 + Math.random() * 20),
                              height: "10"
                            }, null, 8, _hoisted_13)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("td", null, [
                        _createVNode(_component_ContentLoader, {
                          width: "170",
                          height: "18",
                          "primary-color": "#1f2a3c",
                          "secondary-color": "#2c3b55"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("rect", {
                              x: "0",
                              y: "5",
                              rx: "2",
                              ry: "2",
                              width: (150 + Math.random() * 20),
                              height: "10"
                            }, null, 8, _hoisted_14)
                          ]),
                          _: 1
                        })
                      ]),
                      _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1)),
                      _cache[3] || (_cache[3] = _createElementVNode("td", null, null, -1)),
                      _cache[4] || (_cache[4] = _createElementVNode("td", null, null, -1))
                    ])
                  }), 64))
                ]))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
            (_ctx.records)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.records.Entries, (record) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "record-card",
                    key: record.RunId
                  }, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", null, _toDisplayString(record.LevelName) + " " + _toDisplayString(record.CategoryName), 1),
                            _createElementVNode("div", _hoisted_19, _toDisplayString(record.GameName) + " - " + _toDisplayString(record.Difficulty), 1)
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          (record.Participants && record.Participants.length && record.Participants[0].EvidenceLink)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: record.Participants[0].EvidenceLink,
                                class: "timecode"
                              }, _toDisplayString(_ctx.$filters.durationDisplay(record.Duration)), 9, _hoisted_20))
                            : _createCommentVNode("", true),
                          (!record.Participants.length)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.$filters.durationDisplay(record.Duration)), 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.Participants, (p, j) => {
                            return (_openBlock(), _createElementBlock("small", {
                              key: p.Username
                            }, [
                              _createVNode(_component_router_link, {
                                to: '/profiles/' + p.Username
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(p.Username), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"]),
                              (j != (record.Participants.length - 1))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_23, ", "))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]),
                        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.formatDate(record.OccuredAt)), 1)
                      ])
                    ])
                  ]))
                }), 128))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(20, (i) => {
                  return _createElementVNode("div", {
                    class: "record-card",
                    key: i
                  }, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_ContentLoader, {
                                width: "170",
                                height: "18",
                                "primary-color": "#1f2a3c",
                                "secondary-color": "#2c3b55"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("rect", {
                                    x: "0",
                                    y: "5",
                                    rx: "2",
                                    ry: "2",
                                    width: (150 + Math.random() * 20),
                                    height: "10"
                                  }, null, 8, _hoisted_28)
                                ]),
                                _: 1
                              })
                            ]),
                            _createElementVNode("div", _hoisted_29, [
                              _createVNode(_component_ContentLoader, {
                                width: "170",
                                height: "18",
                                "primary-color": "#1f2a3c",
                                "secondary-color": "#2c3b55"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("rect", {
                                    x: "0",
                                    y: "5",
                                    rx: "2",
                                    ry: "2",
                                    width: (150 + Math.random() * 20),
                                    height: "10"
                                  }, null, 8, _hoisted_30)
                                ]),
                                _: 1
                              })
                            ])
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_ContentLoader, {
                            width: "120",
                            height: "18",
                            "primary-color": "#1f2a3c",
                            "secondary-color": "#2c3b55"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("rect", {
                                x: "0",
                                y: "5",
                                rx: "2",
                                ry: "2",
                                width: (100 + Math.random() * 20),
                                height: "10"
                              }, null, 8, _hoisted_31)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("div", null, [
                          _createVNode(_component_ContentLoader, {
                            width: "170",
                            height: "18",
                            "primary-color": "#1f2a3c",
                            "secondary-color": "#2c3b55"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("rect", {
                                x: "0",
                                y: "5",
                                rx: "2",
                                ry: "2",
                                width: (150 + Math.random() * 20),
                                height: "10"
                              }, null, 8, _hoisted_33)
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", _hoisted_34, [
                          _createVNode(_component_ContentLoader, {
                            width: "120",
                            height: "18",
                            "primary-color": "#1f2a3c",
                            "secondary-color": "#2c3b55"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("rect", {
                                x: "0",
                                y: "5",
                                rx: "2",
                                ry: "2",
                                width: (100 + Math.random() * 20),
                                height: "10"
                              }, null, 8, _hoisted_35)
                            ]),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ])
                }), 64))
          ]))
    ])
  ], 64))
}