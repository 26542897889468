/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BadgeAwardDto } from '../models/BadgeAwardDto';
import type { BadgeAwardRequest } from '../models/BadgeAwardRequest';
import type { BadgeDto } from '../models/BadgeDto';
import { request as __request } from '../core/request';

export class BadgesService {

    /**
     * @returns BadgeDto OK
     * @throws ApiError
     */
    public static async getBadges(): Promise<Array<BadgeDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Badges`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns BadgeDto OK
     * @throws ApiError
     */
    public static async createBadge(
requestBody?: any,
): Promise<BadgeDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/Badges`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns BadgeDto OK
     * @throws ApiError
     */
    public static async getBadge(
id: string,
): Promise<BadgeDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/Badges/${id}`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static async updateBadge(
id: string,
requestBody?: any,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Badges/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param badgeId 
     * @param requestBody 
     * @returns BadgeAwardDto OK
     * @throws ApiError
     */
    public static async awardBadge(
badgeId: string,
requestBody?: BadgeAwardRequest,
): Promise<BadgeAwardDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/Badges/${badgeId}/award`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param badgeId 
     * @param awardId 
     * @returns any OK
     * @throws ApiError
     */
    public static async revokeBadge(
badgeId: string,
awardId: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/Badges/${badgeId}/award/${awardId}`,
        });
        return result.body;
    }

}