<template>
<div class="nav-wrapper">
    <nav id="mainNavbar" class="container nav">
        <div class="nav-mandatory">
            <div class="nav-item">
                <router-link to="/"><img src="/img/logo_vec_color.png" width="64" height="64" /></router-link>
            </div>
            <div class="nav-item">
                <router-link to="/leaderboards">Leaderboards</router-link>
            </div>
        </div>
        <div class="nav-toggle">
            <button v-on:click="toggleMenu">≡</button>
        </div>
        <div class="nav-extras" v-bind:class="{ 'nav-shown': $store.state.nav }">
            <div class="nav-left">
                <div class="nav-item">
                    <router-link to="/extras">Extras</router-link>
                </div>
                <div class="nav-item">
                    <router-link to="/runners">Runners</router-link>
                </div>
                <div class="nav-item">
                    <router-link to="/rules">Rules</router-link>
                </div>
                <div class="nav-item">
                    <router-link to="/discord"><img class="nav-desktop" src="/img/Discord-Logo-White.png" width="18" height="18" /><span class="nav-mobile">HaloRuns Discord </span></router-link>
                </div>
            </div>

            <!-- Right side -->
            <div class="nav-right" v-if="$store.state.isMobile">
                <p class="nav-item non-upper" v-if="auth.isAuthenticated"><router-link v-bind:to="'/profiles/' + auth.username"> {{auth.username}}</router-link></p>
                <p class="nav-item" v-if="auth.isAuthenticated"><router-link to="/submit">Submit</router-link></p>
                <p class="nav-item" v-if="auth.isAuthenticated && hasAdminClaim"><router-link to="/admin">Admin</router-link></p>
                <p class="nav-item" v-if="!auth.isAuthenticated"><router-link to="/auth/login">Login</router-link></p>
                <p class="nav-item" v-if="auth.isAuthenticated"><a v-on:click="logout">Logout</a></p>
            </div>
            <div class="nav-right" v-else-if="auth.isAuthenticated">
                <p class="nav-item" v-if="auth.isAuthenticated"><router-link to="/submit">Submit</router-link></p>
                <p class="nav-item" v-if="hasAdminClaim && attentionRequired"><router-link :to="attentionRequired">(!)</router-link></p>
                <p class="nav-item non-upper" v-if="auth.isAuthenticated"><router-link v-bind:to="'/profiles/' + auth.username"> {{auth.username}}</router-link></p>
                <div class="nav-item dropdown is-right">
                    <div class="dropdown-trigger" @click="menuPress" v-focus-toggle:parent="'is-active'" tabindex="0">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" tab-index="-1">
                            <span class="icon is-small">
                                <i class="fas fa-caret-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="user-menu" role="menu">
                        <div class="dropdown-content">
                            <router-link :to="{name: 'Admin'}" class="dropdown-item" v-if="hasAdminClaim">Admin</router-link>
                            <hr class="dropdown-divider" v-if="hasAdminClaim" />
                            <button class="dropdown-item" v-if="showAdvanced" v-on:click="clearCaches">
                                Clear Caches
                            </button>
                            <button class="dropdown-item" v-on:click="logout">
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="nav-right">
                <p class="nav-item"><router-link to="/auth/login">Login</router-link></p>
            </div>
        </div>
    </nav>
</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { mapState } from 'vuex'
import AuthStore from '@/store/auth'
import { RunSubmissionsService, UserProfile } from "@/api";
import OnAsync from '@/directives/OnAsync';
import FocusToggle from '@/directives/FocusToggle';

@Component({
  components: {
    NavBar
  },
  directives: {
      FocusToggle,
      OnAsync
  }
})
export default class NavBar extends Vue 
{
    public showAdvanced = false;
    public user: UserProfile | null | undefined;
  
    async mounted() {
        this.$store.subscribe((m,s) => 
        {
            if(m.type == "authenticate" && AuthStore.hasAdminClaim()) {
                RunSubmissionsService.getSubmissions().then(s => 
                {
                    if((s.Submissions?.length ?? 0) > 0) {
                        this.$store.commit("attentionRequired", "/admin/queue");
                    }
                });
            }
        })
    }

    async logout() {
        await AuthStore.logout();
        this.$router.push("/");
    }

    async toggleMenu() {
        this.$store.commit("toggleNav");
    }

    public menuPress(e: MouseEventInit) {
        if(e.ctrlKey) {
            this.showAdvanced = true;
        }
    }

    async clearCaches() {
        this.$store.dispatch("clearCaches");
    }

    get hasAdminClaim() {
        return AuthStore.hasAdminClaim();
    }

    get auth() {
        return this.$store.state.auth;
    }

    get attentionRequired() {
        return this.$store.state.attentionRequired;
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

$nav-padding: 0.85rem;

.nav-wrapper {
    display: flex;
    margin-bottom: 1.33rem;
    background-color: $body-background-color;
}

.nav {
    display: flex;
    background-color: $body-background-color;
}

.nav-mandatory {
    display: flex;
    margin-right: $nav-padding;
}

.nav-extras {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.nav-toggle {
    display: none;
}

.nav-left {
    display: flex;
}

.nav-right {
    display: flex;
    padding-right: 1rem;
}

.nav-item {
    display: flex;
    justify-content: center;
    flex-basis: auto;
    align-items:center;
    margin-right: $nav-padding;

    img {
        display: inline-block;
        vertical-align: middle;
    }

    a, button {
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 0.1rem;
        font-size: 1.05rem;
        font-weight: bold;
    }

    &:last-child {
        margin-right: 0;
    }

    &.non-upper > a {
        text-transform: unset;
    }

    .dropdown-trigger {
        button {
            background: none;
            border: none;
            padding: 0 8px;
        }
    }

    .dropdown-menu {
        background-color: $body-background-color;
        border: 1px solid $border-color;
        box-shadow: 0 0 5px 2px $border-color;

        .dropdown-content {
            background-color: $body-background-color;

            .dropdown-item {
                color: $primary;

                &:hover, &:focus, &:active {
                    color: lighten($primary, 10%);
                    background-color: desaturate(lighten($body-background-color, 10%), 15%);
                }
            }

            button.dropdown-item {
                background: none;
                border: unset;
                cursor: pointer;
                outline: none;
            }

            hr.dropdown-divider {
                background-color: $border-color;
                height: 2px;
            }
        }
    }
}

.nav-mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .nav-wrapper {
        height: 64px;
    }

    .nav {
        position: fixed; 
        top: 0;
        left: 0;
        width: 100vw;
        justify-content: space-between;
        margin-bottom: 0;
        height: 64px;
        z-index: 999;
    }

    .nav-mobile {
        display: unset;
    }

    .nav-desktop {
        display: none !important;
    }

    .nav-toggle {
        display: flex;
        align-items: center;

        button {
            background: none;
            font-size: 3rem;
            border: none;
            color: #fff;
            display: flex;
            width: 5rem;
            justify-content: center;
            height: 4rem;
            padding: 0;
            margin: 0;
            line-height: 3.5rem;
        }
        
    }

    .nav-extras, .nav-left, .nav-right {
        display: block;
    }

    .nav-right {
        border-top: 2px solid #444;
        padding-top: $nav-padding;
    }
    
    .nav-extras {
        display: block;
        position: fixed;
        top: 64px;
        left: 100vw;
        z-index: 999;

        height: calc(100vh - 64px);
        width: max-content;
        padding-left: 1.33rem;
        padding-top: $nav-padding;
        padding-right: 1.5rem;
        transform: translateX(100%);
        transition: transform 250ms ease;

        &.nav-shown {
            transform: translateX(-100%);
        }

        background-color: $body-background-color;
        border-left: 2px solid desaturate($body-background-color, 20%);

        .nav-item {
            justify-content: right;
            margin-bottom: $nav-padding;
        }
    }

    .nav-item:last-child {
        margin-right: $nav-padding;
    }
}

</style>