<template>
    <div class="columns is-centered">
        <div class="column is-one-third hr-tile">
            <ConfirmRegistration></ConfirmRegistration>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ConfirmRegistration from '@/components/Auth/ConfirmRegistration.vue';

@Component({
  components: {
      ConfirmRegistration
  },
})
export default class Login extends Vue 
{
    created() {
        if(this.$store.state.auth.isAuthenticated) {
            this.$router.push("/");
        }
    }
}
</script>
