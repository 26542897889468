<template>
<div class="run-input">
    <div class="run-input-entry-row">
        <div class="run-input-cell">
            <div class="level-name">{{context.runnableSegmentName}}</div>
            <small class="time-comparisons">
                Record <span class="timecode" v-if="segmentRecord != null">{{$filters.durationDisplay(segmentRecord.Duration)}}</span>
                <span v-else>not set</span>
                // 
                <span class="timecode" v-if="context.pb?.Duration">PB {{$filters.durationDisplay(context.pb.Duration)}} ({{getPoints(context.pb)}} points)</span>
                <span v-else>No PB</span>
            </small>
        </div>
        <div class="run-input-cell time-input">
            <label class="label is-hidden-tablet">New Time </label>
            <div class="time-input-wrapper">
                <span class="time-input-field">
                    <span v-if="context.probablyRecord && !context.editingDuration" class="fas fa-award record-icon"></span>
                    <time-input v-model="context.duration" @update="updatePoints(); onChange();" @focus="context.editingDuration = true" @blur="context.editingDuration = false; onChange();"></time-input>
                </span>
                <span class="point-viz" v-if="context.pointsEstimate">{{context.pointsEstimate}} <small>points</small></span>
            </div>
            <span v-if="context.probablyRecord && !context.editingDuration && context.probablyMistake" class="warning-text">This time is dramatically faster than world record, are you sure this is correct?</span>
        </div>
        <div class="run-input-cell">
            <div class="evidence-field">
                <label class="label is-hidden-tablet">Video </label>
                <input type="text" class="input" placeholder="Video link" v-model="context.evidenceLinks[0]" :required="context.requiresEvidence && !context.editingDuration && !context.evidenceLinks[0]" @input="onChange()" :class="{'is-danger': context.requiresEvidence && !context.editingDuration && !validUrl(context.evidenceLinks[0])}" />
            </div>
        </div>
    </div>
    
    <div class="run-input-entry-row" v-if="context.partners.length">
        <div class="partner-row"  v-for="(partner, i) in context.partners" :key="i">
            <div class="run-input-cell partner-remove-field" v-if="i+1 > context.requiredPartners">
                <button type="button" class="button is-ghost" @click="removePartner(partner)"><i class="fas fa-times-circle"></i></button>
            </div>
            
            <div class="run-input-cell partner-field"><typeahead :api="userTypeaheadApi" :display-transform="m => m.Username" placeholder="Find partner" v-model="context.partners[i]" :model-transform="m => { return {id: m.UserId, username: m.Username }}" :bindTransform="m => m.username" :required="true"></typeahead></div>
            <div class="run-input-cell additional-evidence-field"><input class="input" type="text" size="10" placeholder="Partner video link" v-model="context.evidenceLinks[i+1]"  :required="context.requiresEvidence && !context.editingDuration && !context.evidenceLinks[i+1]" @input="onChange()" :class="{'is-danger': context.requiresEvidence && !context.editingDuration && !validUrl(context.evidenceLinks[i+1])}" /></div>
        </div>
    </div>

    <div class="run-input-entry-row">
        <div class="run-input-cell add-partner is-hidden-tablet has-text-right" v-if="context.partners.length < context.allowedPartners">
            <button type="button" class="button is-ghost" @click="context.partners.push({}); onChange();">Add Partner</button>
        </div>
        <div class="run-input-cell filters" v-if="category">
            <template v-for="filter in category.Filters" :key="filter.Id">
                <div class="select">
                    <select v-model="context.filterValues[filter.Id]">
                        <option value="" disabled="disabled" selected="selected">{{filter.Name}}</option>
                        <option :value="undefined" v-if="filter.Required == false"></option>
                        <option v-for="val in filter.Values" :key="val.Id" :value="val.Id">{{val.Name}}</option>
                    </select>
                </div>
            </template>
        </div>
        <div class="run-input-cell add-partner is-hidden-mobile" v-if="context.partners.length < context.allowedPartners">
            <button type="button" class="button is-ghost" @click="context.partners.push({}); onChange();">Add Partner</button>
        </div>
    </div>
    <div class="run-input-entry-row mt-4" v-if="adminEdit">
        
        <div class="run-input-cell">
            <label class="label">Run Occurred At (admin only)</label>
            <VueDatePicker v-model="context.occurredAt" dark :format="occurredAtFormat" :is-24="true" utc></VueDatePicker>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { GameStore } from "@/store/games";
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import TimeInput from "@/components/Input/TimeInput.vue";
import Typeahead from '@/components/Input/Typeahead.vue';
import { Category, Game, RunnableSegment, Run, UserCareer, ProfilesService, RecordBoardEntry } from "@/api";
import UrlService from "@/services/UrlService";
import RunInputService, { RunInputContext, RunInputContextPartner } from "@/services/RunInputService";
import VueDatePicker from '@vuepic/vue-datepicker';

@Component({
    components: {
        TimeInput,
        Typeahead,
        VueDatePicker
    },
})
export default class RunInput extends Vue {
    @Prop()
    career: UserCareer | null = null;

    @Prop()
    recordData: { [runnableSegmentId: string]: { [difficulty: string]: RecordBoardEntry[] } } | null = null;

    @Prop()
    context: RunInputContext | null = null;

    @Prop()
    adminEdit: boolean = false;

    games: Game[] = new Array();
    game: Game | null = null;
    category: Category | null = null;

    userTypeaheadApi = ProfilesService.findUsers;

    async mounted(){
        this.games = (await GameStore.get()).games;
        this.game = this.games.find(g => g.Id == this.context?.gameId) ?? null;
        this.category = this.game?.Categories?.find(c => c.Id == this.context?.categoryId) ?? null;
        
        if(this.context) {
            this.context.durationModifyHook = s => this.updatePoints();
        }

        this.updatePoints();
    }

    public removePartner(partner: RunInputContextPartner) {
        RunInputService.removePartner(this.context, partner);
    }

    public async updatePoints() {
        await RunInputService.updatePoints(this.game!, this.recordData, this.context, (await this.$store.state.globals).RunValidationOptions!);
    }

    public getPoints(run: Run) {
        return RunInputService.getPoints(this.game!, this.recordData, this.context, run);
    }

    get categoryLevels(): {[props: string]: RunnableSegment} {
        if(this.category == null) return {};

        var initial = {} as {[props: string]: RunnableSegment};

        return this.game?.RunnableSegments!
            .filter(l => this.category!.AllowedSegments!.indexOf(l.Id!) >= 0)
            .reduce ((a: any, c) => { a[c.Id!] = c; return a; }, initial);
    }

    get segmentRecord() {
        if(this.recordData && this.context?.runnableSegmentId && this.context.difficulty
            && this.recordData[this.context.runnableSegmentId] 
            && this.recordData[this.context.runnableSegmentId][this.context.difficulty] 
            && this.recordData[this.context.runnableSegmentId][this.context.difficulty][0]) {
                return this.recordData[this.context.runnableSegmentId][this.context.difficulty][0];
            }

        return null;
    }

    public validUrl(val: string | null) {
        return UrlService.isValidUrl(val);
    }

    public onChange() {
        this.$emit("change", { run: this.context });
    }

    public occurredAtFormat(date: Date) {
        return date.toISOString();
    }
}


</script>

<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.run-input {
    .select {
        margin: 0;
    }

    .level-name {
        margin-bottom: -0.4rem;
        letter-spacing: 0.05rem;
        font-size: 1.1rem;
    }

    .time-comparisons {
        margin-bottom: 0.6rem;
        display: inline-block;
    }


    .run-input-entry-row {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        row-gap: 0.5rem;
        column-gap: 0.5rem;

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }

        .run-input-cell {
            
            small {
                font-size: 0.7rem;
                color: #999;
            }
        }
    }
    

    @media screen and (max-width: 768px) {
        .run-input-entry-row .partner-row {
            display: grid;
            grid-template-columns: 3rem auto;
            grid-template-rows: auto auto;
            grid-column-gap: 0;
            row-gap: 0;
        }
    }

    .partner-row {
        grid-column: 1 / 4;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        row-gap: 0.5rem;
        column-gap: 0.5rem;
    }
    
    .partner-remove-field {
        grid-column: 1 / 2;
        text-align: right;

        @media screen and (max-width: 768px) {
            grid-row: 1 / 3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            justify-items: center;
            align-content: center;
        }
    }

    .partner-field {
        grid-column: 2 / 3;

        @media screen and (max-width: 768px) {
            grid-row: 1 / 2;
        }
    }

    .additional-evidence-field {
        grid-column: 3 / 4;

        @media screen and (max-width: 768px) {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            margin-bottom: 1rem;
        }
    }

    .filters {
        grid-column: 1 / 4;
        
        .select {
            margin-right: 0.5rem;
        }
    }

    .add-partner {
        grid-column: 4 / 5;
    }

    .time-input {
        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .label {
            margin-right: 15px;
            width: 25%;
        }

        .time-input-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;

            .time-input-field {
                position: relative;

                .record-icon {
                    position: absolute;
                    top: -4px;
                    right: -4px;
                    transform: rotateZ(25deg);
                    z-index: 5;
                    font-size: 1.5rem;
                }
            }

            .time-input {
                display: inline-block;
            }

            .point-viz {
                display: inline-block;
                margin-left: 4px;
            }
        }
    }

    .evidence-field {
        position: relative;

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 5px;

            .input {
                width: unset;
            }
        }

        .label {
            margin-right: 15px;
            width: 25%;
        }
    }

    .warning-text {
        color: $primary;
    }

    .submit-row {
        margin-top: 15px;
        margin-right: 15px;
    }
}
</style>