import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withKeys as _withKeys, resolveDirective as _resolveDirective, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "has-text-danger mb-4"
}
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { class: "control" }
const _hoisted_5 = { class: "field" }
const _hoisted_6 = { class: "control" }
const _hoisted_7 = { class: "field" }
const _hoisted_8 = { class: "control" }
const _hoisted_9 = { class: "field" }
const _hoisted_10 = { class: "control" }
const _hoisted_11 = { class: "field" }
const _hoisted_12 = { class: "control" }
const _hoisted_13 = { class: "field is-grouped is-justify-content-flex-end" }
const _hoisted_14 = { class: "control" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_on_async = _resolveDirective("on-async")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.userRegistered)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { class: "label" }, "Username", -1)),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.register.Username) = $event))
              }, null, 512), [
                [_vModelText, _ctx.register.Username]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[9] || (_cache[9] = _createElementVNode("label", { class: "label" }, "Email Address", -1)),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: _normalizeClass(["input", {'is-danger': _ctx.hasTouchedEmail && (!_ctx.emailValid || _ctx.emailMismatch)}]),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.register.EmailAddress) = $event)),
                onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emailChanged()))
              }, null, 34), [
                [_vModelText, _ctx.register.EmailAddress]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[10] || (_cache[10] = _createElementVNode("label", { class: "label" }, "Confirm Email Address", -1)),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: _normalizeClass(["input", {'is-danger': _ctx.hasTouchedEmail && (!_ctx.emailValid || _ctx.emailMismatch)}]),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.confirmEmailAddress) = $event)),
                onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.emailChanged()))
              }, null, 34), [
                [_vModelText, _ctx.confirmEmailAddress]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[11] || (_cache[11] = _createElementVNode("label", { class: "label" }, [
              _createTextVNode("Password "),
              _createElementVNode("small", { class: "subtitle is-7" }, "(at least 8 characters)")
            ], -1)),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createElementVNode("input", {
                type: "password",
                class: "input",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.register.Password) = $event))
              }, null, 512), [
                [_vModelText, _ctx.register.Password]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[12] || (_cache[12] = _createElementVNode("label", { class: "label" }, "Confirm Password", -1)),
            _createElementVNode("div", _hoisted_12, [
              _withDirectives(_createElementVNode("input", {
                type: "password",
                class: "input",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.register.ConfirmPassword) = $event)),
                onKeyup: _cache[7] || (_cache[7] = _withKeys(($event: any) => (!_ctx.invalid && _ctx.submit()), ["enter"]))
              }, null, 544), [
                [_vModelText, _ctx.register.ConfirmPassword]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _withDirectives((_openBlock(), _createElementBlock("a", {
                class: "button is-primary",
                disabled: _ctx.invalid
              }, _cache[13] || (_cache[13] = [
                _createTextVNode("Register")
              ]), 8, _hoisted_15)), [
                [_directive_on_async, _ctx.submit]
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.userRegistered)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("p", null, "Registration complete. Please check your email " + _toDisplayString(_ctx.register.EmailAddress) + " to verify your account to allow logging in.", 1),
          _cache[14] || (_cache[14] = _createElementVNode("p", null, "Be sure to check your spam folder if you don't see the email shortly", -1))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}