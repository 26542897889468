import { Directive, VNode } from "vue";

const FocusToggle: Directive<HTMLElement & VNode, string> = {
    mounted(el, binding) {
        if(binding.value == null) {
            throw "No async method provided to FocusToggle directive";
        }

        let target = el as HTMLElement;

        if(binding.arg == "parent" && el.parentElement) {
            target = el.parentElement;
        }

        let open = () => { if(!target.classList.contains(binding.value)) target.classList.add(binding.value); }
        let isOpen = () => target.classList.contains(binding.value);
        let close = () => { if(target.classList.contains(binding.value)) target.classList.remove(binding.value); }

        el.addEventListener("click", e =>
        {
            if(el.getAttribute("disabled")) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();
            e.cancelBubble = true;

            if(isOpen()) {
                close();
            } else {
                open();
            }
        });

        el.addEventListener("focus", e => 
        {
            if(el.getAttribute("disabled")) {
                return;
            }

            open();
        });

        document.addEventListener('keydown', function (event) {
            let e = event || window.event;
            if (e.key === 'Esc' || e.key === 'Escape') {
              close();
            }
        });

        document.addEventListener("click", e => 
        {
            close();
        });
    },
};

export default FocusToggle;