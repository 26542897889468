<template>
    <table v-if="!$store.state.isMobileSmall">
        <thead>
            <tr>
                <th>Game</th>
                <th>Time</th>
                <th>Runner</th>
                <th>Date</th>
            </tr>
        </thead>
        <tbody v-if="!boardData">
            <tr v-for="i in 5" :key="i">
                <td>
                    <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                        <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                    </ContentLoader>
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
        <tbody v-if="boardData">
            <tr v-if="boardData.length == 0">
                <td colspan="999" class="has-text-centered">
                    <p>There aren't any extra categories for this game yet!</p>
                    <!-- <router-link :to="{name: 'Request Category Extension'}">Request an Extension</router-link> -->
                </td>
            </tr>
            <tr v-for="entry in boardData" v-bind:key="entry.Id">
                <td>
                    <router-link :to="getLink(entry)">{{entry.name}} <small> - {{entry.difficulty}}</small></router-link>
                </td>
                <td>
                  <a
                        v-if="entry.records && entry.records.length > 0 && entry.records[0].Participants && entry.records[0].Participants.length && entry.records[0].Participants[0].EvidenceLink"
                        :href="entry.records[0].Participants[0].EvidenceLink"
                        class="timecode"
                    >
                        {{$filters.durationDisplay(entry.records[0].Duration)}}
                    </a>
                    <span v-else-if="entry.records.length > 0" class="timecode">
                        {{$filters.durationDisplay(entry.records[0].Duration)}}
                    </span>
                    <span v-else class="has-text-grey-light">No times</span>
                </td>
                <td>
                    <span v-if="entry.records.length > 0">
                        <span v-for="(p,i) in entry.records[0].Participants" :key="p.Username">
                            <router-link :to="'/profiles/' + p.Username">{{ p.Username }}</router-link><span v-if="entry.records[0].Participants.length > 0 && i < entry.records[0].Participants.length-1">, </span>
                        </span>
                    </span>
                    <small v-if="entry.records.length > 1"> + {{entry.records.length - 1}} more</small>
                </td>
                <td><span v-if="entry.records.length > 0">{{formatDate(entry.records[0].OccurredAt)}}</span></td>
            </tr>
        </tbody>
    </table>
    <div v-else class="run-cards">
        <template v-if="!boardData">
            <div class="run-card" v-for="i in 5" :key="i">
                <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                    <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
                </ContentLoader>
            </div>
        </template>
        <template v-if="boardData">
            <div v-if="boardData.length == 0" >
                <div class="has-text-centered run-card">
                    <p>There aren't any extra categories for this game yet!</p>
                    <!-- <router-link :to="{name: 'Request Category Extension'}">Request an Extension</router-link> -->
                </div>
            </div>
            <div v-for="entry in boardData" v-bind:key="entry.Id" class="run-card">
                <div class="run-details">
                    <router-link :to="getLink(entry)" class="has-text-weight-bold">{{entry.name}} - {{entry.difficulty}}</router-link>
                    <div>
                        <a
                            v-if="entry.records && entry.records.length > 0 && entry.records[0].Participants && entry.records[0].Participants.length && entry.records[0].Participants[0].EvidenceLink"
                            :href="entry.records[0].Participants[0].EvidenceLink"
                            class="timecode"
                        >
                            {{$filters.durationDisplay(entry.records[0].Duration)}}
                        </a>
                        <span v-else-if="entry.records.length > 0 && !entry.records[0].Participants.length" class="timecode">
                            {{$filters.durationDisplay(entry.records[0].Duration)}}
                        </span>
                        <span v-else class="has-text-grey-light">No times</span>
                    </div>
                </div>
                <div class="run-details">
                    <div class="runners">
                        <span v-if="entry.records.length > 0">
                            <span v-for="(p,i) in entry.records[0].Participants" :key="p.Username">
                                <router-link :to="'/profiles/' + p.Username">{{ p.Username }}</router-link><span v-if="entry.records[0].Participants.length > 0 && i < entry.records[0].Participants.length-1">, </span>
                            </span>
                        </span>
                        <small v-if="entry.records.length > 1"> + {{entry.records.length - 1}} more</small>
                    </div>
                    <div class="timestamp">
                        <span v-if="entry.records.length > 0">{{formatDate(entry.records[0].OccurredAt)}}</span>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { ExtensionRecordBoard, ExtensionRecordBoardRow, FeaturedRecordBoard, Game, RecordBoard, RecordBoardEntry, StaticContentService } from "@/api";
import { GameStore } from "@/store/games";
import { ContentLoader } from "vue-content-loader";
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";

interface RecordRow {
    row: ExtensionRecordBoardRow;
    name: string;
    difficulty: string;
    records: RecordBoardEntry[];
}

@Component({
    components: {
        ContentLoader
    }
})
export default class ExtensionRecordView extends Vue {
    @Prop()
    readonly gameId: string | null = null;

    boardData: RecordRow[] | null = null;
    games: Game[] = new Array();

    private dateFormatter = new Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" });

    async created() {
        this.games = (await GameStore.get()).games.filter(g => !g.Hidden);
        await this.refresh();
    }

    @Watch("gameId", {deep: true})
    onGameIdChanged(val: any, oldVal: any) {
      this.refresh();
    }

    async refresh() {
        let boardData : ExtensionRecordBoard | null = null;

        if(!this.gameId) return;

        boardData = await StaticContentService.extensionRecordBoard(this.gameId);

        this.boardData = boardData!.Entries!.reduce((r: RecordRow[], e) => {
            for (const dif in e.RecordsByDifficulty) {
                let diffName = this.getDifficultyName(e, dif);
                r.push({ name: e.CategoryName!, row: e, difficulty: diffName, records: e.RecordsByDifficulty[dif] });
            }

            return r;
        }, <RecordRow[]>[]);
    }

    private getDifficultyName(entry: ExtensionRecordBoardRow, diffId: string) : string {
        let game = this.games.find((g) => g.Id == entry.GameId);

        if (!game) return "Uknown Difficulty";

        var diff = game.Difficulties?.find(d => d.Id == diffId);

        return diff?.Name ?? "Unknown Difficulty";
    }

    formatDate(date: string) : string {
      return this.dateFormatter.format(new Date(date));
    }

    getLink(entry: RecordRow) {
        let game = this.games.find(g => g.Id == this.gameId);

        if(!game) return { name: "Leaderboards" };

        let params: any = {
            game: game.UrlStub,
            level: game.RunnableSegments?.find(r => r.Featured)?.UrlStub,
            difficulty: entry.difficulty
        };

        if(!game.Categories) {
            return {name: "Leaderboards", params };
        }

        let cat = game.Categories.find(c => c.Id == entry.row.CategoryId);

        if(!cat) {
            return {name: "Leaderboards", params };
        }

        params.category = cat.UrlStub;

        return {name: "Leaderboards", params };
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

$tile-double-padding: 2 * $tile-padding;

table {
    width: calc(100% + #{$tile-double-padding});
    margin-left: -$tile-padding;

    tr {
        td:not(:nth-child(1)),
        th:not(:nth-child(1)) {
            text-align: center;
        }

        td:last-child,
        th:last-child {
            text-align: right;
            padding-right: 25px;
            white-space: nowrap;
        }

        td:first-child,
        th:first-child {
            padding-left: 25px;
        }
    }

    tbody  td {
      vertical-align: middle;
    }
}

.extra-links {
    margin-top: 15px;
    margin-bottom: 5px;
}

.run-cards {
    @include hr-tile-full-bleed;
    margin-top: 7px;

    border-top: 2px solid $border-color;
    border-bottom: 2px solid $border-color;

    .run-card {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 7px;
        padding-bottom: 7px;
        
        &:not(:last-child) {
            border-bottom: 1px solid $border-color;
        }

        .run-details {
            display: flex;
            justify-content:space-between;
        }

        .runners {
            font-size: small;
        }

        .timestamp {
            font-size: small;
            color: #ccc;
            text-align: end;
            white-space: nowrap;
        }
    }

}
</style>
