<template>
    <div class="loading-wrapper" v-if="show">
        <div class="loading-container" :style="{animationDuration: animationDuration + 's', animationTimingFunction: 'linear'}">
            <img src="@/assets/running.loop.gif"/>
        </div>
    </div>
</template>
<script lang="ts">

import { Component, Vue } from "vue-facing-decorator";
import { LoadingPlugin } from '@/plugins/Loading';


@Component({})
export default class Loading extends Vue {
    show = false;

    get animationDuration() {
        return this.$store.state.windowWidth / 450;
    }

    created() {
        LoadingPlugin.loadingAction = this.prompt;
    }

    public prompt(show: boolean) {
        this.show = show;
    }
}

</script>

<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.loading-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;

    .loading-container {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        opacity: 1;
        animation-timing-function: linear;
        //animation: running;
        animation-duration: 9s;
        animation-iteration-count: infinite;

        img {
            display: inline-block;
        }
    }
}

@keyframes running {
  0% {
    transform: translateX(-30vw) translateY(-50%);
  }

  100% {
    transform: translateX(30vw) translateY(-50%);
  }
}
</style>