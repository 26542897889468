<template>
<div class="dropdown own-profile-actions " v-if="ownProfile || $store.state.devMode">
    <div class="dropdown-trigger" v-focus-toggle:parent="'is-active'" tabindex="0">
        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" tab-index="-1">
            <span class="icon is-small">
                <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
            </span>
        </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
            <router-link :to="{name: 'Change Username'}" class="dropdown-item" v-if="ownProfile">
                Change Username
            </router-link>
            <hr class="dropdown-divider" v-if="ownProfile && $store.state.devMode" />
            <button class="dropdown-item" v-on-async="rebuildCareer" v-if="$store.state.devMode">
                Rebuild career
            </button>
            <button class="dropdown-item" v-on-async="rebuildProfile" v-if="$store.state.devMode">
                Rebuild profile
            </button>
            <button class="dropdown-item" v-on-async="updateEmbeddedUsernames" v-if="$store.state.devMode">
                Update Embedded Usernames
            </button>
            <hr class="dropdown-divider" v-if="ownProfile" />
            <button class="dropdown-item has-background-danger" v-on-async="exportUserData" v-if="ownProfile">
                Export User Data
            </button>
            <button class="dropdown-item has-background-danger" v-on-async="deleteUserAccount" v-if="ownProfile">
                Delete Account (permanently!)
            </button>
        </div>
    </div>
</div>

</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import { AccountsService, AccountStatus, ProfilesService, UserProfile } from "@/api";
import AuthStore from "@/store/auth";
import OnAsync from "@/directives/OnAsync";
import FocusToggle from "@/directives/FocusToggle";

@Component({
    components: {},
    directives: {
        OnAsync,
        FocusToggle,
    },
})
export default class ProfileActions extends Vue {

    @Prop()
    readonly ownProfile: boolean = false;

    @Prop()
    public profile: UserProfile | null = null;

    mounted() {

    }


    async reloadProfile() {
        if (!this.ownProfile) return;

        await AuthStore.pendingAuth;

        this.profile = await ProfilesService.getUserProfile(this.$store.state.auth.claims.userId!);
    }

    async rebuildCareer() {
        let rebuildEvent = {
            Event: "UserCareerUpdateEvent",
            Payload: {
                UserId: this.profile?.UserId,
            },
        };

        //await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
    }

    async rebuildProfile() {
        let rebuildEvent = {
            Event: "UserProfileUpdateEvent",
            Payload: {
                UserId: this.profile?.UserId,
            },
        };

        //await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
    }

    async updateEmbeddedUsernames() {
        let rebuildEvent = {
            Event: "EmbeddedUsernamesUpdateEvent",
            Payload: {
                UserId: this.profile?.UserId,
            },
        };

        //await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
    }

    async exportUserData() {
        let exportRequest = {
            Password: "",
        };

        let [doit, pw]  = await this.$prompt({
            message: "Confirm your password to export your user data.",
            entryType: "password"
        });

        if(doit && pw != null)
        {
            exportRequest.Password = pw;

            try {
                this.$loading(true);
                let download = await AccountsService.exportSelfData(exportRequest);
                this.$toast.success("Data ready for download");
                let blob = await download.blob();

                setTimeout(() => 
                {
                    var url = window.URL.createObjectURL(blob);
                    const anchorElement = document.createElement('a');
                    anchorElement.href = url;
                    anchorElement.download = `haloruns-userdata-${this.$store.state.auth.claims.userId}.zip`;
                    anchorElement.target = "_blank";

                    anchorElement.click();
                    anchorElement.remove();
                }, 500);
                
            } catch(e) {
                console.log(e);
                this.$toast.error("Export request failed, contact @doubl3h3lix on discord or site admins/moderators");
            } finally {
                this.$loading(false);
            }
        }
        else
        {
            this.$toast.error("Data request cancelled");
        }
    }

    async deleteUserAccount() {
        let deleteRequest = {
            Password: "",
        };

        let [doit, pw]  = await this.$prompt({
            message: "There's no coming back from this, be sure you want to delete everything! Confirm your password to delete your account and all associated runs.",
            entryType: "password"
        });

        if(doit && pw != null)
        {
            deleteRequest.Password = pw;
            try {
                this.$loading(true);
                await AccountsService.destructivelyDeleteSelf(deleteRequest);
                this.$toast.success("Delete succeeded, logging out");
                await AuthStore.logout();
                this.$router.push("/");
            } catch {
                this.$toast.error("Delete request failed, things might be fucked, contact @doubl3h3lix on discord or site admins/moderators");
            } finally {
                this.$loading(false);
            }
        }
        else
        {
            this.$toast.error("Delete request cancelled, nothing changed");
        }
    }

    public togglingStreamVisibility = false;
    async toggleStreamVisibility(e: MouseEvent) {
        e.preventDefault();

        if (this.togglingStreamVisibility) return;
        if (!this.profile?.UserId) return;

        this.togglingStreamVisibility = true;

        try {
            await ProfilesService.toggleStreamVisibility(this.profile.UserId);
            await this.reloadProfile();
        } finally {
            this.togglingStreamVisibility = false;
        }
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.own-profile-actions {
    margin-left: 2rem;

    .dropdown-content {
        border-radius: 0;
    }

    .dropdown-menu {
        right: 0;
        left: unset;
    }

    button {
        border: none;
        cursor: pointer;
        background-color: $grey-darker;

        &:hover {
            background-color: $grey-light;
        }
    }
}
</style>