declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $loading: (show: boolean) => void
    }
}

export class LoadingPlugin {
    static loadingAction = (show: boolean) => {};

    static install(app: any, options: any) {
      app.config.globalProperties.$loading = (show: boolean) => this.loadingAction(show);
    }
}