import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dropdown own-profile-actions"
}
const _hoisted_2 = {
  class: "dropdown-trigger",
  tabindex: "0"
}
const _hoisted_3 = {
  class: "dropdown-menu",
  id: "dropdown-menu",
  role: "menu"
}
const _hoisted_4 = { class: "dropdown-content" }
const _hoisted_5 = {
  key: 1,
  class: "dropdown-divider"
}
const _hoisted_6 = {
  key: 2,
  class: "dropdown-item"
}
const _hoisted_7 = {
  key: 3,
  class: "dropdown-item"
}
const _hoisted_8 = {
  key: 4,
  class: "dropdown-item"
}
const _hoisted_9 = {
  key: 5,
  class: "dropdown-divider"
}
const _hoisted_10 = {
  key: 6,
  class: "dropdown-item has-background-danger"
}
const _hoisted_11 = {
  key: 7,
  class: "dropdown-item has-background-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_focus_toggle = _resolveDirective("focus-toggle")!
  const _directive_on_async = _resolveDirective("on-async")!

  return (_ctx.ownProfile || _ctx.$store.state.devMode)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("button", {
            class: "button",
            "aria-haspopup": "true",
            "aria-controls": "dropdown-menu",
            "tab-index": "-1"
          }, [
            _createElementVNode("span", { class: "icon is-small" }, [
              _createElementVNode("i", {
                class: "fas fa-ellipsis-v",
                "aria-hidden": "true"
              })
            ])
          ], -1)
        ]))), [
          [_directive_focus_toggle, 'is-active', "parent"]
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.ownProfile)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: {name: 'Change Username'},
                  class: "dropdown-item"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Change Username ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.ownProfile && _ctx.$store.state.devMode)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_5))
              : _createCommentVNode("", true),
            (_ctx.$store.state.devMode)
              ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_6, _cache[2] || (_cache[2] = [
                  _createTextVNode(" Rebuild career ")
                ]))), [
                  [_directive_on_async, _ctx.rebuildCareer]
                ])
              : _createCommentVNode("", true),
            (_ctx.$store.state.devMode)
              ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_7, _cache[3] || (_cache[3] = [
                  _createTextVNode(" Rebuild profile ")
                ]))), [
                  [_directive_on_async, _ctx.rebuildProfile]
                ])
              : _createCommentVNode("", true),
            (_ctx.$store.state.devMode)
              ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_8, _cache[4] || (_cache[4] = [
                  _createTextVNode(" Update Embedded Usernames ")
                ]))), [
                  [_directive_on_async, _ctx.updateEmbeddedUsernames]
                ])
              : _createCommentVNode("", true),
            (_ctx.ownProfile)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_9))
              : _createCommentVNode("", true),
            (_ctx.ownProfile)
              ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_10, _cache[5] || (_cache[5] = [
                  _createTextVNode(" Export User Data ")
                ]))), [
                  [_directive_on_async, _ctx.exportUserData]
                ])
              : _createCommentVNode("", true),
            (_ctx.ownProfile)
              ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_11, _cache[6] || (_cache[6] = [
                  _createTextVNode(" Delete Account (permanently!) ")
                ]))), [
                  [_directive_on_async, _ctx.deleteUserAccount]
                ])
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}