<template>
    <div class="columns">
        <div class="column is-7-desktop">
            <section class="hr-tile greeble-tr">
                <h3 class="title is-4">Times</h3>
                <div class="submission-grid" v-if="category">
                    <div class="submission-header-row">
                        <div class="submission-cell header">
                            
                        </div>
                        <div class="submission-cell header time-input is-hidden-mobile">
                            New Times
                        </div>
                        <div class="submission-cell header is-hidden-mobile">Points</div>
                    </div>

                    <div class="submission-entry"  v-for="context in base.submissions" :key="context.runnableSegmentId ?? 'empty'">
                        <div class="submission-entry-row">
                            <div class="submission-cell">
                                <div class="level-name">{{context.runnableSegmentName}}</div>
                                <small class="time-comparisons">
                                    Record <span class="timecode" v-if="getSegmentRecord(context) != null">{{$filters.durationDisplay(getSegmentRecord(context)!.Duration)}}</span>
                                    <span v-else>not set</span>
                                    // 
                                    <span class="timecode" v-if="context.pb?.Duration">PB {{$filters.durationDisplay(context.pb.Duration)}} ({{getPoints(context, context.pb)}} points)</span>
                                    <span v-else>No PB</span>
                                </small>
                            </div>
                            <div class="submission-cell time-input">
                                <div class="time-input-wrapper">
                                    <span class="time-input-field">
                                        <time-input v-model="context.duration" 
                                            @update="updatePoints(context); onChange(context);" 
                                            @focus="context.editingDuration = true" 
                                            @blur="context.editingDuration = false; onChange(context);"></time-input>
                                    </span>
                                </div>
                            </div>
                            <div class="submission-cell points-display">
                                <span v-if="context.probablyRecord && !context.editingDuration" class="fas fa-award record-icon"></span>
                                <span class="point-viz" v-if="context.pointsEstimate">{{context.pointsEstimate}} <small>points</small></span>
                            </div>
                            <span v-if="context.probablyRecord && !context.editingDuration && context.probablyMistake" class="warning-text">This time is dramatically faster than world record, are you sure this is correct?</span>
                        </div>
                    </div>
                    
                    <div class="has-text-right submit-row">
                        <div class="level-right">
                            <router-link class="level-item subtitle mb-0 mr-4 is-6" :to="{name: 'Game Calculators', params: $route.params}">Time Calculator</router-link>
                            <button type="button" class="level-item button is-primary" v-on-async="submit" :disabled="!base.liveSubmissionCount">Submit {{base.liveSubmissionCount}} Run(s)</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="column is-5-desktop">
            <section class="hr-tile">
                <h3 class="title has-subtitle is-4">Run Info</h3>
                 <router-link class="subtitle is-6" :to="{name: 'Detailed Submit', params: $route.params}">(use detailed submission form instead)</router-link>
                
                <submit-details v-if="globalContext" :context="globalContext" :career="base.career" :recordData="base.recordData" @change="anyChange()" ></submit-details>
            </section>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import RunInputService, { RunInputContext } from "@/services/RunInputService";
import Typeahead from '@/components/Input/Typeahead.vue';
import { Category, Difficulty, Game, Run } from "@/api";
import SubmitBase, { SubmitHost } from "./SubmitBase";
import TimeInput from "../Input/TimeInput.vue";
import SubmitDetails from "@/components/Submit/SubmitDetails.vue";
import OnAsync from "@/directives/OnAsync";

@Component({
    components: {
        Typeahead,
        TimeInput,
        SubmitDetails
    },
    directives: {
        OnAsync
    }
})
export default class SubmitForm extends Vue implements SubmitHost {
    submitKind = "SimpleSubmitForm";

    @Prop()
    game: Game | null = null;

    @Prop()
    category: Category | null = null;

    @Prop()
    difficulty: Difficulty | null = null;

    @Prop()
    modelValue: any;
    
    globalContext: RunInputContext | null = null;

    base : SubmitBase = null!;

    public created() {
        this.base = (new SubmitBase(this, this.$store, this.$router, this.$loading, this.$confirm, this.$emit, this.$toast, this.$filters));
    }

    public async mounted() {
        await this.base.mounted(this.submitKind);
        await this.initialize();
    }

    @Watch("game")
    @Watch("category")
    @Watch("difficulty")
    public async initialize() {
        await this.base.initialize();

        for(let sub of this.base.submissions) {
            sub.durationModifyHook = s => {
                this.updatePoints(s);
                this.anyChange();
            }
        }

        this.globalContext = this.base.createGlobalSubmissionContext();
    }

    public getPoints(context: RunInputContext, run: Run) {
        return RunInputService.getPoints(this.game!, this.base.recordData, context, run);
    }

    public async updatePoints(context: RunInputContext) {
        await RunInputService.updatePoints(this.game!, this.base.recordData, context, (await this.$store.state.globals).RunValidationOptions!);
    }

    public getSegmentRecord(context: RunInputContext) {
        if(this.base.recordData && context?.runnableSegmentId && context.difficulty
            && this.base.recordData[context.runnableSegmentId] 
            && this.base.recordData[context.runnableSegmentId][context.difficulty] 
            && this.base.recordData[context.runnableSegmentId][context.difficulty][0]) {
                return this.base.recordData[context.runnableSegmentId][context.difficulty][0];
            }

        return null;
    }

    public async onChange(c: RunInputContext) {
        this.anyChange();
        this.$emit("change", { run: c });
        await this.base.dirty(c);
    }

    public anyChange() {
        if(!this.globalContext) return;

        let anyRequiresEvidence = false;

        for(let submission of this.base.submissions.filter(s => s.duration != null && s.duration > 0)) {
            if(submission.requiresEvidence) {
                anyRequiresEvidence = true;
            }
        }

        this.globalContext.requiresEvidence = anyRequiresEvidence;
    }

    public async submit() {
        for(let submission of this.base.submissions) {

            if(this.globalContext?.partners) {
                submission.partners = this.globalContext.partners;
            }

            if(this.globalContext?.evidenceLinks) {
                submission.evidenceLinks = this.globalContext.evidenceLinks;
            }

            if(this.globalContext?.filterValues) {
                submission.filterValues = this.globalContext.filterValues;
            }
        }

        await this.base.submit();
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

@media screen and (max-width: 768px) {
    div.columns {
        display: flex;
        flex-direction: column-reverse;
    }
}

.submission-grid {
    @include hr-tile-full-bleed;

    .select {
        margin: 0;
    }

    .level-name {
        margin-bottom: -0.4rem;
        letter-spacing: 0.05rem;
        font-size: 1.1rem;
    }

    .time-comparisons {
        margin-bottom: 0.6rem;
        display: inline-block;
    }

    .submission-header-row {
        display: grid;
        grid-template-columns: 3fr repeat(2, 1fr);
        border-bottom: 2px solid $border-color;
        font-family: "Oswald", sans-serif;

        .submission-cell {
            
            small {
                font-size: 0.7rem;
                color: #999;
            }
            
            &.header {
                text-transform: uppercase;
                letter-spacing: 0.15rem;
                padding-bottom: 15px;
            }
        }
    }

    .submission-entry {
        padding: 12px 25px 10px 25px;
        border-bottom: 2px solid $border-color;

        .submission-entry-row {
            display: grid;
            grid-template-columns: 3fr 1fr 1fr;
            row-gap: 0.5rem;
            column-gap: 0.5rem;

            .submission-cell {
                
                small {
                    font-size: 0.7rem;
                    color: #999;
                }

                &.time-input {
                    display: flex;
                    align-items: center;
                }

                &.points-display {
                    display: flex;
                    align-items: center;

                    .record-icon {
                        margin-right: 0.3rem;
                    }
                }
            }

            .warning-text {
                text-align: center;
                grid-column: 1 / 5;
            }
        }
    }
    
    .warning-text {
        color: $primary;
    }

    .submit-row {
        margin-top: 15px;
        margin-right: 15px;
    }
}

.title.has-subtitle {
    margin-bottom: 0;
}

a.subtitle {
    display: inline-block;
    margin-bottom: 1rem;
    text-decoration: underline;
}

</style>