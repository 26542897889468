<template>
<li class="breadcrumb-item">
    <span class="select">
        {{modelValue.game.Name}}
        <select v-model="modelValue.game" @change="gameChange">
            <option v-for="game in games" :key="game.Id" :value="game">
                {{ game.Name }}
            </option>
        </select>
    </span>
</li>
<li class="breadcrumb-item">
    <span class="select">
        {{modelValue.category.Name}}
        <select v-model="modelValue.category" @change="categoryChange">
            <option v-for="category in normalCats" :key="category.Id" :value="category">
                {{ category.Name }}
            </option>
            <template v-for="(category, i) in extensionCats" :key="category.Id" >
                <option v-if="i == 0" class="separator" disabled="disabled">Extras</option>
                <option :value="category">
                    {{ category.Name }}
                </option>
            </template>
        </select>
    </span>
</li>
<li class="breadcrumb-item">
    <span class="select">
    {{modelValue.difficulty?.Name}}
      <select v-model="modelValue.difficulty" @change="difficultyChange">
        <option v-for="diff in categoryDifficulties" :key="diff.Id" :value="diff">
          {{ diff.Name }}
        </option>
      </select>
    </span>
</li>
</template>
<script lang="ts">
import { GameStore } from "@/store/games";
import { Category, Difficulty, Game } from "@/api";
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";

export interface SubmitSelection {
    game: Game | null;
    category: Category | null;
    difficulty: Difficulty | undefined | null;
}

@Component({})
export default class SubmitSelector extends Vue {
    games: Game[] = new Array();

    @Prop()
    modelValue: SubmitSelection = {} as SubmitSelection;

    async created() {
        await this.load();
    }

    async load() {
        var gamestore = await GameStore.get();
        this.games = gamestore.games.filter(g => !g.Hidden);
        this.modelValue.game = this.modelValue.game || this.games[0];
        this.modelValue.category = this.modelValue.category || this.modelValue.game.Categories![0];
        this.modelValue.difficulty = this.modelValue.difficulty || this.modelValue.game.Difficulties?.filter(d => this.modelValue.category?.Difficulties?.indexOf(d?.Id!)! >= 0)[0];
    }

    public gameChange() {
        if(this.modelValue.game == null) return;
        this.modelValue.category = this.modelValue.game.Categories![0];
        this.$emit("gameChange", this.modelValue.game);
        this.categoryChange();
    }

    public categoryChange() {
        if(this.modelValue.category == null) return;
        this.modelValue.difficulty = this.modelValue.game?.Difficulties?.filter(d => this.modelValue.category?.Difficulties?.indexOf(d?.Id!)! >= 0)[0];
        this.$emit("categoryChange", this.modelValue.category);
        this.difficultyChange();
    }

    public difficultyChange() {
        this.$emit("difficultyChange", this.modelValue.difficulty);
        this.anyChange();
    }

    public anyChange() {
        this.$emit("update:modelValue", {
            game: this.modelValue?.game,
            category: this.modelValue?.category,
            difficulty: this.modelValue?.difficulty
        });
    }

    get categoryDifficulties() {
        return this.modelValue.game?.Difficulties?.filter(d => this.modelValue.category?.Difficulties?.indexOf(d?.Id!)! >= 0);
    }

    get normalCats() {
        return this.modelValue?.game?.Categories?.filter(c => !c.IsExtension);
    }

    get extensionCats() {
        return this.modelValue?.game?.Categories?.filter(c => c.IsExtension);
    }
}

</script>
<style lang="scss" scoped>
option.separator {
    margin-top: 5px;
    border-bottom: 2px solid #ccc;
}

</style>