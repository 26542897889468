<template>
    <div class="overlay" v-if="showModal" @click="overlayCancel">
        <div class="modal-row">
            <div class="modal-box" @click="suppressCancel">
                <div class="title-bar" v-if="title">
                    {{title}}
                </div>
                <p class="is-large" v-for="message in messages" :key="message">{{message}}</p>
                <div class="buttons">
                    <button class="button is-ghost" @click="cancel()" v-if="args.cancelAlias">{{args.cancelAlias}}</button>
                    <button class="button is-primary" @click="confirm()">{{args.confirmAlias}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">

import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import ConfirmArguments, { ConfirmPlugin } from '@/plugins/Confirm';


@Component({})
export default class Confirm extends Vue {
    private static DefaultArgs: ConfirmArguments = {
        title: null,
        message: "Are you sure?",
        confirmAlias: "Confirm",
        cancelAlias: "Cancel"
    };

    showModal = false;
    args = Confirm.DefaultArgs;
    title: string | null = null;
    messages: string[] = new Array();
    pendingConfirm: Promise<boolean> = Promise.resolve<boolean>(true);

    resolver: ((v: boolean) => void) | null = null;

    created() {
        ConfirmPlugin.confirmAction = this.prompt;
    }

    public prompt(args: ConfirmArguments | null) {
        this.pendingConfirm = new Promise<boolean>((resolve, reject) => {
            this.resolver = resolve;
        });
        
        this.args = Object.assign({}, Confirm.DefaultArgs, args);

        this.title = args?.title ?? null;

        if(args?.message) {
            this.messages = args?.message.split("\n");
        } else {
            this.messages = [args?.message!];
        }

        this.showModal = true;
        return this.pendingConfirm;
    }

    public cancel() {
        if(this.resolver)
            this.resolver(false);

        this.showModal = false;
    }

    public confirm() {
        if(this.resolver)
            this.resolver(true);

        this.showModal = false;
    }

    public overlayCancel(e: InputEvent) {
        if((e.target as HTMLElement).classList.contains("overlay")) {
            this.cancel();
        }
    }
}

</script>

<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 

    .modal-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; 
        width: 100%;

        .modal-box {
            min-width: 30%;
            background-color: $body-background-color;
            padding: 2rem;
            z-index: 1000;

            .title-bar {
                padding-bottom: 7px;
                margin-bottom: 10px;
                border-bottom: 2px solid $border-color;
            }

            .buttons {
                margin-top: 2rem;
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
            }
        }
    }
}


</style>