<template>
    <div class="columns is-centered">
        <div class="column is-half hr-tile">
            <h2 class="title is-3">User Login</h2>
            <LoginForm></LoginForm>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import LoginForm from '@/components/Auth/Login.vue'

@Component({
  components: {
      LoginForm
  },
})
export default class Login extends Vue 
{
    created() {
        if(this.$store.state.auth.isAuthenticated) {
            this.$router.push("/");
        }
    }
}
</script>
