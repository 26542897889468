import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "home hr-tile" }
const _hoisted_2 = {
  key: 0,
  class: "hr-tile career-details"
}
const _hoisted_3 = { class: "title is-4" }
const _hoisted_4 = { class: "select inline-select" }
const _hoisted_5 = {
  key: 0,
  class: "trophy-container"
}
const _hoisted_6 = {
  key: 1,
  class: "trophy-container"
}
const _hoisted_7 = {
  key: 2,
  class: "trophy-container"
}
const _hoisted_8 = { style: {"min-height":"100vh"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_profile_tabs = _resolveComponent("user-profile-tabs")!
  const _component_user_career_game = _resolveComponent("user-career-game")!
  const _directive_masonry = _resolveDirective("masonry")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_user_profile_tabs, { "is-own-profile": _ctx.isOwnProfile }, null, 8, ["is-own-profile"])
    ]),
    (_ctx.career)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.chosenCategory) + " ", 1),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chosenCategory) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.catOrder, (category) => {
                  return (_openBlock(), _createElementBlock("option", { key: category }, _toDisplayString(category), 1))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.chosenCategory]
              ])
            ]),
            _cache[1] || (_cache[1] = _createTextVNode(" Career "))
          ]),
          (_ctx.career.RankInfoByCategory[_ctx.chosenCategory])
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", null, [
                  _cache[2] || (_cache[2] = _createElementVNode("h6", { class: "subtitle is-6 is-inline" }, "Games: ", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: game.Id,
                      class: _normalizeClass(["game-symbol", {'submitted': _ctx.career.SubmittedGamesByCategory[_ctx.chosenCategory].indexOf(game.Id) >= 0}])
                    }, _toDisplayString(game.UrlStub), 3))
                  }), 128))
                ]),
                _createElementVNode("div", null, [
                  _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "subtitle is-6 is-inline" }, "Live Runs: ", -1)),
                  _createTextVNode(_toDisplayString(_ctx.career.RunsByCategory[_ctx.chosenCategory]?.length || 0), 1)
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("h6", { class: "subtitle is-6 is-inline" }, "Points: ", -1)),
                _createTextVNode(_toDisplayString(_ctx.career.RankInfoByCategory[_ctx.chosenCategory].Points), 1),
                _cache[9] || (_cache[9] = _createElementVNode("small", { class: "muted" }, "pts", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.career.RankInfoByCategory[_ctx.chosenCategory]], (info) => {
                  return (_openBlock(), _createElementBlock("div", { key: info }, [
                    _cache[7] || (_cache[7] = _createElementVNode("h6", { class: "subtitle is-6 is-inline" }, "Trophies: ", -1)),
                    (info.GoldCount + info.SilverCount + info.BronzeCount)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (info.GoldCount)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                _cache[4] || (_cache[4] = _createElementVNode("i", { class: "trophy in-line rank-1" }, null, -1)),
                                _createTextVNode(" × " + _toDisplayString(info.GoldCount), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (info.SilverCount)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                                _cache[5] || (_cache[5] = _createElementVNode("i", { class: "trophy in-line rank-2" }, null, -1)),
                                _createTextVNode(" × " + _toDisplayString(info.SilverCount), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (info.BronzeCount)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                _cache[6] || (_cache[6] = _createElementVNode("i", { class: "trophy in-line rank-3" }, null, -1)),
                                _createTextVNode(" × " + _toDisplayString(info.BronzeCount), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ], 64))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
      (_ctx.career && _ctx.chosenCategory)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.categoryGames, (game) => {
            return (_openBlock(), _createBlock(_component_user_career_game, {
              key: game.Id,
              runs: _ctx.career.RunsByCategory[_ctx.chosenCategory],
              categoryName: _ctx.chosenCategory,
              game: game,
              editable: _ctx.canEditRuns,
              submittedOnly: true
            }, null, 8, ["runs", "categoryName", "game", "editable"]))
          }), 128))
        : _createCommentVNode("", true)
    ])), [
      [_directive_masonry, _ctx.$store.state.windowWidth <= 1215 ? 1 : 2, _ctx.$route]
    ])
  ], 64))
}