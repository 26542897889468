import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "hr-tile" }
const _hoisted_2 = { class: "breadcrumbs" }
const _hoisted_3 = { class: "breadcrumb-item" }
const _hoisted_4 = { class: "hr-tile" }
const _hoisted_5 = { class: "filters" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "select" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "select" }
const _hoisted_10 = ["value"]
const _hoisted_11 = {
  key: 0,
  class: "record-table"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "rank" }
const _hoisted_14 = { class: "has-text-centered" }
const _hoisted_15 = { class: "subtitle is-6" }
const _hoisted_16 = ["href"]
const _hoisted_17 = {
  key: 1,
  class: "timecode"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = ["width"]
const _hoisted_21 = ["width"]
const _hoisted_22 = {
  key: 1,
  class: "record-cards"
}
const _hoisted_23 = { class: "rank" }
const _hoisted_24 = { class: "content" }
const _hoisted_25 = { class: "record-info" }
const _hoisted_26 = { class: "headline" }
const _hoisted_27 = { class: "subtitle is-6" }
const _hoisted_28 = ["href"]
const _hoisted_29 = {
  key: 1,
  class: "timecode"
}
const _hoisted_30 = { class: "record-info" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { class: "timestamp subtitle is-6" }
const _hoisted_33 = { class: "content" }
const _hoisted_34 = { class: "record-info" }
const _hoisted_35 = { class: "headline" }
const _hoisted_36 = ["width"]
const _hoisted_37 = { class: "subtitle is-6" }
const _hoisted_38 = ["width"]
const _hoisted_39 = ["width"]
const _hoisted_40 = { class: "record-info" }
const _hoisted_41 = ["width"]
const _hoisted_42 = { class: "timestamp subtitle is-6" }
const _hoisted_43 = ["width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ContentLoader = _resolveComponent("ContentLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: '/' }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("li", { class: "breadcrumb-item" }, [
          _createElementVNode("span", null, "Oldest Records")
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "title is-4" }, "Oldest Records", -1)),
        (_ctx.records)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.GameId) = $event)),
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter()))
                }, [
                  _cache[6] || (_cache[6] = _createElementVNode("option", { value: null }, "Any Game", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: game.Id,
                      value: game.Id
                    }, _toDisplayString(game.Name), 9, _hoisted_8))
                  }), 128))
                ], 544), [
                  [_vModelSelect, _ctx.filters.GameId]
                ])
              ]),
              _createElementVNode("span", _hoisted_9, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.CategoryName) = $event)),
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filter()))
                }, [
                  _cache[7] || (_cache[7] = _createElementVNode("option", { value: null }, "Any Category", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: category,
                      value: category
                    }, _toDisplayString(category), 9, _hoisted_10))
                  }), 128))
                ], 544), [
                  [_vModelSelect, _ctx.filters.CategoryName]
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.$store.state.isMobileSmall)
        ? (_openBlock(), _createElementBlock("table", _hoisted_11, [
            _cache[14] || (_cache[14] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Rank"),
                _createElementVNode("th", null, "Date"),
                _createElementVNode("th", null, "Run"),
                _createElementVNode("th", null, "Duration"),
                _createElementVNode("th", null, "Participant(s)"),
                _createElementVNode("th", null, "Age")
              ])
            ], -1)),
            (_ctx.filteredRecords)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredRecords, (record) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: record.rank
                    }, [
                      _createElementVNode("td", _hoisted_13, _toDisplayString(record.rank), 1),
                      _createElementVNode("td", null, _toDisplayString(record.occurredAt), 1),
                      _createElementVNode("td", _hoisted_14, [
                        _createElementVNode("div", null, _toDisplayString(record.segmentName) + " " + _toDisplayString(record.categoryName), 1),
                        _createElementVNode("div", _hoisted_15, _toDisplayString(record.gameName) + " - " + _toDisplayString(record.difficulty), 1)
                      ]),
                      _createElementVNode("td", null, [
                        (record.participants && record.participants.length && record.participants[0].EvidenceLink)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: record.participants[0].EvidenceLink,
                              class: "timecode"
                            }, _toDisplayString(_ctx.$filters.durationDisplay(record.duration)), 9, _hoisted_16))
                          : _createCommentVNode("", true),
                        (!record.participants.length)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$filters.durationDisplay(record.duration)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.participants, (p, j) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: p.Username
                          }, [
                            _createVNode(_component_router_link, {
                              to: '/profiles/' + p.Username
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(p.Username), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"]),
                            (j != (record.participants.length - 1))
                              ? (_openBlock(), _createElementBlock("span", _hoisted_18, ", "))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("td", null, [
                        _createTextVNode(_toDisplayString(record.ageDays) + " ", 1),
                        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "subtitle is-6" }, "days", -1))
                      ])
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("tbody", _hoisted_19, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(20, (i) => {
                    return _createElementVNode("tr", { key: i }, [
                      _cache[10] || (_cache[10] = _createElementVNode("td", { class: "rank" }, "#", -1)),
                      _createElementVNode("td", null, [
                        _createVNode(_component_ContentLoader, {
                          width: "170",
                          height: "18",
                          "primary-color": "#1f2a3c",
                          "secondary-color": "#2c3b55"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("rect", {
                              x: "0",
                              y: "5",
                              rx: "2",
                              ry: "2",
                              width: (150 + Math.random() * 20),
                              height: "10"
                            }, null, 8, _hoisted_20)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("td", null, [
                        _createVNode(_component_ContentLoader, {
                          width: "170",
                          height: "18",
                          "primary-color": "#1f2a3c",
                          "secondary-color": "#2c3b55"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("rect", {
                              x: "0",
                              y: "5",
                              rx: "2",
                              ry: "2",
                              width: (150 + Math.random() * 20),
                              height: "10"
                            }, null, 8, _hoisted_21)
                          ]),
                          _: 1
                        })
                      ]),
                      _cache[11] || (_cache[11] = _createElementVNode("td", null, null, -1)),
                      _cache[12] || (_cache[12] = _createElementVNode("td", null, null, -1)),
                      _cache[13] || (_cache[13] = _createElementVNode("td", null, null, -1))
                    ])
                  }), 64))
                ]))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_22, [
            (_ctx.filteredRecords)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filteredRecords, (record) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "record-card",
                    key: record.rank
                  }, [
                    _createElementVNode("div", _hoisted_23, _toDisplayString(record.rank), 1),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", null, _toDisplayString(record.segmentName) + " " + _toDisplayString(record.categoryName), 1),
                            _createElementVNode("div", _hoisted_27, _toDisplayString(record.gameName) + " - " + _toDisplayString(record.difficulty), 1)
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          (record.participants && record.participants.length && record.participants[0].EvidenceLink)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: record.participants[0].EvidenceLink,
                                class: "timecode"
                              }, _toDisplayString(_ctx.$filters.durationDisplay(record.duration)), 9, _hoisted_28))
                            : _createCommentVNode("", true),
                          (!record.participants.length)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_ctx.$filters.durationDisplay(record.duration)), 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.participants, (p, j) => {
                            return (_openBlock(), _createElementBlock("small", {
                              key: p.Username
                            }, [
                              _createVNode(_component_router_link, {
                                to: '/profiles/' + p.Username
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(p.Username), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"]),
                              (j != (record.participants.length - 1))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_31, ", "))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128)),
                          _cache[15] || (_cache[15] = _createTextVNode(" - ")),
                          _createElementVNode("small", null, _toDisplayString(record.ageDays), 1),
                          _cache[16] || (_cache[16] = _createTextVNode()),
                          _cache[17] || (_cache[17] = _createElementVNode("span", { class: "subtitle is-6" }, "days ago", -1))
                        ]),
                        _createElementVNode("div", _hoisted_32, _toDisplayString(record.occurredAt), 1)
                      ])
                    ])
                  ]))
                }), 128))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(20, (i) => {
                  return _createElementVNode("div", {
                    class: "record-card",
                    key: i
                  }, [
                    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "rank" }, "#", -1)),
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("div", _hoisted_35, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_ContentLoader, {
                                width: "170",
                                height: "18",
                                "primary-color": "#1f2a3c",
                                "secondary-color": "#2c3b55"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("rect", {
                                    x: "0",
                                    y: "5",
                                    rx: "2",
                                    ry: "2",
                                    width: (150 + Math.random() * 20),
                                    height: "10"
                                  }, null, 8, _hoisted_36)
                                ]),
                                _: 1
                              })
                            ]),
                            _createElementVNode("div", _hoisted_37, [
                              _createVNode(_component_ContentLoader, {
                                width: "170",
                                height: "18",
                                "primary-color": "#1f2a3c",
                                "secondary-color": "#2c3b55"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("rect", {
                                    x: "0",
                                    y: "5",
                                    rx: "2",
                                    ry: "2",
                                    width: (150 + Math.random() * 20),
                                    height: "10"
                                  }, null, 8, _hoisted_38)
                                ]),
                                _: 1
                              })
                            ])
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_ContentLoader, {
                            width: "120",
                            height: "18",
                            "primary-color": "#1f2a3c",
                            "secondary-color": "#2c3b55"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("rect", {
                                x: "0",
                                y: "5",
                                rx: "2",
                                ry: "2",
                                width: (100 + Math.random() * 20),
                                height: "10"
                              }, null, 8, _hoisted_39)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("div", null, [
                          _createVNode(_component_ContentLoader, {
                            width: "170",
                            height: "18",
                            "primary-color": "#1f2a3c",
                            "secondary-color": "#2c3b55"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("rect", {
                                x: "0",
                                y: "5",
                                rx: "2",
                                ry: "2",
                                width: (150 + Math.random() * 20),
                                height: "10"
                              }, null, 8, _hoisted_41)
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", _hoisted_42, [
                          _createVNode(_component_ContentLoader, {
                            width: "120",
                            height: "18",
                            "primary-color": "#1f2a3c",
                            "secondary-color": "#2c3b55"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("rect", {
                                x: "0",
                                y: "5",
                                rx: "2",
                                ry: "2",
                                width: (100 + Math.random() * 20),
                                height: "10"
                              }, null, 8, _hoisted_43)
                            ]),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ])
                }), 64))
          ]))
    ])
  ], 64))
}