import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store, { key } from './store'
import AuthStore from './store/auth'
import { ConfirmPlugin } from './plugins/Confirm';
import { PromptPlugin } from './plugins/Prompt';
import { Chart, registerables } from "chart.js";
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import { ToastPlugin } from './plugins/Toast'
import Configuration from './config'
import { LoadingPlugin } from './plugins/Loading'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { FiltersPlugin } from './plugins/Filters'
import { StaticContentService } from './api';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { toNative } from 'vue-facing-decorator'

var globalData = StaticContentService.globalMetadata();

// Bootstrap config, data, and try to get auth key silently
try { 
    Configuration.get(); 
    console.log(process.env.VUE_APP_GIT_HASH); 
    console.log(process.env.VUE_APP_BUILD_TIME); 
    AuthStore.refresh();
} catch{}

Chart.register(...registerables);
Chart.register(annotationPlugin);

let toastDefaults: PluginOptions = {
    // Setting the global default position
    position: POSITION.BOTTOM_CENTER,
    draggable: false,
    hideProgressBar: true,
    timeout: 3000
};

let app = createApp(App)
    .use(store, key)
    .use(router)
    .use(FiltersPlugin, {})
    .use(ConfirmPlugin, {})
    .use(PromptPlugin, {})
    .use(LoadingPlugin, {})
    .use(Toast, toastDefaults)
    .use(ToastPlugin, {})
    .component("VueDatePicker", VueDatePicker)
    .mount('#app');

app.$store.commit("initializeGlobals", globalData);

export const AppInsights = new ApplicationInsights({ 
    config: {
        connectionString: Configuration.AppInsightsConnection,
        disableCookiesUsage: true,
        disableFetchTracking: false,
        enableAutoRouteTracking: true
    }
});

AppInsights.loadAppInsights();
AppInsights.addTelemetryInitializer(item => {
    if(app.$store?.state?.auth?.claims) {
        Object.assign(item.data as {}, { 
            hrUserId: app.$store.state.auth.claims.userId, 
            hrUser: app.$store.state.auth.claims.name, 
            hrClaims: app.$store.state.auth.claims.hrClaims 
        });
    }
});

