import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withKeys as _withKeys, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "warning"
}
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = { class: "field" }
const _hoisted_5 = { class: "control" }
const _hoisted_6 = { class: "field is-grouped is-flex-direction-row-reverse is-justify-content-flex-end" }
const _hoisted_7 = { class: "control" }
const _hoisted_8 = {
  class: "button is-primary",
  href: "#"
}
const _hoisted_9 = { class: "control" }
const _hoisted_10 = { class: "is-flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_on_async = _resolveDirective("on-async")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { class: "label" }, "Username", -1)),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.authReq.Username) = $event))
        }, null, 512), [
          [_vModelText, _ctx.authReq.Username]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { class: "label" }, "Password", -1)),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          type: "password",
          class: "input",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.authReq.Secret) = $event)),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["enter"]))
        }, null, 544), [
          [_vModelText, _ctx.authReq.Secret]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _withDirectives((_openBlock(), _createElementBlock("a", _hoisted_8, _cache[5] || (_cache[5] = [
          _createTextVNode("Login")
        ]))), [
          [_directive_on_async, _ctx.submit]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_link, {
          class: "button is-text",
          to: "/auth/register"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Register")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_router_link, { to: "/auth/forgot" }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createElementVNode("small", null, "Forgot password?", -1)
          ])),
          _: 1
        })
      ])
    ])
  ], 64))
}