<template>
    <Authenticating></Authenticating>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator"
import Authenticating from "@/components/Authenticating.vue"
import { IntegrationsService } from "@/api";


@Component({
    components: {Authenticating}
})
export default class XboxAuth extends Vue {
    async created() {
        if (this.$route.query.code?.toString() == null) {
            await this.$router.push({name: 'User Profile', params: {'username': this.$store.state.auth.username}});
        } else {
            await IntegrationsService.linkXbox(this.$route.query.code?.toString());
            this.$toast.success("Linked your Xbox account!");
            setTimeout(() => this.$router.push({name: 'User Profile', params: {'username': this.$store.state.auth.username}}), 1000);
        }
    }
}
</script>