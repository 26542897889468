<template>
<div class="hr-tile">
    <ul class="breadcrumbs">
        <li v-for="(match, i) in routeComponents" :key="match.path" class="breadcrumb-item">
            <!-- <router-link :to="match.path" v-if="i < routeComponents.length - 1">{{match.name}}</router-link> -->
            <!-- <span v-if="i == routeComponents.length - 1">{{match.name}}</span> -->
            <span v-if="i == i">{{match.name}}</span>
        </li>
    </ul>
</div>
<div class="columns is-desktop">
    <div class="column is-one-third-desktop">
        <div class="hr-tile">
            <table class="time-table">
                <thead>
                    <tr>
                        <th>Level</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="level in categoryLevels" :key="level.Id">
                        <td>{{level.Name}}</td>
                        <td>
                            <time-input v-model="level.Duration"></time-input>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="has-text-right">Total</td>
                        <td>{{levelDurationSum}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><router-link :to="{name: 'Simple Submit', params: $route.params}">Submit Time</router-link></td>
                    </tr>
                </tfoot>
            </table>

        </div>
    </div>
    <div class="column is-two-thirds-desktop">
        <div class="hr-tile" v-if="!selectedGame">
            <h4 class="title is-4">Game Rules</h4>
        </div>
        <div class="hr-tile rules" v-if="selectedGame">
            <h4 class="title is-4">{{selectedGame.Name}} Rules</h4>
            <Markdown v-if="selectedGame.Rules" :source="selectedGame.Rules" class="rule-section" :breaks="true"></Markdown>
            <div v-if="selectedCategory.Rules">
                <h1>{{selectedCategory.Name}} Rules</h1>
                <Markdown :source="selectedCategory.Rules" class="rule-section" :breaks="true"></Markdown>
            </div>
        </div>
    </div>
</div>



</template>
<script lang="ts">
import { Category, Game, RunnableSegment } from "@/api";
import { GameStore } from "@/store/games";
import { Component, Vue } from "vue-facing-decorator";
import Markdown from 'vue3-markdown-it';
import TimeInput from "@/components/Input/TimeInput.vue";
import TimeService from "@/services/TimeService";

@Component({
    components: {
        TimeInput,
        Markdown
    }
})
export default class Calc extends Vue {
    public games: Game[] = new Array();
    public selectedGame: Game | undefined | null = null;
    public selectedCategory: Category | undefined | null = null;
    public categoryLevels: (RunnableSegment & {Duration: number})[] = new Array();
    public routeComponents: {name: string, path: string}[] = new Array();

    async created() {
        this.games = (await GameStore.get()).games;

        this.loadRoute();
    }

    get levelDurationSum() {
        var totalSeconds = this.categoryLevels.reduce((e,l) => e + (l.Duration || 0), 0);
        return TimeService.secondsToString(totalSeconds);
    }

    public loadRoute() {
        let gameStub = this.$route.params["game"];
        let categoryStub = this.$route.params["category"];

        if(gameStub && typeof gameStub == "string") {
            this.selectedGame = this.games.find(g => g.UrlStub == gameStub);
        }

        if(this.selectedGame?.Categories && categoryStub && typeof categoryStub == "string") {
            this.selectedCategory = this.selectedGame.Categories.find(g => g.UrlStub == categoryStub);
        }

        if(this.selectedGame?.Categories?.length && !this.selectedCategory) {
            this.selectedCategory = this.selectedGame.Categories[0];
            // todo fix route
        }

        this.categoryLevels = new Array();
        for(let id of this.selectedCategory?.AllowedSegments ?? []) {

            let segment = this.selectedGame?.RunnableSegments?.find(s => s.Id == id) as (RunnableSegment & {Duration: number});

            // todo: il flag on segment
            if(!segment || segment.Points! > 100) {
                continue;
            }

            this.categoryLevels.push(segment);
        }

        this.routeComponents = [{
            path: "/calc",
            name: "Game Calculators"
        }];

        if(this.selectedGame) {
            let last = this.routeComponents[this.routeComponents.length - 1];
            this.routeComponents.push({
                path: last.path + "/" + this.selectedGame.UrlStub,
                name: this.selectedGame.Name!
            })
        }

        if(this.selectedGame && this.selectedCategory) {
            let last = this.routeComponents[this.routeComponents.length - 1];
            this.routeComponents.push({
                path: last.path + "/" + this.selectedCategory.UrlStub,
                name: this.selectedCategory.Name!
            })
        }
    }
}


</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.time-table {
    @include hr-tile-full-bleed;

    tr:first-child() {
        padding-left: 15px;
    }

    tfoot {
        border-top: 2px solid $border-color;
    }
}
</style>