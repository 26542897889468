import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "column is-7-desktop" }
const _hoisted_3 = { class: "hr-tile greeble-tr" }
const _hoisted_4 = {
  key: 0,
  class: "submission-grid"
}
const _hoisted_5 = { class: "submission-entry-row" }
const _hoisted_6 = { class: "submission-cell" }
const _hoisted_7 = { class: "level-name" }
const _hoisted_8 = { class: "time-comparisons" }
const _hoisted_9 = {
  key: 0,
  class: "timecode"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 2,
  class: "timecode"
}
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { class: "submission-cell time-input" }
const _hoisted_14 = { class: "time-input-wrapper" }
const _hoisted_15 = { class: "time-input-field" }
const _hoisted_16 = { class: "submission-cell points-display" }
const _hoisted_17 = {
  key: 0,
  class: "fas fa-award record-icon"
}
const _hoisted_18 = {
  key: 1,
  class: "point-viz"
}
const _hoisted_19 = {
  key: 0,
  class: "warning-text"
}
const _hoisted_20 = { class: "has-text-right submit-row" }
const _hoisted_21 = { class: "level-right" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = { class: "column is-5-desktop" }
const _hoisted_24 = { class: "hr-tile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_input = _resolveComponent("time-input")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_submit_details = _resolveComponent("submit-details")!
  const _directive_on_async = _resolveDirective("on-async")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "title is-4" }, "Times", -1)),
        (_ctx.category)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "submission-header-row" }, [
                _createElementVNode("div", { class: "submission-cell header" }),
                _createElementVNode("div", { class: "submission-cell header time-input is-hidden-mobile" }, " New Times "),
                _createElementVNode("div", { class: "submission-cell header is-hidden-mobile" }, "Points")
              ], -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.base.submissions, (context) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "submission-entry",
                  key: context.runnableSegmentId ?? 'empty'
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, _toDisplayString(context.runnableSegmentName), 1),
                      _createElementVNode("small", _hoisted_8, [
                        _cache[1] || (_cache[1] = _createTextVNode(" Record ")),
                        (_ctx.getSegmentRecord(context) != null)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$filters.durationDisplay(_ctx.getSegmentRecord(context)!.Duration)), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_10, "not set")),
                        _cache[2] || (_cache[2] = _createTextVNode(" // ")),
                        (context.pb?.Duration)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, "PB " + _toDisplayString(_ctx.$filters.durationDisplay(context.pb.Duration)) + " (" + _toDisplayString(_ctx.getPoints(context, context.pb)) + " points)", 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_12, "No PB"))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, [
                          _createVNode(_component_time_input, {
                            modelValue: context.duration,
                            "onUpdate:modelValue": ($event: any) => ((context.duration) = $event),
                            onUpdate: ($event: any) => {_ctx.updatePoints(context); _ctx.onChange(context);},
                            onFocus: ($event: any) => (context.editingDuration = true),
                            onBlur: ($event: any) => {context.editingDuration = false; _ctx.onChange(context);}
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onUpdate", "onFocus", "onBlur"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      (context.probablyRecord && !context.editingDuration)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_17))
                        : _createCommentVNode("", true),
                      (context.pointsEstimate)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                            _createTextVNode(_toDisplayString(context.pointsEstimate) + " ", 1),
                            _cache[3] || (_cache[3] = _createElementVNode("small", null, "points", -1))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    (context.probablyRecord && !context.editingDuration && context.probablyMistake)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, "This time is dramatically faster than world record, are you sure this is correct?"))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_component_router_link, {
                    class: "level-item subtitle mb-0 mr-4 is-6",
                    to: {name: 'Game Calculators', params: _ctx.$route.params}
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Time Calculator")
                    ])),
                    _: 1
                  }, 8, ["to"]),
                  _withDirectives((_openBlock(), _createElementBlock("button", {
                    type: "button",
                    class: "level-item button is-primary",
                    disabled: !_ctx.base.liveSubmissionCount
                  }, [
                    _createTextVNode("Submit " + _toDisplayString(_ctx.base.liveSubmissionCount) + " Run(s)", 1)
                  ], 8, _hoisted_22)), [
                    [_directive_on_async, _ctx.submit]
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("section", _hoisted_24, [
        _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "title has-subtitle is-4" }, "Run Info", -1)),
        _createVNode(_component_router_link, {
          class: "subtitle is-6",
          to: {name: 'Detailed Submit', params: _ctx.$route.params}
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("(use detailed submission form instead)")
          ])),
          _: 1
        }, 8, ["to"]),
        (_ctx.globalContext)
          ? (_openBlock(), _createBlock(_component_submit_details, {
              key: 0,
              context: _ctx.globalContext,
              career: _ctx.base.career,
              recordData: _ctx.base.recordData,
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.anyChange()))
            }, null, 8, ["context", "career", "recordData"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}