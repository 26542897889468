<template>
    <div v-if="!userRegistered">
        <p class="has-text-danger mb-4" v-if="errorMessage">{{errorMessage}}</p>
        <div class="field">
            <label class="label">Username</label>
            <div class="control">
                <input type="text" class="input" v-model="register.Username" />
            </div>
        </div>
        <div class="field">
            <label class="label">Email Address</label>
            <div class="control">
                <input type="text" class="input" v-model="register.EmailAddress" @input="emailChanged()" :class="{'is-danger': hasTouchedEmail && (!emailValid || emailMismatch)}" />
            </div>
        </div>
        <div class="field">
            <label class="label">Confirm Email Address</label>
            <div class="control">
                <input type="text" class="input" v-model="confirmEmailAddress" @input="emailChanged()" :class="{'is-danger': hasTouchedEmail && (!emailValid || emailMismatch)}" />
            </div>
        </div>
        <div class="field">
            <label class="label">Password <small class="subtitle is-7">(at least 8 characters)</small></label>
            <div class="control">
                <input type="password" class="input" v-model="register.Password" />
            </div>
        </div>
        <div class="field">
            <label class="label">Confirm Password</label>
            <div class="control">
                <input type="password" class="input" v-model="register.ConfirmPassword" v-on:keyup.enter="!invalid && submit()" />
            </div>
        </div>
        <div class="field is-grouped is-justify-content-flex-end">
            <div class="control">
                <a class="button is-primary" :disabled="invalid" v-on-async="submit">Register</a>
            </div>
        </div>
    </div>
    <div v-if="userRegistered">
        <p>Registration complete. Please check your email {{register.EmailAddress}} to verify your account to allow logging in.</p>
        <p>Be sure to check your spam folder if you don't see the email shortly</p>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { AuthService, UserRegistrationRequest } from "@/api";
import OnAsync from "@/directives/OnAsync";

@Component({
    directives: {
        OnAsync,
    },
})
export default class Register extends Vue {
    register: UserRegistrationRequest = {};
    errorMessage: string | null = null;
    hasTouchedEmail = false;
    userRegistered = false;

    emailMismatch = false;
    confirmEmailAddress = "";

    private emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    async submit() {
        if(this.invalid) {
            this.errorMessage = "All fields are required";
        }

        try {
            let resp = await AuthService.register(this.register);
            this.userRegistered = true;
        } catch(ex: any) {
            this.register = {};
            this.errorMessage = ex.body.toString();
            this.$toast.error("Registration failed, try again");
        }
    }

    emailChanged() {
        this.hasTouchedEmail = true;

        if(this.register.EmailAddress != this.confirmEmailAddress) {
            this.emailMismatch = true;
        } else {
            this.emailMismatch = false;
        }
    }

    get invalid() {
        var valid =
            this.register.Username &&
            this.register.EmailAddress &&
            this.emailValid &&
            !this.emailMismatch &&
            this.register.Password &&
            this.register.ConfirmPassword &&
            this.register.Password == this.register.ConfirmPassword;

        return valid ? undefined : true;
    }

    get emailValid() {
        return this.emailPattern.test(this.register.EmailAddress!);
    }
}
</script>