import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "profile-info" }
const _hoisted_2 = { class: "hr-tile greeble-tr" }
const _hoisted_3 = { class: "is-multiline" }
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "title is-4" }
const _hoisted_6 = { class: "muted is-size-7 pt-0" }
const _hoisted_7 = {
  key: 0,
  class: "column"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "subtitle is-6 is-inline" }
const _hoisted_10 = {
  key: 0,
  class: "trophy-container"
}
const _hoisted_11 = {
  key: 1,
  class: "trophy-container"
}
const _hoisted_12 = {
  key: 2,
  class: "trophy-container"
}
const _hoisted_13 = {
  key: 0,
  class: "trophy-container"
}
const _hoisted_14 = {
  key: 1,
  class: "trophy-container"
}
const _hoisted_15 = {
  key: 2,
  class: "trophy-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_country_flag = _resolveComponent("country-flag")!
  const _component_integration_profile = _resolveComponent("integration-profile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.profileUsername ?? _ctx.$route.params.username) + " ", 1),
            (_ctx.profile && _ctx.profile.IsBanned)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode("(Banned)")
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.profile?.CountryDesignation)
              ? (_openBlock(), _createBlock(_component_country_flag, {
                  key: 1,
                  country: _ctx.profile.CountryDesignation,
                  size: "normal",
                  title: _ctx.flagInfo
                }, null, 8, ["country", "title"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("strong", _hoisted_6, _toDisplayString(_ctx.registrationDate) + " (" + _toDisplayString(_ctx.getLastSeen()) + ")", 1)
          ])
        ]),
        (_ctx.profile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_integration_profile, {
                service: "Twitch",
                link: _ctx.linkTwitch,
                unlink: _ctx.unlinkTwitch,
                editable: false,
                hasValue: !!_ctx.profile.TwitchInfo,
                avatarUrl: _ctx.profile.TwitchInfo?.Avatar,
                profileUrl: 'https://twitch.tv/' + _ctx.profile.TwitchInfo?.Username,
                profileName: _ctx.profile.TwitchInfo?.Username
              }, null, 8, ["link", "unlink", "hasValue", "avatarUrl", "profileUrl", "profileName"]),
              _createVNode(_component_integration_profile, {
                service: "YouTube",
                link: _ctx.linkYoutube,
                unlink: _ctx.unlinkYoutube,
                editable: false,
                hasValue: !!_ctx.profile.Youtube,
                avatarUrl: _ctx.profile.Youtube?.Avatar,
                profileUrl: _ctx.profile.Youtube?.ChannelUrl,
                profileName: _ctx.profile.Youtube?.ChannelTitle
              }, null, 8, ["link", "unlink", "hasValue", "avatarUrl", "profileUrl", "profileName"]),
              _createVNode(_component_integration_profile, {
                service: "Discord",
                link: _ctx.linkDiscord,
                unlink: _ctx.unlinkDiscord,
                editable: false,
                hasValue: !!_ctx.profile.DiscordInfo,
                avatarUrl: 'https://cdn.discordapp.com/avatars/' + _ctx.profile.DiscordInfo?.UserID + '/' + _ctx.profile.DiscordInfo?.Avatar,
                profileUrl: 'https://discordapp.com/users/' + _ctx.profile.DiscordInfo?.UserID,
                profileName: _ctx.profile.DiscordInfo?.Username + '#' + _ctx.profile.DiscordInfo?.Discriminator
              }, null, 8, ["link", "unlink", "hasValue", "avatarUrl", "profileUrl", "profileName"]),
              _createVNode(_component_integration_profile, {
                service: "Xbox",
                link: _ctx.linkXbox,
                unlink: _ctx.unlinkXbox,
                editable: false,
                hasValue: !!_ctx.profile.XboxInfo,
                avatarUrl: _ctx.profile.XboxInfo?.Avatar,
                profileUrl: 'https://account.xbox.com/en-us/profile?gamertag='+_ctx.profile.XboxInfo?.Gamertag,
                profileName: _ctx.profile.XboxInfo?.Gamertag
              }, null, 8, ["link", "unlink", "hasValue", "avatarUrl", "profileUrl", "profileName"]),
              _createVNode(_component_integration_profile, {
                service: "Steam",
                link: _ctx.linkSteam,
                unlink: _ctx.unlinkSteam,
                editable: false,
                hasValue: !!_ctx.profile.SteamInfo,
                avatarUrl: _ctx.profile.SteamInfo?.Avatar,
                profileUrl: _ctx.profile.SteamInfo?.ProfileUrl,
                profileName: _ctx.profile.SteamInfo?.PersonaName
              }, null, 8, ["link", "unlink", "hasValue", "avatarUrl", "profileUrl", "profileName"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.games && _ctx.career)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _cache[0] || (_cache[0] = _createElementVNode("h6", { class: "subtitle is-6 is-inline" }, "Main Category Points: ", -1)),
              _createTextVNode(_toDisplayString(_ctx.mainPoints), 1)
            ]),
            _createElementVNode("div", null, [
              _cache[1] || (_cache[1] = _createElementVNode("h6", { class: "subtitle is-6 is-inline" }, "Main Category Trophies: ", -1)),
              _createTextVNode(_toDisplayString(_ctx.mainTrophies), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainCats, (cat) => {
              return (_openBlock(), _createElementBlock("div", { key: cat }, [
                (_ctx.career.RankInfoByCategory[cat])
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("h6", _hoisted_9, _toDisplayString(cat) + ": ", 1),
                      _createTextVNode(_toDisplayString(_ctx.career.RankInfoByCategory[cat].Points), 1),
                      _cache[5] || (_cache[5] = _createElementVNode("small", { class: "muted" }, "pts", -1)),
                      _cache[6] || (_cache[6] = _createTextVNode("   ")),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.career.RankInfoByCategory[cat]], (info) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: info }, [
                          (info.GoldCount + info.SilverCount + info.BronzeCount)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                (info.GoldCount)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                      _cache[2] || (_cache[2] = _createElementVNode("i", { class: "trophy in-line rank-1" }, null, -1)),
                                      _createTextVNode(" × " + _toDisplayString(info.GoldCount), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                (info.SilverCount)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                      _cache[3] || (_cache[3] = _createElementVNode("i", { class: "trophy in-line rank-2" }, null, -1)),
                                      _createTextVNode(" × " + _toDisplayString(info.SilverCount), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                (info.BronzeCount)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                      _cache[4] || (_cache[4] = _createElementVNode("i", { class: "trophy in-line rank-3" }, null, -1)),
                                      _createTextVNode(" × " + _toDisplayString(info.BronzeCount), 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            _cache[10] || (_cache[10] = _createElementVNode("h6", { class: "subtitle is-6 is-inline" }, "Extras: ", -1)),
            _createTextVNode(_toDisplayString(_ctx.extraPoints), 1),
            _cache[11] || (_cache[11] = _createElementVNode("small", { class: "muted" }, "pts", -1)),
            _cache[12] || (_cache[12] = _createTextVNode("   ")),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.extraTrophies], (info) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: info }, [
                (info.GoldCount + info.SilverCount + info.BronzeCount)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (info.GoldCount)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                            _cache[7] || (_cache[7] = _createElementVNode("i", { class: "trophy in-line rank-1" }, null, -1)),
                            _createTextVNode(" × " + _toDisplayString(info.GoldCount), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (info.SilverCount)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                            _cache[8] || (_cache[8] = _createElementVNode("i", { class: "trophy in-line rank-2" }, null, -1)),
                            _createTextVNode(" × " + _toDisplayString(info.SilverCount), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (info.BronzeCount)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                            _cache[9] || (_cache[9] = _createElementVNode("i", { class: "trophy in-line rank-3" }, null, -1)),
                            _createTextVNode(" × " + _toDisplayString(info.BronzeCount), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}