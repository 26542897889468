import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "home hr-tile" }
const _hoisted_2 = {
  key: 0,
  class: "home hr-tile"
}
const _hoisted_3 = { class: "submission-entries" }
const _hoisted_4 = {
  key: 0,
  class: "submission-entry"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 1,
  class: "timecode"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "button is-danger" }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_profile_tabs = _resolveComponent("user-profile-tabs")!
  const _directive_on_async = _resolveDirective("on-async")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_user_profile_tabs, { "is-own-profile": _ctx.isOwnProfile }, null, 8, ["is-own-profile"])
    ]),
    (_ctx.recentSubmissions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "title is-4" }, "Your Recent Submissions", -1)),
          _createElementVNode("div", _hoisted_3, [
            (!_ctx.recentSubmissions || _ctx.recentSubmissions.length == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, "No recent submissions!"))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recentSubmissions, (sub) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sub.Id,
                class: "submission-entry"
              }, [
                _createElementVNode("p", null, _toDisplayString(sub.Description) + " - " + _toDisplayString(sub.SubDescription), 1),
                _createElementVNode("p", null, [
                  (sub.Evidence)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        target: "_blank",
                        rel: "noopener noreferrer",
                        href: sub.Evidence,
                        class: "timecode"
                      }, _toDisplayString(_ctx.$filters.durationDisplay(sub.Duration)), 9, _hoisted_5))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$filters.durationDisplay(sub.Duration)), 1)),
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "muted" }, " submitted ", -1)),
                  _createTextVNode(" " + _toDisplayString(sub.SubmittedAt), 1)
                ]),
                _createElementVNode("p", null, [
                  _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Status:", -1)),
                  _createTextVNode(" " + _toDisplayString(sub.Status), 1)
                ]),
                (sub.Status == 'Pending')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                      _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_8, _cache[2] || (_cache[2] = [
                        _createTextVNode("Retract")
                      ]))), [
                        [_directive_on_async, () => _ctx.retractSubmission(sub)]
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (sub.Reason)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                      _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Reason:", -1)),
                      _createTextVNode(" " + _toDisplayString(sub.Reason), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}