<template>
    <section class="hr-tile">
        <ul class="breadcrumbs">
            <li class="breadcrumb-item">
                <router-link :to="'/'">Home</router-link>
            </li>
            <li  v-if="$route.matched.length == 2" class="breadcrumb-item">
                <span>User Profile</span>
            </li>
            <template v-if="$route.matched.length > 2">
                <li v-for="(match, i) in $route.matched" :key="match.path" class="breadcrumb-item">
                    <router-link :to="{name: match.name, params: $route.params}" v-if="i < $route.matched.length - 1">
                        {{match.name}}
                    </router-link>
                    <span v-if="i == $route.matched.length - 1">{{match.name}}</span>
                </li>
            </template>
        </ul>
    </section>
    <section class="columns">
        <div class="column is-one-quarter">
            <ProfileInfo
                    :profileUsername="user?.Username"
                    :selectedProfile="user"
                    :career="career"
                    :ownProfile="isOwnProfile"
                />
                <div class="home hr-tile" v-if="user?.Badges?.length">
                    <h3>Badges</h3>
                    <div class="user-badge-display">
                        <template v-for="badge in user?.Badges" :key="badge.Id">
                            <user-badge-display :badge="badge"></user-badge-display>
                        </template>
                    </div>
                    
                </div>
                <div class="home hr-tile" v-if="false">
                    <h3>Achievements</h3>
                </div>
        </div>

        <div class="column is-three-quarters">
            <router-view :career="career" :user="user" :isOwnProfile="isOwnProfile" :key="$route.fullPath"></router-view>
        </div>
    </section>
    
    

</template>

<script lang="ts">
import { Game, ProfilesService, StaticContentService, UserCareer, UserProfile } from "@/api";
import ProfileInfo from "@/components/User/ProfileInfo.vue";
import UserBadgeDisplay from "@/components/User/UserBadgeDisplay.vue";
import AuthStore from "@/store/auth";
import { GameStore } from "@/store/games";
import { Component, Vue } from "vue-facing-decorator";
import { Watch } from "vue-facing-decorator";

@Component({
    components: {ProfileInfo, UserBadgeDisplay}
})
export default class Profile extends Vue {
    public username = "";
    public user: UserProfile | null | undefined = null;
    public isOwnProfile: boolean | undefined = false;
    public career: UserCareer | null = null;
    public games: Game[] = new Array();

    async created() {
        this.games = (await GameStore.get()).games;
        await this.load();
    }

    @Watch("$route.params.username")
    async onRouteChange() {
        await this.load();
    }

    async load() {
        this.isOwnProfile = false;
        this.username = this.$route?.params?.username?.toString();

        if(!this.username) {
            this.user = null;
            return;
        }

        try {
            this.user = await StaticContentService.userProfile(this.username);
        } catch {
            await AuthStore.pendingAuth;
            this.user = await ProfilesService.getUserProfile(this.username);
        }

        if (this.user?.UserId == null) return;
        try {
            this.career = await StaticContentService.userCareer(this.user.UserId);
        } catch (e: any) {
            this.$toast.error("Unable to load user career: " + e.toString());
        }

        try { 
            await AuthStore.pendingAuth; 
            this.isOwnProfile = this.username == this.$store.state?.auth?.username;
        } catch {}
    }
}
</script>

<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;
.submission-entry {
    border-bottom: 1px solid $border-color;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.right-col {
    max-height: 600px;
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 5px;
        height: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #3b3f52;
        outline: 1px solid #3b3f52;
    }
}

.user-badge-display {
    display: flex;
    margin-bottom: 15px;
    gap: 15px;
    flex-wrap: wrap;
}
</style>