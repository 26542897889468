<template>
<div class="edit-run">
    <div class="hr-tile">
        <ul class="breadcrumbs">
            <li class="breadcrumb-item">
                <router-link :to="'/'">Home</router-link>
            </li>
            <li class="breadcrumb-item">
                <span>Edit Run</span>
            </li>
        </ul>
    </div>
    <div class="hr-tile" v-if="run == null">
        <h1 class="title has-text-centered">{{error || 'Loading...'}}</h1>
    </div>

    <div class="hr-tile" v-if="run != null">
        <h1 class="title">Edit Run</h1>

        <p class="has-text-centered">
            Editing your time of {{$filters.durationDisplay(run.Duration)}} for <router-link :to="{name: 'Leaderboards', params: {game: game.UrlStub, category: category.UrlStub, level: segment.UrlStub, difficulty: difficulty.Name}}">{{runDescription}}</router-link> originally submitted on {{formatDate(run.SubmittedAt)}}
        </p>
        <p class="has-text-centered">
            Do you have a faster time than this one? <router-link :to="{name: 'Simple Submit', params: {game: game.UrlStub, category: category.UrlStub, difficulty: difficulty.Name}}">Submit a new time for {{runDescription}}</router-link>
        </p>
        
        <div class="edit-run-input">
            <run-input v-if="runContext" :career="career" :context="runContext" :recordData="recordData" :adminEdit="hasGameEditClaim"></run-input>
        </div>
        <div class="has-text-right">
            <button type="button" class="button is-text" v-on-async="deleteRun">Delete Run</button>
            <button type="button" class="button is-primary" v-on-async="save">Save Changes</button>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Category, Claim, Difficulty, DifficultyDto, Game, RecordBoardEntry, Run, RunnableSegment, RunParticipantInfo, RunsService, StaticContentService } from "@/api";
import { GameStore } from "@/store/games";
import { Component, Vue } from "vue-facing-decorator";
import RunInput from "@/components/Input/RunInput.vue";
import TimeService from "@/services/TimeService";
import OnAsync from "@/directives/OnAsync";
import { useToast } from "vue-toastification";
import RunInputService, { RunInputContext, RunInputContextPartner } from "@/services/RunInputService";
import AuthStore from "@/store/auth";

@Component({
    components:{
        RunInput
    },
    directives: {
        OnAsync
    }
})
export default class EditRun extends Vue {
    run: Run | null = null;
    error: string | null = null;
    games: Game[] = new Array();
    runContext: RunInputContext | null = null;
    recordData: any;

    game: Game | null | undefined = null;
    category: Category | null | undefined = null;
    segment: RunnableSegment | null | undefined = null;
    difficulty: Difficulty | null | undefined = null;
    career: any = null;

    toast = useToast();
    submittingParticipant: RunParticipantInfo | undefined | null = null;

    created() { this.load() };
    activated() { this.load() };

    async load() {
        let gamesPromise = GameStore.get();
        let runId = this.$route.params['runId'];
        let runPartitionKey = this.$route.params['partitionKey'];
        if(typeof runId !== 'string' || typeof runPartitionKey !== 'string') {
            this.error = "Unable to load - Invalid run ID provided";
            return;
        }

        let gamestore;
        [gamestore, this.run] = await Promise.all([gamesPromise, RunsService.getRun(runId, runPartitionKey)]);

        this.games = gamestore.games;
        this.game = this.games.find(g => g.Id == this.run?.GameId) ?? null;

        if(this.game != null)
        {
            this.category = this.game.Categories?.find(c => c.Id == this.run?.CategoryId)
            this.segment = this.game.RunnableSegments?.find(c => c.Id == this.run?.RunnableSegmentId)
            this.difficulty = this.game.Difficulties?.find(c => c.Id == this.run?.DifficultyId)
        }

        await this.fetchRecords();

        if(this.segment == null)
        {
            this.runContext = null;
            return;
        }

        this.submittingParticipant = this.run.Participants?.find(p => p.UserId == this.run?.SubmittedBy);

        this.runContext = {
            gameId: this.run.GameId,
            categoryId: this.run.CategoryId,
            runnableSegmentId: this.run.RunnableSegmentId,
            difficulty: this.run.DifficultyId,
            runnableSegmentName: this.segment.Name,
            isIl: !this.segment.Featured,
            duration: TimeService.stringToSeconds(this.run.Duration),
            pb: this.run, // BUG this isn't exactly correct if you're editing a non-pb
            evidenceLinks: this.run.Participants?.sort((a,b) => a.UserId == this.run?.SubmittedBy ? -1 : 0).map(p => p.EvidenceLink),
            allowedPartners: this.category?.AllowedPartners,
            requiredPartners: this.category?.RequiredPartners,
            filterValues: this.run.FilterValues,
            partners: this.run.Participants?.filter(p => p.UserId != this.run?.SubmittedBy).map(p => {
                return {
                    id: p.UserId,
                    username: p.Username ?? "Unknown User",
                } as RunInputContextPartner;
            }),
            occurredAt: this.run.OccurredAt
        } as RunInputContext;
    }

    public async save() {
        try {
            await RunsService.updateRun(this.getUpdatedRun());
        } catch(e: any) {
            this.toast.error("Unable to save run: " + e.toString() + "\r\n" + e.body);
            return;
        }

        this.toast.success("Saved run!", { timeout: 1500, closeButton: false, hideProgressBar: false });

        setTimeout(() => {
            this.$router.back();
        }, 1500)
    }
    
    public async deleteRun() {
        let result = await this.$confirm({ 
            message: "Are you sure you want to delete this run? This is irreversable and permanently removes this run from all leaderboards", 
            cancelAlias: "Nevermind, keep this run in place",
            confirmAlias: "I understand, delete this run"
        });

        if(!result) return;

        try {
            await RunsService.deleteRun(this.getUpdatedRun());
        } catch(e: any) {
            this.toast.error("Unable to delete the run: " + e.toString());
            return;
        }

        this.toast.success("Deleted the run!", { timeout: 1500, closeButton: false, hideProgressBar: false });

        setTimeout(() => {
            this.$router.back();
        }, 1500)
    }

    private getUpdatedRun(): Run {
        if(!this.runContext?.duration) {
            throw "Run duration is required";
        }

        let updatedRun = Object.assign({}, this.run);
        updatedRun.Duration = this.runContext?.duration;
        updatedRun.FilterValues = this.runContext.filterValues;

        const participants = this.runContext.partners.map((p, i) => { return <RunParticipantInfo>{ UserId: p.id, Username: p.username, EvidenceLink: this.runContext!.evidenceLinks[i+1]};});
        if(this.submittingParticipant) {
            this.submittingParticipant.EvidenceLink = this.runContext.evidenceLinks[0];
            participants.unshift(this.submittingParticipant);
        } else {
            participants.unshift({UserId: this.run?.SubmittedBy!, Username: this.run?.SubmittedBy!, EvidenceLink: this.runContext.evidenceLinks[0]});
        }
        updatedRun.Participants = participants;

        if(this.hasGameEditClaim) {
            updatedRun.OccurredAt = this.runContext?.occurredAt ?? this.run?.OccurredAt;
        }

        return updatedRun;
    }

    get runDescription() {
        if(this.run == null) return "Unknown run";

        return `${this.game?.Name} ${this.segment?.Name ?? 'of an unknown segment'} ${this.difficulty?.Name} - ${this.category?.Name ?? 'in an unknown category'}`;
    }

    async fetchRecords() {
        if(this.game == null || this.category == null || this.run == null) {
            this.recordData = null;
            return;
        }

        this.recordData = null;
        this.career = null;
        const userId = this.$store.state.auth.claims.userId;
        let self = (this.run?.Participants?.filter(p => p.UserId == userId).length ?? 0) > 0;

        let resp = await Promise.all([
            StaticContentService.recordBoard(this.game.Id!, this.category.Id!).catch(r => null),
            StaticContentService.userCareer(self ? userId : this.run.SubmittedBy).catch(r => null)]);

        let recordBoard = resp[0];
        let career = resp[1];

        var initial = {} as {[runnableSegmentId: string]: { [difficulty: string]: RecordBoardEntry[] }};
        this.recordData = recordBoard?.Entries!.reduce ((a: any, c) => { a[c.RunnableSegmentId!] = c.RecordsByDifficulty; return a; }, initial);
        this.career = career;
    }

    public formatDate(date: string) {
        return TimeService.iso8601ToShortFormat(date);
    }

    get hasGameEditClaim() {
        return AuthStore.hasClaim(Claim.EDIT_GAMES_GLOBAL) || AuthStore.hasClaim(Claim.EDIT_GAMES_LIMITED);
    }
}

</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;
.edit-run-input {
    @include hr-tile-full-bleed;
    padding: 12px 25px 10px 25px;
    margin-top: 35px;
    margin-bottom: 15px;
    border-bottom: 2px solid $border-color;
    border-top: 2px solid $border-color;
}

</style>