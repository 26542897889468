import { BadgeDto, BadgesService } from "@/api";

export class BadgeImageService
{
    private static instance: Promise<BadgeImageService>;

    private badges:  BadgeDto[] | null = null;

    private constructor() {}
    static get() : Promise<BadgeImageService> {
        if(!BadgeImageService.instance) {
            BadgeImageService.instance = BadgeImageService.create();
        }

        return BadgeImageService.instance;
    }

    static refresh() : Promise<BadgeImageService> {
        BadgeImageService.instance = BadgeImageService.create();
        return BadgeImageService.instance;
    }

    private static async create(): Promise<BadgeImageService> {
        let bis = new BadgeImageService()
        await bis.load();
        return bis;
    }

    private async load() {
        try {
            this.badges = await BadgesService.getBadges();
        } catch {
            console.error("Failed to load badge data");
        }
    }

    public static async getBadge(badgeId: string) : Promise<BadgeDto | undefined> {
        let instance = await BadgeImageService.get();

        return instance.badges?.find(b => b.Id == badgeId);
    }
}