<template>
    <div class="columns">
        <div class="column is-two-thirds">
            <div class="hr-tile">
                <ul class="breadcrumbs">
                    <li class="breadcrumb-item">
                        <router-link :to="'/'">Home</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <span>Extras</span>
                    </li>
                </ul>
            </div>
            <section class="hr-tile greeble-tr featured-records">
                <div class="filters">
                    <h4 class="title is-4">{{recordBoardTitle}}</h4>
                    <div class="select">
                        <select v-model="gameId">
                            <option v-for="game in games" :key="game.Id" :value="game.Id">
                                {{ game.Name }}
                            </option>
                        </select>
                    </div>
                </div>
                <ExtensionRecordView :gameId="gameId"></ExtensionRecordView>
            </section>
        </div>
        <div class="column is-one-third">
            <section class="hr-tile">
                <h4 class="title is-5">Latest Extra Records</h4>
                <RunFeed v-if="recordsFeed" :feed="recordsFeed.ExtraEntries"></RunFeed>
            </section>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import RunFeed from "@/components/Feeds/RunFeed.vue";
import StreamFeed from "@/components/Feeds/StreamFeed.vue";
import { RecordBoardKeySelectorConsts } from "@/components/Boards/RecordBoardKeySelectorConsts";
import { GameStore } from "@/store/games";
import { Game, LatestRecordsFeed, RecordBoardKey, StaticContentService } from "@/api";
import ExtensionRecordView from "@/components/Boards/ExtensionRecordView.vue";

@Component({
    components: {
        ExtensionRecordView,
        RunFeed,
        StreamFeed,
    },
})
export default class Extensions extends Vue {
    
    recordBoardKey: RecordBoardKey = { GameId: RecordBoardKeySelectorConsts.FeaturedKey, CategoryId: "Solo" };
    games: Game[] = new Array();
    gameId: string | undefined | null = null;
    recordsFeed: LatestRecordsFeed | null = null;

    async created() {
        this.games = (await GameStore.get()).games.filter(g => !g.Hidden);
        this.gameId = this.games[0].Id;

        let globals = await this.$store.state.globals;

        if(!globals.RecordsFeed) {
            this.recordsFeed = await StaticContentService.latestRecordsFeed();
        } else {
            this.recordsFeed = globals.RecordsFeed;
        }
    }

    get recordBoardTitle() {
        var filtered = this.games.filter(g => g.Id == this.gameId);
        if(filtered.length == 1) {
            return `${filtered[0].Name} Extra Category Records`
        } else {
            return "Game Records"
        }
    }
}
</script>

<style scoped lang="scss">
.input.search {
    border: none;
    border-bottom: 2px solid #1f2a3c;
    background: transparent;
}

.separator {
    margin-bottom: 3rem;
}

.featured-records .filters {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 6px; // child's select has a 4px margin, so this is 6px
    flex-wrap: wrap;

    h4 {
        margin-bottom: 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 768px) {
    .featured-records .filters {
        text-align: center;
        display: block;
        margin-bottom: 0.5rem;

        h4 {
            margin-bottom: 1rem;
        }
    }
}
</style>
