<template>
  <div class="run-board">

    <table v-if="!$store.state.isMobileSmall" class="leaderboard-table">
      <thead>
        <tr>
          <th class="participants">Runner</th>
          <th>Time</th>
          <th>Date</th>
          <th>Timesave</th>
        </tr>
      </thead>
      <tbody v-if="!history">
        <tr v-for="i in 10" :key="i">
          <td colspan="999">
            <ContentLoader width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
            </ContentLoader>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="history.length == 0">
        <tr>
          <td colspan="999">
            No runs to show yet!
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <template v-for="(entry,index) in history" :key="entry.RunId">
          <tr class="run-info">
            <td class="participants">
              <span v-for="(p,i) in entry?.Participants" :key="p">
                <router-link :to="'/profiles/' + p.Username">{{ p.Username }}</router-link><span v-if="entry.Participants.length > 0 && i < entry.Participants.length-1">, </span>
              </span>
            </td>
            <td class="timecode">
              <a target="_blank" rel="noopener noreferrer" v-if="entry.Participants[0].EvidenceLink" :href="entry.Participants[0].EvidenceLink" class="timecode">{{ $filters.durationDisplay(entry.Duration) }}</a>
              <span v-else class="timecode">{{$filters.durationDisplay(entry.Duration)}}</span>
              <template v-if="entry.Participants.length > 1">
                <template v-for="partner in entry.Participants.slice(1)" :key="partner.Username">
                  <a target="_blank" rel="noopener noreferrer" v-if="partner.EvidenceLink" :href="partner.EvidenceLink" :title="partner.Username" class="partner-evidence"><i class="fas fa-video"></i></a>
                </template>
              </template>
            </td>
            <td>{{ formatDate(entry.OccurredAt) }}</td>
            <td v-if="index == 0">-</td>
            <td v-if="index > 0">{{ $filters.durationDisplay(history[index-1].Duration - entry.Duration, true) }}</td>
          </tr>
        </template>
        
      </tbody>
    </table>
    <div v-else class="run-cards">
      <template v-if="!history">
        <div class="run-card" v-for="i in 10" :key="i">
          <div class="run-info">
            <ContentLoader class="headline" width="170" height="18" primary-color="#1f2a3c" secondary-color="#2c3b55">
                <rect x="0" y="5" rx="2" ry="2" :width="(150 + Math.random() * 20)" height="10" />
            </ContentLoader>
          </div>
        </div>
      </template>
      <template v-else-if="history.length == 0">
        <div class="run-card">
          <div class="run-info">
            <div class="headline">
                No runs to show yet!
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="run-card" v-for="(entry,index)  in history" :key="entry.RunId">
            <div class="run-info">
                <div class="headline">
                    <div class="ranks">
                    </div>
                    <div>
                        <div  v-for="(p,j) in entry?.Participants" :key="p.Username">
                            <router-link :to="'/profiles/' + p.Username">{{p.Username}}</router-link><span v-if="j != (entry.Participants.length - 1)">, </span>
                        </div>
                        <div v-if="index > 0">{{ $filters.durationDisplay(history[index-1].Duration - entry.Duration, true) }} <span class="subtitle is-6">of timesave</span></div>
                    </div>
                </div>
                <div class="time-info">
                    <a v-if="entry.Participants[0].EvidenceLink" :href="entry.Participants[0].EvidenceLink" target="_blank" rel="noopener noreferrer" class="timecode">{{ $filters.durationDisplay(entry.Duration) }}</a>
                    <span v-else class="timecode">{{$filters.durationDisplay(entry.Duration)}}</span>
                    <template v-if="entry.Participants.length > 1">
                      <template v-for="partner in entry.Participants.slice(1)" :key="partner.Username">
                        <a v-if="partner.EvidenceLink" :href="partner.EvidenceLink" :title="partner.Username" target="_blank" rel="noopener noreferrer" class="partner-evidence"><i class="fas fa-video"></i></a>
                      </template>
                    </template>
                    <div class="timestamp subtitle is-6">
                      {{formatDate(entry.OccurredAt)}}
                  </div>
                </div>
            </div>
            <div class="run-info">
                <div>
                </div>
            </div>
        </div>
      </template>
      
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import TimeService from "@/services/TimeService";
import { LeaderboardEntry, LeaderboardHistory, Run } from "@/api";
import { ContentLoader } from "vue-content-loader";

@Component({
  components: {
    ContentLoader
  },
})
export default class RecordHistoryBoard extends Vue {
  @Prop()
  history: LeaderboardHistory | null = null;

  getHostname(url: string) {
    var rawHostname = new URL(url).hostname;

    return rawHostname.split(".").slice(-2).join(".");
  }

  formatDate(date: string): string {
    return TimeService.iso8601ToShortFormat(date);
  }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.partner-evidence {
  margin-left: 5px;
  border: 1px solid $primary;
  border-radius: 50%;
  padding: 4px;
  font-size: 10px;
}

.leaderboard-table {
  @include hr-tile-full-bleed;

  .participants {
    text-align: left;
    padding-left: 25px;
  }

  .links {
    text-align: right;
    padding-right: 25px;
  }

  .run-info {
    td {
      padding-bottom: 7px;
    }
  }
}

.run-cards {
    @include hr-tile-full-bleed;

    border-top: 2px solid $border-color;
    border-bottom: 2px solid $border-color;

    .run-card {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 7px;
        padding-bottom: 7px;
        
        &:not(:last-child) {
            border-bottom: 1px solid $border-color;
        }

        .ranks {
          text-align: left;
          .trophy {
            margin-top: 4px;
            margin-right: 15px;
          }
        }

        .run-info {
            display: flex;
            justify-content: space-between;

            .headline {
                display: flex;
                margin-bottom: 0.35rem;
            }
        }

        .time-info {
          text-align: right;
        }

        .timestamp {
            text-align: end;
            white-space: nowrap;
        }
    }
}
</style>
