<template>
    <div v-if="resetInitiated">
        An email with instructions to reset your password was sent to the email address for the account
    </div>
    <div v-if="!resetInitiated">
        <div class="field">
            <label class="label">Username</label>
            <div class="control">
                <input type="text" class="input" v-model="resetRequest.Username" />
            </div>
        </div>
        <div class="field is-grouped is-justify-content-flex-end">
            <div class="control">
                <a class="button is-primary" v-on-async:click="submit">Send Reset Email</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { AuthService, ForgotPasswordRequest } from "@/api";
import OnAsync from '@/directives/OnAsync';

@Component({
    directives: {
        OnAsync
    }
})
export default class ForgotPassword extends Vue {
    resetRequest: ForgotPasswordRequest = {};
    errorMessage: string | null = null;
    resetInitiated: boolean = false;

    async submit() {
        try
        {
            let resp = await AuthService.forgotPassword(this.resetRequest);
            this.resetInitiated = true;
        }
        catch
        {
            this.resetInitiated = false;
            this.resetRequest = {};
        }
    }
}
</script>