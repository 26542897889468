<template>
<span class="country-designation-selector">
    <country-flag :country="chosen.alpha3 ?? 'null'" size="normal" />
    <template v-if="editing"> 
        <span class="select">
            <select v-model="chosen" :disabled="saving">
                <option v-for="option in options" :key="option.numeric" :value="option" >
                    {{option.country}}
                </option>
            </select>
        </span>
    </template>
    <i class="clickable fas" :class="{'fa-pencil-alt':editable, 'fa-save': savable, 'fa-spinner': saving, 'fa-check': saved}"  @click="transition" @keydown.enter="transition" @keydown.space="transition" title="Choose country flag" role="button" tabindex="0"></i>
</span>

</template>
<script lang="ts" scoped>
import CountryData, { Country } from "./../../assets/countries";
import { Component, Vue } from "vue-facing-decorator";
import { Prop, Watch } from "vue-facing-decorator";
import CountryFlag from "vue-country-flag-next";
import { AccountsService, AccountStatus, IntegrationsService, ProfilesService, UserCareer, UserProfile } from "@/api";


@Component({
    components: {
        CountryFlag
    }
})
export default class CountryDesignationSelector extends Vue {
    editing = false;
    editable = true;
    savable = false;
    saving = false;
    saved = false;

    options: (Country | null)[] = new Array();
    nullOption = { country: "None", alpha2: null!, alpha3: null!, numeric: null! };
    chosen: Country = this.nullOption;


    @Prop()
    public modelValue: UserProfile | null = null;

    async mounted() {
        this.options = CountryData.countries.map(c => c);

        this.options.unshift(this.nullOption);

        this.init();
    }

    @Watch("modelValue")
    onModelValueChange(newVal: any, oldVal: any) {
        if(oldVal == newVal) return false;

        this.init();
    }

    init() {
        if(this.modelValue != null) {
            this.chosen = this.options.find(o => o?.alpha3 == this.modelValue?.CountryDesignation) || this.nullOption;
        } else {
            this.chosen = this.nullOption;
        }
    }

    public async transition() {
        if(this.editable) {
            this.editable = false;
            this.editing = true;
            this.savable = true;
        }
        else if (this.savable) {
            this.savable = false;
            this.saving = true;
            await this.save();
            this.saving = false;
            this.editing = false;
            this.saved = true;

            setTimeout(() => {
                this.saved = false;
                this.editable = true;
            }, 1000);
        }
    }

    public async save() {
        this.modelValue!.CountryDesignation = this.chosen.alpha3;
        await ProfilesService.setCountryDesignation(this.modelValue?.UserId!, this.modelValue!);
    }
}

</script>
<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.country-designation-selector {
    font-size: 1.1rem;

    .flag {
        top: 2px;
    }

    i.clickable {
        position: relative;
        margin-left: -7px;
        margin-top: -3px;
        font-size: 14px;
        background-color: $primary;
        border-radius: 50%;
        padding: 5px 4px 5px 6px;
        z-index: 3;
        border: 2px solid $body-background-color;

        &.fa-pencil-alt, &.fa-check {
            vertical-align: top;
        }

        &.fa-save, &.fa-pencil-alt {
            cursor: pointer;

            &:hover, &:active {
                color: darken($primary, 35%);
            }
        }
    }
}

</style>