import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "submit" }
const _hoisted_2 = { class: "columns" }
const _hoisted_3 = { class: "column" }
const _hoisted_4 = { class: "hr-tile" }
const _hoisted_5 = { class: "breadcrumbs" }
const _hoisted_6 = { class: "breadcrumb-item" }
const _hoisted_7 = {
  key: 0,
  class: "hr-tile rules"
}
const _hoisted_8 = { class: "collapsible-title" }
const _hoisted_9 = { class: "title is-4" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "hr-tile greeble-tr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_submit_selector = _resolveComponent("submit-selector")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_submit_form_full = _resolveComponent("submit-form-full")!
  const _directive_collapsible = _resolveDirective("collapsible")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("section", _hoisted_4, [
          _createElementVNode("ul", _hoisted_5, [
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, { to: '/' }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Home")
                ])),
                _: 1
              })
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("li", { class: "breadcrumb-item" }, [
              _createElementVNode("span", null, "Submit Run")
            ], -1)),
            (_ctx.selection)
              ? (_openBlock(), _createBlock(_component_submit_selector, {
                  key: 0,
                  "model-value": _ctx.selection,
                  "onUpdate:modelValue": _ctx.updateSubmitSelection
                }, null, 8, ["model-value", "onUpdate:modelValue"]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.selection?.game)
          ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _withDirectives((_openBlock(), _createElementBlock("h4", _hoisted_9, [
                  _createTextVNode(_toDisplayString(_ctx.selection?.game?.Name) + " Rules", 1)
                ])), [
                  [_directive_collapsible, void 0, "parent"]
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "subtitle is-6 mb-0" }, "(click to expand)", -1))
              ]),
              _createElementVNode("div", null, [
                (_ctx.selection?.game?.Rules)
                  ? (_openBlock(), _createBlock(_component_Markdown, {
                      key: 0,
                      source: _ctx.selection?.game?.Rules,
                      class: "rule-section",
                      breaks: true
                    }, null, 8, ["source"]))
                  : _createCommentVNode("", true),
                (_ctx.selection?.category?.Rules)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("h1", null, _toDisplayString(_ctx.selection?.category?.Name) + " Rules", 1),
                      _createVNode(_component_Markdown, {
                        source: _ctx.selection?.category?.Rules,
                        class: "rule-section",
                        breaks: true
                      }, null, 8, ["source"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("section", _hoisted_11, [
          _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "title has-subtitle is-4" }, "Times", -1)),
          _createVNode(_component_router_link, {
            class: "subtitle is-6",
            to: {name: 'Simple Submit', params: _ctx.$route.params}
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("(back to simple submission)")
            ])),
            _: 1
          }, 8, ["to"]),
          (_ctx.effectiveSelection?.game && _ctx.effectiveSelection?.category && _ctx.effectiveSelection?.difficulty)
            ? (_openBlock(), _createBlock(_component_submit_form_full, {
                key: 0,
                modelValue: _ctx.submission,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.submission) = $event)),
                game: _ctx.effectiveSelection?.game,
                category: _ctx.effectiveSelection?.category,
                difficulty: _ctx.effectiveSelection?.difficulty
              }, null, 8, ["modelValue", "game", "category", "difficulty"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}