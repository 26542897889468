import TimeService from "@/services/TimeService"

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $filters: Record<string, any>
    }
}

export class FiltersPlugin {
    static install(app: any, options: any) {
        app.config.globalProperties.$filters = {
            durationDisplay(value: any, minimal = true) {
                if(typeof value === "number") {
                    return TimeService.secondsToString(value, minimal)
                } else {
                    console.error("Non number passed to duration display");
                    return value.toString();
                }
            }
        }
    }
}