import { Directive, VNode } from "vue";

const Collapsible: Directive<HTMLElement & VNode, string> = {
    mounted(el, binding) {
        let subject = el as HTMLElement;
        if(binding.arg == "parent") {
            subject = el.parentElement!;
        }

        let target = subject.nextElementSibling! as HTMLElement;
        if(!target) {
            return;
        }

        target.style.height = "0";
        target.classList.add("collapsible-content");


        el.classList.add("collapsible-trigger");
        subject.addEventListener("click", e =>
        {
            if(target.clientHeight) {
                el.classList.remove("collapsible-open");
                el.classList.add("collapsible-closed");
                subject.classList.remove("collapsible-open");
                subject.classList.add("collapsible-closed");
                target.style.height = "0";
            } else {
                el.classList.add("collapsible-open");
                el.classList.remove("collapsible-closed");
                subject.classList.add("collapsible-open");
                subject.classList.remove("collapsible-closed");
                target.style.height = target.scrollHeight + "px";
            }
        });
    }
};

export default Collapsible;