/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserAccount } from '../models/UserAccount';
import type { UserProfile } from '../models/UserProfile';
import { request as __request } from '../core/request';

export class ProfilesService {

    /**
     * @param userId 
     * @returns UserProfile OK
     * @throws ApiError
     */
    public static async getUserProfile(
userId: string,
): Promise<UserProfile> {
        const result = await __request({
            method: 'GET',
            path: `/api/Profiles/${userId}`,
        });
        return result.body;
    }

    /**
     * @param query 
     * @returns UserProfile OK
     * @throws ApiError
     */
    public static async findUsers(
query?: string,
): Promise<Array<UserProfile>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Profiles/search`,
            query: {
                'query': query,
            },
        });
        return result.body;
    }

    /**
     * @param userId 
     * @returns any OK
     * @throws ApiError
     */
    public static async toggleStreamVisibility(
userId: string,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Profiles/${userId}/toggleStreamVisibility`,
        });
        return result.body;
    }

    /**
     * @param userId 
     * @param requestBody 
     * @returns UserAccount OK
     * @throws ApiError
     */
    public static async setCountryDesignation(
userId: string,
requestBody?: UserProfile,
): Promise<UserAccount> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Profiles/${userId}/country`,
            body: requestBody,
        });
        return result.body;
    }

}