<template>
     <div class="home hr-tile">
        <user-profile-tabs :is-own-profile="isOwnProfile"></user-profile-tabs>
        <div class="columns is-multiline" v-if="profile">
            <div class="column is-full">
                <h3 class="section-header">General Info</h3>
            </div>
            <div class="column is-offset-one-fifth">
                <div class="setting-label">Country Flag</div>
                <CountryDesignationSelector :modelValue="profile"></CountryDesignationSelector>
            </div>
            <div class="column is-full">
                <h3 class="section-header">Integrations</h3>
            </div>
            <div class="column is-offset-one-fifth">
                <integration-profile-edit service="Twitch" :link="linkTwitch" :unlink="unlinkTwitch" :editable="isOwnProfile" :hasValue="!!profile.TwitchInfo"
                    :avatarUrl="profile.TwitchInfo?.Avatar" 
                    :profileUrl="'https://twitch.tv/' + profile.TwitchInfo?.Username" 
                    :profileName="profile.TwitchInfo?.Username" />

                <div v-if="profile.TwitchInfo" class="ml-6 mb-5">
                
                    <div class="integration-option" v-if="profile.TwitchInfo">
                        <template v-if="isOwnProfile">
                            <div class="field">
                                <input
                                    id="streamVisibility"
                                    type="checkbox"
                                    name="streamVisibility"
                                    class="switch"
                                    v-model="streamIsVisible"
                                    :disabled="togglingStreamVisibility"
                                    @click="toggleStreamVisibility"
                                />
                                <label for="streamVisibility" class="label">Visible on Stream List</label>
                            </div>
                            <p class="has-text-warning" v-if="profile.ValidationStreak < validationStreakRequirement">{{validationStreakRequirement-profile.ValidationStreak}} more verified runs until eligible</p>
                        </template>
                    </div>
                </div>

                <integration-profile-edit service="Discord" :link="linkDiscord" :unlink="unlinkDiscord" :editable="isOwnProfile" :hasValue="!!profile.DiscordInfo"
                    :avatarUrl="'https://cdn.discordapp.com/avatars/' + profile.DiscordInfo?.UserID + '/' + profile.DiscordInfo?.Avatar" 
                    :profileUrl="'https://discordapp.com/users/' + profile.DiscordInfo?.UserID" 
                    :profileName="profile.DiscordInfo?.Username + '#' + profile.DiscordInfo?.Discriminator" />

                <integration-profile-edit service="Xbox" :link="linkXbox" :unlink="unlinkXbox" :editable="isOwnProfile" :hasValue="!!profile.XboxInfo"
                    :avatarUrl="profile.XboxInfo?.Avatar" 
                    :profileUrl="'https://account.xbox.com/en-us/profile?gamertag='+profile.XboxInfo?.Gamertag" 
                    :profileName="profile.XboxInfo?.Gamertag" />

                <integration-profile-edit service="Steam" :link="linkSteam" :unlink="unlinkSteam" :editable="isOwnProfile" :hasValue="!!profile.SteamInfo"
                    :avatarUrl="profile.SteamInfo?.Avatar" 
                    :profileUrl="profile.SteamInfo?.ProfileUrl" 
                    :profileName="profile.SteamInfo?.PersonaName" />

                <integration-profile-edit service="YouTube" :link="linkYoutube" :unlink="unlinkYoutube" :editable="isOwnProfile" :hasValue="!!profile.Youtube"
                    :avatarUrl="profile.Youtube?.Avatar" 
                    :profileUrl="profile.Youtube?.ChannelUrl" 
                    :profileName="profile.Youtube?.ChannelTitle" />

            </div>
            <div class="column is-full">
                <h3 class="section-header">Danger Zone</h3>
            </div>
            <div class="column is-offset-one-fifth">
                <p class="action-button">
                    <router-link :to="{name: 'Change Username'}" class="button has-background-primary" v-if="isOwnProfile">
                        Change Username
                    </router-link>
                </p>
                <p class="action-button">
                    <button class="button has-background-danger" v-on-async="exportUserData" v-if="isOwnProfile">
                        Export User Data
                    </button>
                </p>
                <p class="action-button">
                    <button class="button has-background-danger" v-on-async="deleteUserAccount" v-if="isOwnProfile">
                        Delete Account (permanently!)
                    </button>
                </p>
            </div>
        </div>
    </div>

</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import TimeAgo from "javascript-time-ago";
import { AccountsService, Category, Game, IntegrationsService, ProfilesService, UserCareer, UserProfile } from "@/api";
import Configuration from "@/config";
import AuthStore from "@/store/auth";
import OnAsync from "@/directives/OnAsync";
import FocusToggle from "@/directives/FocusToggle";
import { ContentLoader } from "vue-content-loader";
import CountryFlag from "vue-country-flag-next";
import CountryDesignationSelector from "./CountryDesignationSelector.vue";
import countries from "@/assets/countries";
import { GameStore } from "@/store/games";
import ProfileActions from "./ProfileActions.vue";
import UserProfileTabs from "./UserProfileTabs.vue";
import IntegrationProfileEdit from "./IntegrationProfileEdit.vue";

@Component({
    components: {
        ContentLoader,
        CountryFlag,
        CountryDesignationSelector,
        IntegrationProfileEdit,
        ProfileActions,
        UserProfileTabs
    },
    directives: {
        OnAsync,
        FocusToggle,
    },
})
export default class UserSettings extends Vue {
    @Prop()
    public user: UserProfile | null = null;
    @Prop()
    readonly career: UserCareer | null = null;
    @Prop()
    readonly isOwnProfile: boolean = false;
    @Prop()
    readonly profileUsername: string | null = null;

    public username: string = "UnknownUser";
    public profile: UserProfile | null = null;
    public streamIsVisible = false;
    public games: Game[] = new Array();
    public mainCats: string[] = new Array();

    // TODO get from backend via global config
    public validationStreakRequirement = 5;

    async created() {
        this.games = (await GameStore.get()).games;
        this.mainCats = [...new Set(this.games.flatMap(g => g.Categories!).filter(c => c!.IsExtension == false).map(c => c.Name!))];
    }


    mounted() {
        this.profile = this.user;
        this.streamIsVisible = this.profile?.StreamIsVisible ?? false;
    }

    @Watch("selectedProfile")
    updateProfile() {
        this.profile = this.user;
        this.streamIsVisible = this.profile?.StreamIsVisible ?? false;
    }

    getLastSeen() {
        var lastSeen = this.user?.LastSeen;
        if (lastSeen) {
            if (lastSeen > Date.now() - 300000) {
                return "Online Now";
            } else {
                const timeAgo = new TimeAgo("en-us");
                return "Last seen " + timeAgo.format(new Date(lastSeen));
            }
        }
    }

    async reloadProfile() {
        if (!this.isOwnProfile) return;

        await AuthStore.pendingAuth;

        this.profile = await ProfilesService.getUserProfile(this.$store.state.auth.claims.userId!);
        this.streamIsVisible = this.profile?.StreamIsVisible ?? false;
    }

    async rebuildCareer() {
        let rebuildEvent = {
            Event: "UserCareerUpdateEvent",
            Payload: {
                UserId: this.profile?.UserId,
            },
        };

        //await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
    }

    async rebuildProfile() {
        let rebuildEvent = {
            Event: "UserProfileUpdateEvent",
            Payload: {
                UserId: this.profile?.UserId,
            },
        };

        //await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
    }

    async linkDiscord() {
        window.location.href =
            "https://discord.com/api/oauth2/authorize?client_id=" +
            Configuration.DiscordClientID +
            "&redirect_uri=" +
            Configuration.RedirectURIBase +
            "/integrate/discord&response_type=code&scope=identify";
    }

    async unlinkDiscord() {
        await IntegrationsService.unlinkDiscord();
        await this.reloadProfile();
    }

    async linkTwitch() {
        window.location.href =
            "https://id.twitch.tv/oauth2/authorize?client_id=" +
            Configuration.TwitchClientID +
            "&redirect_uri=" +
            Configuration.RedirectURIBase +
            "/integrate/twitch&response_type=code";
    }

    async unlinkTwitch() {
        await IntegrationsService.unlinkTwitch();
        await this.reloadProfile();
    }

    async linkXbox() {
        let page = "https://login.live.com/oauth20_authorize.srf";

        this.synthesizeAndSubmitForm(
            page,
            {
                client_id: Configuration.XboxClientID,
                redirect_uri: Configuration.RedirectURIBase + "/integrate/xbox",
                scope: "Xboxlive.signin Xboxlive.offline_access",
                response_type: "code",
                approval_prompt: "auto",
            },
            "get"
        );
    }

    async unlinkXbox() {
        await IntegrationsService.unlinkXbox();
        await this.reloadProfile();
    }

    async linkSteam() {
        let page = "https://steamcommunity.com/openid/login";

        this.synthesizeAndSubmitForm(page, {
            "openid.identity": "http://specs.openid.net/auth/2.0/identifier_select",
            "openid.claimed_id": "http://specs.openid.net/auth/2.0/identifier_select",
            "openid.ns": "http://specs.openid.net/auth/2.0",
            "openid.mode": "checkid_setup",
            "openid.realm": Configuration.RedirectURIBase,
            "openid.return_to": Configuration.RedirectURIBase + "/integrate/steam",
        });
    }

    async unlinkSteam() {
        await IntegrationsService.unlinkSteam();
        await this.reloadProfile();
    }

    async linkYoutube() {
        let page = "https://accounts.google.com/o/oauth2/v2/auth";

        this.synthesizeAndSubmitForm(
            page,
            {
                client_id: Configuration.YoutubeClientID,
                redirect_uri: Configuration.RedirectURIBase + "/integrate/youtube",
                scope: "https://www.googleapis.com/auth/youtube.readonly",
                include_granted_scopes: "true",
                response_type: "token",
            },
            "get"
        );
    }

    async unlinkYoutube() {
        await IntegrationsService.unlinkYoutube();
        await this.reloadProfile();
    }

    public togglingStreamVisibility = false;
    async toggleStreamVisibility(e: MouseEvent) {
        e.preventDefault();

        if (this.togglingStreamVisibility) return;
        if (!this.profile?.UserId) return;

        this.togglingStreamVisibility = true;

        try {
            await ProfilesService.toggleStreamVisibility(this.profile.UserId);
            await this.reloadProfile();
        } finally {
            this.togglingStreamVisibility = false;
        }
    }

    synthesizeAndSubmitForm(path: string, params: any, method = "post") {
        const form = document.createElement("form");
        form.method = method;
        form.action = path;

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement("input");
                hiddenField.type = "hidden";
                hiddenField.name = key;
                hiddenField.value = params[key];

                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    }

    async exportUserData() {
        let exportRequest = {
            Password: "",
        };

        let [doit, pw]  = await this.$prompt({
            message: "Confirm your password to export your user data.",
            entryType: "password"
        });

        if(doit && pw != null)
        {
            exportRequest.Password = pw;

            try {
                this.$loading(true);
                let download = await AccountsService.exportSelfData(exportRequest);
                this.$toast.success("Data ready for download");
                let blob = await download.blob();

                setTimeout(() => 
                {
                    var url = window.URL.createObjectURL(blob);
                    const anchorElement = document.createElement('a');
                    anchorElement.href = url;
                    anchorElement.download = `haloruns-userdata-${this.$store.state.auth.claims.userId}.zip`;
                    anchorElement.target = "_blank";

                    anchorElement.click();
                    anchorElement.remove();
                }, 500);
                
            } catch(e) {
                console.log(e);
                this.$toast.error("Export request failed, contact @doubl3h3lix on discord or site admins/moderators");
            } finally {
                this.$loading(false);
            }
        }
        else
        {
            this.$toast.error("Data request cancelled");
        }
    }

    async deleteUserAccount() {
        let deleteRequest = {
            Password: "",
        };

        let [doit, pw]  = await this.$prompt({
            message: "There's no coming back from this, be sure you want to delete everything! Confirm your password to delete your account and all associated runs.",
            entryType: "password"
        });

        if(doit && pw != null)
        {
            deleteRequest.Password = pw;
            try {
                this.$loading(true);
                await AccountsService.destructivelyDeleteSelf(deleteRequest);
                this.$toast.success("Delete succeeded, logging out");
                await AuthStore.logout();
                this.$router.push("/");
            } catch {
                this.$toast.error("Delete request failed, things might be fucked, contact @doubl3h3lix on discord or site admins/moderators");
            } finally {
                this.$loading(false);
            }
        }
        else
        {
            this.$toast.error("Delete request cancelled, nothing changed");
        }
    }

    get flagInfo() {
      return `Flag of ${countries.all().find(c => c.alpha3 == this.profile?.CountryDesignation)?.country}`;
    }

    get mainPoints() {
        if(this.career?.RankInfoByCategory == null) return;

        const mainCats = new Set(this.games.flatMap(g => g.Categories).filter(c => !c?.IsExtension).map(c => c?.Name!));

        return [...mainCats].reduce((a,cat) => a + (this.career?.RankInfoByCategory![cat]?.Points || 0), 0)
    }

    get mainTrophies() {
        if(this.career?.RankInfoByCategory == null) return;

        const mainCats = new Set(this.games.flatMap(g => g.Categories).filter(c => !c?.IsExtension).map(c => c?.Name!));


        return [...mainCats].reduce((a,cat) => {
          if(!this.career?.RankInfoByCategory) return a;
          if(!this.career?.RankInfoByCategory?.hasOwnProperty(cat)) return a;

          a += this.career?.RankInfoByCategory![cat].GoldCount || 0;
          a += this.career?.RankInfoByCategory![cat].SilverCount || 0;
          a += this.career?.RankInfoByCategory![cat].BronzeCount || 0;
          return a;
        }, 0);

    }

    get extraPoints() {
        if(this.career?.RankInfoByCategory == null) return;

        const extraCats = new Set(this.games.flatMap(g => g.Categories).filter(c => c?.IsExtension).map(c => c?.Name!));

        return [...extraCats].reduce((a,cat) => a + (this.career?.RankInfoByCategory![cat]?.Points || 0), 0)
    }

    get extraTrophies() {
        if(this.career?.RankInfoByCategory == null) return;

        const extraCats = new Set(this.games.flatMap(g => g.Categories).filter(c => c?.IsExtension).map(c => c?.Name!));

        return [...extraCats].reduce((a,cat) => {
          if(!this.career?.RankInfoByCategory) return a;
          if(!this.career?.RankInfoByCategory?.hasOwnProperty(cat)) return a;

          a.GoldCount += this.career?.RankInfoByCategory![cat].GoldCount || 0;
          a.SilverCount += this.career?.RankInfoByCategory![cat].SilverCount || 0;
          a.BronzeCount += this.career?.RankInfoByCategory![cat].BronzeCount || 0;
          return a;
        }, {
          GoldCount: 0,
          SilverCount: 0,
          BronzeCount: 0
        });
    }

    get registrationDate() {
        if(this.profile?.RegistrationDate) {
            return `Registered ${new Date(this.profile.RegistrationDate).toLocaleDateString()}`;
        }

        return "";
    }
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.is-whole-flex {
    flex: 0 0 100%;
    
}

.is-end-justified-tablet {
    justify-content: flex-end;
    display: flex;

    @media screen and (max-width: 768px) {
        justify-content: flex-start;
        display: flex;
    }
}

.flag {
    font-size: 1.1rem;
}

.profile-info {
    position: relative;
}

.trophy-container {
    margin-right: 1rem;
}


.headline {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 0.5rem;

    .horizontal-titles {
        .title,
        .subtitle {
            margin-bottom: 0;
            display: inline-block;
        }
        .title {
            margin-right: 1rem;
        }
    }
}

div {
    text-align: left;
}

input[type="text"] {
    width: 250px;
}


.own-profile-actions {
    margin-left: 2rem;

    .dropdown-content {
        border-radius: 0;
    }

    button {
        border: none;
        cursor: pointer;
        background-color: $grey-darker;

        &:hover {
            background-color: $grey-light;
        }
    }
}

.section-header {
    border-bottom: 1px solid $border-color;
}

.action-button {
    margin-bottom: 15px;
}

.setting-label {
    position:relative;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-family: "Oswald", sans-serif;
    line-height:20px;
    margin-bottom:5px;
}
</style>
