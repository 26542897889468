import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "user-career" }
const _hoisted_2 = { class: "hr-tile" }
const _hoisted_3 = { class: "headline" }
const _hoisted_4 = ["width"]
const _hoisted_5 = ["width"]
const _hoisted_6 = { class: "columns is-multiline" }
const _hoisted_7 = { class: "user-career-game hr-tile" }
const _hoisted_8 = ["width"]
const _hoisted_9 = { class: "level-table" }
const _hoisted_10 = ["width"]
const _hoisted_11 = { class: "hr-tile toggle-button-parent" }
const _hoisted_12 = { class: "headline" }
const _hoisted_13 = { class: "title is-4" }
const _hoisted_14 = { class: "filters field is-horizontal" }
const _hoisted_15 = { class: "select" }
const _hoisted_16 = { class: "subtitle is-6 is-inline" }
const _hoisted_17 = { class: "subtitle is-6 is-inline" }
const _hoisted_18 = {
  key: 0,
  class: "trophy-container"
}
const _hoisted_19 = {
  key: 1,
  class: "trophy-container"
}
const _hoisted_20 = {
  key: 2,
  class: "trophy-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_profile_tabs = _resolveComponent("user-profile-tabs")!
  const _component_ContentLoader = _resolveComponent("ContentLoader")!
  const _component_user_career_game = _resolveComponent("user-career-game")!
  const _directive_masonry = _resolveDirective("masonry")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.career)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_user_profile_tabs, { "is-own-profile": _ctx.isOwnProfile }, null, 8, ["is-own-profile"]),
            _createElementVNode("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "title is-4" }, "User's Run Career", -1)),
              _createVNode(_component_ContentLoader, {
                width: "50",
                height: "45",
                "primary-color": "#1f2a3c",
                "secondary-color": "#2c3b55"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("rect", {
                    x: "0",
                    y: "5",
                    rx: "2",
                    ry: "2",
                    width: (30 + Math.random() * 20),
                    height: "35"
                  }, null, 8, _hoisted_4)
                ]),
                _: 1
              })
            ]),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (i) => {
              return _createElementVNode("div", { key: i }, [
                _createVNode(_component_ContentLoader, {
                  width: "170",
                  height: "18",
                  "primary-color": "#1f2a3c",
                  "secondary-color": "#2c3b55"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("rect", {
                      x: "0",
                      y: "5",
                      rx: "2",
                      ry: "2",
                      width: (150 + Math.random() * 20),
                      height: "10"
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 1
                })
              ])
            }), 64))
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
              return _createElementVNode("div", {
                key: i,
                class: "column is-half-desktop is-full-tablet"
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_ContentLoader, {
                    width: "170",
                    height: "30",
                    "primary-color": "#1f2a3c",
                    "secondary-color": "#2c3b55"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("rect", {
                        x: "0",
                        y: "5",
                        rx: "2",
                        ry: "2",
                        width: (150 + Math.random() * 20),
                        height: "25"
                      }, null, 8, _hoisted_8)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("table", _hoisted_9, [
                    _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "Level"),
                        _createElementVNode("th", null, "Time")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (j) => {
                        return _createElementVNode("tr", { key: j }, [
                          _createElementVNode("td", null, [
                            _createVNode(_component_ContentLoader, {
                              width: "170",
                              height: "18",
                              "primary-color": "#1f2a3c",
                              "secondary-color": "#2c3b55"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("rect", {
                                  x: "0",
                                  y: "5",
                                  rx: "2",
                                  ry: "2",
                                  width: (150 + Math.random() * 20),
                                  height: "10"
                                }, null, 8, _hoisted_10)
                              ]),
                              _: 1
                            })
                          ]),
                          _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1))
                        ])
                      }), 64))
                    ])
                  ])
                ])
              ])
            }), 64))
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_user_profile_tabs, { "is-own-profile": _ctx.isOwnProfile }, null, 8, ["is-own-profile"]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("h4", _hoisted_13, _toDisplayString(_ctx.user?.Username ?? 'User') + "'s Run Career", 1),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chosenCategory) = $event))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.catOrder, (category) => {
                      return (_openBlock(), _createElementBlock("option", { key: category }, _toDisplayString(category), 1))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, _ctx.chosenCategory]
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", null, [
              _cache[4] || (_cache[4] = _createElementVNode("h6", { class: "subtitle is-6 is-inline" }, "Total Points: ", -1)),
              _createTextVNode(_toDisplayString(_ctx.totalPoints), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.career.RankInfoByCategory, (info, cat) => {
              return (_openBlock(), _createElementBlock("div", { key: cat }, [
                _createElementVNode("h6", _hoisted_16, _toDisplayString(cat) + " Points: ", 1),
                _createTextVNode(_toDisplayString(info.Points), 1)
              ]))
            }), 128)),
            _createElementVNode("div", null, [
              _cache[5] || (_cache[5] = _createElementVNode("h6", { class: "subtitle is-6 is-inline" }, "Total Trophies: ", -1)),
              _createTextVNode(_toDisplayString(_ctx.totalTrophies), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.career.RankInfoByCategory, (info, cat) => {
              return (_openBlock(), _createElementBlock("div", { key: cat }, [
                (info.GoldCount + info.SilverCount + info.BronzeCount)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("h6", _hoisted_17, _toDisplayString(cat) + " Trophies: ", 1),
                      (info.GoldCount)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                            _cache[6] || (_cache[6] = _createElementVNode("i", { class: "trophy in-line rank-1" }, null, -1)),
                            _createTextVNode(" × " + _toDisplayString(info.GoldCount), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (info.SilverCount)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                            _cache[7] || (_cache[7] = _createElementVNode("i", { class: "trophy in-line rank-2" }, null, -1)),
                            _createTextVNode(" × " + _toDisplayString(info.SilverCount), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (info.BronzeCount)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                            _cache[8] || (_cache[8] = _createElementVNode("i", { class: "trophy in-line rank-3" }, null, -1)),
                            _createTextVNode(" × " + _toDisplayString(info.BronzeCount), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryGames, (game) => {
              return (_openBlock(), _createBlock(_component_user_career_game, {
                key: game.Id,
                runs: _ctx.career.RunsByCategory[_ctx.chosenCategory],
                categoryName: _ctx.chosenCategory,
                game: game,
                editable: _ctx.canEditRuns,
                submittedOnly: true
              }, null, 8, ["runs", "categoryName", "game", "editable"]))
            }), 128))
          ])), [
            [_directive_masonry, _ctx.$store.state.windowWidth <= 1215 ? 1 : 2, _ctx.$route]
          ])
        ], 64))
  ]))
}