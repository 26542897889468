<template>
    <div class="submit">
        <div class="columns">
            <div class="column">
                <section class="hr-tile">
                    <ul class="breadcrumbs">
                        <li class="breadcrumb-item">
                            <router-link :to="'/'">Home</router-link>
                        </li>
                        <li class="breadcrumb-item">
                            <span>Submit Run</span>
                        </li>
                        <submit-selector v-if="selection" :model-value="selection" @update:model-value="updateSubmitSelection"></submit-selector>
                    </ul>
                </section>
                <section class="hr-tile rules" v-if="selection?.game">
                    <div class="collapsible-title">
                        <h4 class="title is-4" v-collapsible:parent>{{selection?.game?.Name}} Rules</h4>
                        <h6 class="subtitle is-6 mb-0">(Click to expand)</h6>
                    </div>
                    <div>
                        <Markdown v-if="selection?.game?.Rules" :source="selection?.game?.Rules" class="rule-section" :breaks="true"></Markdown>
                        <div v-if="selection?.category?.Rules">
                            <h1>{{selection?.category?.Name}} Rules</h1>
                            <Markdown :source="selection?.category?.Rules" class="rule-section" :breaks="true"></Markdown>
                        </div>
                    </div>
                </section>
                <submit-form v-if="effectiveSelection?.game && effectiveSelection?.category && effectiveSelection?.difficulty"
                    v-model="submission" 
                    :game="effectiveSelection?.game" 
                    :category="effectiveSelection?.category" 
                    :difficulty="effectiveSelection?.difficulty" ></submit-form>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Hook, Vue } from "vue-facing-decorator";
import SubmitForm from "@/components/Submit/SubmitForm.vue";
import SubmitSelector, { SubmitSelection } from "@/components/Submit/SubmitSelector.vue";
import Markdown from 'vue3-markdown-it';
import { NavigationGuardNext, onBeforeRouteUpdate, RouteLocationNormalized, RouteParamValueRaw } from "vue-router";
import { GameStore } from "@/store/games";
import { Game } from "@/api";
import Collapsible from "@/directives/Collapsible";

@Component({
    components: {
        SubmitForm,
        SubmitSelector,
        Markdown
    },
    directives: {
        Collapsible
    },
})
export default class SubmitView extends Vue {
    selection: SubmitSelection | null = null;
    effectiveSelection: SubmitSelection | null = null;
    submission: any = null;
    games: Game[] = new Array();

    public async created() {
        this.games = (await GameStore.get()).games;

        this.loadFromParams();
        this.applySelection();

        if(this.effectiveSelection)
            this.replaceRoute(this.effectiveSelection);
    }

    public loadFromParams() {
        let gameStub = this.getParam("game");
        let catStub = this.getParam("category");
        let difficulty = this.getParam("difficulty");

        let selection = {} as SubmitSelection;

        let game = this.games.find(g => g.UrlStub == gameStub);
        if(!game) {
            game = this.games[0];
        }

        let cat = game.Categories?.find(c => c.UrlStub == catStub);
        if(!cat) {
            cat = game.Categories![0];
        }

        let catDifs = game.Difficulties!.filter(d => cat?.Difficulties?.indexOf(d?.Id!)! >= 0);
        let dif = catDifs.find(d => d.Name == difficulty);
        if(!dif) {
            dif = catDifs[0];
        }

        selection.game = game;
        selection.category = cat;
        selection.difficulty = dif;

        this.selection = selection;
    }

    public updateSubmitSelection(sel: SubmitSelection) {
        this.replaceRoute(sel);
    }

    public applySelection() {
        this.effectiveSelection = Object.assign({}, this.selection);
    }

    public revertSelection() {
        this.selection = Object.assign({}, this.effectiveSelection);
    }

    public replaceRoute(sel: SubmitSelection) {
        if(!sel) return;

        this.$router.replace({name: this.$route.matched[0].name, params: {
            game: sel.game!.UrlStub as RouteParamValueRaw,
            category: sel.category!.UrlStub as RouteParamValueRaw,
            difficulty: sel.difficulty!.Name as RouteParamValueRaw,
        }})
    }

    @Hook
    public async beforeRouteUpdate(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        if(await this.guardExit()) {
            next();
            // In case we're just switching submit context, apply the selection
            this.applySelection();
        } else {
            // Stay here, make sure selector is reverted back to what's current
            this.revertSelection();
            next(false);
        }
    }

    @Hook
    public async beforeRouteLeave (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        if(await this.guardExit()) {
            next();
            // In case we're just switching submit context, apply the selection
            this.applySelection();
        } else {
            // Stay here, make sure selector is reverted back to what's current
            this.revertSelection();
            next(false);
        }
    }


    public async guardExit(): Promise<boolean> {
        if(this.submission?.inProgress) {
            let resp = await this.$confirm({ 
                message: "Are you sure you want to leave? You have a submission in progress", 
                confirmAlias: "Yes", 
                cancelAlias: "No, stay here"
            });

            return !!resp;
        }

        return true;
    }

    getParam(name: string) : string | null {
        let val = this.$route.params[name];
        if(typeof val == "string") {
            return val;
        } else if (Array.isArray(val)) {
            return val[0];
        } else { 
            return null;
        }
    }
}
</script>
<style lang="scss" scoped>
.collapsible-title {
    display: inline-block;
    user-select: none;
    cursor: pointer;

    .title, .subtitle {
        transition: padding-bottom .25s;
        transition: margin-bottom .25s;
    }

    .subtitle {
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
        text-transform: none;
        letter-spacing: unset;
    }

    &.collapsible-closed {
        .subtitle {
            display: block;
        }
    }

    &.collapsible-open {
        .subtitle {
            display: none;
        }
    }
}
</style>